import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class SelectableStore {
  rootStore

  manufacturers: any = []
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setManufacturers = (manufacturers: any) => { this.manufacturers = manufacturers }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setManufacturers([])
    this.setLoading(false)
  }

  async getManufacturers() {
    this.setLoading(true)
    try {
      const response: any = await Api.getManufacturers()
      if (response?.ok) {
        const data = response?.data || []
        const manufacturers = data?.items || []
        this.setManufacturers(manufacturers)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createManufacturer(manufacturer: any, callback: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createManufacturer(manufacturer)
      if (response?.ok) {
        const manufacturers = response?.data?.items || []
        this.setManufacturers(manufacturers)
        showMessage(i18n.t('manufacturer_created'))
        callback()
      } else {
        showMessage('manufacturer_create_failed')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateManufacturer(uuid: string, manufacturer: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateManufacturer(uuid, manufacturer)
      if (response?.ok) {
        const manufacturers = response?.data?.items || []
        this.setManufacturers(manufacturers)
        showMessage(i18n.t('manufacturer_updated'))
      } else {
        showMessage('manufacturer_update_failed')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveManufacturers(uuids: string[], callback: any) {
    this.setLoading(true)
    try {
      for (const uuid of uuids) {
        await Api.archiveManufacturer(uuid)
      }
      this.getManufacturers()
      showMessage(i18n.t('manufacturers_archived'))
      callback()
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
