import { useMemo, useState, useEffect, useCallback, MouseEvent, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import { useDropzone } from 'react-dropzone'
import Lottie from 'lottie-react'
import upperFirst from 'lodash/upperFirst'
import uniq from 'lodash/uniq'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
// import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import Backdrop from '@mui/material/Backdrop'
import ButtonBase from '@mui/material/ButtonBase'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Table from '../../../Components/Common/Table'
import Input from '../../../Components/Common/Input'
import Select from '../../../Components/Common/Select'
import IconButton from '../../../Components/Common/IconButton'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import ArchiveGridFolder from '../../../Components/Common/ArchiveGridFolder'
import ArchiveGridFile from '../../../Components/Common/ArchiveGridFile'
import Button from '../../../Components/Common/Button'
import Lightbox from '../../../Components/Common/Lightbox'
import Chip from '../../../Components/Common/Chip'
import LogItem from '../../../Components/Common/LogItem'
import { SelectionWrapper } from '../../../Components/Common/SelectionWrapper'
// import PdfViewer from '../../../Components/Common/PdfViewer'
import {
  GridColDef,
  GridRenderCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid'
import FolderImage from '../../../Assets/Images/folder.png'
import ImageIcon from '../../../Assets/Images/img-2.png'
import PdfIcon from '../../../Assets/Images/pdf-2.png'
import DocIcon from '../../../Assets/Images/doc-2.png'
import ExcelIcon from '../../../Assets/Images/xls-2.png'
import PowerpointIcon from '../../../Assets/Images/ppt-2.png'
import TextIcon from '../../../Assets/Images/txt-2.png'
import UploadAnimation from '../../../Assets/Animations/upload.json'
import { formatFileSize, formatFileType } from '../../../Utils/transformers'
import { FileTypes } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'

const Archive = () => {
  const { sessionStore, archiveStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const { me } = sessionStore
  const { loading, path, item, downloading, uploading, deleting, progress } = archiveStore

  const [pathName, setPathName] = useState('')

  // Data manipulation and UI state
  const [searchKey, setSearchKey] = useState('')
  const [isGridView, setIsGridView] = useState(true)
  const [propertyType, setPropertyType] = useState('all_properties')
  const [sortOrder, setSortOrder] = useState('name_asc')
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [selectedImage, setSelectedImage] = useState('')

  const clearSelectedImage = () => setSelectedImage('')

  // PDF viewer state
  /*
  const [showPdfViewer, setShowPdfViewer] = useState(false)

  const openPdfViewer = (item: any) => {
    setShowPdfViewer(true)
    archiveStore.downloadPropertyPdfForViewer(params?.pUuid, item?.uuid)
  }
  const closePdfViewer = () => setShowPdfViewer(false)
  */

  // Three-dot menu state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  // Confirmation dialog (archive)
  const [actionType, setActionType] = useState('')
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)

  // Modal (edit, info, log)
  const [modalType, setModalType] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [fileName, setFileName] = useState('')
  // const [fileTags, setFileTags] = useState([])

  const toggleGridView = () => setIsGridView(!isGridView)
  const clearSearchKey = () => setSearchKey('')

  const openArchiveConfirmation = () => {
    setActionType('archive')
    setShowActionConfirmation(true)
  }
  const openReplaceFilesConfirmation = () => {
    setActionType('upload_replace_files')
    setShowActionConfirmation(true)
  }
  const openSetAsBackgroundImageConfirmation = () => {
    setActionType('set_as_background_image')
    setShowActionConfirmation(true)
  }
  const closeActionConfirmation = () => {
    setActionType('')
    setShowActionConfirmation(false)
  }
  const closeUploadConfirmation = () => {
    setActionType('')
    setShowActionConfirmation(false)
    archiveStore.clearProgress()
  }
  const openEditModal = () => {
    setModalType('edit')
    const selectedItem = items?.find((item: any) => item?.uuid === selectedItems[0])
    archiveStore.setItem(selectedItem)
    setFileName(selectedItem?.name || selectedItem?.originalName)
    setShowModal(true)
  }
  const openInfoModal = () => {
    setModalType('info')
    const selectedItem = items?.find((item: any) => item?.uuid === selectedItems[0])
    archiveStore.setItem(selectedItem)
    archiveStore.getFileInfo(params?.pUuid, selectedItem?.uuid)
    setShowModal(true)
  }
  const openLogModal = () => {
    setModalType('log')
    const selectedItem = items?.find((item: any) => item?.uuid === selectedItems[0])
    archiveStore.setItem(selectedItem)
    archiveStore.getFileLogs(params?.pUuid, selectedItem?.uuid)
    setShowModal(true)
  }
  const closeModal = () => {
    setModalType('')
    archiveStore.setItem(null)
    setShowModal(false)
  }

  useEffect(() => {
    if (!pathName) {
      return
    }

    if (!params?.pUuid || params?.pUuid?.length !== 36) {
      archiveStore.getArchiveProperties()
    } else {
      const pUuid = params?.pUuid
      const uuid = params?.uuid
      const type = params?.type
      const subtype = params?.subtype
      if (type === 'buildings') {
        if (uuid) {
          if (subtype && subtype === 'equipment') {
            if (params?.sUuid) {
              archiveStore.getArchiveBuildingEquipmentFolder(pUuid, uuid, params?.sUuid)
            } else {
              archiveStore.getArchiveBuildingEquipmentFolders(pUuid, uuid)
            }
          } else if (subtype && subtype === 'systems') {
            if (params?.sUuid) {
              archiveStore.getArchiveBuildingSystemFolder(pUuid, uuid, params?.sUuid)
            } else {
              archiveStore.getArchiveBuildingSystemFolders(pUuid, uuid)
            }
          } else {
            archiveStore.getArchiveBuildingFolder(pUuid, uuid)
          }
        } else {
          archiveStore.getArchiveBuildings(pUuid)
        }
      } else if (type === 'apartments') {
        if (uuid) {
          archiveStore.getArchiveApartmentFolder(pUuid, uuid)
        } else {
          archiveStore.getArchiveApartments(pUuid)
        }
      } else if (type === 'projects') {
        if (uuid) {
          archiveStore.getArchiveProjectFolder(pUuid, uuid)
        } else {
          archiveStore.getArchiveProjects(pUuid)
        }
      } else {
        if (type === 'files') {
          archiveStore.getArchivePropertyFolder(pUuid)
        } else {
          archiveStore.getArchivePropertyFolders(pUuid)
        }
      }
    }
  }, [pathName])

  useEffect(() => {
    if (pathName !== location.pathname) {
      setPathName(location.pathname)
    }
  }, [params])

  const PropertyTypeOptions = useMemo(() => [
    { value: 'all_properties', label: t('all_properties') },
    { value: 'own_properties', label: t('only_own_properties') },
  ], [])

  const SortOrderOptions = useMemo(() => {
    if (!params?.uuid && params?.type !== 'files') {
      return [
        { value: 'name_asc', label: t('name_asc') },
        { value: 'name_desc', label: t('name_desc') },
        { value: 'updatedAt_desc', label: t('updated_at_desc') },
        { value: 'updatedAt_asc', label: t('updated_at_asc') },
        { value: 'createdAt_desc', label: t('created_at_desc') },
        { value: 'createdAt_asc', label: t('created_at_asc') },
        { value: 'fileCount_desc', label: t('file_count_desc') },
        { value: 'fileCount_asc', label: t('file_count_asc') },
      ]
    }
    return [
      { value: 'name_asc', label: t('name_asc') },
      { value: 'name_desc', label: t('name_desc') },
      { value: 'updatedAt_desc', label: t('updated_at_desc') },
      { value: 'updatedAt_asc', label: t('updated_at_asc') },
      { value: 'createdAt_desc', label: t('created_at_desc') },
      { value: 'createdAt_asc', label: t('created_at_asc') },
    ]
  }, [params])

  const IsUploadVisible = useMemo(() => {
    if (params?.subtype && !params?.sUuid) {
      return false
    }
    if (params?.subtype === 'systems') {
      return false
    }
    return params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)
  }, [params])
  const IsEditVisible = useMemo(() => {
    if (params?.subtype && !params?.sUuid) {
      return false
    }
    if (params?.subtype === 'systems') {
      return false
    }
    return params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)
  }, [params])
  const IsArchiveVisible = useMemo(() => {
    if (params?.subtype && !params?.sUuid) {
      return false
    }
    if (params?.subtype === 'systems') {
      return false
    }
    return params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)
  }, [params])
  const IsSetAsBackgroundImageVisible = useMemo(() => {
    if (params?.subtype) {
      return false
    }
    return params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)
  }, [params])
  const IsMenuVisible = useMemo(() => {
    return false
  }, [params])
  const IsInfoVisible = useMemo(() => {
    if (params?.subtype && !params?.sUuid) {
      return false
    }
    return params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)
  }, [params])
  const IsLogVisible = useMemo(() => {
    if (params?.subtype && !params?.sUuid) {
      return false
    }
    return params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)
  }, [params])
  const IsDownloadDisabled = useMemo(() => {
    if (params?.type === 'files' || (params?.uuid && params?.uuid?.length === 36)) {
      return !selectedItems?.length
    }
    return !selectedItems?.some((item: any) => {
      return archiveStore.items?.find((i: any) => (i?.uuid || i?.path) === item)?.files?.length
    })
  }, [selectedItems])
  const IsEditDisabled = useMemo(() => {
    if (!selectedItems?.length || selectedItems?.length > 1) {
      return true
    }
    const file = archiveStore.items?.find((item: any) => item?.uuid === selectedItems[0])
    if (!file?.originalSize && !file?.size) {
      return true
    }
    return false
  }, [selectedItems])
  const IsArchiveDisabled = useMemo(() => {
    if (!selectedItems?.length) {
      return true
    }

    // Can't archive folders
    if (selectedItems?.some((item: any) => !archiveStore.items?.find((i: any) => i?.uuid === item)?.mimeType)) {
      return true
    }

    return false
  }, [selectedItems])
  const IsSetAsBackgroundImageDisabled = useMemo(() => {
    if (selectedItems?.length === 1) {
      const file = archiveStore.items?.find((item: any) => item?.uuid === selectedItems[0])
      if (file?.mimeType?.includes('image')) {
        return false
      }
    }
    return true
  }, [selectedItems])
  const IsMenuDisabled = useMemo(() => !selectedItems?.length, [selectedItems])
  const IsInfoDisabled = useMemo(() => {
    if (!selectedItems?.length || selectedItems?.length > 1) {
      return true
    }
    const file = archiveStore.items?.find((item: any) => item?.uuid === selectedItems[0])
    if (!file?.originalSize && !file?.size) {
      return true
    }
    return false
  }, [selectedItems])
  const IsLogDisabled = useMemo(() => {
    if (!selectedItems?.length || selectedItems?.length > 1) {
      return true
    }
    const file = archiveStore.items?.find((item: any) => item?.uuid === selectedItems[0])
    if (!file?.originalSize && !file?.size) {
      return true
    }
    return false
  }, [selectedItems])

  const getIcon = (mimeType: string) => {
    if (FileTypes.Image.includes(mimeType)) {
      return ImageIcon
    } else if (mimeType === FileTypes.Pdf) {
      return PdfIcon
    } else if (FileTypes.Doc.includes(mimeType)) {
      return DocIcon
    } else if (FileTypes.Excel.includes(mimeType)) {
      return ExcelIcon
    } else if (FileTypes.Powerpoint.includes(mimeType)) {
      return PowerpointIcon
    } else if (mimeType === FileTypes.Text) {
      return TextIcon
    }
    return PdfIcon
  }

  const toPath = (path: string) => {
    setPathName(path)
    navigate(path)
  }

  const onOpen = (uuid: string, item?: any) => {
    if (uuid) {
      if (item?.mimeType?.includes('image')) {
        return setSelectedImage(item?.url)
      } else if (item?.size || item?.originalSize) {
        /*
        if (item?.mimeType === 'application/pdf') {
          return openPdfViewer(item)
        }
        */
        // Inferred as non-image / non-pdf file so we have no action to take currently
        return
      }
      const pUuid = params?.pUuid
      const type = params?.type || uuid

      if (type === 'buildings') {
        if (uuid && uuid !== 'buildings') {
          if (item?.path === 'equipment' || params?.subtype === 'equipment') {
            if (params?.subtype === 'equipment') {
              toPath(`/archive/properties/${pUuid}/buildings/${params?.uuid}/equipment/${uuid}`)
            } else {
              toPath(`/archive/properties/${pUuid}/buildings/${uuid}`)
            }
          } else if (item?.path === 'systems' || params?.subtype === 'systems') {
            if (params?.subtype === 'systems') {
              toPath(`/archive/properties/${pUuid}/buildings/${params?.uuid}/systems/${uuid?.replaceAll('_', '-')}`)
            } else {
              toPath(`/archive/properties/${pUuid}/buildings/${uuid}`)
            }
          } else {
           toPath(`/archive/properties/${pUuid}/buildings/${uuid}`)
          }
        } else {
          toPath(`/archive/properties/${pUuid}/buildings`)
        }
      } else if (type === 'apartments') {
        if (uuid && uuid !== 'apartments') {
          toPath(`/archive/properties/${pUuid}/apartments/${uuid}`)
        } else {
          toPath(`/archive/properties/${pUuid}/apartments`)
        }
      } else if (type === 'projects') {
        if (uuid && uuid !== 'projects') {
          toPath(`/archive/properties/${pUuid}/projects/${uuid}`)
        } else {
          toPath(`/archive/properties/${pUuid}/projects`)
        }
      } else if (pUuid) {
        toPath(`/archive/properties/${pUuid}/files`)
      } else {
        toPath(`/archive/properties/${uuid}`)
      }
      setSelectedItems([])
    }
  }

  const onVerifyIfFilesExist = useCallback((files: any) => {
    if (files?.length) {
      const pUuid = params?.pUuid
      const uuid = params?.uuid
      if (pUuid && pUuid?.length === 36) {
        const type = params?.type
        const callback = () => openReplaceFilesConfirmation()
        if (type === 'buildings') {
          if (params?.subtype === 'equipment' && params?.sUuid) {
            archiveStore.setPendingItems(files)
            archiveStore.uploadBuildingEquipmentFiles(pUuid, uuid, params?.sUuid)
          } else {
            archiveStore.checkIfBuildingFilesExist(
              pUuid,
              uuid,
              files,
              callback
            )
          }
        } else if (type === 'apartments') {
          archiveStore.checkIfApartmentFilesExist(
            pUuid,
            uuid,
            files,
            callback
          )
        } else if (type === 'projects') {
          archiveStore.checkIfProjectFilesExist(
            pUuid,
            uuid,
            files,
            callback
          )
        } else if (type === 'files') {
          archiveStore.checkIfPropertyFilesExist(
            pUuid,
            files,
            callback
          )
        }
      }
    }
  }, [params])

  const onUpload = (shouldReplace: boolean = false) => {
    if (archiveStore.pendingItems?.length) {
      const pUuid = params?.pUuid
      if (pUuid && pUuid?.length === 36) {
        closeActionConfirmation()
        const uuid = params?.uuid
        const type = params?.type
        if (type === 'buildings') {
          if (params?.subtype === 'equipment' && params?.sUuid) {
            archiveStore.uploadBuildingEquipmentFiles(pUuid, uuid, params?.sUuid)
          } else {
            archiveStore.uploadBuildingFiles(pUuid, uuid, shouldReplace)
          }
        } else if (type === 'apartments') {
          archiveStore.uploadApartmentFiles(pUuid, uuid, shouldReplace)
        } else if (type === 'projects') {
          archiveStore.uploadProjectFiles(pUuid, uuid, shouldReplace)
        } else if (type === 'files') {
          archiveStore.uploadPropertyFiles(pUuid, shouldReplace)
        }
      }
    }
  }

  const buttonDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'text/plain': ['.txt']
    },
    multiple: true,
    noDrag: true,
    noClick: false,
    noKeyboard: true,
    onDrop: onVerifyIfFilesExist
  })

  const areaDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'text/plain': ['.txt']
    },
    multiple: true,
    noDrag: false,
    noClick: true,
    noKeyboard: true,
    onDrop: onVerifyIfFilesExist
  })

  const onDownload = () => {
    if (selectedItems?.length) {
      if (!params?.pUuid || params?.pUuid?.length !== 36) {
        archiveStore.downloadPropertyFolders(selectedItems)
      } else {
        const pUuid = params?.pUuid
        const uuid = params?.uuid
        const type = params?.type
        if (type === 'buildings') {
          if (uuid) {
            archiveStore.downloadBuildingFiles(pUuid, uuid, selectedItems)
          } else {
            archiveStore.downloadBuildingFolders(pUuid, selectedItems)
          }
        } else if (type === 'apartments') {
          if (uuid) {
            archiveStore.downloadApartmentFiles(pUuid, uuid, selectedItems)
          } else {
            archiveStore.downloadApartmentFolders(pUuid, selectedItems)
          }
        } else if (type === 'projects') {
          if (uuid) {
            archiveStore.downloadProjectFiles(pUuid, uuid, selectedItems)
          } else {
            archiveStore.downloadProjectFolders(pUuid, selectedItems)
          }
        } else {
          if (type === 'files') {
            archiveStore.downloadPropertyFiles(pUuid, selectedItems)
          } else {
            archiveStore.downloadPropertySubFolders(pUuid, selectedItems)
          }
        }
      }
    }
  }

  const onArchive = () => {
    const pUuid = params?.pUuid
    const uuid = params?.uuid
    if (pUuid && pUuid?.length === 36 && selectedItems?.length) {
      const type = params?.type
      if (type === 'buildings') {
        archiveStore.archiveBuildingFiles(
          pUuid,
          uuid,
          selectedItems,
          closeActionConfirmation,
          params?.sUuid
        )
      } else if (type === 'apartments') {
        archiveStore.archiveApartmentFiles(pUuid, uuid, selectedItems, closeActionConfirmation)
      } else if (type === 'projects') {
        archiveStore.archiveProjectFiles(pUuid, uuid, selectedItems, closeActionConfirmation)
      } else if (type === 'files') {
        archiveStore.archivePropertyFiles(pUuid, selectedItems, closeActionConfirmation)
      }
    }
  }

  const onSetAsBackgroundImage = () => {
    const pUuid = params?.pUuid
    const uuid = params?.uuid
    if (pUuid && pUuid?.length === 36 && selectedItems?.length) {
      const type = params?.type
      const file = selectedItems[0]
      if (type === 'buildings') {
        archiveStore.updateBuildingBackgroundPhoto(pUuid, uuid, file, closeActionConfirmation)
      } else if (type === 'apartments') {
        archiveStore.updateApartmentBackgroundPhoto(pUuid, uuid, file, closeActionConfirmation)
      } else if (type === 'projects') {
        archiveStore.updateProjectBackgroundPhoto(pUuid, uuid, file, closeActionConfirmation)
      } else if (type === 'files') {
        archiveStore.updatePropertyBackgroundPhoto(pUuid, file, closeActionConfirmation)
      }
    }
  }

  const onSaveFileChanges = () => {
    const pUuid = params?.pUuid
    if (pUuid && pUuid?.length === 36) {
      const uuid = params?.uuid?.length === 36 ? params?.uuid : null
      const type = params?.type
      const payload: any = { name: fileName }
      if (type === 'buildings' && uuid) {
        if (params?.subtype === 'equipment') {
          payload.eUuid = params?.sUuid
        }
        archiveStore.updateBuildingFile(pUuid, uuid, item?.uuid, payload)
      } else if (type === 'apartments' && uuid) {
        archiveStore.updateApartmentFile(pUuid, uuid, item?.uuid, payload)
      } else if (type === 'projects' && uuid) {
        archiveStore.updateProjectFile(pUuid, uuid, item.uuid, payload)
      } else if (type === 'files') {
        archiveStore.updatePropertyFile(pUuid, item.uuid, payload)
      }
    }
  }

  const getRowId = (row: any) => (row?.uuid && row?.path) ?
    `${row?.uuid}-${row?.path}` :
    row?.uuid || row?.nameFi || row?.path || row?.name || row?.originalName || ''

  const FolderColumns: GridColDef[] = useMemo(() => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    {
      field: 'nameFi',
      headerName: t('name'),
      flex: 1,
      // maxWidth: 700,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box position='relative'>
          {(params.row?.mimeType && params.row?.originalSize) ? (
            <>
              <Box 
                component='img' 
                src={getIcon(params.row?.mimeType)} 
                sx={styles.tableImage}
                draggable={false}
              />
              <Button
                sx={styles.name}
                text={(params.row?.name || params.row?.originalName || '')?.trim()}
                onClick={() => onOpen(params.row?.uuid || params.row?.path || '', params.row)}
                variant='text'
              />
            </>
          ) : (
          <>
            <Box 
              component='img' 
              src={FolderImage} 
              sx={styles.tableImage}
              draggable={false}
            />
            <Button
              sx={styles.name}
              text={(params.row?.nameFi || '')?.trim()}
              onClick={() => onOpen(params.row?.uuid || params.row?.path || '', params.row)}
              variant='text'
            />
          </>
          )}
        </Box>
      )
    },
    {
      field: 'files',
      headerName: t('file_count'),
      flex: 1,
      // maxWidth: 170,
      maxWidth: 220,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      valueGetter: (value, row, column, apiRef) => !row?.originalSize ? row?.files?.length || 0 : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 260,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'createdAt',
      headerName: t('created_at'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 260,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
  ], [params])

  const FileColumns: GridColDef[] = useMemo(() => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      // maxWidth: 700,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box position='relative'>
          {(!params.row?.mimeType && !params.row?.originalSize) ? (
            <>
              <Box 
                component='img' 
                src={FolderImage} 
                sx={styles.tableImage}
                draggable={false}
              />
              <Button
                sx={styles.name}
                text={(params.row?.nameFi || '')?.trim()}
                onClick={() => onOpen(params.row?.uuid || params.row?.path || '', params.row)}
                variant='text'
              />
            </>
          ) : (
            <>
              <Box 
                component='img' 
                src={getIcon(params.row?.mimeType)} 
                sx={styles.tableImage}
                draggable={false}
              />
              <Button
                sx={styles.name}
                text={(params.row?.name || params.row?.originalName || '')?.trim()}
                onClick={() => onOpen(params.row?.uuid || params.row?.path || '', params.row)}
                variant='text'
              />
            </>
          )}
        </Box>
      )
    },
    {
      field: 'mimeType',
      headerName: t('file_type'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 130,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => formatFileType(value)
    },
    /*
    {
      field: 'size',
      headerName: t('size'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 150,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: number) => formatFileSize(value)
    },
    */
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 240,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'createdAt',
      headerName: t('created_at'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 240,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
  ], [params])

  const items = useMemo(() => {
    let allItems = archiveStore.items
    if (propertyType === 'own_properties' && !params?.pUuid) {
      allItems = allItems.filter((item: any) => {
        return item?.admins?.find((admin: any) => admin === me?.uuid)
      })
    }
    if (searchKey) {
      const sKey = searchKey.toLowerCase()
      allItems = allItems.filter((item: any) => {
        const name = item?.nameFi || item?.name || item?.originalName
        return name?.toLowerCase().includes(sKey)
      })
    }

    const sortVals = sortOrder?.split('_')
    const sortKey = sortVals?.[0] || 'name'
    const sortValue = sortVals?.[1] || 'asc'

    allItems = [...allItems].sort((a: any, b: any) => {
      if (sortKey === 'name') {
        const nameA = a?.nameFi || a?.name || a?.originalName
        const nameB = b?.nameFi || b?.name || b?.originalName
        return sortValue === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
      }
      if (sortKey === 'updatedAt') {
        const dateA = DateTime.fromISO(a?.updatedAt)
        const dateB = DateTime.fromISO(b?.updatedAt)
        return sortValue === 'asc' ?
          dateA.toMillis() - dateB.toMillis() :
          dateB.toMillis() - dateA.toMillis()
      }
      if (sortKey === 'createdAt') {
        const dateA = DateTime.fromISO(a?.createdAt)
        const dateB = DateTime.fromISO(b?.createdAt)
        return sortValue === 'asc' ?
          dateA.toMillis() - dateB.toMillis() :
          dateB.toMillis() - dateA.toMillis()
      }
      if (sortKey === 'fileCount') {
        return sortValue === 'asc' ?
          a?.files?.length - b?.files?.length :
          b?.files?.length - a?.files?.length
      }
      return 0
    })

    return allItems
  }, [archiveStore.items, searchKey, propertyType, sortOrder])

  const renderSecondaryTitle = () => {
    if (params?.pUuid && params.pUuid.length === 36 && path?.length) {
      return (
        <Box sx={styles.breadcrumbs}>
          <ButtonBase
            onClick={() => toPath('/archive')}
            sx={styles.crumb}
            disableRipple
          >
            {t('archives')}
          </ButtonBase>
          <Typography variant='body1' sx={styles.arrow}>{'>'}</Typography>
          {path.map((crumb: any, index: number) => (
            <Fragment key={crumb.path}>
              <ButtonBase
                onClick={() => toPath(crumb.path)}
                sx={index < path.length - 1 ? styles.crumb : styles.activeCrumb}
                disableRipple
              >
                {crumb?.nameFi || ''}
              </ButtonBase>
              {index < path.length - 1 && <Typography variant='body1' sx={styles.arrow}>{'>'}</Typography>}
            </Fragment>
          ))}
        </Box>
      )
    }
    return (
      <Typography variant='subtitle1' sx={styles.subtitle}>
        {t('order_documents')}:
      </Typography>
    )
  }

  const renderGridItems = () => {
    if (items?.length) {
      return items.map((item: any) => {
        return !(item?.size || item?.originalSize) ? (
          <ArchiveGridFolder
            key={`${item?.uuid}-${item?.path || item?.nameFi}` || item?.nameFi}
            item={item}
            selected={selectedItems.includes(item?.uuid || item?.nameFi)}
            multiRow={!params?.pUuid}
          />
        ) : (
          <ArchiveGridFile
            key={item?.uuid || item?.nameFi}
            item={item}
            selected={selectedItems.includes(item?.uuid || item?.nameFi)}
          />
        )
      })
    }
    if (!params?.pUuid) {
      return (
        <Box sx={styles.noFilesContainer}>
          <Typography variant='body1' sx={styles.noFolders}>{t('no_folders')}</Typography>
        </Box>
      )
    }
    return (
      <Box sx={styles.noFilesContainer}>
        <Lottie
          animationData={UploadAnimation}
          style={styles.uploadAnimation}
        />
        <Typography variant='body1' sx={styles.noFiles}>{t('no_files')}</Typography>
      </Box>
    )
  }

  const renderContent = () => {
    if (loading && !items?.length) {
      return <CircularProgress sx={styles.loading} />
    }

    if (isGridView) {
      if (params?.type === 'files' || params?.uuid && params?.uuid?.length === 36) {
        return (
          <Box width='100%' {...areaDrop.getRootProps()}>
            <SelectionWrapper
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onOpen={onOpen}
            >
              <input {...areaDrop.getInputProps()} />
              {renderGridItems()}
            </SelectionWrapper>
          </Box>
        )
      }
      return (
        <SelectionWrapper
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          onOpen={onOpen}
        >
          {renderGridItems()}
        </SelectionWrapper>
      )
    }

    return (
      <Box sx={styles.tableContainer}>
        <Table
          rows={items}
          columns={(items?.[0]?.size || items?.[0]?.originalSize) ? FileColumns : FolderColumns}
          getRowId={getRowId}
          rowSelectionModel={selectedItems}
          onSelectionChange={setSelectedItems}
        />
      </Box>
    )
  }

  const renderLightbox = () => {
    if (selectedImage) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            items
              .filter((file: any) => file?.mimeType?.includes('image'))
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            items
              .filter((file: any) => file?.mimeType?.includes('image'))
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  /*
  const renderPdfViewer = () => {
    if (showPdfViewer) {
      return (
        <PdfViewer
          open={showPdfViewer}
          onClose={closePdfViewer}
          pdfUrl={archiveStore.pdfUrl}
        />
      )
    }
    return null
  }
  */

  const renderDataModalContent = () => {
    if (showModal) {
      if (modalType === 'edit') {
        return (
          <>
            <Input
              label={t('name')}
              value={fileName}
              onChange={setFileName}
              mb='1.25rem'
            />
            <Box sx={styles.modalActions}>
              <Button
                text={t('save')}
                onClick={onSaveFileChanges}
                width='10rem'
                height='3.125rem'
                fontSize='1rem'
                disabled={!fileName}
              />
            </Box>
          </>
        )
      }
      if (modalType === 'info') {
        return (
          <>
            <Box sx={styles.infoRow} mb='1rem'>
              <Box sx={{ ...styles.infoColumn, width: '100%' }}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('file_name')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {item?.name || item?.originalName}
                </Typography>
              </Box>
            </Box>
            <Divider sx={styles.infoDivider} />
            <Box sx={styles.infoRow} mb='1rem'>
              <Box sx={styles.infoColumn}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('last_edited_at')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {`${upperFirst(DateTime.fromISO(item?.updatedAt).setLocale('fi').toFormat('cccc'))} `}
                  {DateTime.fromISO(item?.updatedAt).toFormat('dd.MM.yyyy')}
                  {` klo `}
                  {DateTime.fromISO(item?.updatedAt).toFormat('HH.mm')}
                </Typography>
              </Box>
              <Box sx={styles.infoColumn}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('file_edited_by')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {item?.updaterUser ? `${item?.updaterUser?.firstName} ${item?.updaterUser?.lastName}` : '-'}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.infoRow} mb='1rem'>
              <Box sx={styles.infoColumn}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('file_created_at')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {`${upperFirst(DateTime.fromISO(item?.createdAt).setLocale('fi').toFormat('cccc'))} `}
                  {DateTime.fromISO(item?.createdAt).toFormat('dd.MM.yyyy')}
                  {` klo `}
                  {DateTime.fromISO(item?.createdAt).toFormat('HH.mm')}
                </Typography>
              </Box>
              <Box sx={styles.infoColumn}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('file_created_by')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {item?.creatorUser ? `${item?.creatorUser?.firstName} ${item?.creatorUser?.lastName}` : '-'}
                </Typography>
              </Box>
            </Box>
            <Divider sx={styles.infoDivider} />
            <Box sx={styles.infoRow}>
              <Box sx={styles.infoColumn}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('file_ext')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {formatFileType(item?.mimeType)}
                </Typography>
              </Box>
              <Box sx={styles.infoColumn}>
                <Typography variant='body1' sx={styles.infoLabel}>
                  {t('file_size')}
                </Typography>
                <Typography variant='body1' color={Colors.text}>
                  {formatFileSize(item?.size)}
                </Typography>
              </Box>
            </Box>
            {IsSetAsBackgroundImageVisible && !IsSetAsBackgroundImageDisabled && (
              <Button
                text={t('set_as_background_image')}
                onClick={openSetAsBackgroundImageConfirmation}
                variant='secondary'
                sx={styles.setAsBackgroundImageButton}
                disabled={IsSetAsBackgroundImageDisabled}
              />
            )}
          </>
        )
      }
      if (modalType === 'log') {
        const logs = archiveStore.logs || []
        if (!logs?.length && !archiveStore.loading) {
          return <Typography variant='body1' sx={styles.noLogs}>{t('no_logs')}</Typography>
        }
        return archiveStore.logs.map((item: any) => (
          <LogItem
            key={item.uuid || item?.createdAt}
            log={item}
          />
        ))
      }
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      const multipleFiles = selectedItems?.length > 1
      const filesText = multipleFiles ? 'files' : 'file'
      if (actionType === 'archive') {
        return (
          <ConfirmationDialog
            title={t(`archive_${filesText}_title`)}
            content={t(`archive_${filesText}_info`, { count: selectedItems?.length })}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={onArchive}
            actionText={t('delete')}
          />
        )
      } else if (actionType === 'upload_replace_files') {
        const names = uniq(
          archiveStore?.foundItems
            ?.map((file: any) => file?.name || file?.originalName)
        )
        const multipleFiles = names?.length > 1
        const filesText = multipleFiles ? 'files' : 'file'
        return (
          <ConfirmationDialog
            title={t(`replace_${filesText}_title`)}
            content={t(`replace_${filesText}_info`)}
            list={names}
            onClose={closeUploadConfirmation}
            onCancel={() => onUpload(false)}
            cancelText={multipleFiles ? t('keep_all') : t('keep_both')}
            cancelButtonWidth='15rem'
            onAction={() => onUpload(true)}
            actionText={t(`replace_${filesText}`)}
            actionButtonWidth='14.5rem'
            width='36rem'
            buttonFontSize='1rem'
            chipClose
          />
        )
      } else if (actionType === 'set_as_background_image') {
        return (
          <ConfirmationDialog
            title={t('set_as_background_image_title')}
            content={t('set_as_background_image_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={onSetAsBackgroundImage}
            actionText={t('set')}
          />
        )
      }
    }
    return null
  }

  const renderProgressNotification = () => {
    if (downloading || uploading || deleting) {
      const filesText = selectedItems?.length > 1 ? 'files' : 'file'
      return (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={archiveStore.clearProgress}
          open
        >
          <Card sx={styles.progressCard} elevation={3}>
            <Stack direction='row' spacing='0.875rem' alignItems='center'>
              <Button
                text={t('close')}
                onClick={archiveStore.clearProgress}
                sx={styles.closeProgressButton}
              />
              <Divider orientation='vertical' flexItem />
              <Stack flexGrow={1}>
                <Typography color={Colors.text} variant='subtitle2'>
                  {
                    t(
                      downloading ?
                      `downloading_${filesText}` :
                      uploading ?
                      `uploading_${filesText}` :
                      deleting ?
                      `deleting_${filesText}` :
                      ''
                      )
                  }
                </Typography>           
                <LinearProgress 
                  variant='determinate'
                  value={progress}
                  sx={styles.progress}
                />
                <Typography variant='caption' sx={styles.progressText}>
                  {Math.round(progress)}% {t('completed')}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Snackbar>
      )
    }
    return null
  }

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.actions}>
          <Input
            placeholder={t('search_by_name')}
            value={searchKey}
            onChange={setSearchKey}
            onAction={clearSearchKey}
            iconSx={styles.clearIcon}
            sx={styles.searchInputContainer}
            inputSx={styles.searchInput}
            search
          />
          <Box sx={styles.actionsRow}>
            {IsUploadVisible && (
              <Box {...buttonDrop.getRootProps()}>
                <input {...buttonDrop.getInputProps()} />
                <IconButton
                  icon='upload2'
                  onClick={onUpload}
                  iconSize='1.25rem'
                  mr='0.75rem'
                  transparentBg
                />
              </Box>
            )}
            <IconButton
              icon='download2'
              onClick={onDownload}
              iconSize='1.25rem'
              mr='0.75rem'
              disabled={IsDownloadDisabled}
              transparentBg
            />
            {IsEditVisible && (
              <IconButton
                icon='edit2'
                onClick={openEditModal}
                iconSize='1.25rem'
                mr='0.75rem'
                disabled={IsEditDisabled}
                transparentBg
              />
            )}
            {IsArchiveVisible && (
              <IconButton
                icon='binAlt3'
                onClick={openArchiveConfirmation}
                iconSize='1.25rem'
                mr='0.75rem'
                disabled={IsArchiveDisabled}
                transparentBg
              />
            )}
            {IsSetAsBackgroundImageVisible && (
              <IconButton
                icon='add2'
                onClick={openSetAsBackgroundImageConfirmation}
                iconSize='1.25rem'
                mr='0.75rem'
                disabled={IsSetAsBackgroundImageDisabled}
                transparentBg
              />
            )}
            {IsMenuVisible && (
              <>
                <IconButton
                  icon='menu2'
                  onClick={onMenuClick}
                  iconSize='1.25rem'
                  mr='0.75rem'
                  disabled={IsMenuDisabled}
                  transparentBg
                />
                <Backdrop sx={styles.backdrop} open={isMenuOpen} onClick={onMenuClose}>
                  <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={onMenuClose}
                    // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                  </Menu>
                </Backdrop>
              </>
            )}
            <Divider orientation='vertical' flexItem />
            <IconButton
              icon={isGridView ? 'list2' : 'grid2'}
              onClick={toggleGridView}
              iconSize='1.25rem'
              ml='0.75rem'
              mr={(IsInfoVisible || IsLogVisible) ? '0.75rem' : '0.5rem'}
              transparentBg
            />
            {IsInfoVisible && (
              <IconButton
                icon='info2'
                onClick={openInfoModal}
                iconSize='1.25rem'
                mr='0.75rem'
                disabled={IsInfoDisabled}
                transparentBg
              />
            )}
            {IsLogVisible && (
              <IconButton
                icon='log'
                onClick={openLogModal}
                iconSize='1.25rem'
                mr='0.5rem'
                disabled={IsLogDisabled}
                transparentBg
              />
            )}
          </Box>
        </Box>
        <Box sx={styles.secondaryActions}>
          {renderSecondaryTitle()}
          <Box sx={styles.row}>
            <Fade in={selectedItems?.length > 0}>
              <Typography variant='body1' sx={styles.count}>
                {`${selectedItems?.length || ''} ${selectedItems?.length ? t('selected') : ''}`.trim()}
              </Typography>
            </Fade>
            {!params?.pUuid && (
              <Select
                options={PropertyTypeOptions}
                value={propertyType}
                onChange={setPropertyType}
                mr='1.5rem'
                sx={styles.typeSelect}
                inputSx={styles.selectInput}
                borderless
                disableClearable
              />
            )}
            <Select
              options={SortOrderOptions}
              value={sortOrder}
              onChange={setSortOrder}
              sx={styles.sortSelect}
              inputSx={styles.selectInput}
              borderless
              disableClearable
            />
          </Box>
        </Box>
        {renderContent()}
        {renderLightbox()}
        {/* renderPdfViewer() */}
        {renderProgressNotification()}
        {renderActionConfirmation()}
      </Box>
      <Drawer
        anchor='right'
        open={showModal}
        onClose={closeModal}
      >
        <Box sx={styles.drawerContent}>
          <Box sx={styles.modalContainer}>
            <Box sx={styles.modalHeader}>
              <Typography variant='h3'>
                {modalType === 'log' ? t('logs') : modalType === 'edit' ? t('edit_info') : t('info')}
              </Typography>
              <Chip sx={styles.closeButton} text={t('close')} onClick={closeModal} />
            </Box>
            <Box sx={styles.modalContent}>
              {renderDataModalContent()}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default observer(Archive)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nestedTableContainer: {
    width: '100%',
    mb: '1.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: '1.25rem',
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-between',
    width: '100%',
    mb: '1.25rem',
    border: 0
  },
  secondaryActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    // mb: '1.25rem',
    border: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionsRow: {
    height: '3.125rem',
    fontSize: '1.125rem',
    color: Colors.text,
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderColor: Colors.border,
    padding: '0rem 0.125rem',
    pl: '0.5rem',
    border: `0.0625rem solid ${Colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    ml: '1.5rem'
  },
  searchInputContainer: {
    maxWidth: {
      xs: '100%'
    }
  },
  searchInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  clearIcon: {
    top: '0.675rem',
    right: '1rem',
    width: '1.675rem'
  },
  subtitle: {
    fontWeight: 600,
  },
  count: {
    pr: '1rem',
    mr: '0.25rem',
    borderRight: `0.0625rem solid ${Colors.border}`,
    textTransform: 'lowercase',
  },
  typeSelect: {
    width: '13.5rem',
  },
  sortSelect: {
    width: '16rem',
  },
  selectInput: {
    fontSize: '1rem',
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    gap: '1.5rem',
    mt: '1.5rem'
  },
  tableContainer: {
    width: '100%',
    pt: '1.5rem',
    pb: '10rem'
  },
  drawerContent: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: Colors.white,
    width: {
      xs: '40rem',
    },
  },
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  crumb: {
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 400,
    color: Colors.text,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    userSelect: 'none',
    ':hover': {
      color: Colors.primaryDarkest,
      textDecoration: 'underline',
    }
  },
  activeCrumb: {
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.primaryDarkest,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    userSelect: 'none',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  arrow: {
    fontSize: '0.875rem',
    m: '0rem 0.425rem',
    mt: '0.0625rem',
    userSelect: 'none',
  },
  tableImage: {
    transform: 'translateY(32%)',
    height: '2rem',
    width: 'auto',
    objectFit: 'contain',
    backgroundColor: Colors.transparent,
    pointerEvents: 'none',
  },
  modalContainer: {
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'column',
    alingItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: {
      xs: '1.25rem 1.75rem'
    },
    boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.1)'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 'calc(100% - 5rem)',
    overflow: 'auto',
    padding: {
      xs: '1.5rem 2rem 3rem 2rem'
    },
  },
  closeButton: {
    height: '2rem',
    fontSize: '1rem'
  },
  modalActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  infoRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  infoColumn: {
    width: '50%',
  },
  infoLabel: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.primary,
    mb: '0.0625rem'
  },
  infoValue: {
  },
  noLogs: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.text,
    textAlign: 'center',
    mt: 'calc(50vh - 7.0625rem)'
  },
  infoDivider: {
    width: '100%',
    height: '0.125rem',
    backgroundColor: Colors.secondary10,
    margin: '0.5rem 0rem 1rem 0rem'
  },
  noFilesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  uploadAnimation: {
    width: '20vw',
    height: 'auto',
  },
  noFiles: {
    mt: '-3.5rem',
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.text,
    textAlign: 'center',
    alignSelf: 'center',
  },
  noFolders: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: Colors.text,
    textAlign: 'center',
    alignSelf: 'center',
    mt: 'calc(50vh - 12rem)'
  },
  loading: {
    color: Colors.button,
    position: 'absolute',
    top: '49%',
    alignSelf: 'center',
  },
  progressCard: {
    p: '0.75rem',
    minWidth: '22rem'
  },
  closeProgressButton: {
    height: '2rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    borderRadius: '0.25rem',
    color: Colors.white,
    width: '5rem'
  },
  progress: {
    mt: '0.5rem',
  },
  progressText: {
    mt: '0.25rem'
  },
  setAsBackgroundImageButton: {
    position: 'absolute',
    bottom: '1.75rem',
    height: '3.125rem',
    width: 'calc(100% - 3.5rem)',
    alignSelf: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.text,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  }
} as const
