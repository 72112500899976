import { Subtitle, Title, GroupTitle, SummaryColumn } from './layout'
import i18n from '../Localization'
import { calculateTotalCount, formatNumber } from './transformers'
import { convert } from 'html-to-text'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'

// Used for some translations to find the correct key
export const AltLabels = {
  'bitumen': 'bitumen_board',
  // 'bitumen': 'bitumen_roll',
  'wool': 'wind_barrier_wool',
  'gypsum': 'wind_barrier_gypsum',
  'other': 'other_type',
  'pile': 'pile_foundation',
  'pad': 'pad_foundation',
  'pillar': 'pillar_foundation',
  // 'pillar': 'pillar_structures',
  'rock': 'rock_foundation',
  'natural_stone': 'natural_stone_cladding',
  // 'natural_stone': 'natural_stone_paving',
  'poured_concrete': 'concrete_poured_in_place_alt',
  // 'poured_concrete': 'concrete_poured_in_place',
  'concrete_block': 'concrete_block_pad',
  'aggregate_ventilated': 'aggregate_ventilated_base_floor',
  'wooden_ventilated': 'wooden_ventilated_base_floor',
  'lightweight_concrete': 'lightweight_concrete_alt',
  'concrete_sub_slab_beam': 'concrete_sub_slab_beam_alt',
  'concrete_element': 'concrete_element_alt',
  'mineral_wool': 'mineral_wool_blown',
  'mixed_fill': 'mixed_filling_alt',
  'plinth': 'plinth_ventilation',
  // 'pile': 'pile_ventilation',
  // 'natural_stone': 'natural_stone_ventilation',
  'mass_wall': 'mass_wall_alt',
  'wood': 'wood_built_in_place',
  'no_insulation': 'no_insulation_alt',
  'no_planting': 'has_none',
  // 'wood': 'wood_or_wood_cladding',
  'thin_plaster': 'thin_insulation_plaster',
  'thick_plaster': 'thick_insulation_plaster',
  'ceramic_board': 'ceramic_facade_board',
  'plastic': 'plastic_coating',
  'eaves_gap': 'through_eaves_gaps',
  'gable_gap': 'through_gable_gaps',
  'underpressure_fan': 'through_underpressure_fans',
  'ventilation_valve': 'through_ventilation_valves_at_ends',
  'in_part': 'has_in_part',
  'in': 'balcony_in',
  // 'in': 'terrace_in',
  'out': 'balcony_out',
  'french': 'french_balcony',
  // 'plate': 'balcony_plating',
  'plate': 'plating',
  'covered': 'covered_terrace',
  'uncovered': 'uncovered_terrace',
  'stratum': 'stratums',
  'mass': 'mass_water_proofing',
  'hydronic': 'hydronic_coil',
  'bathroom': 'bathroom_short',
  'utility_room': 'utility_room_short',
  'walk_in_closet': 'walk_in_closet_short',
  'mat': 'mat_filters',
  'cassette': 'cassette_filters',
  'bag': 'bag_filters',
  'window_frame_valve': 'window_frame_valves',
  'extraction_air_valve': 'extraction_air_valves',
  'ventilation_window': 'ventilation_windows',
  'stone': 'stone_material',
  'poppet': 'poppet_valve',
  'extract': 'extract_air_valve',
  'supply': 'supply_air_valve',
  'supply_diffuser': 'supply_air_diffuser',
  'electric': 'electric_heating',
  'reserving_electric': 'reserving_electric_heating',
  'district': 'district_heating',
  'oil': 'oil_heating',
  // 'wood': 'wood_heating',
  'geothermal': 'geothermal_heating',
  'air_water': 'air_water_heat_pump',
  'none': 'has_not',
  // 'ceiling': 'ceiling_er_heating',
  // 'floor': 'floor_er_heating',
  // 'floor': 'on_floor',
  // 'air': 'air_heating',
  // 'radiator': 'radiator_heating',
  'water_radiator': 'water_radiators',
  'water_underfloor': 'water_underfloor_heating',
  'hallway': 'hallway_short',
  'roof': 'water_roof',
  'kitchen': 'kitchen_short',
  'automatic': 'automatic_fuse',
  'asphalt': 'asphalt_paving',
  'wood_chip': 'wood_chips',
  'gravel': 'graveling',
  'lightweight_concrete_other': 'lightweight_concrete_alt',
  'wall': 'on_wall',
  'single_leaf': 'single_leaf_door',
  'double_leaf': 'double_leaf_door',
  'revolving': 'revolving_door',
  'overhead': 'overhead_door',
  'sliding': 'sliding_door',
  'lap_brick': 'lap_brick_masonry',
}

const SummaryOption = (key: string, title?: string) => {
  return { key, title: title || key }
}

const SummaryItem = (
  label: string,
  value: string | number | null | undefined,
  unit?: string,
  isNumber?: boolean
) => {
  try {
    if (value === null || value === undefined || value === '') {
      return { label, value: '-' }
    }
    // Check if value is number
    if (isNumber) {
      return { label, value: `${formatNumber(Number(value))}${unit ? ` ${unit}` : ''}` }
    }
    return { label, value: `${value}${unit ? ` ${unit}` : ''}` }
  } catch (error) {
    return { label, value: '-' }
  }
}

const TSummaryItem = (label: string, value: string | number | null | undefined, unit?: string) => {
  try {
    if (value === null || value === undefined || value === '') {
      return { label, value: '-' }
    }
    let stringValue = `${value}`
    if (stringValue === 'true' || stringValue === 'false') {
      stringValue = stringValue === 'true' ? 'yes' : 'no'
    }
    let translatedValue = i18n.t(stringValue)
    if (AltLabels[stringValue]) {
      translatedValue = i18n.t(AltLabels[stringValue])
    }
    return { label, value: `${translatedValue}${unit ? ` ${unit}` : ''}` }
  } catch (error) {
    return { label, value: '-' }
  }
}

const TSummaryWrapperItem = (item: any, extra?: any) => {
  if (!extra) {
    return item
  }
  return { ...item, extra }
}


const TSummaryVisibilityWrapper = (item: any, value: any, conditions: any[] = []) => {
  if (!value) {
    return null
  }
  // Check if conditions are met (0 is value, 1 is array of values to check)
  for (const condition of conditions) {
    if (!condition?.[0]) {
      return null
    }
    if (condition?.[1]?.length && !condition?.[1].includes(condition?.[0])) {
      return null
    }
  }
  return item
}

const TArraySummaryItem = (
  label: string,
  values: string[] | null | undefined,
  otherValue?: string,
) => {
  try {
    if (values === null || values === undefined || !values?.length) {
      return { label, value: '-' }
    }
    const stringValue = values
      ?.filter((item: string) => item && item !== ',')
      ?.map((value: string) => {
        if (value === 'other' && otherValue) {
          return otherValue?.trim()
        }
        let translatedValue = i18n.t(value)
        if (AltLabels?.[value]) {
          translatedValue = i18n.t(AltLabels[value])
        }
        return translatedValue
      })?.filter((item: string) => item)
      ?.join(', ')
    return { label, value: stringValue }
  } catch (error) {
    return { label, value: '-' }
  } 
}

const TArraySummaryItemWithInstallationYear = (
  data: any,
  label: string,
  values: string[] | null | undefined,
  otherValue?: string,
) => {
  try {
    if (values === null || values === undefined || !values?.length) {
      return { label, value: '-' }
    }

    const stringValue = values
      ?.filter((item: string) => item && item !== ',')
      ?.map((value: string) => {
        const iYear = data?.[`${camelCase(value)}InstallationYear`]
        if (value === 'other' && otherValue) {
          return `${otherValue}${iYear? ` (${iYear})` : ''}`?.trim()
        }
        let translatedValue = i18n.t(value)
        if (AltLabels[value]) {
          translatedValue = i18n.t(AltLabels[value])
        }
        return `${translatedValue} ${iYear? ` (${iYear})` : ''}`?.trim()
      })
      ?.join(', ')
    return { label, value: stringValue }
  } catch (error) {
    return { label, value: '-' }
  } 
}

export const SummaryDefaultPropertyHiddenItems = [
  'property_admin',
  'property_management',
  'maintenance_companies',
  'security',
  'landlords',
  'insurance_companies',
  'housing_company_board',
  'access_and_driveways',
  'water_drainage',
  'lawn_areas_and_plantings',
  'building_sides',
  'outdoor_parking',
  'outdoor_fixtures_and_equipment',
  'yard_deck',
  'side_buildings'
]
export const SummaryDefaultBuildingHiddenItems = [
  'foundations_and_plinth',
  'base_floor',
  'exterior_walls_and_facade',
  'intermediate_floors',
  'interior_walls',
  'upper_floor',
  'water_roof',
  'windows',
  'doors',
  'balconies',
  'terraces',
  'ventilation',
  'heating',
  'stormwater',
  'domestic_water',
  'waste_management',
  'elevator',
  'electrical_engineering',
  'sewerage',
  'fire_safety',
  'security',
  'industrial_waste',
  'energy',
  'coatings_and_materials',
  'windows_and_doors',
  'space_heating',
  'water_fixtures',
  'drainage',
  'water_proofing',
  'space_ventilation',
  'sockets_and_lighting',
  'hearths',
  'electrical_devices',
  'fixtures'
]
export const SummaryDefaultApartmentHiddenItems = [
  'coatings_and_materials',
  'windows_and_doors',
  'room_heating',
  'water_fixtures',
  'drainage',
  'water_proofing',
  'room_ventilation',
  'sockets_and_lighting',
  'hearths',
  'electrical_devices',
  'fixtures'
]

export const SummarySectionPropertyItemsMappings = [
  {
    key: 'property_basic_information',
    title: 'basic_information',
    orderKey: 'propertyBasicInformationOrder',
    items: [
      SummaryOption('basic_information'),
      SummaryOption('rent_information'),
      SummaryOption('insurances'),
      // SummaryOption('property_admin'),
      SummaryOption('buildings'),
      SummaryOption('common_spaces_and_areas'),
      SummaryOption('parking'),
      SummaryOption('maintenance_information'),
      SummaryOption('energy_certificate'),
      // SummaryOption('property_management'),
    ]
  },
  {
    key: 'property_contact_information',
    title: 'contact_information',
    orderKey: 'propertyContactInformationOrder',
    items: [
      SummaryOption('property_admin'),
      SummaryOption('property_management'),
      SummaryOption('maintenance_companies'),
      SummaryOption('security'),
      SummaryOption('landlords'),
      SummaryOption('insurance_companies'),
      SummaryOption('housing_company_board')
    ]
  },
  {
    key: 'property_areas',
    title: 'areas',
    orderKey: 'propertyAreasOrder',
    items: [
      SummaryOption('access_and_driveways'),
      SummaryOption('water_drainage'),
      SummaryOption('lawn_areas_and_plantings'),
      SummaryOption('building_sides'),
      SummaryOption('outdoor_parking'),
      SummaryOption('outdoor_fixtures_and_equipment'),
      SummaryOption('yard_deck'),
      SummaryOption('side_buildings'),
    ]
  }
]

export const SummarySectionBuildingItemsMappings = (bData?: any) => [
  {
    key: 'building_basic_information',
    title: 'basic_information',
    orderKey: 'buildingBasicInformationOrder',
    items: [
      SummaryOption('basic_information'),
      SummaryOption('structures'),
      SummaryOption('hvac_systems'),
      SummaryOption('elevators'),
      SummaryOption('tv_and_communication_connections'),
    ]
  },
  {
    key: 'building_structures',
    title: 'structures',
    orderKey: 'buildingStructuresOrder',
    items: [
      SummaryOption('foundations_and_plinth'),
      SummaryOption('base_floor'),
      SummaryOption('exterior_walls_and_facade'),
      SummaryOption('intermediate_floors'),
      SummaryOption('interior_walls'),
      SummaryOption('upper_floor'),
      SummaryOption('water_roof'),
    ],
  },
  {
    key: 'building_parts',
    title: 'building_parts',
    orderKey: 'buildingPartsOrder',
    items: [
      SummaryOption('windows'),
      SummaryOption('doors'),
      SummaryOption('balconies'),
      SummaryOption('terraces'),
    ]
  },
  {
    key: 'building_systems',
    title: 'systems',
    orderKey: 'buildingSystemsOrder',
    items: [
      SummaryOption('ventilation'),
      SummaryOption('heating'),
      SummaryOption('stormwater'),
      SummaryOption('domestic_water'),
      SummaryOption('waste_management'),
      SummaryOption('elevator', 'elevators'),
      SummaryOption('electrical_engineering'),
      SummaryOption('sewerage'),
      SummaryOption('fire_safety'),
      bData?.propertyType !== 'housing' ? SummaryOption('security') : null,
      bData?.propertyType !== 'housing' ? SummaryOption('industrial_waste') : null,
      bData?.propertyType !== 'housing' ? SummaryOption('energy') : null,
    ]?.filter((item: any) => item)
  },
  {
    key: 'building_spaces',
    title: 'visible_space_information',
    orderKey: 'buildingSpacesOrder',
    dragDisabled: true,
    items: [
      SummaryOption('coatings_and_materials'),
      SummaryOption('windows_and_doors'),
      SummaryOption('space_heating'),
      SummaryOption('water_fixtures'),
      SummaryOption('drainage'),
      SummaryOption('water_proofing'),
      SummaryOption('space_ventilation'),
      SummaryOption('sockets_and_lighting'),
      SummaryOption('hearths'),
      SummaryOption('electrical_devices'),
      SummaryOption('fixtures'),
      // SummaryOption('cooling'),
    ]
  }
  /*
  {
    key: 'building_spaces',
    title: 'spaces',
    items: buildings?.map((building: any) => {
      return building
        ?.spaces
        ?.map((space: any) => {
          return SummaryOption(
            space.uuid,
            `${building?.nameFi} - ${space?.nameFi}`
          ) || []
      })
    })
    .flat()
  }
  */
]

export const SummarySectionApartmentItemsMappings = (aData?: any) => [
  {
    key: 'apartment_basic_information',
    title: 'basic_information',
    orderKey: 'apartmentBasicInformationOrder',
    dragDisabled: true,
    items: [
      SummaryOption('basic_information'),
      aData?.propertyType === 'housing' ? SummaryOption('resident_information') : null,
      aData?.propertyType !== 'housing' ? SummaryOption('owner_information') : null,
      aData?.propertyType !== 'housing' ? SummaryOption('tenant_information') : null,
    ]?.filter((item: any) => item)
  },
  /*
  {
    key: 'apartments_rooms',
    title: 'rooms',
    items: apartments?.map((apartment: any) => {
      return apartment
        ?.rooms
        ?.map((room: any) => {
          return SummaryOption(
            room.uuid,
            `${apartment?.nameFi} - ${room?.nameFi}`
          ) || []
      })
    })
    .flat()
  },
  */
  {
    key: 'apartment_rooms',
    title: 'visible_room_information',
    orderKey: 'apartmentRoomsOrder',
    dragDisabled: true,
    items: [
      SummaryOption('coatings_and_materials'),
      SummaryOption('windows_and_doors'),
      SummaryOption('room_heating'),
      SummaryOption('water_fixtures'),
      SummaryOption('drainage'),
      SummaryOption('water_proofing'),
      SummaryOption('room_ventilation'),
      SummaryOption('sockets_and_lighting'),
      SummaryOption('hearths'),
      SummaryOption('electrical_devices'),
      SummaryOption('fixtures'),
      // SummaryOption('cooling'),
    ],
  }
]

export const DefaultPropertyCategoryOrder = SummarySectionPropertyItemsMappings.map((section: any) => section.key)
export const DefaultPropertyBasicInformationOrder = SummarySectionPropertyItemsMappings[0].items.map((section: any) => section.key)
export const DefaultPropertyContactInformationOrder = SummarySectionPropertyItemsMappings[1].items.map((section: any) => section.key)
export const DefaultPropertyAreasOrder = SummarySectionPropertyItemsMappings[2].items.map((section: any) => section.key)
export const DefaultBuildingCategoryOrder = SummarySectionBuildingItemsMappings().map((section: any) => section.key)
export const DefaultBuildingBasicInformationOrder = SummarySectionBuildingItemsMappings()[0].items.map((section: any) => section.key)
export const DefaultBuildingStructuresOrder = SummarySectionBuildingItemsMappings()[1].items.map((section: any) => section.key)
export const DefaultBuildingPartsOrder = SummarySectionBuildingItemsMappings()[2].items.map((section: any) => section.key)
export const DefaultBuildingSystemsOrder = (bData?: any) => SummarySectionBuildingItemsMappings(bData)[3].items.map((section: any) => section.key)
export const DefaultBuildingSpacesOrder = SummarySectionBuildingItemsMappings()[4].items.map((section: any) => section.key)
export const DefaultApartmentCategoryOrder = SummarySectionApartmentItemsMappings().map((section: any) => section.key)
export const DefaultApartmentBasicInformationOrder = (aData?: any) => SummarySectionApartmentItemsMappings(aData)[0].items.map((section: any) => section.key)
export const DefaultApartmentRoomsOrder = SummarySectionApartmentItemsMappings()[1].items.map((section: any) => section.key)

export const SummaryPropertyLayout = (property: any, template: any) => {
  const layout = [
    {
      ...SummaryOption('property_basic_information', 'property_basic_information'),
      ...Title,
      items: [
        {
          ...SummaryOption('basic_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'property_basic_information_left',
              items: [
                SummaryItem('business_id', property?.businessId),
                SummaryItem('company_registration_date', property?.registrationDate),
                SummaryItem('plot_area', property?.area, 'm²', true),
                property?.type !== 'housing' ? TSummaryItem('property_ownership_type', property?.ownershipType) : null,
                property?.type !== 'housing' ? TSummaryItem('property_space_ownership_type', property?.spaceOwnershipType) : null,
                TSummaryItem('property_plot_ownership_type', property?.plotOwnershipType),
                TSummaryItem('has_multiple_properties', property?.hasMultipleProperties || false),
                SummaryItem('property_count', property?.hasMultipleProperties ? property?.propertyCount : 1, 'kpl'),
              ]?.filter((item: any) => item)
            },
            {
              ...SummaryColumn,
              key: 'property_basic_information_right',
              items: [
                SummaryItem('property_id', property?.propertyId),
                SummaryItem('commissioning_years', property?.buildingCommissioningYears?.join(', ')),
                SummaryItem('street_address', property?.address),
                SummaryItem('postal_code', property?.postalCode),
                SummaryItem('city_or_municipality', property?.city),
              ]
            }
          ]
        },
        [property?.ownershipType, property?.spaceOwnershipType, property?.plotOwnershipType].includes('rent') && {
          ...SummaryOption('rent_information'),
          ...Subtitle,
          multiRow: true,
          items: [
            property?.ownershipType === 'rent' && {
              key: 'property_rent_information',
              groupTitle: 'property_alt',
              items: [
                {
                  ...SummaryColumn,
                  key: 'property_rent_information_left',
                  items: [
                    SummaryItem(
                      'rent',
                      property?.propertyRentCosts?.total,
                      '€',
                      true
                    ),
                    TSummaryItem(
                      'payment_interval',
                      property?.propertyRentCosts?.paymentInterval
                    ),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: 'property_rent_information_right',
                  items: [
                    SummaryItem('payment_date', property?.propertyRentCosts?.paymentDate),
                  ]
                }
              ]
            },
            property?.spaceOwnershipType === 'rent' && {
              key: 'space_rent_information',
              groupTitle: 'spaces',
              items: [
                {
                  ...SummaryColumn,
                  key: 'space_information_left',
                  items: [
                    SummaryItem(
                      'rent',
                      property?.spaceRentCosts?.total,
                      '€',
                      true
                    ),
                    TSummaryItem(
                      'payment_interval',
                      property?.spaceRentCosts?.paymentInterval
                    ),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: 'space_rent_information_right',
                  items: [
                    SummaryItem('payment_date', property?.spaceRentCosts?.paymentDate),
                  ]
                }
              ]
            },
            property?.plotOwnershipType === 'rent' && {
              key: 'plot_rent_information',
              groupTitle: 'plot',
              items: [
                {
                  ...SummaryColumn,
                  key: 'plot_rent_information_left',
                  items: [
                    SummaryItem(
                      'rent',
                      property?.plotRentCosts?.total,
                      '€',
                      true
                    ),
                    TSummaryItem(
                      'payment_interval',
                      property?.plotRentCosts?.paymentInterval
                    ),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: 'plot_rent_information_right',
                  items: [
                    SummaryItem('payment_date', property?.plotRentCosts?.paymentDate),
                  ]
                }
              ]
            }
          ]?.filter((item: any) => item)
        },
        property?.insurances?.length ? {
          ...SummaryOption('insurances'),
          ...Subtitle,
          multiRow: true,
          items: flatten((property?.insurances || [])?.map((insurance: any, index: number) => [
            {
              key: `insurances_${index}`,
              groupTitle: 'insurance',
              groupTitleExt: index + 1,
              items: [
                {
                  ...SummaryColumn,
                  key: `insurances_left_${index}`,
                  items: [
                    TSummaryItem(
                      'type',
                      insurance?.type === 'other' ?
                        insurance?.typeNotes :
                        insurance?.type ?
                        `${i18n.t(insurance?.type)}_insurance` :
                        null
                    ),
                    TSummaryItem(
                      'company',
                      insurance?.company === 'other' ? insurance?.companyNotes : insurance?.company
                    ),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: `insurances_right_${index}`,
                  items: [
                    SummaryItem('insurance_number', insurance?.number),
                  ]
                }
              ]
            }
          ]))
        } : null,
        /*
        property?.admin &&
        {
          ...SummaryOption('property_admin'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'property_admin_left',
              items: [
                SummaryItem('name', `${property?.admin?.firstName} ${property?.admin?.lastName}`),
                SummaryItem('phone_number', property?.admin?.phone),
              ]
            },
            {
              ...SummaryColumn,
              key: 'property_admin_right',
              items: [
                SummaryItem('email', property?.admin?.email),
              ]
            },
          ]
        },
        */
        {
          ...SummaryOption('buildings'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'buildings_left',
              items: [
                TArraySummaryItem(
                  'building_types',
                  property?.buildingTypes
                ),
              ]
            },
            {
              ...SummaryColumn,
              key: 'buildings_right',
              items: [
                SummaryItem('building_count', property?.buildingCount, 'kpl'),
              ]
            }
          ]
        },
        {
          ...SummaryOption('common_spaces_and_areas'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'common_spaces_and_areas_left',
              items: [
                SummaryItem('residence_count', property?.apartmentCount, 'kpl'),
                SummaryItem('commercial_space_count', property?.commercialSpaceCount, 'kpl'),
                SummaryItem('other_space_count', property?.otherSpaceCount, 'kpl'),
                TArraySummaryItem(
                  'common_spaces_and_areas',
                  property?.spaceTypes?.split(',') || [],
                  property?.spaceTypesInfo
                ),
              ]
            },
            {
              ...SummaryColumn,
              key: 'common_spaces_and_areas_right',
              items: [
                SummaryItem('total_residence_area', property?.apartmentArea, 'm²', true),
                SummaryItem('total_commercial_space_area', property?.commercialSpaceArea, 'm²', true),
                SummaryItem('total_other_space_area', property?.otherSpaceArea, 'm²', true),
              ]
            }
          ]
        },
        {
          ...SummaryOption('parking'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'parking_left',
              items: [
                SummaryItem('parking_space_count', property?.parkingSpaceCount, 'kpl'),
              ]
            },
          ]
        },
        {
          ...SummaryOption('maintenance_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'maintenance_information_left',
              items: [
                TSummaryItem('has_maintenance_report', property?.hasMaintenanceReport),
                SummaryItem('past_maintenance_info', convert(property?.pastMaintenanceInfo)),
              ]
            },
            {
              ...SummaryColumn,
              key: 'maintenance_information_right',
              items: [
                TSummaryItem('has_maintenance_plan', property?.hasMaintenancePlan),
                SummaryItem('planned_maintenance_info', convert(property?.plannedMaintenanceInfo)),
              ]
            }
          ]
        },
        {
          ...SummaryOption('energy_certificate'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'energy_certificate_left',
              items: [
                TSummaryItem('property_has_valid_energy_certificate', property?.hasValidEnergyCertificate),
                SummaryItem('valid_until_year', property?.energyCertificateEndYear),
              ]
            },
            {
              ...SummaryColumn,
              key: 'energy_certificate_right',
              items: [
                SummaryItem('year_of_preparation', property?.energyCertificateStartYear),
              ]
            }
          ]
        },
        /*
        {
          ...SummaryOption('property_management'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'management_left',
              items: [
                SummaryItem('contact_person', property?.propertyManagerName),
                SummaryItem('phone_number', property?.propertyManagerPhone),
                SummaryItem('email', property?.propertyManagerEmail),
                SummaryItem('company', property?.propertyManagerCompany),
              ]
            },
            {
              ...SummaryColumn,
              key: 'management_right',
              items: [
                SummaryItem('address', property?.propertyManagerAddress),
                SummaryItem('postal_code', property?.propertyManagerPostalCode),
                SummaryItem('city', property?.propertyManagerCity),
              ]
            }
          ]
        }
        */
      ]
    },
    {
      ...SummaryOption('property_contact_information', 'contact_information'),
      ...Title,
      items: [
        property?.admin &&
        {
          ...SummaryOption('property_admin'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'property_admin_left',
              items: [
                SummaryItem('name', `${property?.admin?.firstName} ${property?.admin?.lastName}`),
                SummaryItem('phone_number', property?.admin?.phone),
              ]
            },
            {
              ...SummaryColumn,
              key: 'property_admin_right',
              items: [
                SummaryItem('email', property?.admin?.email),
              ]
            },
          ]
        },
        {
          ...SummaryOption('property_management'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'management_left',
              items: [
                SummaryItem('contact_person', property?.propertyManagerName),
                SummaryItem('phone_number', property?.propertyManagerPhone),
                SummaryItem('email', property?.propertyManagerEmail),
                SummaryItem('company', property?.propertyManagerCompany),
              ]
            },
            {
              ...SummaryColumn,
              key: 'management_right',
              items: [
                SummaryItem('address', property?.propertyManagerAddress),
                SummaryItem('postal_code', property?.propertyManagerPostalCode),
                SummaryItem('city', property?.propertyManagerCity),
                SummaryItem('areas_of_responsibility', property?.propertyManagerAreasOfResponsibility),
              ]
            }
          ]
        },
        property?.type === 'housing' && {
          ...SummaryOption('maintenance_company'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'maintenance_company_left',
              items: [
                SummaryItem('contact_person', property?.maintenanceCompanyContactPersonName),
                SummaryItem('company', property?.maintenanceCompanyName),
                SummaryItem('phone_number', property?.maintenanceCompanyPhone),
                SummaryItem('email', property?.maintenanceCompanyEmail),
                SummaryItem('move_notifications', property?.maintenanceCompanyMoveNotificationUrl),
              ]
            },
            {
              ...SummaryColumn,
              key: 'maintenance_company_right',
              items: [
                SummaryItem('address', property?.maintenanceCompanyAddress),
                SummaryItem('postal_code', property?.maintenanceCompanyPostalCode),
                SummaryItem('city', property?.maintenanceCompanyCity),
                SummaryItem('areas_of_responsibility', property?.maintenanceCompanyResponsibilities),
              ]
            }
          ]
        },
        property?.type !== 'housing' && {
          ...SummaryOption('maintenance_companies'),
          ...Subtitle,
          multiRow: true,
          items: flatten((property?.maintenanceCompanies || [])?.map((mCompany: any, index: number) => [
            {
              key: `maintenance_companies_${index}`,
              groupTitle: 'maintenance_company',
              groupTitleExt: index + 1,
              items: [
                {
                  ...SummaryColumn,
                  key: `maintenance_companies_left_${index}`,
                  items: [
                    SummaryItem('contact_person', mCompany?.contactPersonName),
                    SummaryItem('company', mCompany?.company),
                    SummaryItem('phone_number', mCompany?.phone),
                    SummaryItem('email', mCompany?.email),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: `maintenance_companies_right_${index}`,
                  items: [
                    SummaryItem('address', mCompany?.address),
                    SummaryItem('postal_code', mCompany?.postalCode),
                    SummaryItem('city', mCompany?.city),
                    SummaryItem('areas_of_responsibility', mCompany?.responsibilities),
                  ]
                }
              ]
            }
          ]))
        },
        property?.type !== 'housing' && {
          ...SummaryOption('security'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'security_left',
              items: [
                SummaryItem('contact_person', property?.securityCompanyContactPersonName),
                SummaryItem('phone_number', property?.securityCompanyName),
                SummaryItem('email', property?.securityCompanyEmail),
                SummaryItem('company', property?.securityCompanyPhone),
              ]
            },
            {
              ...SummaryColumn,
              key: 'security_right',
              items: [
                SummaryItem('address', property?.securityCompanyAddress),
                SummaryItem('postal_code', property?.securityCompanyPostalCode),
                SummaryItem('city', property?.securityCompanyCity),
              ]
            }
          ]
        },
        {
          ...SummaryOption('landlords'),
          ...Subtitle,
          multiRow: true,
          items: flatten((property?.landlords || [])?.map((landlord: any, index: number) => [
            {
              key: `landlords_${index}`,
              groupTitle: 'landlord',
              groupTitleExt: index + 1,
              items: [
                {
                  ...SummaryColumn,
                  key: `maintenance_companies_left_${index}`,
                  items: [
                    SummaryItem('contact_person', landlord?.contactPersonName),
                    SummaryItem('company', landlord?.company),
                    SummaryItem('phone_number', landlord?.phone),
                    SummaryItem('email', landlord?.email),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: `maintenance_companies_right_${index}`,
                  items: [
                    SummaryItem('address', landlord?.address),
                    SummaryItem('postal_code', landlord?.postalCode),
                    SummaryItem('city', landlord?.city),
                  ]
                }
              ]
            }
          ]))
        },
        {
          ...SummaryOption('insurance_companies'),
          ...Subtitle,
          multiRow: true,
          items: flatten((property?.insuranceCompanies || [])?.map((insuranceCompany: any, index: number) => [
            {
              key: `insurance_companies_${index}`,
              groupTitle: 'insurance_company',
              groupTitleExt: index + 1,
              items: [
                {
                  ...SummaryColumn,
                  key: `insurance_companies_left_${index}`,
                  items: [
                    SummaryItem('contact_person', insuranceCompany?.contactPersonName),
                    SummaryItem('company', insuranceCompany?.company),
                    SummaryItem('phone_number', insuranceCompany?.phone),
                    SummaryItem('email', insuranceCompany?.email),
                  ]
                },
                {
                  ...SummaryColumn,
                  key: `insurance_companies_right_${index}`,
                  items: [
                    SummaryItem('address', insuranceCompany?.address),
                    SummaryItem('postal_code', insuranceCompany?.postalCode),
                    SummaryItem('city', insuranceCompany?.city),
                  ]
                }
              ]
            }
          ]))
        },
        property?.type === 'housing' && {
          ...SummaryOption('housing_company_board'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'housing_company_board_left',
              items: [
                TSummaryWrapperItem(
                  TSummaryItem(
                    'board_member',
                    property?.companyBoard?.member1Name
                  ),
                  { group: i18n.t('name') }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'phone_number',
                    property?.companyBoard?.member1Phone
                  ),
                  { group: i18n.t('phone_number'), hideLabel: true }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'email',
                    property?.companyBoard?.member1Email
                  ),
                  { group: i18n.t('email'), hideLabel: true }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'board_member',
                    property?.companyBoard?.member2Name
                  ),
                  { group: i18n.t('name') }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'phone_number',
                    property?.companyBoard?.member2Phone
                  ),
                  { group: i18n.t('phone_number'), hideLabel: true }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'email',
                    property?.companyBoard?.member2Email
                  ),
                  { group: i18n.t('email'), hideLabel: true }
                ),
              ]
            },
            {
              ...SummaryColumn,
              key: 'housing_company_board_right',
              items: [
                TSummaryWrapperItem(
                  TSummaryItem(
                    'board_member',
                    property?.companyBoard?.member3Name
                  ),
                  { group: i18n.t('name') }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'phone_number',
                    property?.companyBoard?.member3Phone
                  ),
                  { group: i18n.t('phone_number'), hideLabel: true }
                ),
                TSummaryWrapperItem(
                  TSummaryItem(
                    'email',
                    property?.companyBoard?.member3Email
                  ),
                  { group: i18n.t('email'), hideLabel: true }
                ),
                TSummaryItem('areas_of_responsibility', property?.companyBoard?.areasOfResponsibility)
              ]
            }
          ]
        }
      ]
    },
    {
      ...SummaryOption('property_areas', 'areas'),
      ...Title,
      items: [
        {
          ...SummaryOption('access_and_driveways'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'access_and_driveways_left',
              items: [
                TArraySummaryItemWithInstallationYear(
                  {
                    ...property?.accessAndDriveways,
                    naturalStonePavingInstallationYear: property?.accessAndDriveways?.naturalStoneInstallationYear,
                  },
                  'material',
                  property?.accessAndDriveways?.materials?.split(',')
                    ?.map((item: string) => item === 'natural_stone' ? 'natural_stone_paving' : item) || [],
                  property?.accessAndDriveways?.otherMaterial,
                ),
              ]
            }
          ]
        },
        {
          ...SummaryOption('water_drainage'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'water_drainage_left',
              items: [
                TSummaryItem(
                  'type',
                  property?.waterDrainage?.type,
                  property?.waterDrainage?.installationYear ?
                  `(${property?.waterDrainage?.installationYear})` :
                  ''
                ),
              ]
            },
          ]
        },
        {
          ...SummaryOption('lawn_areas_and_plantings'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'lawn_areas_and_plantings_left',
              items: [
                TSummaryItem(
                  'lawn_areas',
                  property?.lawnAreasAndPlantings?.lawnAreaType,
                  property?.lawnAreasAndPlantings?.lawnAreaInstallationYear ?
                  `(${property?.lawnAreasAndPlantings?.lawnAreaInstallationYear})` :
                  ''
                ),
                TSummaryItem('lawn_area_edging', property?.lawnAreasAndPlantings?.lawnAreaEdging),
              ]
            },
            {
              ...SummaryColumn,
              key: 'lawn_areas_and_plantings_right',
              items: [
                TSummaryItem(
                  'plantings',
                  property?.lawnAreasAndPlantings?.plantingType,
                  property?.lawnAreasAndPlantings?.plantingType !== 'no_planting' && property?.lawnAreasAndPlantings?.plantingInstallationYear ?
                  `(${property?.lawnAreasAndPlantings?.plantingInstallationYear})` :
                  ''
                ),
                TSummaryItem('planting_edging', property?.lawnAreasAndPlantings?.plantingEdging),
              ]
            }
          ]
        },
        {
          ...SummaryOption('building_sides'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'building_sides_left',
              items: [
                TSummaryItem(
                  'edge_paving',
                  property?.buildingSides?.edgePaving,
                  property?.buildingSides?.edgePavingInstallationYear ?
                  `(${property?.buildingSides?.edgePavingInstallationYear})` :
                  ''
                ),
              ]
            },
          ]
        },
        {
          ...SummaryOption('outdoor_parking'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'outdoor_parking_left',
              items: [
                TSummaryItem(
                  'coating',
                  property?.outdoorParking?.areaCoating,
                  property?.outdoorParking?.areaInstallationYear ?
                  `(${property?.outdoorParking?.areaInstallationYear})` :
                  ''
                ),
              ]
            },
          ]
        },
        {
          ...SummaryOption('outdoor_fixtures_and_equipment'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'outdoor_fixtures_and_equipment_left',
              items: [
                TArraySummaryItemWithInstallationYear(
                  {
                    playAreaInstallationYear: property?.outdoorFixturesAndEquipment?.playAreaInstallationYear,
                    mailboxInstallationYear: property?.outdoorFixturesAndEquipment?.mailboxInstallationYear,
                    airingRackInstallationYear: property?.outdoorFixturesAndEquipment?.airingRackInstallationYear,
                    clotheslineInstallationYear: property?.outdoorFixturesAndEquipment?.clotheslineInstallationYear,
                    dryingRackInstallationYear: property?.outdoorFixturesAndEquipment?.dryingRackInstallationYear,
                    barbecueAreaInstallationYear: property?.outdoorFixturesAndEquipment?.barbecueAreaInstallationYear,
                    benchesInstallationYear: property?.outdoorFixturesAndEquipment?.benchesInstallationYear,
                    chairsInstallationYear: property?.outdoorFixturesAndEquipment?.chairsInstallationYear,
                    tablesInstallationYear: property?.outdoorFixturesAndEquipment?.tablesInstallationYear,
                    gardenSwingsInstallationYear: property?.outdoorFixturesAndEquipment?.gardenSwingsInstallationYear,
                  },
                  'fixtures_and_equipment',
                  [
                    property?.outdoorFixturesAndEquipment?.hasPlayArea ? 'play_area' : '',
                    property?.outdoorFixturesAndEquipment?.hasMailboxes ? 'mailboxes' : '',
                    property?.outdoorFixturesAndEquipment?.hasAiringRack ? 'airing_rack' : '',
                    property?.outdoorFixturesAndEquipment?.hasClothesline ? 'clothesline' : '',
                    property?.outdoorFixturesAndEquipment?.hasDryingRack ? 'drying_rack' : '',
                    property?.outdoorFixturesAndEquipment?.hasBarbecueArea ? 'barbecue_area' : '',
                    property?.outdoorFixturesAndEquipment?.hasBenches ? 'benches' : '',
                    property?.outdoorFixturesAndEquipment?.hasChairs ? 'chairs' : '',
                    property?.outdoorFixturesAndEquipment?.hasTables ? 'tables' : '',
                    property?.outdoorFixturesAndEquipment?.hasGardenSwings ? 'garden_swings' : '',
                  ].filter((item: string) => item),
                )
              ]
            },
          ]
        },
        {
          ...SummaryOption('yard_deck'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'yard_deck_left',
              items: [
                TArraySummaryItemWithInstallationYear(
                  {
                    outdoorSpacesInstallationYear: property?.yardDeck?.outdoorSpacesInstallationYear,
                    coldIndoorSpacesInstallationYear: property?.yardDeck?.coldIndoorSpacesInstallationYear,
                    semiWarmIndoorSpacesInstallationYear: property?.yardDeck?.semiWarmIndoorSpacesInstallationYear,
                    warmIndoorSpacesInstallationYear: property?.yardDeck?.warmIndoorSpacesInstallationYear,
                  },
                  'above_the_yard_deck',
                  [
                    property?.yardDeck?.hasOutdoorSpaces ? 'outdoor_spaces' : '',
                    property?.yardDeck?.hasColdIndoorSpaces ? 'cold_indoor_spaces' : '',
                    property?.yardDeck?.hasSemiWarmIndoorSpaces ? 'semi_warm_indoor_spaces' : '',
                    property?.yardDeck?.hasWarmIndoorSpaces ? 'warm_indoor_spaces' : '',
                  ].filter((item: string) => item),
                ),
                TArraySummaryItemWithInstallationYear(
                  {
                    parkingAreaInstallationYear: property?.yardDeck?.parkingAreaInstallationYear,
                    yardAreasInstallationYear: property?.yardDeck?.yardAreasInstallationYear,
                    pathwaysInstallationYear: property?.yardDeck?.pathwaysInstallationYear,
                    playAreasInstallationYear: property?.yardDeck?.playAreasInstallationYear,
                  },
                  'below_the_yard_deck',
                  [
                    property?.yardDeck?.hasParkingArea ? 'parking_area' : '',
                    property?.yardDeck?.hasYardAreas ? 'yard_areas' : '',
                    property?.yardDeck?.hasPathways ? 'pathways' : '',
                    property?.yardDeck?.hasPlayAreas ? 'play_areas' : '',
                  ].filter((item: string) => item),
                )
              ]
            },
          ]
        },
        {
          ...SummaryOption('side_buildings'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'side_buildings_left',
              items: [
                TArraySummaryItemWithInstallationYear(
                  {
                    parkingBuildingsInstallationYear: property?.sideBuildings?.parkingBuildingsInstallationYear,
                    yardBuildingsInstallationYear: property?.sideBuildings?.yardBuildingsInstallationYear,
                    technicalSpacesInstallationYear: property?.sideBuildings?.technicalSpacesInstallationYear,
                  },
                  'buildings_and_spaces',
                  [
                    property?.sideBuildings?.hasParkingBuildings ? 'parking_buildings' : '',
                    property?.sideBuildings?.hasYardBuildings ? 'yard_buildings' : '',
                    property?.sideBuildings?.hasTechnicalSpaces ? 'technical_spaces' : '',
                  ].filter((item: string) => item),
                )
              ]
            },
          ]
        }
      ]
    }
  ]

  const propertyCategoryOrder = template?.propertyCategoryOrder || DefaultPropertyCategoryOrder
  const propertyBasicInformationOrder = template?.propertyBasicInformationOrder || DefaultPropertyBasicInformationOrder
  const propertyContactInformationOrder = template?.propertyContactInformationOrder || DefaultPropertyContactInformationOrder
  const propertyAreasOrder = template?.propertyAreasOrder || DefaultPropertyAreasOrder
  const finalLayout = []
  for (const category of propertyCategoryOrder) {
    let categoryLayout: any = layout.find((item: any) => item?.key === category)
    if (categoryLayout?.key === 'property_basic_information') {
      const categoryItems = []
      for (const item of propertyBasicInformationOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'property_contact_information') {
      const categoryItems = []
      for (const item of propertyContactInformationOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'property_areas') {
      const categoryItems = []
      for (const item of propertyAreasOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    }
    finalLayout.push(categoryLayout)
  }
  return finalLayout
}

const FoundationsAndPlinthLayout = (entity: any) => {
  return {
    ...SummaryOption('foundations_and_plinth'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'foundations_and_plinth_left',
        items: [
          TSummaryItem('foundation_type', entity?.type === 'other' ? entity?.typeNotes : entity?.type),
          TSummaryItem('foundation_material', entity?.material),
          TSummaryItem(
            'plinth_material',
            entity?.plinthMaterial === 'other' ? entity?.plinthMaterialNotes : entity?.plinthMaterial,
            entity?.plinthInstallationYear ?
            `(${entity?.plinthInstallationYear})` :
            ''
            
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'foundations_and_plinth_right',
        items: [
          TSummaryItem(
            'plinth_coating',
            entity?.plinthCoating === 'other' ? entity?.plinthCoatingNotes : entity?.plinthCoating,
            entity?.plinthCoatingInstallationYear ?
            `(${entity?.plinthCoatingInstallationYear})` :
            ''
          ),
          TSummaryItem(
            'plinth_damp_proofing',
            entity?.plinthDampProofing === 'other' ? entity?.plinthDampProofingNotes : entity?.plinthDampProofing,
            entity?.plinthDampProofingInstallationYear ?
            `(${entity?.plinthDampProofingInstallationYear})` :
            ''
          ),
        ]
      }
    ]
  }
}

const BaseFloorLayout = (entity: any) => {
  return {
    ...SummaryOption('base_floor'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'base_floor_left',
        items: [
          TSummaryItem(
            'type',
            entity?.type,
            entity?.installationYear ?
            `(${entity?.installationYear})` :
            ''
          ),
          TSummaryItem('frame_material', entity?.frameMaterial),
          TSummaryItem(
            'insulation',
            entity?.frameMaterialInsulation === 'other' ?
            entity?.frameMaterialInsulationNotes :
            entity?.frameMaterialInsulation,
            entity?.frameMaterialInsulationInstallationYear ?
            `(${entity?.frameMaterialInsulationInstallationYear})` :
            ''
          ),
          TSummaryItem(
            'insulation_upper_surface_plate',
            entity?.surfacePlateMaterial === 'other' ?
            entity?.surfacePlateMaterialNotes :
            entity?.frameInsulationUpperSurfacePlate === 'yes' ?
            entity?.surfacePlateMaterial :
            'no',
            entity?.surfacePlateInstallationYear ?
            `(${entity?.surfacePlateInstallationYear})` :
            ''
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'base_floor_right',
        items: [
          TSummaryItem(
            'ventilation_type',
            entity?.ventilationType === 'other' ?
            entity?.ventilationTypeNotes :
            entity?.ventilationType,
          ),
          TSummaryVisibilityWrapper(
            SummaryItem(
              'machine_model_extraction',
              entity?.extractionMachineType,
              entity?.extractionMachineInstallationYear ?
              `(${entity?.extractionMachineInstallationYear})` :
              ''
            ),
            entity?.ventilationHasMechanicalExtraction === 'yes',
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'machine_location_extraction',
              entity?.extractionMachineLocation === 'other' ?
              entity?.extractionMachineLocationNotes :
              entity?.extractionMachineLocation
            ),
            entity?.ventilationHasMechanicalExtraction === 'yes',
          ),
          TSummaryVisibilityWrapper(
            SummaryItem(
              'machine_model_ventilation',
              entity?.ventilationMachineType,
              entity?.ventilationMachineInstallationYear ?
              `(${entity?.ventilationMachineInstallationYear})` :
              ''
            ),
            entity?.ventilationHasMechanicalVentilation === 'yes',
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'machine_location_ventilation',
              entity?.ventilationMachineLocation === 'other' ?
              entity?.ventilationMachineLocationNotes :
              entity?.ventilationMachineLocation
            ),
            entity?.ventilationHasMechanicalVentilation === 'yes',
          ),
        ]
      }
    ]
  }
}

const ExteriorWallsAndFacadeLayout = (entity: any) => {
  return         {
    ...SummaryOption('exterior_walls_and_facade'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'exterior_walls_and_facade_left',
        items: [
          TSummaryItem(
            'frame_material',
            entity?.frameMaterial === 'other' ?
            entity?.frameMaterialNotes :
            entity?.frameMaterial
          ),
          TSummaryItem(
            'insulation_material',
            entity?.insulationMaterial === 'other' ?
            entity?.insulationMaterialNotes :
            entity?.insulationMaterial,
            entity?.insulationInstallationYear ?
            `(${entity?.insulationInstallationYear})` :
            ''
          ),
          TSummaryItem(
            'wind_barrier_board',
            entity?.windBarrierBoardType === 'other' ?
            entity?.windBarrierBoardTypeNotes :
            AltLabels?.[entity?.windBarrierBoardType] || entity?.windBarrierBoardType,
            entity?.windBarrierBoardInstallationYear ?
            `(${entity?.windBarrierBoardInstallationYear})` :
            ''
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'exterior_walls_and_facade_right',
        items: [
          TSummaryItem(
            'facade_material',
            entity?.facadeMaterial === 'other' ?
            entity?.facadeMaterialNotes :
            entity?.facadeMaterial,
            entity?.facadeInstallationYear ?
            `(${entity?.facadeInstallationYear})` :
            ''
          ),
          TSummaryItem(
            'facade_coating',
            entity?.facadeCoatingMaterial === 'other' ?
            entity?.facadeCoatingMaterialNotes :
            entity?.facadeCoatingMaterial,
            entity?.facadeCoatingInstallationYear ?
            `(${entity?.facadeCoatingInstallationYear})` :
            ''
          ),
        ]
      }
    ]
  }
}

const IntermediateFloorsLayout = (entity: any) => {
  return         {
    ...SummaryOption('intermediate_floors'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'intermediate_floors_left',
        items: [
          TSummaryItem(
            'frame_material',
            entity?.frameMaterial === 'other' ?
            entity?.frameMaterialNotes :
            entity?.frameMaterial,
            entity?.installationYear ?
            `(${entity?.installationYear})` :
            ''
          ),
          TSummaryItem(
            'insulation_material',
            entity?.frameInsulationMaterial === 'other' ?
            entity?.frameInsulationMaterialNotes :
            entity?.frameInsulationMaterial,
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'intermediate_floors_right',
        items: [
          TSummaryItem(
            'insulation_upper_surface_plate',
            entity?.surfacePlateMaterial === 'other' ?
            entity?.surfacePlateMaterialNotes :
            entity?.surfacePlateMaterial,
            entity?.surfacePlateInstallationYear ?
            `(${entity?.surfacePlateInstallationYear})` :
            ''
          ),
        ]
      }
    ]
  }
}

const InteriorWallsLayout = (entity: any) => {
  return         {
    ...SummaryOption('interior_walls'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'interior_walls_left',
        items: [
          TArraySummaryItem(
            'bearing_interior_walls',
            entity?.bearingMaterial?.split(',') || [],
            entity?.bearingMaterialNotes
          ),
          TArraySummaryItem(
            'lightweight_interior_walls',
            entity?.lightweightMaterial?.split(',') || [],
            entity?.lightweightMaterialNotes
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'interior_walls_right',
        items: [
          TSummaryItem(
            'insulation_material',
            entity?.insulationMaterial === 'other' ?
            entity?.insulationMaterialNotes :
            entity?.insulationMaterial
          ),
          TSummaryItem(
            'wall_cladding_material',
            entity?.insulationCladdingMaterial === 'other' ?
            entity?.insulationCladdingMaterialNotes :
            entity?.insulationCladdingMaterial
          ),
        ]
      }
    ]
  }
}

const UpperFloorLayout = (entity: any) => {
  return         {
    ...SummaryOption('upper_floor'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'upper_floor_left',
        items: [
          TSummaryItem(
            'bearing_structures',
            entity?.bearingStructures === 'other' ?
            entity?.bearingStructureMaterialNotes :
            entity?.bearingStructureMaterial
          ),
          TSummaryItem(
            'insulation_material',
            entity?.insulationMaterial === 'other' ?
            entity?.insulationMaterialNotes :
            entity?.insulationMaterial
          ),
          TSummaryItem(
            'insulation_upper_fire_plate',
            entity?.firePlateMaterial === 'other' ?
            entity?.firePlateMaterialNotes :
            entity?.firePlateMaterial,
            entity?.firePlateInstallationYear ?
            `(${entity?.firePlateInstallationYear})` :
            ''
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'upper_floor_right',
        items: [
          TSummaryItem(
            'ventilation_route',
            entity?.ventilationRouteType === 'other' ?
            entity?.ventilationRouteTypeNotes :
            entity?.ventilationRouteType
          ),
          TSummaryItem('parallel_to_water_roof_or_low_upper_floor_structure', entity?.parallelOrLowStructure),
          TSummaryItem(
            'ventilation_height',
            entity?.ventilationHeight === 'other' ?
            entity?.ventilationHeightNotes :
            entity?.ventilationHeight
          ),
        ]
      }
    ],
  }
}

const WaterRoofLayout = (entity: any) => {
  return {
    ...SummaryOption('water_roof'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'water_roof_left',
        items: [
          TSummaryItem(
            'type',
            entity?.type === 'other' ?
            entity?.typeNotes :
            entity?.type,
          ),
          TSummaryItem(
            'material',
            entity?.material === 'other' ?
            entity?.materialNotes :
            entity?.material,
            entity?.installationYear ?
            `(${entity?.installationYear})` :
            ''
          ),
          TSummaryItem(
            'bearing_structures',
            entity?.bearingStructureMaterial === 'other' ?
            entity?.bearingStructureMaterialNotes :
            entity?.bearingStructureMaterial,
            entity?.bearingStructureInstallationYear ?
            `(${entity?.bearingStructureInstallationYear})` :
            ''
          ),
          TSummaryItem('water_drainage_system', entity?.waterDrainageType),
          TSummaryItem(
            'water_drainage',
            entity?.waterDrainageMaterial === 'other' ?
            entity?.waterDrainageMaterialNotes :
            entity?.waterDrainageMaterial,
            entity?.waterDrainageInstallationYear ?
            `(${entity?.waterDrainageInstallationYear})` :
            ''
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'water_roof_right',
        items: [
          TSummaryItem('deicing_system', entity?.waterDrainageHasDeicing),
          TSummaryItem('bearing_structure_same_as_upper_floor', entity?.bearingStructureSameAsUpperFloor),
          TSummaryItem(
            'old_water_roof_as_underlayment',
            entity?.oldWaterRoofUnderlayment,
            entity?.underlaymentMaterial ?
              `${entity?.underlaymentMaterial === 'other' ? entity?.underlaymentMaterialNotes : i18n.t(entity?.underlaymentMaterial)} ${entity?.underlaymentInstallationYear ? `(${entity?.underlaymentInstallationYear})` : ''}`
              : '',
          ),
        ]
      }
    ]
  }
}

const BuildingWindowsLayout = (entity: any) => {
  return {
    ...SummaryOption('windows'),
    parentTitle: 'building_parts',
    ...GroupTitle,
    items: [
      {
        ...SummaryColumn,
        key: 'windows_left',
        items: [
          TSummaryWrapperItem(
            TArraySummaryItem(
              'window_types',
              entity?.apartmentWindowTypes?.split(',') || [],
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'window_types',
              entity?.commonSpaceWindowTypes?.split(',') || [],
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.apartmentWindowMsuMaterial,
                entity?.apartmentWindowMsMaterial,
                entity?.apartmentWindowMskMaterial,
                entity?.apartmentWindowMseaMaterial,
                entity?.apartmentWindowMseMaterial,
                entity?.apartmentWindowMekMaterial,
                entity?.apartmentWindowMekaMaterial,
                entity?.apartmentWindowSeMaterial,
                entity?.apartmentWindowMs2eMaterial,
                entity?.apartmentWindowMs3eMaterial,
                entity?.apartmentWindowDkMaterial,
                entity?.apartmentWindowMsuOtherMaterial,
                entity?.apartmentWindowMsOtherMaterial,
                entity?.apartmentWindowMskOtherMaterial,
                entity?.apartmentWindowMseaOtherMaterial,
                entity?.apartmentWindowMseOtherMaterial,
                entity?.apartmentWindowMekOtherMaterial,
                entity?.apartmentWindowMekaOtherMaterial,
                entity?.apartmentWindowSeOtherMaterial,
                entity?.apartmentWindowMs2eOtherMaterial,
                entity?.apartmentWindowMs3eOtherMaterial,
                entity?.apartmentWindowDkOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.commonSpaceWindowMsuMaterial,
                entity?.commonSpaceWindowMsMaterial,
                entity?.commonSpaceWindowMskMaterial,
                entity?.commonSpaceWindowMseaMaterial,
                entity?.commonSpaceWindowMseMaterial,
                entity?.commonSpaceWindowMekMaterial,
                entity?.commonSpaceWindowMekaMaterial,
                entity?.commonSpaceWindowSeMaterial,
                entity?.commonSpaceWindowMs2eMaterial,
                entity?.commonSpaceWindowMs3eMaterial,
                entity?.commonSpaceWindowDkMaterial,
                entity?.commonSpaceWindowMsuOtherMaterial,
                entity?.commonSpaceWindowMsOtherMaterial,
                entity?.commonSpaceWindowMskOtherMaterial,
                entity?.commonSpaceWindowMseaOtherMaterial,
                entity?.commonSpaceWindowMseOtherMaterial,
                entity?.commonSpaceWindowMekOtherMaterial,
                entity?.commonSpaceWindowMekaOtherMaterial,
                entity?.commonSpaceWindowSeOtherMaterial,
                entity?.commonSpaceWindowMs2eOtherMaterial,
                entity?.commonSpaceWindowMs3eOtherMaterial,
                entity?.commonSpaceWindowDkOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'type_count',
              calculateTotalCount([
                entity?.apartmentWindowMsuCount,
                entity?.apartmentWindowMsCount,
                entity?.apartmentWindowMskCount,
                entity?.apartmentWindowMseaCount,
                entity?.apartmentWindowMseCount,
                entity?.apartmentWindowMekCount,
                entity?.apartmentWindowMekaCount,
                entity?.apartmentWindowSeCount,
                entity?.apartmentWindowMs2eCount,
                entity?.apartmentWindowMs3eCount,
                entity?.apartmentWindowDkCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'type_count',
              calculateTotalCount([
                entity?.commonSpaceWindowMsuCount,
                entity?.commonSpaceWindowMsCount,
                entity?.commonSpaceWindowMskCount,
                entity?.commonSpaceWindowMseaCount,
                entity?.commonSpaceWindowMseCount,
                entity?.commonSpaceWindowMekCount,
                entity?.commonSpaceWindowMekaCount,
                entity?.commonSpaceWindowSeCount,
                entity?.commonSpaceWindowMs2eCount,
                entity?.commonSpaceWindowMs3eCount,
                entity?.commonSpaceWindowDkCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'windows_right',
        items: [
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.apartmentWindowMsuInstallationYear,
                entity?.apartmentWindowMsInstallationYear,
                entity?.apartmentWindowMskInstallationYear,
                entity?.apartmentWindowMseaInstallationYear,
                entity?.apartmentWindowMseInstallationYear,
                entity?.apartmentWindowMekInstallationYear,
                entity?.apartmentWindowMekaInstallationYear,
                entity?.apartmentWindowSeInstallationYear,
                entity?.apartmentWindowMs2eInstallationYear,
                entity?.apartmentWindowMs3eInstallationYear,
                entity?.apartmentWindowDkInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.commonSpaceWindowMsuInstallationYear,
                entity?.commonSpaceWindowMsInstallationYear,
                entity?.commonSpaceWindowMskInstallationYear,
                entity?.commonSpaceWindowMseaInstallationYear,
                entity?.commonSpaceWindowMseInstallationYear,
                entity?.commonSpaceWindowMekInstallationYear,
                entity?.commonSpaceWindowMekaInstallationYear,
                entity?.commonSpaceWindowSeInstallationYear,
                entity?.commonSpaceWindowMs2eInstallationYear,
                entity?.commonSpaceWindowMs3eInstallationYear,
                entity?.commonSpaceWindowDkInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipment',
              uniq(flatten([
                entity?.apartmentWindowMsuProperties?.split(',') || [],
                entity?.apartmentWindowMsProperties?.split(',') || [],
                entity?.apartmentWindowMskProperties?.split(',') || [],
                entity?.apartmentWindowMseaProperties?.split(',') || [],
                entity?.apartmentWindowMseProperties?.split(',') || [],
                entity?.apartmentWindowMekProperties?.split(',') || [],
                entity?.apartmentWindowMekaProperties?.split(',') || [],
                entity?.apartmentWindowSeProperties?.split(',') || [],
                entity?.apartmentWindowMs2eProperties?.split(',') || [],
                entity?.apartmentWindowMs3eProperties?.split(',') || [],
                entity?.apartmentWindowDkProperties?.split(',') || [],
              ]?.filter((item: string) => item) || [])),
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipment',
              uniq(flatten([
                entity?.commonSpaceWindowMsuProperties?.split(',') || [],
                entity?.commonSpaceWindowMsProperties?.split(',') || [],
                entity?.commonSpaceWindowMskProperties?.split(',') || [],
                entity?.commonSpaceWindowMseaProperties?.split(',') || [],
                entity?.commonSpaceWindowMseProperties?.split(',') || [],
                entity?.commonSpaceWindowMekProperties?.split(',') || [],
                entity?.commonSpaceWindowMekaProperties?.split(',') || [],
                entity?.commonSpaceWindowSeProperties?.split(',') || [],
                entity?.commonSpaceWindowMs2eProperties?.split(',') || [],
                entity?.commonSpaceWindowMs3eProperties?.split(',') || [],
                entity?.commonSpaceWindowDkProperties?.split(',') || [],
              ]?.filter((item: string) => item) || [])),
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
        ]
      }
    ]
  }
}

const BuildingDoorsLayout = (entity: any) => {
  return {
    ...SummaryOption('doors'),
    ...GroupTitle,
    parentTitle: 'building_parts',
    items: [
      {
        ...SummaryColumn,
        key: 'doors_left',
        items: [
          TSummaryWrapperItem(
            TArraySummaryItem(
              'door_types',
              entity?.exteriorDoorTypes?.split(',').map((item: string) => `${item}_door`) || [],
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'door_types',
              entity?.balconyAndTerraceDoorTypes?.split(',').map((item: string) => `${item}_door`) || [],
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'door_types',
              entity?.commonSpaceDoorTypes?.split(',').map((item: string) => `${item}_door`) || [],
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'door_types',
              entity?.apartmentDoorTypes?.split(',').map((item: string) => `${item}_door`) || [],
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'door_types',
              entity?.interiorDoorTypes?.split(',').map((item: string) => `${item}_door`) || [],
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'fire_compartmentalization',
              [
                entity?.exteriorDoorSingleLeafFireCompartmentalization === 'yes' ? 'single_leaf_door' : null,
                entity?.exteriorDoorDoubleLeafFireCompartmentalization === 'yes' ? 'double_leaf_door' : null,
                entity?.exteriorDoorRevolvingFireCompartmentalization === 'yes' ? 'revolving_door' : null,
                entity?.exteriorDoorOverheadFireCompartmentalization === 'yes' ? 'overhead_door' : null,
                entity?.exteriorDoorSlidingFireCompartmentalization === 'yes' ? 'sliding_door' : null,
                entity?.exteriorDoorOtherFireCompartmentalization === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'fire_compartmentalization',
              [
                entity?.balconyAndTerraceDoorSingleLeafFireCompartmentalization === 'yes' ? 'single_leaf_door' : null,
                entity?.balconyAndTerraceDoorDoubleLeafFireCompartmentalization === 'yes' ? 'double_leaf_door' : null,
                entity?.balconyAndTerraceDoorRevolvingFireCompartmentalization === 'yes' ? 'revolving_door' : null,
                entity?.balconyAndTerraceDoorOverheadFireCompartmentalization === 'yes' ? 'overhead_door' : null,
                entity?.balconyAndTerraceDoorSlidingFireCompartmentalization === 'yes' ? 'sliding_door' : null,
                entity?.balconyAndTerraceDoorOtherFireCompartmentalization === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'fire_compartmentalization',
              [
                entity?.commonSpaceDoorSingleLeafFireCompartmentalization === 'yes' ? 'single_leaf_door' : null,
                entity?.commonSpaceDoorDoubleLeafFireCompartmentalization === 'yes' ? 'double_leaf_door' : null,
                entity?.commonSpaceDoorRevolvingFireCompartmentalization === 'yes' ? 'revolving_door' : null,
                entity?.commonSpaceDoorOverheadFireCompartmentalization === 'yes' ? 'overhead_door' : null,
                entity?.commonSpaceDoorSlidingFireCompartmentalization === 'yes' ? 'sliding_door' : null,
                entity?.commonSpaceDoorOtherFireCompartmentalization === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'fire_compartmentalization',
              [
                entity?.apartmentDoorSingleLeafFireCompartmentalization === 'yes' ? 'single_leaf_door' : null,
                entity?.apartmentDoorDoubleLeafFireCompartmentalization === 'yes' ? 'double_leaf_door' : null,
                entity?.apartmentDoorRevolvingFireCompartmentalization === 'yes' ? 'revolving_door' : null,
                entity?.apartmentDoorOverheadFireCompartmentalization === 'yes' ? 'overhead_door' : null,
                entity?.apartmentDoorSlidingFireCompartmentalization === 'yes' ? 'sliding_door' : null,
                entity?.apartmentDoorOtherFireCompartmentalization === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'fire_compartmentalization',
              [
                entity?.interiorDoorSingleLeafFireCompartmentalization === 'yes' ? 'single_leaf_door' : null,
                entity?.interiorDoorDoubleLeafFireCompartmentalization === 'yes' ? 'double_leaf_door' : null,
                entity?.interiorDoorRevolvingFireCompartmentalization === 'yes' ? 'revolving_door' : null,
                entity?.interiorDoorOverheadFireCompartmentalization === 'yes' ? 'overhead_door' : null,
                entity?.interiorDoorSlidingFireCompartmentalization === 'yes' ? 'sliding_door' : null,
                entity?.interiorDoorOtherFireCompartmentalization === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipped_with_extension',
              [
                entity?.exteriorDoorSingleLeafHasExtension === 'yes' ? 'single_leaf_door' : null,
                entity?.exteriorDoorDoubleLeafHasExtension === 'yes' ? 'double_leaf_door' : null,
                entity?.exteriorDoorRevolvingHasExtension === 'yes' ? 'revolving_door' : null,
                entity?.exteriorDoorOverheadHasExtension === 'yes' ? 'overhead_door' : null,
                entity?.exteriorDoorSlidingHasExtension === 'yes' ? 'sliding_door' : null,
                entity?.exteriorDoorOtherHasExtension === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipped_with_extension',
              [
                entity?.balconyAndTerraceDoorSingleLeafHasExtension === 'yes' ? 'single_leaf_door' : null,
                entity?.balconyAndTerraceDoorDoubleLeafHasExtension === 'yes' ? 'double_leaf_door' : null,
                entity?.balconyAndTerraceDoorRevolvingHasExtension === 'yes' ? 'revolving_door' : null,
                entity?.balconyAndTerraceDoorOverheadHasExtension === 'yes' ? 'overhead_door' : null,
                entity?.balconyAndTerraceDoorSlidingHasExtension === 'yes' ? 'sliding_door' : null,
                entity?.balconyAndTerraceDoorOtherHasExtension === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipped_with_extension',
              [
                entity?.commonSpaceDoorSingleLeafHasExtension === 'yes' ? 'single_leaf_door' : null,
                entity?.commonSpaceDoorDoubleLeafHasExtension === 'yes' ? 'double_leaf_door' : null,
                entity?.commonSpaceDoorRevolvingHasExtension === 'yes' ? 'revolving_door' : null,
                entity?.commonSpaceDoorOverheadHasExtension === 'yes' ? 'overhead_door' : null,
                entity?.commonSpaceDoorSlidingHasExtension === 'yes' ? 'sliding_door' : null,
                entity?.commonSpaceDoorOtherHasExtension === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipped_with_extension',
              [
                entity?.apartmentDoorSingleLeafHasExtension === 'yes' ? 'single_leaf_door' : null,
                entity?.apartmentDoorDoubleLeafHasExtension === 'yes' ? 'double_leaf_door' : null,
                entity?.apartmentDoorRevolvingHasExtension === 'yes' ? 'revolving_door' : null,
                entity?.apartmentDoorOverheadHasExtension === 'yes' ? 'overhead_door' : null,
                entity?.apartmentDoorSlidingHasExtension === 'yes' ? 'sliding_door' : null,
                entity?.apartmentDoorOtherHasExtension === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'equipped_with_extension',
              [
                entity?.interiorDoorSingleLeafHasExtension === 'yes' ? 'single_leaf_door' : null,
                entity?.interiorDoorDoubleLeafHasExtension === 'yes' ? 'double_leaf_door' : null,
                entity?.interiorDoorRevolvingHasExtension === 'yes' ? 'revolving_door' : null,
                entity?.interiorDoorOverheadHasExtension === 'yes' ? 'overhead_door' : null,
                entity?.interiorDoorSlidingHasExtension === 'yes' ? 'sliding_door' : null,
                entity?.interiorDoorOtherHasExtension === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'turning_clip_hinges',
              [
                entity?.exteriorDoorSingleLeafHasTurningClipHinges === 'yes' ? 'single_leaf_door' : null,
                entity?.exteriorDoorDoubleLeafHasTurningClipHinges === 'yes' ? 'double_leaf_door' : null,
                entity?.exteriorDoorRevolvingHasTurningClipHinges === 'yes' ? 'revolving_door' : null,
                entity?.exteriorDoorOverheadHasTurningClipHinges === 'yes' ? 'overhead_door' : null,
                entity?.exteriorDoorSlidingHasTurningClipHinges === 'yes' ? 'sliding_door' : null,
                entity?.exteriorDoorOtherHasTurningClipHinges === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'turning_clip_hinges',
              [
                entity?.balconyAndTerraceDoorSingleLeafHasTurningClipHinges === 'yes' ? 'single_leaf_door' : null,
                entity?.balconyAndTerraceDoorDoubleLeafHasTurningClipHinges === 'yes' ? 'double_leaf_door' : null,
                entity?.balconyAndTerraceDoorRevolvingHasTurningClipHinges === 'yes' ? 'revolving_door' : null,
                entity?.balconyAndTerraceDoorOverheadHasTurningClipHinges === 'yes' ? 'overhead_door' : null,
                entity?.balconyAndTerraceDoorSlidingHasTurningClipHinges === 'yes' ? 'sliding_door' : null,
                entity?.balconyAndTerraceDoorOtherHasTurningClipHinges === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'turning_clip_hinges',
              [
                entity?.commonSpaceDoorSingleLeafHasTurningClipHinges === 'yes' ? 'single_leaf_door' : null,
                entity?.commonSpaceDoorDoubleLeafHasTurningClipHinges === 'yes' ? 'double_leaf_door' : null,
                entity?.commonSpaceDoorRevolvingHasTurningClipHinges === 'yes' ? 'revolving_door' : null,
                entity?.commonSpaceDoorOverheadHasTurningClipHinges === 'yes' ? 'overhead_door' : null,
                entity?.commonSpaceDoorSlidingHasTurningClipHinges === 'yes' ? 'sliding_door' : null,
                entity?.commonSpaceDoorOtherHasTurningClipHinges === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'turning_clip_hinges',
              [
                entity?.apartmentDoorSingleLeafHasTurningClipHinges === 'yes' ? 'single_leaf_door' : null,
                entity?.apartmentDoorDoubleLeafHasTurningClipHinges === 'yes' ? 'double_leaf_door' : null,
                entity?.apartmentDoorRevolvingHasTurningClipHinges === 'yes' ? 'revolving_door' : null,
                entity?.apartmentDoorOverheadHasTurningClipHinges === 'yes' ? 'overhead_door' : null,
                entity?.apartmentDoorSlidingHasTurningClipHinges === 'yes' ? 'sliding_door' : null,
                entity?.apartmentDoorOtherHasTurningClipHinges === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'turning_clip_hinges',
              [
                entity?.interiorDoorSingleLeafHasTurningClipHinges === 'yes' ? 'single_leaf_door' : null,
                entity?.interiorDoorDoubleLeafHasTurningClipHinges === 'yes' ? 'double_leaf_door' : null,
                entity?.interiorDoorRevolvingHasTurningClipHinges === 'yes' ? 'revolving_door' : null,
                entity?.interiorDoorOverheadHasTurningClipHinges === 'yes' ? 'overhead_door' : null,
                entity?.interiorDoorSlidingHasTurningClipHinges === 'yes' ? 'sliding_door' : null,
                entity?.interiorDoorOtherHasTurningClipHinges === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.exteriorDoorSingleLeafMaterial,
                entity?.exteriorDoorDoubleLeafMaterial,
                entity?.exteriorDoorRevolvingMaterial,
                entity?.exteriorDoorOverheadMaterial,
                entity?.exteriorDoorSlidingMaterial,
                entity?.exteriorDoorOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.balconyAndTerraceDoorSingleLeafMaterial,
                entity?.balconyAndTerraceDoorDoubleLeafMaterial,
                entity?.balconyAndTerraceDoorRevolvingMaterial,
                entity?.balconyAndTerraceDoorOverheadMaterial,
                entity?.balconyAndTerraceDoorSlidingMaterial,
                entity?.balconyAndTerraceDoorOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.commonSpaceDoorSingleLeafMaterial,
                entity?.commonSpaceDoorDoubleLeafMaterial,
                entity?.commonSpaceDoorRevolvingMaterial,
                entity?.commonSpaceDoorOverheadMaterial,
                entity?.commonSpaceDoorSlidingMaterial,
                entity?.commonSpaceDoorOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.apartmentDoorSingleLeafMaterial,
                entity?.apartmentDoorDoubleLeafMaterial,
                entity?.apartmentDoorRevolvingMaterial,
                entity?.apartmentDoorOverheadMaterial,
                entity?.apartmentDoorSlidingMaterial,
                entity?.apartmentDoorOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'material',
              uniq([
                entity?.interiorDoorSingleLeafMaterial,
                entity?.interiorDoorDoubleLeafMaterial,
                entity?.interiorDoorRevolvingMaterial,
                entity?.interiorDoorOverheadMaterial,
                entity?.interiorDoorSlidingMaterial,
                entity?.interiorDoorOtherMaterial,
              ]?.filter((item: string) => item && item !== 'other') || []),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'doors_right',
        items: [
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.exteriorDoorSingleLeafInstallationYear,
                entity?.exteriorDoorDoubleLeafInstallationYear,
                entity?.exteriorDoorRevolvingInstallationYear,
                entity?.exteriorDoorOverheadInstallationYear,
                entity?.exteriorDoorSlidingInstallationYear,
                entity?.exteriorDoorOtherInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.balconyAndTerraceDoorSingleLeafInstallationYear,
                entity?.balconyAndTerraceDoorDoubleLeafInstallationYear,
                entity?.balconyAndTerraceDoorRevolvingInstallationYear,
                entity?.balconyAndTerraceDoorOverheadInstallationYear,
                entity?.balconyAndTerraceDoorSlidingInstallationYear,
                entity?.balconyAndTerraceDoorOtherInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.commonSpaceDoorSingleLeafInstallationYear,
                entity?.commonSpaceDoorDoubleLeafInstallationYear,
                entity?.commonSpaceDoorRevolvingInstallationYear,
                entity?.commonSpaceDoorOverheadInstallationYear,
                entity?.commonSpaceDoorSlidingInstallationYear,
                entity?.commonSpaceDoorOtherInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.apartmentDoorSingleLeafInstallationYear,
                entity?.apartmentDoorDoubleLeafInstallationYear,
                entity?.apartmentDoorRevolvingInstallationYear,
                entity?.apartmentDoorOverheadInstallationYear,
                entity?.apartmentDoorSlidingInstallationYear,
                entity?.apartmentDoorOtherInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'installation_year',
              uniq([
                entity?.interiorDoorSingleLeafInstallationYear,
                entity?.interiorDoorDoubleLeafInstallationYear,
                entity?.interiorDoorRevolvingInstallationYear,
                entity?.interiorDoorOverheadInstallationYear,
                entity?.interiorDoorSlidingInstallationYear,
                entity?.interiorDoorOtherInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'has_lock',
              [
                entity?.exteriorDoorSingleLeafHasLock === 'yes' ? 'single_leaf_door' : null,
                entity?.exteriorDoorDoubleLeafHasLock === 'yes' ? 'double_leaf_door' : null,
                entity?.exteriorDoorRevolvingHasLock === 'yes' ? 'revolving_door' : null,
                entity?.exteriorDoorOverheadHasLock === 'yes' ? 'overhead_door' : null,
                entity?.exteriorDoorSlidingHasLock === 'yes' ? 'sliding_door' : null,
                entity?.exteriorDoorOtherHasLock === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'has_lock',
              [
                entity?.balconyAndTerraceDoorSingleLeafHasLock === 'yes' ? 'single_leaf_door' : null,
                entity?.balconyAndTerraceDoorDoubleLeafHasLock === 'yes' ? 'double_leaf_door' : null,
                entity?.balconyAndTerraceDoorRevolvingHasLock === 'yes' ? 'revolving_door' : null,
                entity?.balconyAndTerraceDoorOverheadHasLock === 'yes' ? 'overhead_door' : null,
                entity?.balconyAndTerraceDoorSlidingHasLock === 'yes' ? 'sliding_door' : null,
                entity?.balconyAndTerraceDoorOtherHasLock === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'has_lock',
              [
                entity?.commonSpaceDoorSingleLeafHasLock === 'yes' ? 'single_leaf_door' : null,
                entity?.commonSpaceDoorDoubleLeafHasLock === 'yes' ? 'double_leaf_door' : null,
                entity?.commonSpaceDoorRevolvingHasLock === 'yes' ? 'revolving_door' : null,
                entity?.commonSpaceDoorOverheadHasLock === 'yes' ? 'overhead_door' : null,
                entity?.commonSpaceDoorSlidingHasLock === 'yes' ? 'sliding_door' : null,
                entity?.commonSpaceDoorOtherHasLock === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'has_lock',
              [
                entity?.apartmentDoorSingleLeafHasLock === 'yes' ? 'single_leaf_door' : null,
                entity?.apartmentDoorDoubleLeafHasLock === 'yes' ? 'double_leaf_door' : null,
                entity?.apartmentDoorRevolvingHasLock === 'yes' ? 'revolving_door' : null,
                entity?.apartmentDoorOverheadHasLock === 'yes' ? 'overhead_door' : null,
                entity?.apartmentDoorSlidingHasLock === 'yes' ? 'sliding_door' : null,
                entity?.apartmentDoorOtherHasLock === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'has_lock',
              [
                entity?.interiorDoorSingleLeafHasLock === 'yes' ? 'single_leaf_door' : null,
                entity?.interiorDoorDoubleLeafHasLock === 'yes' ? 'double_leaf_door' : null,
                entity?.interiorDoorRevolvingHasLock === 'yes' ? 'revolving_door' : null,
                entity?.interiorDoorOverheadHasLock === 'yes' ? 'overhead_door' : null,
                entity?.interiorDoorSlidingHasLock === 'yes' ? 'sliding_door' : null,
                entity?.interiorDoorOtherHasLock === 'yes' ? 'other_type' : null,
              ].filter((item: string | null) => item),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_types',
              uniq([
                entity?.exteriorDoorSingleLeafLockType,
                entity?.exteriorDoorDoubleLeafLockType,
                entity?.exteriorDoorRevolvingLockType,
                entity?.exteriorDoorOverheadLockType,
                entity?.exteriorDoorSlidingLockType,
                entity?.exteriorDoorOtherLockType,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_types',
              uniq([
                entity?.balconyAndTerraceDoorSingleLeafLockType,
                entity?.balconyAndTerraceDoorDoubleLeafLockType,
                entity?.balconyAndTerraceDoorRevolvingLockType,
                entity?.balconyAndTerraceDoorOverheadLockType,
                entity?.balconyAndTerraceDoorSlidingLockType,
                entity?.balconyAndTerraceDoorOtherLockType,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_types',
              uniq([
                entity?.commonSpaceDoorSingleLeafLockType,
                entity?.commonSpaceDoorDoubleLeafLockType,
                entity?.commonSpaceDoorRevolvingLockType,
                entity?.commonSpaceDoorOverheadLockType,
                entity?.commonSpaceDoorSlidingLockType,
                entity?.commonSpaceDoorOtherLockType,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_types',
              uniq([
                entity?.apartmentDoorSingleLeafLockType,
                entity?.apartmentDoorDoubleLeafLockType,
                entity?.apartmentDoorRevolvingLockType,
                entity?.apartmentDoorOverheadLockType,
                entity?.apartmentDoorSlidingLockType,
                entity?.apartmentDoorOtherLockType,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_types',
              uniq([
                entity?.interiorDoorSingleLeafLockType,
                entity?.interiorDoorDoubleLeafLockType,
                entity?.interiorDoorRevolvingLockType,
                entity?.interiorDoorOverheadLockType,
                entity?.interiorDoorSlidingLockType,
                entity?.interiorDoorOtherLockType,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_installation_year',
              uniq([
                entity?.exteriorDoorSingleLeafLockInstallationYear,
                entity?.exteriorDoorDoubleLeafLockInstallationYear,
                entity?.exteriorDoorRevolvingLockInstallationYear,
                entity?.exteriorDoorOverheadLockInstallationYear,
                entity?.exteriorDoorSlidingLockInstallationYear,
                entity?.exteriorDoorOtherLockInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_installation_year',
              uniq([
                entity?.balconyAndTerraceDoorSingleLeafLockInstallationYear,
                entity?.balconyAndTerraceDoorDoubleLeafLockInstallationYear,
                entity?.balconyAndTerraceDoorRevolvingLockInstallationYear,
                entity?.balconyAndTerraceDoorOverheadLockInstallationYear,
                entity?.balconyAndTerraceDoorSlidingLockInstallationYear,
                entity?.balconyAndTerraceDoorOtherLockInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_installation_year',
              uniq([
                entity?.commonSpaceDoorSingleLeafLockInstallationYear,
                entity?.commonSpaceDoorDoubleLeafLockInstallationYear,
                entity?.commonSpaceDoorRevolvingLockInstallationYear,
                entity?.commonSpaceDoorOverheadLockInstallationYear,
                entity?.commonSpaceDoorSlidingLockInstallationYear,
                entity?.commonSpaceDoorOtherLockInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_installation_year',
              uniq([
                entity?.apartmentDoorSingleLeafLockInstallationYear,
                entity?.apartmentDoorDoubleLeafLockInstallationYear,
                entity?.apartmentDoorRevolvingLockInstallationYear,
                entity?.apartmentDoorOverheadLockInstallationYear,
                entity?.apartmentDoorSlidingLockInstallationYear,
                entity?.apartmentDoorOtherLockInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TArraySummaryItem(
              'lock_installation_year',
              uniq([
                entity?.interiorDoorSingleLeafLockInstallationYear,
                entity?.interiorDoorDoubleLeafLockInstallationYear,
                entity?.interiorDoorRevolvingLockInstallationYear,
                entity?.interiorDoorOverheadLockInstallationYear,
                entity?.interiorDoorSlidingLockInstallationYear,
                entity?.interiorDoorOtherLockInstallationYear,
              ]?.filter((item: string) => item) || []),
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              calculateTotalCount([
                entity?.exteriorDoorSingleLeafCount,
                entity?.exteriorDoorDoubleLeafCount,
                entity?.exteriorDoorRevolvingCount,
                entity?.exteriorDoorOverheadCount,
                entity?.exteriorDoorSlidingCount,
                entity?.exteriorDoorOtherCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('exterior_doors') }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              calculateTotalCount([
                entity?.balconyAndTerraceDoorSingleLeafCount,
                entity?.balconyAndTerraceDoorDoubleLeafCount,
                entity?.balconyAndTerraceDoorRevolvingCount,
                entity?.balconyAndTerraceDoorOverheadCount,
                entity?.balconyAndTerraceDoorSlidingCount,
                entity?.balconyAndTerraceDoorOtherCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('balcony_and_terrace_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              calculateTotalCount([
                entity?.commonSpaceDoorSingleLeafCount,
                entity?.commonSpaceDoorDoubleLeafCount,
                entity?.commonSpaceDoorRevolvingCount,
                entity?.commonSpaceDoorOverheadCount,
                entity?.commonSpaceDoorSlidingCount,
                entity?.commonSpaceDoorOtherCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('common_space_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              calculateTotalCount([
                entity?.apartmentDoorSingleLeafCount,
                entity?.apartmentDoorDoubleLeafCount,
                entity?.apartmentDoorRevolvingCount,
                entity?.apartmentDoorOverheadCount,
                entity?.apartmentDoorSlidingCount,
                entity?.apartmentDoorOtherCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('apartment_doors'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              calculateTotalCount([
                entity?.interiorDoorSingleLeafCount,
                entity?.interiorDoorDoubleLeafCount,
                entity?.interiorDoorRevolvingCount,
                entity?.interiorDoorOverheadCount,
                entity?.interiorDoorSlidingCount,
                entity?.interiorDoorOtherCount,
              ]),
              'kpl'
            ),
            { group: i18n.t('interior_doors'), hideLabel: true }
          ),
        ]
      }
    ]
  }
}

const BuildingOtherDoorsLayout = (entity: any) => {
  return {
    key: 'other_doors',
    items: [
      {
        ...SummaryOption('entrance_doors'),
        ...GroupTitle,
        parentKey: 'doors',
        items: [
          {
            ...SummaryColumn,
            key: 'entrance_doors_left',
            items: [
              SummaryItem(
                'manufacturer',
                entity?.entranceDoorManufacturer
              ),
              SummaryItem(
                'model',
                entity?.entranceDoorModel
              ),
              SummaryItem(
                'count',
                entity?.entranceDoorCount,
                'kpl'
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'entrance_doors_right',
            items: [
              SummaryItem(
                'commissioning_year',
                entity?.entranceDoorInstallationYear
              ),
              SummaryItem(
                'location',
                entity?.entranceDoorLocation
              )
            ]
          }
        ]
      },
      {
        ...SummaryOption('large_fire_doors'),
        ...GroupTitle,
        parentKey: 'doors',
        items: [
          {
            ...SummaryColumn,
            key: 'large_fire_doors_left',
            items: [
              SummaryItem(
                'manufacturer',
                entity?.largeFireDoorManufacturer
              ),
              SummaryItem(
                'model',
                entity?.largeFireDoorModel
              ),
              SummaryItem(
                'count',
                entity?.largeFireDoorCount,
                'kpl'
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'large_fire_doors_right',
            parentKey: 'doors',
            items: [
              SummaryItem(
                'commissioning_year',
                entity?.largeFireDoorInstallationYear
              ),
              SummaryItem(
                'location',
                entity?.largeFireDoorLocation
              )
            ]
          }
        ]
      },
      {
        ...SummaryOption('special_doors'),
        ...GroupTitle,
        parentKey: 'doors',
        items: [
          {
            ...SummaryColumn,
            key: 'special_doors_left',
            items: [
              SummaryItem(
                'manufacturer',
                entity?.specialDoorManufacturer
              ),
              SummaryItem(
                'model',
                entity?.specialDoorModel
              ),
              SummaryItem(
                'count',
                entity?.specialDoorCount,
                'kpl'
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'special_doors_right',
            parentKey: 'doors',
            items: [
              SummaryItem(
                'commissioning_year',
                entity?.specialDoorInstallationYear
              ),
              SummaryItem(
                'location',
                entity?.specialDoorLocation
              )
            ]
          }
        ]
      },
      {
        ...SummaryOption('dock_doors'),
        ...GroupTitle,
        parentKey: 'doors',
        items: [
          {
            ...SummaryColumn,
            key: 'dock_doors_left',
            items: [
              SummaryItem(
                'manufacturer',
                entity?.dockDoorManufacturer
              ),
              SummaryItem(
                'model',
                entity?.dockDoorModel
              ),
              SummaryItem(
                'count',
                entity?.dockDoorCount,
                'kpl'
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'dock_doors_right',
            items: [
              SummaryItem(
                'commissioning_year',
                entity?.dockDoorInstallationYear
              ),
              SummaryItem(
                'location',
                entity?.dockDoorLocation
              )
            ]
          }
        ]
      },
      {
        ...SummaryOption('loading_bridges'),
        ...GroupTitle,
        parentKey: 'doors',
        items: [
          {
            ...SummaryColumn,
            key: 'loading_bridges_left',
            items: [
              SummaryItem(
                'manufacturer',
                entity?.loadingBridgeManufacturer
              ),
              SummaryItem(
                'model',
                entity?.loadingBridgeModel
              ),
              SummaryItem(
                'count',
                entity?.loadingBridgeCount,
                'kpl'
              ),
              SummaryItem(
                'commissioning_year',
                entity?.loadingBridgeInstallationYear
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'loading_bridges_right',
            items: [
              SummaryItem(
                'carrying_capacity',
                entity?.loadingBridgeCarryingCapacity,
                't'
              ),
              SummaryItem(
                'height_from_ground',
                entity?.loadingBridgeHeightFromGround,
                'm'
              ),
              SummaryItem(
                'location',
                entity?.loadingBridgeLocation
              )
            ]
          },
        ]
      }
    ]
  }
}

const BuildingBalconiesLayout = (entity: any) => {
  return {
    ...SummaryOption('balconies'),
    ...GroupTitle,
    parentTitle: 'building_parts',
    items: [
      {
        ...SummaryColumn,
        key: 'balconies_left',
        items: [
          TSummaryWrapperItem(
            TSummaryItem(
              'type',
              entity?.apartmentBalconyType,
              entity?.apartmentBalconyInstallationYear ?
              ` (${entity?.apartmentBalconyInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'type',
              entity?.commonSpaceBalconyType,
              entity?.commonSpaceBalconyInstallationYear ?
              ` (${entity?.commonSpaceBalconyInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'glazed_balconies',
              entity?.apartmentsHaveGlazedBalconies,
              entity?.apartmentBalconyGlazingInstallationYear ?
              ` (${entity?.apartmentBalconyGlazingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'glazed_balconies',
              entity?.commonSpacesHaveGlazedBalconies,
              entity?.commonSpaceBalconyGlazingInstallationYear ?
              ` (${entity?.commonSpaceBalconyGlazingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem('bearing_structures', entity?.apartmentBalconyStructureType),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem('bearing_structures', entity?.commonSpaceBalconyStructureType),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem('material', entity?.apartmentBalconyStructureMaterial),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem('material', entity?.commonSpaceBalconyStructureMaterial),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'balconies_right',
        items: [
          TSummaryWrapperItem(
            TSummaryItem(
              'water_proofing',
              entity?.apartmentBalconyWaterProofingType === 'other' ?
              entity?.apartmentBalconyWaterProofingTypeNotes :
              entity?.apartmentBalconyWaterProofingType,
              entity?.apartmentBalconyWaterProofingInstallationYear ?
              ` (${entity?.apartmentBalconyWaterProofingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'water_proofing',
              entity?.commonSpaceBalconyWaterProofingType === 'other' ?
              entity?.commonSpaceBalconyWaterProofingTypeNotes :
              entity?.commonSpaceBalconyWaterProofingType,
              entity?.commonSpaceBalconyWaterProofingInstallationYear ?
              ` (${entity?.commonSpaceBalconyWaterProofingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'rails',
              entity?.apartmentBalconyRailType,
              entity?.apartmentBalconyRailInstallationYear ?
              ` (${entity?.apartmentBalconyRailInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'rails',
              entity?.commonSpaceBalconyRailType,
              entity?.commonSpaceBalconyRailInstallationYear ?
              ` (${entity?.commonSpaceBalconyRailInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              entity?.apartmentBalconyCount,
                'kpl' + (entity?.apartmentBalconyGlazedCount ?
                ` (${entity?.apartmentBalconyGlazedCount} ${i18n.t('glazed')})` :
                ''
              )
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              entity?.commonSpaceBalconyCount,
              'kpl' + (entity?.commonSpaceBalconyGlazedCount ?
                ` (${entity?.commonSpaceBalconyGlazedCount} ${i18n.t('glazed')})` :
                ''
              )
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
        ]
      }
    ]
  }
}

const BuildingTerracesLayout = (entity: any) => {
  return {
    ...SummaryOption('terraces'),
    ...GroupTitle,
    parentTitle: 'building_parts',
    items: [
      {
        ...SummaryColumn,
        key: 'terraces_left',
        items: [
          TSummaryWrapperItem(
            TSummaryItem(
              'type',
              entity?.apartmentTerraceType,
              entity?.apartmentTerraceInstallationYear ?
              ` (${entity?.apartmentTerraceInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'type',
              entity?.commonSpaceTerraceType,
              entity?.commonSpaceTerraceInstallationYear ?
              ` (${entity?.commonSpaceTerraceInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'glazed_terraces',
              entity?.apartmentsHaveGlazedTerraces,
              entity?.apartmentTerraceGlazingInstallationYear ?
              ` (${entity?.apartmentTerraceGlazingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'glazed_terraces',
              entity?.commonSpaceHasGlazedTerraces,
              entity?.commonSpaceTerraceGlazingInstallationYear ?
              ` (${entity?.commonSpaceTerraceGlazingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'frame_material',
              entity?.apartmentTerraceFrameMaterial === 'other' ?
              entity?.apartmentTerraceFrameMaterialNotes :
              entity?.apartmentTerraceFrameMaterial,
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'frame_material',
              entity?.commonSpaceTerraceFrameMaterial === 'other' ?
              entity?.commonSpaceTerraceFrameMaterialNotes :
              entity?.commonSpaceTerraceFrameMaterial,
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'surface_structures',
              entity?.apartmentTerraceSurfaceMaterial === 'other' ?
              entity?.apartmentTerraceSurfaceMaterialNotes :
              entity?.apartmentTerraceSurfaceMaterial,
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'surface_structures',
              entity?.commonSpaceTerraceSurfaceMaterial === 'other' ?
              entity?.commonSpaceTerraceSurfaceMaterialNotes :
              entity?.commonSpaceTerraceSurfaceMaterial,
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'terraces_right',
        items: [
          TSummaryWrapperItem(
            TSummaryItem(
              'water_proofing',
              entity?.apartmentTerraceWaterProofingType === 'other' ?
              entity?.apartmentTerraceWaterProofingTypeNotes :
              entity?.apartmentTerraceWaterProofingType,
              entity?.apartmentTerraceWaterProofingInstallationYear ?
              ` (${entity?.apartmentTerraceWaterProofingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'water_proofing',
              entity?.commonSpaceTerraceWaterProofingType === 'other' ?
              entity?.commonSpaceTerraceWaterProofingTypeNotes :
              entity?.commonSpaceTerraceWaterProofingType,
              entity?.commonSpaceTerraceWaterProofingInstallationYear ?
              ` (${entity?.commonSpaceTerraceWaterProofingInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'rails',
              entity?.apartmentTerraceRailMaterial,
              entity?.apartmentTerraceRailInstallationYear ?
              ` (${entity?.apartmentTerraceRailInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'rails',
              entity?.commonSpaceTerraceRailMaterial,
              entity?.commonSpaceTerraceRailInstallationYear ?
              ` (${entity?.commonSpaceTerraceRailInstallationYear})` :
              ''
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              entity?.apartmentTerraceCount,
              'kpl' + (entity?.apartmentTerraceGlazedCount ?
              ` (${entity?.apartmentTerraceGlazedCount} ${i18n.t('glazed')})` :
              ''),
            ),
            { group: i18n.t('in_apartments') }
          ),
          TSummaryWrapperItem(
            SummaryItem(
              'count',
              entity?.commonSpaceBalconyCount,
              'kpl' + (entity?.commonSpaceBalconyGlazedCount ?
              ` (${entity?.commonSpaceBalconyGlazedCount} ${i18n.t('glazed')})` :
              ''),
            ),
            { group: i18n.t('in_common_spaces'), hideLabel: true }
          ),
        ]
      }
    ]
  }
}

const BuildingVentilationLayout = (entity: any) => {
  return {
    ...SummaryOption('ventilation'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'systems_ventilation_left',
        items: [
          TSummaryItem('type', entity?.type),
          TSummaryWrapperItem(
            TSummaryItem('ventilation_ducts', entity?.ductMaterial),
            { group: i18n.t('material') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'duct_size',
              entity?.ductSize === 'other' ?
              entity?.ductSizeNotes :
              entity?.ductSize
            ),
            { group: i18n.t('size'), hideLabel: true }
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'machine_location',
              entity?.machineLocation === 'other' ?
              entity?.machineLocationNotes :
              entity?.machineLocation
            ),
            entity?.machineLocation,
            [entity?.type, ['mechanical', 'mechanical_supply_and_extract']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem('condense_water_drainage', entity?.hasCondenseWaterDrainage),
            entity?.hasCondenseWaterDrainage,
            [entity?.type, ['mechanical', 'mechanical_supply_and_extract']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem('heat_recovery', entity?.hasHeatRecovery),
            entity?.hasHeatRecovery,
            [entity?.type, ['mechanical', 'mechanical_supply_and_extract']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem('apartment_specific_machine', entity?.hasApartmentSpecificMachine),
            entity?.hasApartmentSpecificMachine,
            [entity?.type, ['mechanical', 'mechanical_supply_and_extract']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'condense_water_drainage_location',
              entity?.condenseWaterDrainageLocation === 'other' ?
              entity?.condenseWaterDrainageLocationNotes :
              entity?.condenseWaterDrainageLocation
            ),
            entity?.hasCondenseWaterDrainageLocation,
            [entity?.hasCondenseWaterDrainage, ['yes']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem('heat_recovery_cell', entity?.heatRecoveryCell),
            entity?.heatRecoveryCell,
            [entity?.hasHeatRecovery, ['yes']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'apartment_specific_machine_location',
              entity?.apartmentSpecificMachineLocation === 'other' ?
              entity?.apartmentSpecificMachineLocationNotes :
              entity?.apartmentSpecificMachineLocation
            ),
            entity?.apartmentSpecificMachineLocation,
            [entity?.hasApartmentSpecificMachine, ['yes']]
          ),
        ]
      },
      {
        ...SummaryColumn,
        key: 'systems_ventilation_right',
        items: [
          TSummaryWrapperItem(
            TSummaryItem(
              'valves',
              entity?.extractValveType === 'other' ?
              entity?.extractValveTypeNotes :
              entity?.extractValveType
            ),
            { group: i18n.t('extract') }
          ),
          TSummaryWrapperItem(
            TSummaryItem(
              'valves',
              entity?.supplyValveType === 'other' ?
              entity?.supplyValveTypeNotes :
              entity?.supplyValveType
            ),
            { group: i18n.t('supply'), hideLabel: true }
          ),
          TSummaryItem(
            'replacement_air_route',
            entity?.replacementAirRoute === 'other' ?
            entity?.replacementAirRouteNotes :
            entity?.replacementAirRoute
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'machine_model',
              entity?.machineModel === 'other' ?
              entity?.machineModelNotes :
              entity?.machineModel
            ),
            entity?.machineModel,
            [entity?.type, ['mechanical', 'mechanical_supply_and_extract']]
          ),
          TSummaryVisibilityWrapper(
            TSummaryItem(
              'filters',
              entity?.filterType,
              entity?.filtersReplacedAt ?
              `(${entity?.filtersReplacedAt})` :
              ''
            ),
            entity?.filterType,
            [entity?.type, ['mechanical', 'mechanical_supply_and_extract']]
          ),
        ]
      }
    ]
  }
}

const BuildingHeatingLayout = (entity: any) => {
  return {
    ...SummaryOption('heating'),
    ...Subtitle,
    multiRow: true,
    items: [
      {
        key: 'systems_heating',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_heating_left',
            items: [
              TSummaryItem('heating_type', entity?.type),
              TSummaryVisibilityWrapper(
                TSummaryItem('apartment_specific_machine_alt', entity?.hasApartmentSpecificMachine),
                entity?.hasApartmentSpecificMachine === 'yes',
                [entity?.type, ['electric']]
              ),
              TSummaryVisibilityWrapper(
                TSummaryItem(
                  'machine_location_alt',
                  entity?.machineLocation === 'other' ?
                  entity?.machineLocationNotes :
                  entity?.machineLocation
                ),
                entity?.machineLocation,
                [entity?.type, ['electric']]
              ),
              TSummaryVisibilityWrapper(
                TSummaryItem('apartment_specific_heat_pump', entity?.hasApartmentSpecificMachine),
                entity?.hasApartmentSpecificMachine === 'yes',
                [entity?.type, ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water']]
              ),
              TSummaryVisibilityWrapper(
                TSummaryItem('apartment_specific_electric_heating', entity?.hasApartmentSpecificElectricHeating),
                entity?.hasApartmentSpecificElectricHeating === 'yes',
                [entity?.type, ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water']]
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_heating_right',
            items: [
              TSummaryItem('additional_heating_type', entity?.additionalType),
              TSummaryVisibilityWrapper(
                TSummaryItem('pressure_relief_valve_extraction', entity?.hasPressureReliefValveExtraction),
                entity?.hasPressureReliefValveExtraction === 'yes',
                [entity?.type, ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water']]
              ),
              TSummaryVisibilityWrapper(
                TSummaryItem(
                  'pressure_relief_valve_extraction_location',
                  entity?.reliefValveExtractionLocation === 'other' ?
                  entity?.reliefValveExtractionLocationNotes :
                  entity?.reliefValveExtractionLocation
                ),
                entity?.hasPressureReliefValveExtractionLocation,
                [entity?.hasPressureReliefValveExtraction, ['yes']]
              ),
            ]
          },
        ]
      },
      {
        key: 'systems_heating-22',
        groupTitle: 'heat_distribution',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_heating_left_1',
            items: [
              TSummaryItem(
                'heat_distribution',
                entity?.heatDistributionType === 'floor' ?
                  'floor_er_heating' :
                  entity?.heatDistributionType === 'ceiling' ?
                  'ceiling_er_heating' :
                  entity?.heatDistributionType === 'radiator' ?
                  'radiator_heating' :
                  entity?.heatDistributionType === 'air' ?
                  'air_heating' :
                  entity?.heatDistributionType,
                entity?.heatDistributionInstallationYear ?
                `(${entity?.heatDistributionInstallationYear})` :
                ''
              ),
              TSummaryWrapperItem(
                TSummaryItem('frame_lines', entity?.heatDistributionFrameLines),
                { group: i18n.t('type') }
              ),
              TSummaryWrapperItem(
                TSummaryItem('frame_line_size', entity?.heatDistributionFrameLineSize),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem('heat_distribution_circuits', entity?.heatDistributionCircuits),
                { group: i18n.t('type') }
              ),
              TSummaryWrapperItem(
                TSummaryItem('heat_distribution_circuit_size', entity?.heatDistributionCircuitSize),
                { group: i18n.t('size'), hideLabel: true }
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_heating_right_1',
            items: [
              SummaryItem('thermostat_installation_year', entity?.heatDistributionThermostatInstallationYear),
              TSummaryItem(
                'manifold_location',
                entity?.heatDistributionManifoldLocation === 'other' ?
                entity?.heatDistributionManifoldLocationNotes :
                entity?.heatDistributionManifoldLocation
              ),
            ]
          },
        ]
      },
      ['reserving_electric', 'district', 'oil', 'wood', 'geothermal', 'air_water']?.includes(entity?.type) ?
      {
        key: 'systems_heating-2',
        groupTitle: entity?.type === 'reserving_electric' ?
          'water_heater' :
          entity?.type === 'district' ?
          'heat_exchanger' :
          ['oil', 'wood']?.includes(entity?.type) ?
          'pot_and_burner' :
          ['geothermal', 'air_water']?.includes(entity?.type) ?
          'heating_machine' :
          'machine',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_heating_left_2',
            items: [
              TSummaryVisibilityWrapper(
                TSummaryItem(
                  'manufacturer_and_model',
                  entity?.machineManufacturer === 'other' ?
                  entity?.machineManufacturerNotes :
                  entity?.machineManufacturer + ' ' + entity?.machineModel === 'other' ?
                  entity?.machineModelNotes :
                  entity?.machineModel
                ),
                entity?.machineManufacturer || entity?.machineModel,
              ),
              SummaryItem('volume', entity?.machineVolume),
              SummaryItem('installation_year', entity?.machineInstallationYear),
              TSummaryItem(
                'location',
                entity?.machineLocation === 'other' ?
                entity?.machineLocationNotes :
                entity?.machineLocation
              )
            ],
          }
        ]
      } : null,
      {
        key: 'systems_heating-3',
        groupTitle: 'apartment_specific_heat_pump',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_heating_left_3',
            items: [
              SummaryItem(
                'manufacturer_and_model',
                ((entity?.apartmentSpecificHeatPumpManufacturer || '') + ' ' + (entity?.apartmentSpecificHeatPumpModel || ''))?.trim(),
                entity?.apartmentSpecificHeatPumpInstallationYear ?
                `(${entity?.apartmentSpecificHeatPumpInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_heating_right_3',
            items: [
              TSummaryItem(
                'location',
                entity?.apartmentSpecificHeatPumpLocation === 'other' ?
                entity?.apartmentSpecificHeatPumpLocationNotes :
                entity?.apartmentSpecificHeatPumpLocation
              ),
            ]
          }
        ]
      },
      entity?.hasSolarCollectors ? {
        key: 'systems_heating-4',
        groupTitle: 'solar_collectors',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_heating_left_4',
            items: [
              SummaryItem(
                'manufacturer_and_model',
                ((entity?.solarCollectorManufacturer || '') + ' ' + (entity?.solarCollectorModel || ''))?.trim(),
                entity?.solarCollectorInstallationYear ?
                `(${entity?.solarCollectorInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_heating_right_4',
            items: [
              TSummaryItem(
                'location',
                entity?.solarCollectorLocation === 'other' ?
                entity?.solarCollectorLocationNotes :
                entity?.solarCollectorLocation
              ),
            ]
          }
        ]
      } : null
    ].filter(Boolean)
  }
}

const BuildingStormwaterLayout = (entity: any) => {
  return {
    ...SummaryOption('stormwater'),
    ...Subtitle,
    items: [
      {
        ...SummaryOption('drainage_system'),
        ...Subtitle,
        parentKey: 'stormwater',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_stormwater_drainage_system_left',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'drainage_pipes',
                  entity?.drainagePipeMaterial === 'other' ?
                  entity?.drainagePipeMaterialNotes :
                  entity?.drainagePipeMaterial,
                  entity?.drainagePipeInstallationYear ?
                  `(${entity?.drainagePipeInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'drainage_pipe_size',
                  entity?.drainagePipeSize === 'other' ?
                  entity?.drainagePipeSizeNotes :
                  entity?.drainagePipeSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'drainage_pipe_outlet_point',
                  entity?.drainagePipeOutletPoint === 'other' ?
                  entity?.drainagePipeOutletPointNotes :
                  entity?.drainagePipeOutletPoint
                ),
                { group: i18n.t('outlet_point'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'inspection_pipes',
                  entity?.inspectionPipeMaterial === 'other' ?
                  entity?.inspectionPipeMaterialNotes :
                  entity?.inspectionPipeMaterial,
                  entity?.inspectionPipeInstallationYear ?
                  `(${entity?.inspectionPipeInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'inspection_pipe_size',
                  entity?.inspectionPipeSize === 'other' ?
                  entity?.inspectionPipeSizeNotes :
                  entity?.inspectionPipeSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'inspection_pipe_outlet_point',
                  entity?.inspectionPipeOutletPoint === 'other' ?
                  entity?.inspectionPipeOutletPointNotes :
                  entity?.inspectionPipeOutletPoint
                ),
                { group: i18n.t('outlet_point'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem('inspection_pipe_location', entity?.inspectionPipeLocation),
                { group: i18n.t('location'), hideLabel: true }
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_stormwater_drainage_system_right',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'foundation_drain_well',
                  entity?.foundationDrainWellMaterial === 'other' ?
                  entity?.foundationDrainWellMaterialNotes :
                  entity?.foundationDrainWellMaterial,
                  entity?.foundationDrainWellInstallationYear ?
                  `(${entity?.foundationDrainWellInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'foundation_drain_well_size',
                  entity?.foundationDrainWellSize === 'other' ?
                  entity?.foundationDrainWellSizeNotes :
                  entity?.foundationDrainWellSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'foundation_drain_well_outlet_point',
                  entity?.foundationDrainWellOutletPoint === 'other' ?
                  entity?.foundationDrainWellOutletPointNotes :
                  entity?.foundationDrainWellOutletPoint
                ),
                { group: i18n.t('outlet_point'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem('foundation_drain_well_has_backwater_valve', entity?.foundationDrainWellHasBackwaterValve),
                { group: i18n.t('backwater_valve'), hideLabel: true }
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('rainwater_system'),
        ...Subtitle,
        parentKey: 'stormwater',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_stormwater_rainwater_system_left',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'rainwater_pipes',
                  entity?.rainwaterPipeMaterial === 'other' ?
                  entity?.rainwaterPipeMaterialNotes :
                  entity?.rainwaterPipeMaterial,
                  entity?.rainwaterPipeInstallationYear ?
                  `(${entity?.rainwaterPipeInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'rainwater_pipe_size',
                  entity?.rainwaterPipeSize === 'other' ?
                  entity?.rainwaterPipeSizeNotes :
                  entity?.rainwaterPipeSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'rainwater_pipe_outlet_point',
                  entity?.rainwaterPipeOutletPoint === 'other' ?
                  entity?.rainwaterPipeOutletPointNotes :
                  entity?.rainwaterPipeOutletPoint
                ),
                { group: i18n.t('outlet_point'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'rainwater_wells',
                  entity?.rainwaterWellMaterial === 'other' ?
                  entity?.rainwaterWellMaterialNotes :
                  entity?.rainwaterWellMaterial,
                  entity?.rainwaterWellInstallationYear ?
                  `(${entity?.rainwaterWellInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'rainwater_well_size',
                  entity?.rainwaterWellSize === 'other' ?
                  entity?.rainwaterWellSizeNotes :
                  entity?.rainwaterWellSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'rainwater_well_outlet_point',
                  entity?.rainwaterWellOutletPoint === 'other' ?
                  entity?.rainwaterWellOutletPointNotes :
                  entity?.rainwaterWellOutletPoint
                ),
                { group: i18n.t('outlet_point'), hideLabel: true }
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_stormwater_rainwater_system_right',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'collection_wells',
                  entity?.collectionWellMaterial === 'other' ?
                  entity?.collectionWellMaterialNotes :
                  entity?.collectionWellMaterial,
                  entity?.collectionWellInstallationYear ?
                  `(${entity?.collectionWellInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'collection_well_size',
                  entity?.collectionWellSize === 'other' ?
                  entity?.collectionWellSizeNotes :
                  entity?.collectionWellSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'collection_well_outlet_point',
                  entity?.collectionWellOutletPoint === 'other' ?
                  entity?.collectionWellOutletPointNotes :
                  entity?.collectionWellOutletPoint
                ),
                { group: i18n.t('outlet_point'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'horizontal_check_valve',
                  entity?.horizontalCheckValveMaterial === 'other' ?
                  entity?.horizontalCheckValveMaterialNotes :
                  entity?.horizontalCheckValveMaterial,
                  entity?.horizontalCheckValveInstallationYear ?
                  `(${entity?.horizontalCheckValveInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'horizontal_check_valve_location',
                  entity?.horizontalCheckValveLocation === 'other' ?
                  entity?.horizontalCheckValveLocationNotes :
                  entity?.horizontalCheckValveLocation,
                ),
                { group: i18n.t('location'), hideLabel: true }
              )
            ]
          }
        ]
      }
    ]
  }
}

const BuildingDomesticWaterLayout = (entity: any) => {
  return {
    ...SummaryOption('domestic_water'),
    ...Subtitle,
    items: [
      {
        ...SummaryOption('property_water'),
        ...Subtitle,
        parentKey: 'domestic_water',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_domestic_water_property_water_left',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'property_water_pipe',
                  entity?.propertyWaterPipeMaterial === 'other' ?
                  entity?.propertyWaterPipeMaterialNotes :
                  entity?.propertyWaterPipeMaterial,
                  entity?.propertyWaterPipeInstallationYear ?
                  `(${entity?.propertyWaterPipeInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'property_water_pipe_size',
                  entity?.propertyWaterPipeSize === 'other' ?
                  entity?.propertyWaterPipeSizeNotes :
                  entity?.propertyWaterPipeSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'property_water_pipe_has_trace_heating',
                  entity?.propertyWaterPipeHasTraceHeating,
                  entity?.propertyWaterPipeTraceHeatingInstallationYear ?
                  `(${entity?.propertyWaterPipeTraceHeatingInstallationYear})` :
                  ''
                ),
                { group: i18n.t('trace_heating'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem('property_water_pipe_has_casing', entity?.propertyWaterPipeHasCasing),
                { group: i18n.t('casing_pipe'), hideLabel: true },
              ),
              TSummaryItem(
                'property_shut_off_valve',
                entity?.propertyShutOffValveMaterial === 'other' ?
                entity?.propertyShutOffValveMaterialNotes :
                entity?.propertyShutOffValveMaterial,
                entity?.propertyShutOffValveInstallationYear ?
                `(${entity?.propertyShutOffValveInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_domestic_water_property_water_right',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_meters',
                  entity?.waterMeterLocation === 'other' ?
                  entity?.waterMeterLocationNotes :
                  entity?.waterMeterLocation
                ),
                { group: i18n.t('location') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_meter_material',
                  entity?.waterMeterMaterial === 'other' ?
                  entity?.waterMeterMaterialNotes :
                  entity?.waterMeterMaterial,
                  entity?.waterMeterInstallationYear ?
                  `(${entity?.waterMeterInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_meter_type',
                  entity?.waterMeterType === 'other' ?
                  entity?.waterMeterTypeNotes :
                  entity?.waterMeterType
                ),
                { group: i18n.t('type'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_meter_shut_off_valve_type',
                  entity?.waterMeterShutOffValveType === 'other' ?
                  entity?.waterMeterShutOffValveTypeNotes :
                  entity?.waterMeterShutOffValveType,
                  entity?.waterMeterShutOffValveInstallationYear ?
                  `(${entity?.waterMeterShutOffValveInstallationYear})` :
                  ''
                ),
                { group: i18n.t('shut_off_valve'), hideLabel: true }
              ),
              TSummaryVisibilityWrapper(
                SummaryItem('room_specific_water_meter_location', entity?.roomSpecificWaterMeterLocation),
                entity?.hasRoomSpecificWaterMeter === 'yes',
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('water_pipe', 'water_pipes'),
        ...Subtitle,
        parentKey: 'domestic_water',
        multiRow: true,
        items: [
          {
            key: 'systems_domestic_water_water_pipe_main_line',
            groupTitle: 'main_lines',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_domestic_water_water_pipe_left',
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'pipe_riser',
                      entity?.mainLinePipeRiserMaterial === 'other' ?
                      entity?.mainLinePipeRiserMaterialNotes :
                      entity?.mainLinePipeRiserMaterial,
                      entity?.mainLinePipeRiserMaterialInstallationYear ?
                      `(${entity?.mainLinePipeRiserMaterialInstallationYear})` :
                      ''
                    ),
                    { group: i18n.t('material') }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_location',
                      entity?.mainLinePipeRiserLocation === 'other' ?
                      entity?.mainLinePipeRiserLocationNotes :
                      entity?.mainLinePipeRiserLocation
                    ),
                    { group: i18n.t('location'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_location_in_apartment',
                      entity?.mainLinePipeRiserLocationInApartment === 'other' ?
                      entity?.mainLinePipeRiserLocationInApartmentNotes :
                      entity?.mainLinePipeRiserLocationInApartment
                    ),
                    { group: i18n.t('pipe_riser_location_in_apartments'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_installation_method',
                      entity?.mainLinePipeRiserInstallationMethod === 'other' ?
                      entity?.mainLinePipeRiserInstallationMethodNotes :
                      entity?.mainLinePipeRiserInstallationMethod
                    ),
                    { group: i18n.t('installation_method'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_size',
                      entity?.mainLinePipeRiserSize === 'other' ?
                      entity?.mainLinePipeRiserSizeNotes :
                      entity?.mainLinePipeRiserSize
                    ),
                    { group: i18n.t('size'), hideLabel: true }
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: 'systems_domestic_water_water_pipe_right',
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'horizontal_run',
                      entity?.mainLineHorizontalRunMaterial === 'other' ?
                      entity?.mainLineHorizontalRunMaterialNotes :
                      entity?.mainLineHorizontalRunMaterial,
                      entity?.mainLineHorizontalRunMaterialInstallationYear ?
                      `(${entity?.mainLineHorizontalRunMaterialInstallationYear})` :
                      ''
                    ),
                    { group: i18n.t('material') }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_horizontal_run_size',
                      entity?.mainLineHorizontalRunSize === 'other' ?
                      entity?.mainLineHorizontalRunSizeNotes :
                      entity?.mainLineHorizontalRunSize
                    ),
                    { group: i18n.t('size'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_horizontal_run_installation_method',
                      entity?.mainLineHorizontalRunInstallationMethod === 'other' ?
                      entity?.mainLineHorizontalRunInstallationMethodNotes :
                      entity?.mainLineHorizontalRunInstallationMethod
                    ),
                    { group: i18n.t('installation_method'), hideLabel: true }
                  ),
                ]
              }
            ]
          },
          {
            key: 'systems_domestic_water_water_pipe_apartment_line',
            groupTitle: 'apartment_lines',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_domestic_water_water_pipe_left_2',
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'apartment_line_material',
                      entity?.apartmentLineMaterial === 'other' ?
                      entity?.apartmentLineMaterialNotes :
                      entity?.apartmentLineMaterial,
                      entity?.apartmentLineMaterialInstallationYear ?
                      `(${entity?.apartmentLineMaterialInstallationYear})` :
                      ''
                    ),
                    { group: i18n.t('material'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'apartment_line_size',
                      entity?.apartmentLineSize === 'other' ?
                      entity?.apartmentLineSizeNotes :
                      entity?.apartmentLineSize
                    ),
                    { group: i18n.t('size'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'apartment_line_installation_method',
                      entity?.apartmentLineInstallationMethod === 'other' ?
                      entity?.apartmentLineInstallationMethodNotes :
                      entity?.apartmentLineInstallationMethod
                    ),
                    { group: i18n.t('installation_method'), hideLabel: true }
                  ),
                ]
              }
            ]
          }
        ]
      },
      {
        ...SummaryOption('warm_water'),
        ...Subtitle,
        parentKey: 'domestic_water',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_domestic_water_warm_water_left',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_heater',
                  entity?.waterHeaterType === 'other' ?
                  entity?.waterHeaterTypeNotes :
                  entity?.waterHeaterType,
                  entity?.waterHeaterInstallationYear ?
                  `(${entity?.waterHeaterInstallationYear})` :
                  ''
                ),
                { group: i18n.t('type') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_heater_location',
                  entity?.waterHeaterLocation === 'other' ?
                  entity?.waterHeaterLocationNotes :
                  entity?.waterHeaterLocation
                ),
                { group: i18n.t('location'), hideLabel: true }
              ),
              TSummaryWrapperItem(  
                TSummaryItem('water_heater_manufacturer', entity?.waterHeaterManufacturer),
                { group: i18n.t('manufacturer'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                SummaryItem('water_heater_model', entity?.waterHeaterModel),
                { group: i18n.t('model'), hideLabel: true }
              ),
            ]
          },
          entity?.hasApartmentSpecificWaterHeater === 'yes' ?
          {
            ...SummaryColumn,
            key: 'systems_domestic_water_warm_water_right',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'apartment_specific_water_heater',
                  entity?.apartmentSpecificWaterHeaterInstallationYear
                ),
                { group: i18n.t('installation_year') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'apartment_specific_water_heater_location',
                  entity?.apartmentSpecificWaterHeaterLocation === 'other' ?
                  entity?.apartmentSpecificWaterHeaterLocationNotes :
                  entity?.apartmentSpecificWaterHeaterLocation
                ),
                { group: i18n.t('location'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem('apartment_specific_water_heater_manufacturer', entity?.apartmentSpecificWaterHeaterManufacturer),
                { group: i18n.t('manufacturer'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                SummaryItem('apartment_specific_water_heater_model', entity?.apartmentSpecificWaterHeaterModel),
                { group: i18n.t('model'), hideLabel: true }
              ),
            ]
          } : null,
        ]
      }
    ]
  }
}

const BuildingWasteManagementLayout = (entity: any) => {
  return {
    ...SummaryOption('waste_management'),
    ...Subtitle,
    multiRow: true,
    items: [
      {
        key: 'systems_waste_management_1',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left_1',
            items: [
              TSummaryItem('location', entity?.location),
              TArraySummaryItem(
                'collected_waste_categories',
                [
                  entity?.hasCombustibleWasteCollection ? 'combustible_waste' : null,
                  entity?.hasMixedWasteCollection ? 'mixed_waste' : null,
                  entity?.hasPlasticCollection ? 'plastic_waste' : null,
                  entity?.hasBioWasteCollection ? 'bio_waste' : null,
                  entity?.hasPaperWasteCollection ? 'paper_waste' : null,
                  entity?.hasCardboardWasteCollection ? 'cardboard_waste' : null,
                  entity?.hasMetalWasteCollection ? 'metal_waste' : null,
                  entity?.hasGlassWasteCollection ? 'glass_waste' : null,
                  entity?.hasElectronicWasteCollection ? 'electronic_waste' : null,
                ].filter(Boolean)
              )
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right_1',
            items: [
              TArraySummaryItem(
                'waste_containers',
                entity?.container?.split(',') || [],
              ),
              TSummaryVisibilityWrapper(
                SummaryItem('waste_compactor_installation_year', entity?.wasteCompactorInstallationYear),
                entity?.hasWasteCompactor === 'yes',
              )
            ]
          }
        ]
      },
      entity?.hasCombustibleWasteCollection ? {
        key: 'systems_waste_management_2',
        groupTitle: 'combustible_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.combustibleWasteContainerCount,
                'kpl ' + (entity?.combustibleWasteTotalContainerVolume ?
                `(yht. ${entity?.combustibleWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.combustibleWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasMixedWasteCollection ? {
        key: 'systems_waste_management_3',
        groupTitle: 'mixed_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.mixedWasteContainerCount,
                'kpl ' + (entity?.mixedWasteTotalContainerVolume ?
                `(yht. ${entity?.mixedWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.mixedWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasPlasticCollection ? {
        key: 'systems_waste_management_4',
        groupTitle: 'plastic_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.plasticContainerCount,
                'kpl ' + (entity?.plasticTotalContainerVolume ?
                `(yht. ${entity?.plasticTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.plasticMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasBioWasteCollection ? {
        key: 'systems_waste_management_5',
        groupTitle: 'bio_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.bioWasteContainerCount,
                'kpl ' + (entity?.bioWasteTotalContainerVolume ?
                `(yht. ${entity?.bioWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.bioWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasPaperWasteCollection ? {
        key: 'systems_waste_management_6',
        groupTitle: 'paper_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.paperWasteContainerCount,
                'kpl ' + (entity?.paperWasteTotalContainerVolume ?
                `(yht. ${entity?.paperWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.paperWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasCardboardWasteCollection ? {
        key: 'systems_waste_management_7',
        groupTitle: 'cardboard_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.cardboardWasteContainerCount,
                'kpl ' + (entity?.cardboardWasteTotalContainerVolume ?
                `(yht. ${entity?.cardboardWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.cardboardWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasMetalWasteCollection ? {
        key: 'systems_waste_management_8',
        groupTitle: 'metal_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.metalWasteContainerCount,
                'kpl ' + (entity?.metalWasteTotalContainerVolume ?
                `(yht. ${entity?.metalWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.metalWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasGlassWasteCollection ? {
        key: 'systems_waste_management_9',
        groupTitle: 'glass_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.glassWasteContainerCount,
                'kpl ' + (entity?.glassWasteTotalContainerVolume ?
                `(yht. ${entity?.glassWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.glassWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
      entity?.hasElectronicWasteCollection ? {
        key: 'systems_waste_management_10',
        groupTitle: 'electronic_waste',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_waste_management_left',
            items: [
              SummaryItem(
                'container_count',
                entity?.electronicWasteContainerCount,
                'kpl ' + (entity?.electronicWasteTotalContainerVolume ?
                `(yht. ${entity?.electronicWasteTotalContainerVolume} l)` :
                '')
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_waste_management_right',
            items: [
              SummaryItem('emptying_interval', entity?.electronicWasteMonthlyEmptyingCount),
            ]
          }
        ]
      } : null,
    ].filter(Boolean)
  }
}

const BuildingElevatorLayout = (entity: any) => {
  return         {
    ...SummaryOption('elevator', 'elevators'),
    ...Subtitle,
    items: [
      {
        ...SummaryColumn,
        key: 'systems_elevator_left',
        items: [
          SummaryItem('count', entity?.count, 'kpl'),
        ]
      },
      {
        ...SummaryColumn,
        key: 'systems_elevator_right',
        items: [
          TSummaryItem(
            'manufacturer_and_model',
            ((entity?.manufacturer || '') + ' ' + (entity?.model || ''))?.trim(),
            entity?.installationYear ?
            `(${entity?.installationYear})` :
            ''
          ),
        ]
      }
    ]
  }
}

const BuildingElectricalEngineeringLayout = (entity: any) => {
  return {
    ...SummaryOption('electrical_engineering'),
    ...Subtitle,
    items: [
      {
        ...SummaryOption('main_distribution'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        groupTitle: 'fuses',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_main_distribution_left',
            items: [
              TSummaryItem(
                'transformer_station',
                entity?.transformerStationFuseType === 'other' ?
                entity?.transformerStationFuseTypeNotes :
                entity?.transformerStationFuseType,
                entity?.transformerStationInstallationYear ?
                `(${entity?.transformerStationInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'main_distribution_board',
                entity?.mainDistributionBoardFuseType === 'other' ?
                entity?.mainDistributionBoardFuseTypeNotes :
                entity?.mainDistributionBoardFuseType,
                entity?.mainDistributionBoardInstallationYear ?
                `(${entity?.mainDistributionBoardInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'property_distribution_board',
                entity?.propertyDistributionBoardFuseType === 'other' ?
                entity?.propertyDistributionBoardFuseTypeNotes :
                entity?.propertyDistributionBoardFuseType,
                entity?.propertyDistributionBoardInstallationYear ?
                `(${entity?.propertyDistributionBoardInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'metering_board',
                entity?.meteringBoardFuseType === 'other' ?
                entity?.meteringBoardFuseTypeNotes :
                entity?.meteringBoardFuseType,
                entity?.meteringBoardInstallationYear ?
                `(${entity?.meteringBoardInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'sub_distribution_board',
                entity?.subDistributionBoardFuseType === 'other' ?
                entity?.subDistributionBoardFuseTypeNotes :
                entity?.subDistributionBoardFuseType,
                entity?.subDistributionBoardInstallationYear ?
                `(${entity?.subDistributionBoardInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_main_distribution_right',
            items: [
              TSummaryItem(
                'ev_charging_station',
                entity?.evChargingStationFuseType === 'other' ?
                entity?.evChargingStationFuseTypeNotes :
                entity?.evChargingStationFuseType,
                entity?.evChargingStationInstallationYear ?
                `(${entity?.evChargingStationInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'socket_distribution_board',
                entity?.socketDistributionBoardFuseType === 'other' ?
                entity?.socketDistributionBoardFuseTypeNotes :
                entity?.socketDistributionBoardFuseType,
                entity?.socketDistributionBoardInstallationYear ?
                `(${entity?.socketDistributionBoardInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'smoke_extraction_control_center',
                entity?.smokeExtractionControlCenterFuseType === 'other' ?
                entity?.smokeExtractionControlCenterFuseTypeNotes :
                entity?.smokeExtractionControlCenterFuseType,
                entity?.smokeExtractionControlCenterInstallationYear ?
                `(${entity?.smokeExtractionControlCenterInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'hvac_control_center',
                entity?.hvacControlCenterFuseType === 'other' ?
                entity?.hvacControlCenterFuseTypeNotes :
                entity?.hvacControlCenterFuseType,
                entity?.hvacControlCenterInstallationYear ?
                `(${entity?.hvacControlCenterInstallationYear})` :
                ''
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('connection_and_production'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        multiRow: true,
        items: [
          {
            key: 'systems_electrical_engineering_connection_and_production_left_1',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_electrical_engineering_connection_and_production_left_2',
                items: [
                  TSummaryItem(
                    'distribution_system',
                    entity?.connectionDistributionSystem,
                    entity?.connectionInstallationYear ?
                    `(${entity?.connectionInstallationYear})` :
                    ''
                  ),
                ]
              }
            ]
          },
          {
            key: 'systems_electrical_engineering_connection_and_production_left_3',
            groupTitle: 'solar_power_plant',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_electrical_engineering_connection_and_production_left_4',
                items: [
                  TSummaryItem(
                    'panel_manufacturer_and_model',
                    ((entity?.solarPowerPanelManufacturer || '') + ' ' + (entity?.solarPowerPanelModel || ''))?.trim(),
                  ),
                  TSummaryItem(
                    'location',
                    entity?.solarPowerLocation === 'other' ?
                    entity?.solarPowerLocationNotes :
                    entity?.solarPowerLocation
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: 'systems_electrical_engineering_connection_and_production_right',
                items: [
                  SummaryItem(
                    'inverter_manufacturer_and_model',
                    ((entity?.solarPowerInverterManufacturer || '') + ' ' + (entity?.solarPowerInverterModel || ''))?.trim()
                  ),
                  SummaryItem('installation_year', entity?.solarPowerInstallationYear),
                ]
              }
            ]
          },
          {
            key: 'systems_electrical_engineering_connection_and_production_left_3',
            groupTitle: 'battery_system',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_electrical_engineering_connection_and_production_left_4',
                items: [
                  SummaryItem(
                    'manufacturer_and_model',
                    ((entity?.batterySystemManufacturer || '') + ' ' + (entity?.batterySystemModel || ''))?.trim()
                  ),
                  TSummaryItem(
                    'size',
                    entity?.batterySystemSize === 'other' ?
                    entity?.batterySystemSizeNotes :
                    entity?.batterySystemSize
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: 'systems_electrical_engineering_connection_and_production_right',
                items: [
                  TSummaryItem(
                    'location',
                    entity?.batterySystemLocation === 'other' ?
                    entity?.batterySystemLocationNotes :
                    entity?.batterySystemLocation
                  ),
                ]
              }
            ]
          }
        ]
      },
      {
        ...SummaryOption('installation_and_auxiliary_system'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_installation_and_auxiliary_system_left',
            items: [
              TArraySummaryItemWithInstallationYear(
                {
                  cableTrayInstallationYear: entity?.cableTrayInstallationYear,
                  cableDuctInstallationYear: entity?.cableDuctInstallationYear,
                  floorDuctInstallationYear: entity?.floorDuctInstallationYear,
                  suspensionRailInstallationYear: entity?.suspensionRailInstallationYear,
                  pipeAndCableWellsInstallationYear: entity?.pipeAndCableWellsInstallationYear,
                },
                'fixtures_and_equipment',
                [
                  entity?.hasCableTray ? 'cable_tray' : '',
                  entity?.hasCableDuct ? 'cable_duct' : '',
                  entity?.hasFloorDuct ? 'floor_duct' : '',
                  entity?.hasSuspensionRail ? 'suspension_rail' : '',
                  entity?.hasPipeAndCableWells ? 'pipe_and_cable_wells' : '',
                ].filter((item: string) => item),
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('connection_system'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_connection_system_left',
            items: [
              TArraySummaryItemWithInstallationYear(
                {
                  socketsInstallationYear: entity?.socketInstallationYear,
                  switchesInstallationYear: entity?.switchInstallationYear,
                  vehicleChargingSocketsInstallationYear: entity?.vehicleChargingSocketInstallationYear,
                  vehicleHeatingPolesInstallationYear: entity?.vehicleHeatingPoleInstallationYear,
                },
                'systems',
                [
                  entity?.socketInstallationYear? 'sockets' : '',
                  entity?.switchInstallationYear ? 'switches' : '',
                  entity?.vehicleChargingSocketInstallationYear ? 'vehicle_charging_sockets' : '',
                  entity?.vehicleHeatingPoleInstallationYear ? 'vehicle_heating_poles' : '',
                ].filter((item: string) => item),
              ),
            ]
          },
        ]
      },
      {
        ...SummaryOption('lighting'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_lighting_left',
            items: [
              TArraySummaryItemWithInstallationYear(
                {
                  halogenInstallationYear: entity?.interiorLightingHalogenInstallationYear + (entity?.interiorLightingHalogenCount ? `, ${entity?.interiorLightingHalogenCount} kpl` : ''),
                  ledInstallationYear: entity?.interiorLightingLedInstallationYear + (entity?.interiorLightingLedCount ? `, ${entity?.interiorLightingLedCount} kpl` : ''),
                  otherInstallationYear: entity?.interiorLightingOtherInstallationYear + (entity?.interiorLightingOtherCount ? `, ${entity?.interiorLightingOtherCount} kpl` : ''),
                },
                'interior_lighting',
                entity?.interiorLightingTypes?.split(',') || [],
                entity?.interiorLightingOtherType
              ),
              TSummaryItem(
                'area_lighting',
                entity?.areaLightingType === 'other' ?
                entity?.areaLightingOtherType :
                entity?.areaLightingType,
                entity?.areaLightingInstallationYear ?
                `(${entity?.areaLightingInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_lighting_right',
            items: [
              TSummaryItem(
                'facade_lighting',
                entity?.facadeLightingType === 'other' ?
                entity?.facadeLightingOtherType :
                entity?.facadeLightingType,
                entity?.facadeLightingInstallationYear ?
                `(${entity?.facadeLightingInstallationYear})` :
                ''
              ),
              TSummaryItem(
                'advertising_lighting',
                entity?.advertisingLightingType === 'other' ?
                entity?.advertisingLightingOtherType :
                entity?.advertisingLightingType,
                entity?.advertisingLightingInstallationYear ?
                `(${entity?.advertisingLightingInstallationYear})` :
                ''
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('grounding'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_grounding_left',
            items: [
              TSummaryItem(
                'lightning_protection_system_installation_year',
                entity?.lightningProtectionSystemInstallationYear
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_grounding_right',
            items: [
              TSummaryItem(
                'equipotential_bonding_system_installation_year',
                entity?.equipotentialBondingSystemInstallationYear
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('communication_and_data_network'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_communication_and_data_network_left',
            items: [
              TSummaryItem(
                'antenna_system_type',
                entity?.antennaSystemType === 'other' ?
                entity?.antennaSystemTypeNotes :
                entity?.antennaSystemType,
                entity?.antennaSystemInstallationYear ?
                `(${entity?.antennaSystemInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_communication_and_data_network_right',
            items: [
              TSummaryItem(
                'telecommunication_system_type',
                entity?.telecommunicationSystemType === 'other' ?
                entity?.telecommunicationSystemTypeNotes :
                entity?.telecommunicationSystemType,
                entity?.telecommunicationSystemInstallationYear ?
                `(${entity?.telecommunicationSystemInstallationYear})` :
                ''
              ),
            ]
          }
        ]
      },
      /*
      {
        ...SummaryOption('fire_safety'),
        ...Subtitle,
        parentKey: 'electrical_engineering',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_fire_safety_left',
            items: [
              TSummaryItem(
                'fire_alarm_system_type',
                entity?.fireAlarmSystemType,
                entity?.fireAlarmSystemInstallationYear ?
                `(${entity?.fireAlarmSystemInstallationYear})` :
                ''
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_electrical_engineering_fire_safety_right',
            items: [
              TSummaryItem(
                'smoke_detector_type',
                entity?.smokeDetectorType,
                entity?.smokeDetectorInstallationYear ?
                `(${entity?.smokeDetectorInstallationYear})` :
                ''
              ),
            ]
          }
        ]
      }
      */
    ]
  }
}

const BuildingSewerageLayout = (entity: any) => {
  return {
    ...SummaryOption('sewerage'),
    ...Subtitle,
    items: [
      {
        ...SummaryOption('property_sewerage'),
        ...Subtitle,
        parentKey: 'sewerage',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_sewerage_property_sewerage_left',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'sewer_inspection_well',
                  entity?.sewerInspectionWellMaterial === 'other' ?
                  entity?.sewerInspectionWellMaterialNotes :
                  entity?.sewerInspectionWellMaterial,
                  entity?.sewerInspectionWellInstallationYear ?
                  `(${entity?.sewerInspectionWellInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                SummaryItem('sewer_inspection_well_count', entity?.sewerInspectionWellCount, 'kpl'),
                { group: i18n.t('count'), hideLabel: true }
              ),
              TSummaryItem('grease_separator_wells', entity?.hasGreaseSeparatorWell),
              TSummaryItem('sand_separator_wells', entity?.hasSandSeparatorWell),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_sewerage_property_sewerage_right',
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'property_sewer_pipe',
                  entity?.propertySewerPipeMaterial === 'other' ?
                  entity?.propertySewerPipeMaterialNotes :
                  entity?.propertySewerPipeMaterial,
                  entity?.propertySewerPipeInstallationYear ?
                  `(${entity?.propertySewerPipeInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'property_sewer_pipe_size',
                  entity?.propertySewerPipeSize === 'other' ?
                  entity?.propertySewerPipeSizeNotes :
                  entity?.propertySewerPipeSize
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'horizontal_check_valve',
                  entity?.horizontalCheckValveMaterial === 'other' ?
                  entity?.horizontalCheckValveMaterialNotes :
                  entity?.horizontalCheckValveMaterial,
                  entity?.horizontalCheckValveInstallationYear ?
                  `(${entity?.horizontalCheckValveInstallationYear})` :
                  ''
                ),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'horizontal_check_valve_location',
                  entity?.horizontalCheckValveLocation === 'other' ?
                  entity?.horizontalCheckValveLocationNotes :
                  entity?.horizontalCheckValveLocation
                ),
                { group: i18n.t('location'), hideLabel: true }
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('sewer_pipe'),
        ...Subtitle,
        parentKey: 'sewerage',
        multiRow: true,
        items: [
          {
            key: 'systems_sewerage_sewer_pipe_main_line',
            groupTitle: 'main_lines',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_sewerage_sewer_pipe_left',
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'pipe_riser',
                      entity?.mainLinePipeRiserMaterial === 'other' ?
                      entity?.mainLinePipeRiserMaterialNotes :
                      entity?.mainLinePipeRiserMaterial,
                      entity?.mainLinePipeRiserMaterialInstallationYear ?
                      `(${entity?.mainLinePipeRiserMaterialInstallationYear})` :
                      ''
                    ),
                    { group: i18n.t('material') }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_location',
                      entity?.mainLinePipeRiserLocation === 'other' ?
                      entity?.mainLinePipeRiserLocationNotes :
                      entity?.mainLinePipeRiserLocation
                    ),
                    { group: i18n.t('location'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_location_in_apartment',
                      entity?.mainLinePipeRiserLocationInApartment === 'other' ?
                      entity?.mainLinePipeRiserLocationInApartmentNotes :
                      entity?.mainLinePipeRiserLocationInApartment
                    ),
                    { group: i18n.t('pipe_riser_location_in_apartments'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_installation_method',
                      entity?.mainLinePipeRiserInstallationMethod === 'other' ?
                      entity?.mainLinePipeRiserInstallationMethodNotes :
                      entity?.mainLinePipeRiserInstallationMethod
                    ),
                    { group: i18n.t('installation_method'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_pipe_riser_size',
                      entity?.mainLinePipeRiserSize === 'other' ?
                      entity?.mainLinePipeRiserSizeNotes :
                      entity?.mainLinePipeRiserSize
                    ),
                    { group: i18n.t('size'), hideLabel: true }
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: 'systems_sewerage_sewer_pipe_right',
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'horizontal_run',
                      entity?.mainLineHorizontalRunMaterial === 'other' ?
                      entity?.mainLineHorizontalRunMaterialNotes :
                      entity?.mainLineHorizontalRunMaterial,
                      entity?.mainLineHorizontalRunMaterialInstallationYear ?
                      `(${entity?.mainLineHorizontalRunMaterialInstallationYear})` :
                      ''
                    ),
                    { group: i18n.t('material') }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_horizontal_run_size',
                      entity?.mainLineHorizontalRunSize === 'other' ?
                      entity?.mainLineHorizontalRunSizeNotes :
                      entity?.mainLineHorizontalRunSize
                    ),
                    { group: i18n.t('size'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'main_line_horizontal_run_installation_method',
                      entity?.mainLineHorizontalRunInstallationMethod === 'other' ?
                      entity?.mainLineHorizontalRunInstallationMethodNotes :
                      entity?.mainLineHorizontalRunInstallationMethod
                    ),
                    { group: i18n.t('installation_method'), hideLabel: true }
                  ),
                ]
              }
            ]
          },
          {
            key: 'systems_sewerage_sewer_pipe_apartment_line',
            groupTitle: 'apartment_lines',
            items: [
              {
                ...SummaryColumn,
                key: 'systems_sewerage_sewer_pipe_left_2',
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'apartment_line_material',
                      entity?.apartmentLineMaterial === 'other' ?
                      entity?.apartmentLineMaterialNotes :
                      entity?.apartmentLineMaterial,
                      entity?.apartmentLineMaterialInstallationYear ?
                      `(${entity?.apartmentLineMaterialInstallationYear})` :
                      ''
                    ),
                    { group: i18n.t('material'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'apartment_line_size',
                      entity?.apartmentLineSize === 'other' ?
                      entity?.apartmentLineSizeNotes :
                      entity?.apartmentLineSize
                    ),
                    { group: i18n.t('size'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'apartment_line_installation_method',
                      entity?.apartmentLineInstallationMethod === 'other' ?
                      entity?.apartmentLineInstallationMethodNotes :
                      entity?.apartmentLineInstallationMethod
                    ),
                    { group: i18n.t('installation_method'), hideLabel: true }
                  ),
                ]
              }
            ]
          }
        ]
      },
      entity?.hasWasteHeatRecoverySystem === 'yes' ? {
        ...SummaryOption('waste_water_heat_recovery'),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: 'systems_sewerage_waste_water_heat_recovery_left',
            items: [
              TSummaryItem(
                'location',
                entity?.wasteHeatRecoverySystemLocation === 'other' ?
                entity?.wasteHeatRecoverySystemLocationNotes :
                entity?.wasteHeatRecoverySystemLocation
              ),
              SummaryItem(
                'installation_year',
                entity?.wasteHeatRecoverySystemInstallationYear
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_sewerage_waste_water_heat_recovery_right',
            items: [
              SummaryItem(
                'manufacturer_and_model',
                ((entity?.wasteHeatRecoverySystemManufacturer || '') + ' ' + (entity?.wasteHeatRecoverySystemModel || ''))?.trim()
              ),
            ]
          }
        ]
      } : null
    ].filter(Boolean)
  }
}

const BuildingFireSafetyLayout = (entity: any, propertyType: string | null) => {
  return {
    ...SummaryOption('fire_safety'),
    ...Subtitle,
    multiRow: true,
    items: [
      {
        key: 'systems_fire_safety_1',
        groupTitle: 'fire_alarm_system',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_1',
            items: [
              TSummaryItem(
                'fire_alarm_system_type',
                entity?.fireAlarmSystemType,
                entity?.fireAlarmSystemInstallationYear ?
                `(${entity?.fireAlarmSystemInstallationYear})` :
                ''
              ),
            ]
          },
        ]
      },
      {
        key: 'systems_fire_safety_2',
        groupTitle: 'smoke_detectors',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_2',
            items: [
              TSummaryItem(
                'smoke_detector_type',
                entity?.smokeDetectorType,
                entity?.smokeDetectorInstallationYear ?
                `(${entity?.smokeDetectorInstallationYear})` :
                ''
              ),
            ]
          }
        ]
      },
      {
        key: 'systems_fire_safety_3',
        groupTitle: 'general_information',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_3',
            items: [
              SummaryItem(
                'acting_in_emergency_situations',
                entity?.actingInEmergencyLink
              ),
              SummaryItem(
                'regulatory_requirements_according_to_the_specific_property_type',
                entity?.propertyTypeSpecificRegulatoryRequirementsLink
              ),
              SummaryItem(
                'inspections_by_authorities',
                entity?.authorityInspectionsLink
              ),
              SummaryItem(
                'security_responsible_persons_and_organizations',
                entity?.securityResponsibilityLink
              ),
              SummaryItem(
                'special_fire_safety_regulations',
                entity?.specialFireSafetyRegulationsLink
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_3',
            items: [
              SummaryItem(
                'security_areas',
                entity?.securityAreasLink
              ),
              SummaryItem(
                'fire_protection_design',
                entity?.fireProtectionDesignLink
              ),
              SummaryItem(
                'arrangements_with_fire_authorities',
                entity?.fireAuthorityArrangementsLink
              ),
              propertyType !== 'housing' && SummaryItem(
                'internal_fire_brigade_and_its_operations',
                entity?.ownFireBrigadeLink
              ),
              SummaryItem(
                'evacuation_diagrams_location_and_placement',
                entity?.evacuationDiagramsLink
              ),
            ]?.filter(Boolean)
          }
        ]
      },
      {
        key: 'systems_fire_safety_4',
        groupTitle: 'fire_extinguishers',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_4',
            items: [
              SummaryItem(
                'map_of_location',
                entity?.fireExtinguisherMapLink
              ),
              SummaryItem(
                'commissioning_year',
                entity?.fireExtinguisherInstallationYear,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_4',
            items: [
              SummaryItem(
                'inspection_interval',
                entity?.fireExtinguisherInspectionInterval,
              ),
              SummaryItem(
                'additional_info',
                entity?.fireExtinguisherInfo,
              ),
            ]
          }
        ]
      },
      {
        key: 'systems_fire_safety_5',
        groupTitle: 'defibrillator',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_5',
            items: [
              SummaryItem(
                'map_of_location',
                entity?.defibrillatorMapLink
              ),
              SummaryItem(
                'commissioning_year',
                entity?.defibrillatorInstallationYear,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_5',
            items: [
              SummaryItem(
                'inspection_interval',
                entity?.defibrillatorInspectionInterval,
              ),
              SummaryItem(
                'additional_info',
                entity?.defibrillatorInfo,
              ),
            ]
          }
        ]
      },
      propertyType !== 'housing' && {
        key: 'systems_fire_safety_6',
        groupTitle: 'emergency_showers',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_6',
            items: [
              SummaryItem(
                'map_of_location',
                entity?.emergencyShowerMapLink
              ),
              SummaryItem(
                'commissioning_year',
                entity?.emergencyShowerInstallationYear,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_6',
            items: [
              SummaryItem(
                'inspection_interval',
                entity?.emergencyShowerInspectionInterval,
              ),
              SummaryItem(
                'additional_info',
                entity?.emergencyShowerInfo,
              ),
            ]
          }
        ]
      },
      {
        key: 'systems_fire_safety_7',
        groupTitle: 'sprinkler_systems',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_7',
            items: [
              SummaryItem(
                'map_of_location',
                entity?.sprinklerSystemMapLink
              ),
              SummaryItem(
                'commissioning_year',
                entity?.sprinklerSystemInstallationYear,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_7',
            items: [
              SummaryItem(
                'inspection_interval',
                entity?.sprinklerSystemInspectionInterval,
              ),
              SummaryItem(
                'additional_info',
                entity?.sprinklerSystemInfo,
              )
            ]
          }
        ]
      },
      {
        key: 'systems_fire_safety_8',
        groupTitle: 'fire_alarms',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_8',
            items: [
              SummaryItem(
                'map_of_location',
                entity?.fireAlarmMapLink
              ),
              SummaryItem(
                'commissioning_year',
                entity?.fireAlarmInstallationYear,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_8',
            items: [
              SummaryItem(
                'inspection_interval',
                entity?.fireAlarmInspectionInterval,
              ),
              SummaryItem(
                'additional_info',
                entity?.fireAlarmInfo,
              )
            ]
          }
        ]
      },
      {
        key: 'systems_fire_safety_9',
        groupTitle: 'fire_alarm_control_panel',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_9',
            items: [
              SummaryItem(
                'map_of_location',
                entity?.fireAlarmControlPanelMapLink
              ),
              SummaryItem(
                'phone_number',
                entity?.fireAlarmControlPanelPhone,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_9',
            items: [
              SummaryItem(
                'additional_info',
                entity?.fireAlarmControlPanelInfo,
              )
            ]
          }
        ]
      },
      propertyType !== 'housing' && {
        key: 'systems_fire_safety_10',
        groupTitle: 'own_fire_brigade',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_10',
            items: [
              SummaryItem(
                'contact_person',
                entity?.fireBrigadeContactPersonName,
              ),
              SummaryItem(
                'phone_number',
                entity?.fireBrigadeContactPersonPhone,
              ),
              SummaryItem(
                'email',
                entity?.fireBrigadeContactPersonEmail,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_10',
            items: [
              SummaryItem(
                'street_address',
                entity?.fireBrigadeAddress,
              ),
              SummaryItem(
                'postal_code',
                entity?.fireBrigadePostalCode,
              ),
              SummaryItem(
                'city',
                entity?.fireBrigadeCity,
              ),
            ]
          }
        ]
      },
      propertyType !== 'housing' && {
        key: 'systems_fire_safety_11',
        groupTitle: 'other_fire_safety_equipment',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_11',
            items: [
              SummaryItem(
                'personnel_safety_equipment_depending_on_the_industry',
                entity?.industrySpecificPersonnelFireSafetyEquipmentLink
              ),
              SummaryItem(
                'safety_equipment_and_its_management',
                entity?.safetyEquipmentLink
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_11',
            items: [
              SummaryItem(
                'fire_alarm_buttons',
                entity?.fireAlarmButtonLink
              ),
              SummaryItem(
                'emergency_lights',
                entity?.emergencyLightingLink
              ),
            ]
          }
        ]
      },
      {
        key: 'systems_fire_safety_12',
        groupTitle: 'arrangements_for_extinguishing_and_rescue_tasks',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_left_12',
            items: [
              SummaryItem(
                'procedures_in_the_event_of_an_alarm',
                entity?.alarmProcedureLink
              ),
              SummaryItem(
                'organization_and_responsible_persons',
                entity?.organizationAndResponsiblePersonnelLink
              ),
              SummaryItem(
                'safety_instructions',
                entity?.safetyInstructionsLink
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_fire_safety_right_12',
            items: [
              SummaryItem(
                'assembly_areas',
                entity?.assemblyAreasLink
              ),
              SummaryItem(
                'staff_training_and_exercises',
                entity?.staffTrainingLink
              ),
            ]
          }
        ]
      }
    ]
  }
}

const BuildingSecurityMonitoringLayout = (entity: any) => {
  return {
    ...SummaryOption('security'),
    ...Subtitle,
    multiRow: true,
    items: [
      {
        key: 'systems_security_1',
        groupTitle: 'camera_surveillance',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_security_left_1',
            items: [
              SummaryItem(
                'additional_info',
                entity?.cameraSurveillanceInfo,
              ),
            ]
          },
        ]
      },
      {
        key: 'systems_security_2',
        groupTitle: 'access_control',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_security_left_2',
            items: [
              SummaryItem(
                'additional_info',
                entity?.accessControlInfo,
              ),
            ],
          },
        ]
      },
      {
        key: 'systems_security_3',
        groupTitle: 'property_site_protection',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_security_left_3',
            items: [
              SummaryItem(
                'additional_info',
                entity?.propertySiteProtectionInfo,
              ),
            ],
          },
        ]
      },
      {
        key: 'systems_security_4',
        groupTitle: 'guards',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_security_left_4',
            items: [
              SummaryItem(
                'contact_person',
                entity?.securityContactPersonName,
              ),
              SummaryItem(
                'company',
                entity?.securityCompany,
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_security_right_4',
            items: [
              SummaryItem(
                'phone_number',
                entity?.securityContactPersonPhone,
              ),
              SummaryItem(
                'email',
                entity?.securityContactPersonEmail,
              ),
            ]
          }
        ]
      }
    ]
  }
}

const BuildingIndustrialWasteRemovalAndPurificationLayout = (entity: any) => {
  return {
    ...SummaryOption('industrial_waste'),
    ...Subtitle,
    multiRow: true,
    items: [
      {
        key: 'systems_industrial_waste_1',
        groupTitle: 'general_information',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_industrial_waste_left_1',
            items: [
              SummaryItem(
                'regulatory_requirements',
                entity?.regulatoryRequirementsLink
              ),
              SummaryItem(
                'authority_inspections',
                entity?.authorityInspectionsLink
              ),
              SummaryItem(
                'organization',
                entity?.organizationLink
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_industrial_waste_right_1',
            items: [
              SummaryItem(
                'security',
                entity?.securityLink
              ),
              SummaryItem(
                'maintenance_plan',
                entity?.maintenancePlanLink
              ),
            ]
          }
        ]
      },
      ...flatten((entity?.maintenanceCompanies || [])?.map((mCompany: any, index: number) => [
        {
          key: `systems_industrial_waste_maintenance_companies_${index}`,
          groupTitle: 'maintenance_company',
          groupTitleExt: index + 1,
          items: [
            {
              ...SummaryColumn,
              key: `systems_industrial_waste_maintenance_companies_left_${index}`,
              items: [
                SummaryItem('company', mCompany?.company),
                SummaryItem('contact_person', mCompany?.contactPersonName),
                SummaryItem('phone_number', mCompany?.contactPersonPhone),
                SummaryItem('email', mCompany?.contactPersonEmail),
              ]
            },
            {
              ...SummaryColumn,
              key: `systems_industrial_waste_maintenance_companies_right_${index}`,
              items: [
                SummaryItem('address', mCompany?.companyAddress),
                SummaryItem('postal_code', mCompany?.companyPostalCode),
                SummaryItem('city', mCompany?.companyCity),
                SummaryItem('contracts', mCompany?.contractLink),
              ]
            }
          ]
        }
      ])),
      ...flatten((entity?.transportCompanies || [])?.map((tCompany: any, index: number) => [
        {
          key: `systems_industrial_waste_transport_companies_${index}`,
          groupTitle: 'transport_company',
          groupTitleExt: index + 1,
          items: [
            {
              ...SummaryColumn,
              key: `systems_industrial_waste_transport_companies_left_${index}`,
              items: [
                SummaryItem('company', tCompany?.company),
                SummaryItem('contact_person', tCompany?.contactPersonName),
                SummaryItem('phone_number', tCompany?.contactPersonPhone),
                SummaryItem('email', tCompany?.contactPersonEmail),
              ]
            },
            {
              ...SummaryColumn,
              key: `systems_industrial_waste_transport_companies_right_${index}`,
              items: [
                SummaryItem('address', tCompany?.companyAddress),
                SummaryItem('postal_code', tCompany?.companyPostalCode),
                SummaryItem('city', tCompany?.companyCity),
              ]
            }
          ]
        }
      ]))
    ]
  }
}

const BuildingEnergyRecoveryAndRecyclingLayout = (entity: any) => {
  return {
    ...SummaryOption('energy'),
    ...Subtitle,
    multiRow: true,
    items: [
      {
        key: 'systems_energy_1',
        groupTitle: 'systems',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_energy_left_1',
            items: [
              SummaryItem(
                'additional_info',
                entity?.systemInfo
              ),
            ]
          },
        ]
      },
      {
        key: 'systems_energy_2',
        groupTitle: 'general_information',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_energy_left_2',
            items: [
              SummaryItem(
                'organization',
                entity?.organizationLink
              ),
              SummaryItem(
                'recyclable_materials_handling_and_instructions',
                entity?.materialHandlingAndInstructionsLink
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_energy_right_2',
            items: [
              SummaryItem(
                'energy_recovery_and_recycling_plan',
                entity?.energyRecoveryAndRecyclingPlanLink
              ),
            ]
          }
        ]
      },
      {
        key: 'systems_energy_3',
        groupTitle: 'maintenance_company',
        items: [
          {
            ...SummaryColumn,
            key: 'systems_energy_left_3',
            items: [
              SummaryItem('company', entity?.maintenanceCompany),
              SummaryItem('contact_person', entity?.maintenanceCompanyContactPersonName),
              SummaryItem('phone_number', entity?.maintenanceCompanyContactPersonPhone),
              SummaryItem('email', entity?.maintenanceCompanyContactPersonEmail),
            ]
          },
          {
            ...SummaryColumn,
            key: 'systems_energy_right_3',
            items: [
              SummaryItem('address', entity?.maintenanceCompanyAddress),
              SummaryItem('postal_code', entity?.maintenanceCompanyPostalCode),
              SummaryItem('city', entity?.maintenanceCompanyCity),
              SummaryItem('contracts', entity?.maintenanceCompanyContractLink),
            ]
          },
        ]
      }
    ]
  }
}

const RoomLayout = (entity: any, type: string = 'apartment_rooms') => {
  return {
    ...SummaryOption(type),
    ...Title,
    items: [
      {
        ...SummaryOption('coatings_and_materials', type !== 'apartment_rooms' ? 'common_spaces_coatings_and_materials' : null),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: `${type}_information_coatings_and_materials_left`,
            items: [
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'frame_material',
                  uniq(entity?.allFloors?.map((floor: any) => {
                    return floor?.coatingAndMaterial?.frameMaterial === 'other' ?
                      floor?.coatingAndMaterial?.frameMaterialNotes :
                      floor?.coatingAndMaterial?.frameMaterial
                  })),
                ),
                { group: i18n.t('floor') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'frame_material',
                  uniq(entity?.allCeilings.map((ceiling: any) => {
                    return ceiling?.coatingAndMaterial?.frameMaterial === 'other' ?
                      ceiling?.coatingAndMaterial?.frameMaterialNotes :
                      ceiling?.coatingAndMaterial?.frameMaterial
                  })),
                ),
                { group: i18n.t('ceiling'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'frame_material',
                  uniq(entity?.allWalls.map((wall: any) => {
                    return wall?.coatingAndMaterial?.frameMaterial === 'other' ?
                      wall?.coatingAndMaterial?.frameMaterialNotes :
                      wall?.coatingAndMaterial?.frameMaterial
                  })),
                ),
                { group: i18n.t('walls'), hideLabel: true }
              ),
              // TSummaryItem('has_suspended_ceiling', entity?.hasSuspendedCeiling),
            ]
          },
          {
            ...SummaryColumn,
            key: `${type}_information_coatings_and_materials_right`,
            items: [
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'coatings',
                  uniq(flatten(
                    entity?.allFloors?.map((floor: any) => {
                      return (floor?.coatingAndMaterial?.coating?.split(',') || [])
                        .filter((item) => item && item !== ',')
                    })
                  )) as any,
                ),
                { group: i18n.t('floor') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'coatings',
                  uniq(flatten(
                    entity?.allCeilings.map((ceiling: any) => {
                      return (ceiling?.coatingAndMaterial?.coating?.split(',') || [])
                        .filter((item) => item && item !== ',')
                    })
                  )) as any,
                ),
                { group: i18n.t('ceiling'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'coatings',
                  uniq(flatten(
                    entity?.allWalls.map((wall: any) => {
                      return (wall?.coatingAndMaterial?.coating?.split(',') || [])
                        .filter((item) => item && item !== ',')
                    })
                  )) as any,
                ),
                { group: i18n.t('walls'), hideLabel: true }
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('windows_and_doors', type !== 'apartment_rooms' ? 'common_spaces_windows_and_doors' : null),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: `${type}_information_windows_and_doors_left`,
            items: [
              // TODO: INSTALLATION YEAR
              TArraySummaryItem(
                'window_types',
                uniq(flatten(
                  [
                    entity?.allCeilings.map((ceiling: any) => {
                      return ceiling?.windowAndDoor?.windowType === 'other' ?
                        ceiling?.windowAndDoor?.windowTypeNotes :
                        ceiling?.windowAndDoor?.windowType
                    }),
                    entity?.allWalls.map((wall: any) => {
                      return wall?.windowAndDoor?.windowType === 'other' ?
                        wall?.windowAndDoor?.windowTypeNotes :
                        wall?.windowAndDoor?.windowType
                    }),
                  ]
                ))
              ),
              TArraySummaryItem(
                'interior_door_type',
                uniq(flatten(
                  [
                    entity?.allCeilings.map((ceiling: any) => {
                      return ceiling?.windowAndDoor?.interiorDoorType === 'other' ?
                        ceiling?.windowAndDoor?.interiorDoorTypeNotes :
                        ceiling?.windowAndDoor?.interiorDoorType
                    }),
                    entity?.allWalls.map((wall: any) => {
                      return wall?.windowAndDoor?.interiorDoorType === 'other' ?
                        wall?.windowAndDoor?.interiorDoorTypeNotes :
                        wall?.windowAndDoor?.interiorDoorType
                    }),
                  ]
                ))
              ),
              TArraySummaryItem(
                'exterior_door_type',
                uniq(flatten(
                  [
                    entity?.allCeilings.map((ceiling: any) => {
                      return ceiling?.windowAndDoor?.exteriorDoorType === 'other' ?
                        ceiling?.windowAndDoor?.exteriorDoorTypeNotes :
                        ceiling?.windowAndDoor?.exteriorDoorType
                    }),
                    entity?.allWalls.map((wall: any) => {
                      return wall?.windowAndDoor?.exteriorDoorType === 'other' ?
                        wall?.windowAndDoor?.exteriorDoorTypeNotes :
                        wall?.windowAndDoor?.exteriorDoorType
                    }),
                  ]
                )
              )),
            ]
          },
          {
            ...SummaryColumn,
            key: `${type}_information_windows_and_doors_right`,
            items: [
              TSummaryItem(
                'window_count',
                calculateTotalCount(
                  flatten(
                    [
                      entity?.allCeilings.map((ceiling: any) => ceiling?.windowAndDoor?.windowCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.windowAndDoor?.windowCount) || []
                    ]
                  )
                ),
                'kpl'
              ),
              TSummaryItem(
                'interior_door_count',
                calculateTotalCount(
                  flatten(
                    [
                      entity?.allCeilings.map((ceiling: any) => ceiling?.windowAndDoor?.interiorDoorCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.windowAndDoor?.interiorDoorCount) || []
                    ]
                  )
                ),
                'kpl'
              ),
              TSummaryItem(
                'exterior_door_count',
                calculateTotalCount(
                  flatten(
                    [
                      entity?.allCeilings.map((ceiling: any) => ceiling?.windowAndDoor?.exteriorDoorCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.windowAndDoor?.exteriorDoorCount) || []
                    ]
                  )
                ),
                'kpl'
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption(`${type !== 'apartment_rooms' ? 'space' : 'room'}_heating`, type !== 'apartment_rooms' ? 'common_spaces_heating' : null),
        ...Subtitle,
        multiRow: true,
        items: [
          entity?.hasManifolds ? {
            key: `${type}_information_heating_1`,
            groupTitle: 'manifolds',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_heating_left_1`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.heating?.manifoldCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.heating?.manifoldCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.manifoldInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.manifoldInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_heating_right_1`,
                items: [
                  TArraySummaryItem(
                    'manufacturer',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.manifoldManufacturer) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.manifoldManufacturer) || []
                      ]
                    )),
                  ),
                  TArraySummaryItem(
                    'model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.manifoldModel) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.manifoldModel) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          } : null,
          {
            key: `${type}_information_heating_2`,
            groupTitle: 'thermostats',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_heating_left_2`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.heating?.thermostatCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.heating?.thermostatCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.thermostatInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.thermostatInstallationYear) || []
                      ]
                    )),
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_heating_right_2`,
                items: [
                  TArraySummaryItem(
                    'manufacturer',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.thermostatManufacturer) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.thermostatManufacturer) || []
                      ]
                    )),
                  ),
                  TArraySummaryItem(
                    'model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.thermostatModel) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.thermostatModel) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          },
          entity?.hasRadiators ? {
            key: `${type}_information_heating_3`,
            groupTitle: 'radiators',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_heating_left_3`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.heating?.radiatorCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.heating?.radiatorCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.radiatorInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.radiatorInstallationYear) || []
                      ]
                    )),
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_heating_right_3`,
                items: [
                  TArraySummaryItem(
                    'manufacturer',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.radiatorManufacturer) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.radiatorManufacturer) || []
                      ]
                    )),
                  ),
                  TArraySummaryItem(
                    'model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.radiatorModel) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.radiatorModel) || []
                      ]
                    ))
                  )
                ]
              }
            ]
          } : null,
          entity?.hasAirHeatPump ? {
            key: `${type}_information_heating_4`,
            groupTitle: 'air_heat_pumps',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_heating_left_4`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.heating?.airHeatPumpCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.heating?.airHeatPumpCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.airHeatPumpInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.airHeatPumpInstallationYear) || []
                      ]
                    )),
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_heating_right_4`,
                items: [
                  TArraySummaryItem(
                    'manufacturer',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.airHeatPumpManufacturer) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.airHeatPumpManufacturer) || []
                      ]
                    )),
                  ),
                  TArraySummaryItem(
                    'model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.heating?.airHeatPumpModel) || [],
                        entity?.allWalls.map((wall: any) => wall?.heating?.airHeatPumpModel) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          } : null,
        ].filter(Boolean)
      },
      {
        ...SummaryOption('water_fixtures', type !== 'apartment_rooms' ? 'common_spaces_water_fixtures' : null),
        ...Subtitle,
        multiRow: true,
        items: [
          {
            key: `${type}_information_water_fixtures_0`,
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_left_0`,
                items: [
                  TArraySummaryItem(
                    'type',
                    [
                      (calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.basinMixerCount),
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.basinMixerCount)
                        ]
                      )) > 0 ? 'basin_mixer' : ''),
                      (calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.showerMixerCount),
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.showerMixerCount)
                        ]
                      )) > 0 ? 'shower_mixer' : ''),
                      (calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.waterDryingRadiatorCount),
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.waterDryingRadiatorCount)
                        ]
                      )) > 0 ? 'water_drying_radiator' : ''),
                      (calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.toiletSeatCount),
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.toiletSeatCount)
                        ]
                      )) > 0 ? 'toilet_seat' : ''),
                      (calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.bidetShowerCount),
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.bidetShowerCount)
                        ]
                      )) > 0 ? 'bidet_shower' : ''),
                    ].filter(Boolean)
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_water_fixtures_1`,
            groupTitle: 'basin_mixer',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_left_1`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.basinMixerCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.basinMixerCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_right_1`,
                items: [
                  TArraySummaryItem(
                    'manufacturer_and_model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => {
                          return ((floor?.waterFixture?.basinMixerManufacturer || '') + ' ' + (floor?.waterFixture?.basinMixerModel || ''))?.trim()
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return ((wall?.waterFixture?.basinMixerManufacturer || '') + ' ' + (wall?.waterFixture?.basinMixerModel || ''))?.trim()
                        }) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_water_fixtures_2`,
            groupTitle: 'shower_mixer',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_left_2`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.showerMixerCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.showerMixerCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_right_2`,
                items: [
                  TArraySummaryItem(
                    'manufacturer_and_model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => {
                          return ((floor?.waterFixture?.showerMixerManufacturer || '') + ' ' + (floor?.waterFixture?.showerMixerModel || ''))?.trim()
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return ((wall?.waterFixture?.showerMixerManufacturer || '') + ' ' + (wall?.waterFixture?.showerMixerModel || ''))?.trim()
                        }) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_water_fixtures_3`,
            groupTitle: 'water_drying_radiator',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_left_3`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.waterDryingRadiatorCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.waterDryingRadiatorCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_right_3`,
                items: [
                  TArraySummaryItem(
                    'manufacturer_and_model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => {
                          return ((floor?.waterFixture?.waterDryingRadiatorManufacturer || '') + ' ' + (floor?.waterFixture?.waterDryingRadiatorModel || ''))?.trim()
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return ((wall?.waterFixture?.waterDryingRadiatorManufacturer || '') + ' ' + (wall?.waterFixture?.waterDryingRadiatorModel || ''))?.trim()
                        }) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_water_fixtures_4`,
            groupTitle: 'toilet_seat',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_left_4`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.toiletSeatCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.toiletSeatCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_right_4`,
                items: [
                  TArraySummaryItem(
                    'manufacturer_and_model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => {
                          return ((floor?.waterFixture?.toiletSeatManufacturer || '') + ' ' + (floor?.waterFixture?.toiletSeatModel || ''))?.trim()
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return ((wall?.waterFixture?.toiletSeatManufacturer || '') + ' ' + (wall?.waterFixture?.toiletSeatModel || ''))?.trim()
                        }) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_water_fixtures_5`,
            groupTitle: 'bidet_shower',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_left_5`,
                items: [
                  TSummaryItem(
                    'count',
                    calculateTotalCount(
                      flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.waterFixture?.bidetShowerCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.waterFixture?.bidetShowerCount) || []
                        ]
                      )
                    ),
                    'kpl'
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_water_fixtures_right_5`,
                items: [
                  TArraySummaryItem(
                    'manufacturer_and_model',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => {
                          return ((floor?.waterFixture?.bidetShowerManufacturer || '') + ' ' + (floor?.waterFixture?.bidetShowerModel || ''))?.trim()
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return ((wall?.waterFixture?.bidetShowerManufacturer || '') + ' ' + (wall?.waterFixture?.bidetShowerModel || ''))?.trim()
                        }) || []
                      ]
                    )),
                  ),
                ]
              }
            ]
          },
        ]?.filter((item: any, index: number) => index === 0 || item?.items?.[0]?.items?.[0]?.value !== '0 kpl')
      },
      {
        ...SummaryOption('drainage', type !== 'apartment_rooms' ? 'common_spaces_drainage' : null),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: `${type}_information_drainage_left`,
            items: [
              TArraySummaryItem(
                'material',
                uniq(flatten(
                  [
                    entity?.allFloors.map((floor: any) => {
                      return floor?.drainage?.sinkMaterial === 'other' ?
                        floor?.drainage?.sinkMaterialNotes :
                        floor?.drainage?.sinkMaterial
                    }) || [],
                    entity?.allWalls.map((wall: any) => {
                      return wall?.drainage?.sinkMaterial === 'other' ?
                        wall?.drainage?.sinkMaterialNotes :
                        wall?.drainage?.sinkMaterial
                    }) || []
                  ]
                ))
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'drain_trap',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.drainTrapMaterial === 'other' ?
                          floor?.drainage?.drainTrapMaterialNotes :
                          floor?.drainage?.drainTrapMaterial
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.drainTrapMaterial === 'other' ?
                          wall?.drainage?.drainTrapMaterialNotes :
                          wall?.drainage?.drainTrapMaterial
                      }) || []
                    ]
                  )
                )),
                { group: i18n.t('material') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'drain_trap_installation_year',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.drainTrapInstallationYear) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.drainTrapInstallationYear) || []
                    ]
                  )),
                ),
                { group: i18n.t('installation_year'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'drain_trap_connection_type',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.drainTrapConnectionType === 'other' ?
                          floor?.drainage?.drainTrapConnectionTypeNotes :
                          floor?.drainage?.drainTrapConnectionType
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.drainTrapConnectionType === 'other' ?
                          wall?.drainage?.drainTrapConnectionTypeNotes :
                          wall?.drainage?.drainTrapConnectionType
                      }) || []
                    ]
                  )),
                ),
                { group: i18n.t('connection_type'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'drain_trap_count',
                  calculateTotalCount(
                    flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.drainage?.drainTrapCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.drainage?.drainTrapCount) || []
                      ]
                    ),
                  ),
                  'kpl'
                ),
                { group: i18n.t('count'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'water_sealed_drain',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.waterSealedDrainType === 'other' ?
                          floor?.drainage?.waterSealedDrainTypeNotes :
                          floor?.drainage?.waterSealedDrainType
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.waterSealedDrainType === 'other' ?
                          wall?.drainage?.waterSealedDrainTypeNotes :
                          wall?.drainage?.waterSealedDrainType
                      }) || []
                    ]
                  )),
                ),
                { group: i18n.t('type') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'water_sealed_drain_installation_year',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.waterSealedDrainInstallationYear) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.waterSealedDrainInstallationYear) || []
                    ]
                  )),
                ),
                { group: i18n.t('installation_year'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'water_sealed_drain_material',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.waterSealedDrainMaterial === 'other' ?
                          floor?.drainage?.waterSealedDrainMaterialNotes :
                          floor?.drainage?.waterSealedDrainMaterial
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.waterSealedDrainMaterial === 'other' ?
                          wall?.drainage?.waterSealedDrainMaterialNotes :
                          wall?.drainage?.waterSealedDrainMaterial
                      }) || []
                    ]
                  )),
                ),
                { group: i18n.t('material'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'water_sealed_drain_manufacturer',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.waterSealedDrainManufacturer === 'other' ?
                          floor?.drainage?.waterSealedDrainManufacturerNotes :
                          floor?.drainage?.waterSealedDrainManufacturer
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.waterSealedDrainManufacturer === 'other' ?
                          wall?.drainage?.waterSealedDrainManufacturerNotes :
                          wall?.drainage?.waterSealedDrainManufacturer
                      }) || []
                    ]
                  )),
                ),
                { group: i18n.t('manufacturer'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_sealed_drain_count',
                  calculateTotalCount(
                    flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.drainage?.waterSealedDrainCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.drainage?.waterSealedDrainCount) || []
                      ]
                    ),
                  ),
                  'kpl'
                ),
                { group: i18n.t('count'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'water_sealed_drain_extension_ring_material',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.waterSealedDrainExtensionRingMaterial === 'other' ?
                          floor?.drainage?.waterSealedDrainExtensionRingMaterialNotes :
                          floor?.drainage?.waterSealedDrainExtensionRingMaterial
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.waterSealedDrainExtensionRingMaterial === 'other' ?
                          wall?.drainage?.waterSealedDrainExtensionRingMaterialNotes :
                          wall?.drainage?.waterSealedDrainExtensionRingMaterial
                      }) || []
                    ]
                  )),
                ),
                { group: i18n.t('extension_ring_material'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'water_sealed_drain_extension_ring_installation_year',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.waterSealedDrainExtensionRingInstallationYear) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.waterSealedDrainExtensionRingInstallationYear) || []
                    ]
                  ))
                ),
                { group: i18n.t('extension_ring_installation_year'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'water_sealed_drain_extension_ring_count',
                  calculateTotalCount(
                    flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.drainage?.waterSealedDrainExtensionRingCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.drainage?.waterSealedDrainExtensionRingCount) || []
                      ]
                    ),
                  ),
                  'kpl'
                ),
                { group: i18n.t('extension_ring_count'), hideLabel: true }
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: `${type}_information_drainage_right`,
            items: [
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'dry_drain',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.dryDrainType === 'other' ?
                          floor?.drainage?.dryDrainTypeNotes :
                          floor?.drainage?.dryDrainType
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.dryDrainType === 'other' ?
                          wall?.drainage?.dryDrainTypeNotes :
                          wall?.drainage?.dryDrainType
                      }) || []
                    ]
                  ))
                ),
                { group: i18n.t('type') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'dry_drain_installation_year',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.dryDrainInstallationYear) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.dryDrainInstallationYear) || []
                    ]
                  ))
                ),
                { group: i18n.t('installation_year'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'dry_drain_material',
                  entity?.dryDrainMaterial === 'other' ?
                  entity?.dryDrainMaterialNotes :
                  entity?.dryDrainMaterial
                ),
                { group: i18n.t('material'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'dry_Drain_manufacturer',
                  entity?.dryDrainManufacturer === 'other' ?
                  entity?.dryDrainManufacturerNotes :
                  entity?.dryDrainManufacturer
                ),
                { group: i18n.t('manufacturer'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'dry_drain_count',
                  calculateTotalCount(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.dryDrainCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.dryDrainCount) || []
                    ]
                  )),
                  'kpl'
                ),
                { group: i18n.t('count'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'dry_drain_extension_ring_material',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.dryDrainExtensionRingMaterial === 'other' ?
                          floor?.drainage?.dryDrainExtensionRingMaterialNotes :
                          floor?.drainage?.dryDrainExtensionRingMaterial
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.dryDrainExtensionRingMaterial === 'other' ?
                          wall?.drainage?.dryDrainExtensionRingMaterialNotes :
                          wall?.drainage?.dryDrainExtensionRingMaterial
                      }) || []
                    ]
                  ))
                ),
                { group: i18n.t('extension_ring_material'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'dry_drain_extension_ring_installation_year',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.dryDrainExtensionRingInstallationYear) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.dryDrainExtensionRingInstallationYear) || []
                    ]
                  ))
                ),
                { group: i18n.t('installation_year'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TSummaryItem(
                  'dry_drain_extension_ring_count',
                  calculateTotalCount(flatten(
                    [
                      entity?.allFloors.map((floor: any) => floor?.drainage?.dryDrainExtensionRingCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.drainage?.dryDrainExtensionRingCount) || []
                    ]
                  )),
                  'kpl'
                ),
                { group: i18n.t('extension_ring_count'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'washing_machine',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.washingMachineConnection === 'other' ?
                          floor?.drainage?.washingMachineConnectionNotes :
                          floor?.drainage?.washingMachineConnection
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.washingMachineConnection === 'other' ?
                          wall?.drainage?.washingMachineConnectionNotes :
                          wall?.drainage?.washingMachineConnection
                      }) || []
                    ]
                  ))
                ),
                { group: i18n.t('water_connection') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'washing_machine_drain_connection',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.washingMachineDrainConnection === 'other' ?
                          floor?.drainage?.washingMachineDrainConnectionNotes :
                          floor?.drainage?.washingMachineDrainConnection
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.washingMachineDrainConnection === 'other' ?
                          wall?.drainage?.washingMachineDrainConnectionNotes :
                          wall?.drainage?.washingMachineDrainConnection
                      }) || []
                    ]
                  ))
                ),
                { group: i18n.t('drain_connection'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'shower_cabin',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.showerCabinType === 'other' ?
                          floor?.drainage?.showerCabinTypeNotes :
                          floor?.drainage?.showerCabinType
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.showerCabinType === 'other' ?
                          wall?.drainage?.showerCabinTypeNotes :
                          wall?.drainage?.showerCabinType
                      }) || []
                    ]
                  ))
                ),
                { group: i18n.t('type') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'shower_cabin_drainage_type',
                  uniq(flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.drainage?.showerCabinDrainageType === 'other' ?
                          floor?.drainage?.showerCabinDrainageTypeNotes :
                          floor?.drainage?.showerCabinDrainageType
                      }) || [],
                      entity?.allWalls.map((wall: any) => {
                        return wall?.drainage?.showerCabinDrainageType === 'other' ?
                          wall?.drainage?.showerCabinDrainageTypeNotes :
                          wall?.drainage?.showerCabinDrainageType
                      }) || []
                    ]
                  ))
                ),
                { group: i18n.t('drainage_type'), hideLabel: true } 
              )
            ]
          }
        ]
      },
      {
        ...SummaryOption('water_proofing', type !== 'apartment_rooms' ? 'common_spaces_water_proofing' : null),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: `${type}_information_water_proofing_left`,
            items: [
              TArraySummaryItem(
                'material',
                uniq(flatten(
                  [
                    entity?.allFloors.map((floor: any) => {
                      return floor?.waterProofing?.waterProofingMaterial === 'other' ?
                        floor?.waterProofing?.waterProofingMaterialNotes :
                        floor?.waterProofing?.waterProofingMaterial
                    }) || [],
                    entity?.allWalls.map((wall: any) => {
                      return wall?.waterProofing?.waterProofingMaterial === 'other' ?
                        wall?.waterProofing?.waterProofingMaterialNotes :
                        wall?.waterProofing?.waterProofingMaterial
                    }) || []
                  ]
                ))
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: `${type}_information_water_proofing_right`,
            items: [
              TArraySummaryItem(
                'installation_year',
                uniq(flatten(
                  [
                    entity?.allFloors.map((floor: any) => {
                      return floor?.waterProofing?.waterProofingInstallationYear
                    }) || [],
                    entity?.allWalls.map((wall: any) => {
                      return wall?.waterProofing?.waterProofingInstallationYear
                    }) || []
                  ]
                ))
              ),
            ]
          },
        ]
      },
      {
        ...SummaryOption(`${type !== 'apartment_rooms' ? 'space' : 'room'}_ventilation`, type !== 'apartment_rooms' ? 'common_spaces_ventilation' : null),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: `${type}_information_ventilation_left`,
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'exhaust_ventilation_units',
                  calculateTotalCount(
                    flatten([
                      entity?.allFloors.map((floor: any) => floor?.ventilation?.exhaustVentilationUnitCount) || [],
                      entity?.allCeilings.map((ceiling: any) => ceiling?.ventilation?.exhaustVentilationUnitCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.ventilation?.exhaustVentilationUnitCount) || []
                    ])
                  ),
                  'kpl'
                ),
                { group: i18n.t('count') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'exhaust_ventilation_unit_type',
                  flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.ventilation?.exhaustVentilationUnitType === 'other' ?
                          floor?.ventilation?.exhaustVentilationUnitTypeNotes :
                          floor?.ventilation?.exhaustVentilationUnitType
                      }),
                      entity?.allCeilings.map((ceiling: any) => {
                        return ceiling?.ventilation?.exhaustVentilationUnitType === 'other' ?
                          ceiling?.ventilation?.exhaustVentilationUnitTypeNotes :
                          ceiling?.ventilation?.exhaustVentilationUnitType
                      }),
                      entity?.allWalls.map((wall: any) => {
                        return wall?.ventilation?.exhaustVentilationUnitType === 'other' ?
                          wall?.ventilation?.exhaustVentilationUnitTypeNotes :
                          wall?.ventilation?.exhaustVentilationUnitType
                      })
                    ]
                  ),
                ),
                { group: i18n.t('type'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'exhaust_ventilation_unit_size',
                  flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.ventilation?.exhaustVentilationUnitSize === 'other' ?
                          floor?.ventilation?.exhaustVentilationUnitSizeNotes :
                          floor?.ventilation?.exhaustVentilationUnitSize
                      }),
                      entity?.allCeilings.map((ceiling: any) => {
                        return ceiling?.ventilation?.exhaustVentilationUnitSize === 'other' ?
                          ceiling?.ventilation?.exhaustVentilationUnitSizeNotes :
                          ceiling?.ventilation?.exhaustVentilationUnitSize
                      }),
                      entity?.allWalls.map((wall: any) => {
                        return wall?.ventilation?.exhaustVentilationUnitSize === 'other' ?
                          wall?.ventilation?.exhaustVentilationUnitSizeNotes :
                          wall?.ventilation?.exhaustVentilationUnitSize
                      })
                    ]
                  )
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
            ]
          },
          {
            ...SummaryColumn,
            key: `${type}_information_ventilation_right`,
            items: [
              TSummaryWrapperItem(
                TSummaryItem(
                  'supply_ventilation_units',
                  calculateTotalCount(
                    flatten([
                      entity?.allFloors.map((floor: any) => floor?.ventilation?.supplyVentilationUnitCount) || [],
                      entity?.allCeilings.map((ceiling: any) => ceiling?.ventilation?.supplyVentilationUnitCount) || [],
                      entity?.allWalls.map((wall: any) => wall?.ventilation?.supplyVentilationUnitCount) || []
                    ])
                  ),
                  'kpl'
                ),
                { group: i18n.t('count') }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'supply_ventilation_unit_type',
                  flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.ventilation?.supplyVentilationUnitType === 'other' ?
                          floor?.ventilation?.supplyVentilationUnitTypeNotes :
                          floor?.ventilation?.supplyVentilationUnitType
                      }),
                      entity?.allCeilings.map((ceiling: any) => {
                        return ceiling?.ventilation?.supplyVentilationUnitType === 'other' ?
                          ceiling?.ventilation?.supplyVentilationUnitTypeNotes :
                          ceiling?.ventilation?.supplyVentilationUnitType
                      }),
                      entity?.allWalls.map((wall: any) => {
                        return wall?.ventilation?.supplyVentilationUnitType === 'other' ?
                          wall?.ventilation?.supplyVentilationUnitTypeNotes :
                          wall?.ventilation?.supplyVentilationUnitType
                      })
                    ]
                  ),
                ),
                { group: i18n.t('type'), hideLabel: true }
              ),
              TSummaryWrapperItem(
                TArraySummaryItem(
                  'supply_ventilation_unit_size',
                  flatten(
                    [
                      entity?.allFloors.map((floor: any) => {
                        return floor?.ventilation?.supplyVentilationUnitSize === 'other' ?
                          floor?.ventilation?.supplyVentilationUnitSizeNotes :
                          floor?.ventilation?.supplyVentilationUnitSize
                      }),
                      entity?.allCeilings.map((ceiling: any) => {
                        return ceiling?.ventilation?.supplyVentilationUnitSize === 'other' ?
                          ceiling?.ventilation?.supplyVentilationUnitSizeNotes :
                          ceiling?.ventilation?.supplyVentilationUnitSize
                      }),
                      entity?.allWalls.map((wall: any) => {
                        return wall?.ventilation?.supplyVentilationUnitSize === 'other' ?
                          wall?.ventilation?.supplyVentilationUnitSizeNotes :
                          wall?.ventilation?.supplyVentilationUnitSize
                      })
                    ]
                  ),
                ),
                { group: i18n.t('size'), hideLabel: true }
              ),
            ]
          }
        ]
      },
      {
        ...SummaryOption('sockets_and_lighting', type !== 'apartment_rooms' ? 'common_spaces_sockets_and_lighting' : null),
        ...Subtitle,
        multiRow: true,
        items: [
          {
            key: `${type}_information_sockets_and_lighting_1`,
            groupTitle: 'sockets',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_sockets_and_lighting_left_1`,
                items: [
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'count',
                      calculateTotalCount(
                        flatten([
                          entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.powerSocketCount) || [],
                          entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.powerSocketCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.powerSocketCount) || []
                        ])
                      ),
                      'kpl'
                    ),
                    { group: i18n.t('power_sockets') }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'data_socket_count',
                      calculateTotalCount(
                        flatten([
                          entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.dataSocketCount) || [],
                          entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.dataSocketCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.dataSocketCount) || []
                        ])
                      ),
                      'kpl'
                    ),
                    { group: i18n.t('data_sockets'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TSummaryItem(
                      'lighting_socket_count',
                      calculateTotalCount(
                        flatten([
                          entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.lightingSocketCount) || [],
                          entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.lightingSocketCount) || [],
                          entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.lightingSocketCount) || []
                        ])
                      ),
                      'kpl'
                    ),
                    { group: i18n.t('lighting_sockets'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TArraySummaryItem(
                      'type',
                      uniq(flatten([
                        entity?.allFloors.map((floor: any) => {
                          return floor?.socketAndLighting?.powerSocketType === 'other' ?
                            floor?.socketAndLighting?.powerSocketTypeNotes :
                            floor?.socketAndLighting?.powerSocketType
                        }) || [],
                        entity?.allCeilings?.map((ceiling: any) => {
                          return ceiling?.socketAndLighting?.powerSocketType === 'other' ?
                            ceiling?.socketAndLighting?.powerSocketTypeNotes :
                            ceiling?.socketAndLighting?.powerSocketType
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return wall?.socketAndLighting?.powerSocketType === 'other' ?
                            wall?.socketAndLighting?.powerSocketTypeNotes :
                            wall?.socketAndLighting?.powerSocketType
                        }) || []
                      ]))
                    ),
                    { group: i18n.t('power_sockets') }
                  ),
                  TSummaryWrapperItem(
                    TArraySummaryItem(
                      'data_socket_type',
                      uniq(flatten([
                        entity?.allFloors.map((floor: any) => {
                          return floor?.socketAndLighting?.dataSocketType === 'other' ?
                            floor?.socketAndLighting?.dataSocketTypeNotes :
                            floor?.socketAndLighting?.dataSocketType
                        }) || [],
                        entity?.allCeilings.map((ceiling: any) => {
                          return ceiling?.socketAndLighting?.dataSocketType === 'other' ?
                            ceiling?.socketAndLighting?.dataSocketTypeNotes :
                            ceiling?.socketAndLighting?.dataSocketType
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return wall?.socketAndLighting?.dataSocketType === 'other' ?
                            wall?.socketAndLighting?.dataSocketTypeNotes :
                            wall?.socketAndLighting?.dataSocketType
                        }) || []
                      ]))
                    ),
                    { group: i18n.t('data_sockets'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TArraySummaryItem(
                      'lighting_socket_type',
                      uniq(flatten([
                        entity?.allFloors.map((floor: any) => {
                          return floor?.socketAndLighting?.lightingSocketType === 'other' ?
                            floor?.socketAndLighting?.lightingSocketTypeNotes :
                            floor?.socketAndLighting?.lightingSocketType
                        }) || [],
                        entity?.allCeilings.map((ceiling: any) => {
                          return ceiling?.socketAndLighting?.lightingSocketType === 'other' ?
                            ceiling?.socketAndLighting?.lightingSocketTypeNotes :
                            ceiling?.socketAndLighting?.lightingSocketType
                        }) || [],
                        entity?.allWalls.map((wall: any) => {
                          return wall?.socketAndLighting?.lightingSocketType === 'other' ?
                            wall?.socketAndLighting?.lightingSocketTypeNotes :
                            wall?.socketAndLighting?.lightingSocketType
                        }) || []
                      ]))
                    ),
                    { group: i18n.t('lighting_sockets'), hideLabel: true }
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_sockets_and_lighting_right_1`,
                items: [
                  TSummaryWrapperItem(
                    TArraySummaryItem(
                      'installation_year',
                      uniq(flatten([
                        entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.powerSocketInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.powerSocketInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.powerSocketInstallationYear) || []
                      ]))
                    ),
                    { group: i18n.t('power_sockets') }
                  ),
                  TSummaryWrapperItem(
                    TArraySummaryItem(
                      'installation_year',
                      uniq(flatten([
                        entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.dataSocketInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.dataSocketInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.dataSocketInstallationYear) || []
                      ]))
                    ),
                    { group: i18n.t('data_sockets'), hideLabel: true }
                  ),
                  TSummaryWrapperItem(
                    TArraySummaryItem(
                      'installation_year',
                      uniq(flatten([
                        entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.lightingSocketInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.lightingSocketInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.lightingSocketInstallationYear) || []
                      ]))
                    ),
                    { group: i18n.t('lighting_sockets'), hideLabel: true }
                  ),
                ]
              },
            ],
          },
          {
            key: `${type}_information_sockets_and_lighting_2`,
            groupTitle: 'light_switches',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_sockets_and_lighting_left_2`,
                items: [
                  TArraySummaryItem(
                    'type',
                    uniq(flatten(
                      [
                        flatten(entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.lightSwitchTypes?.split(',') || []) || []),
                        flatten(entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.lightSwitchTypes?.split(',') || []) || []),
                        flatten(entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.lightSwitchTypes?.split(',') || []) || [])
                      ]
                    ))
                    ?.map((type: string) => {
                      if (!type) return ''
                      const fieldName = `lightSwitch${upperFirst(camelCase(type))}Count`
                      const count = calculateTotalCount(
                        flatten([
                          entity?.allFloors.map((floor: any) => floor?.socketAndLighting[fieldName]) || [],
                          entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting[fieldName]) || [],
                          entity?.allWalls.map((wall: any) => wall?.socketAndLighting[fieldName]) || []
                        ])
                      )
                      return `${i18n.t(type)} (${count} kpl)`
                    })
                  ),
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_sockets_and_lighting_right_2`,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        flatten(entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.lightSwitchTypes?.split(',') || []) || []),
                        flatten(entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.lightSwitchTypes?.split(',') || []) || []),
                        flatten(entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.lightSwitchTypes?.split(',') || []) || [])
                      ]
                    ))
                    ?.map((type: string) => {
                      if (!type) return ''
                      const fieldName = `lightSwitch${upperFirst(camelCase(type))}InstallationYear`
                      const years = uniq(flatten(
                        [
                          entity?.allFloors.map((floor: any) => floor?.socketAndLighting[fieldName]) || [],
                          entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting[fieldName]) || [],
                          entity?.allWalls.map((wall: any) => wall?.socketAndLighting[fieldName]) || []
                        ]
                      ))?.filter(Boolean)
                      return `${years.join(', ')} (${i18n.t(type)})`
                    })
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_sockets_and_lighting_3`,
            groupTitle: 'lighting_fixtures',
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_sockets_and_lighting_left_3`,
                items: [
                  TSummaryItem(
                    'lighting_fixture_count',
                    calculateTotalCount(
                      flatten([
                        entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.lightingFixtureCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.lightingFixtureCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.lightingFixtureCount) || []
                      ])
                    ),
                    'kpl'
                  )
                ]
              },
              {
                ...SummaryColumn,
                key: `${type}_information_sockets_and_lighting_right_3`,
                items: [
                  TArraySummaryItem(
                    'lighting_fixture_installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.socketAndLighting?.lightingFixtureInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.socketAndLighting?.lightingFixtureInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.socketAndLighting?.lightingFixtureInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
        ]
      },
      {
        ...SummaryOption('hearths', type !== 'apartment_rooms' ? 'common_spaces_hearths' : null),
        ...Subtitle,
        multiRow: true,
        items: [
          {
            key: `${type}_information_hearths_1`,
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_hearths_left_1`,
                items: [
                  TArraySummaryItem(
                    'type',
                    uniq(flatten(
                      [
                        flatten(entity?.allFloors.map((floor: any) => floor?.hearth?.hearthTypes?.split(',') || []) || []),
                        flatten(entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.hearthTypes?.split(',') || []) || []),
                        flatten(entity?.allWalls.map((wall: any) => wall?.hearth?.hearthTypes?.split(',') || []) || [])
                      ]
                    ))
                  ),
                ]
              },
            ]
          },
          {
            key: `${type}_information_hearths_2`,
            groupTitle: 'sauna_stove',
            items: [
              {
                key: `${type}_information_hearths_left_2`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.saunaStoveCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.saunaStoveCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.saunaStoveCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_2`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.saunaStoveInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.saunaStoveInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.saunaStoveInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_hearths_3`,
            groupTitle: 'baking_oven',
            items: [
              {
                key: `${type}_information_hearths_left_3`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.bakingOvenCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.bakingOvenCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.bakingOvenCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_3`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.bakingOvenInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.bakingOvenInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.bakingOvenInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_hearths_4`,
            groupTitle: 'fireplace',
            items: [
              {
                key: `${type}_information_hearths_left_4`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.fireplaceCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.fireplaceCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.fireplaceCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_4`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.fireplaceInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.fireplaceInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.fireplaceInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_hearths_5`,
            groupTitle: 'open_fireplace',
            items: [
              {
                key: `${type}_information_hearths_left_5`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.openFireplaceCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.openFireplaceCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.openFireplaceCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_5`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.openFireplaceInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.openFireplaceInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.openFireplaceInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_hearths_6`,
            groupTitle: 'tiled_stove',
            items: [
              {
                key: `${type}_information_hearths_left_6`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.tiledStoveCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.tiledStoveCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.tiledStoveCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_6`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.tiledStoveInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.tiledStoveInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.tiledStoveInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_hearths_7`,
            groupTitle: 'barrel_stove',
            items: [
              {
                key: `${type}_information_hearths_left_7`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.barrelStoveCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.barrelStoveCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.barrelStoveCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_7`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.barrelStoveInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.barrelStoveInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.barrelStoveInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_hearths_8`,
            groupTitle: 'wood_stove',
            items: [
              {
                key: `${type}_information_hearths_left_8`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.woodStoveCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.woodStoveCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.woodStoveCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_hearths_right_8`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.hearth?.woodStoveInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.hearth?.woodStoveInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.hearth?.woodStoveInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
        ]?.filter((item: any, index: number) => index === 0 || item?.items?.[0]?.items?.[0]?.value !== '0 kpl')
      },
      {
        ...SummaryOption('electrical_devices', type !== 'apartment_rooms' ? 'common_spaces_electrical_devices' : null),
        ...Subtitle,
        multiRow: true,
        items: [
          {
            key: `${type}_information_electrical_devices_1`,
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_electrical_devices_left_1`,
                items: [
                  TArraySummaryItem(
                    'electrical_devices',
                    uniq(flatten(
                      [
                        flatten(entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricalDevices?.split(',') || []) || []),
                        flatten(entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricalDevices?.split(',') || []) || []),
                        flatten(entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricalDevices?.split(',') || []) || [])
                      ]
                    )),
                  ),
                  // Calculate the total count of smoke detectors
                  // SummaryItem('smoke_detectors', entity?.hasSmokeDetector),
                ]
              },
            ]
          },
          {
            key: `${type}_information_electrical_devices_2`,
            groupTitle: 'electric_stove',
            items: [
              {
                key: `${type}_information_electrical_devices_left_2`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricStoveCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricStoveCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricStoveCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_electrical_devices_right_2`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricStoveInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricStoveInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricStoveInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              },
            ]
          },
          {
            key: `${type}_information_electrical_devices_3`,
            groupTitle: 'electric_oven',
            items: [
              {
                key: `${type}_information_electrical_devices_left_3`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricOvenCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricOvenCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricOvenCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_electrical_devices_right_3`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricOvenInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricOvenInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricOvenInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              },
            ]
          },
          {
            key: `${type}_information_electrical_devices_4`,
            groupTitle: 'range_hood',
            items: [
              {
                key: `${type}_information_electrical_devices_left_4`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.rangeHoodCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.rangeHoodCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.rangeHoodCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_electrical_devices_right_4`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.rangeHoodInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.rangeHoodInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.rangeHoodInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              },
            ]
          },
          {
            key: `${type}_information_electrical_devices_5`,
            groupTitle: 'electric_sauna_stove',
            items: [
              {
                key: `${type}_information_electrical_devices_left_5`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricSaunaStoveCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricSaunaStoveCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricSaunaStoveCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_electrical_devices_right_5`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.electricSaunaStoveInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.electricSaunaStoveInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.electricSaunaStoveInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              },
            ]
          },
          {
            key: `${type}_information_electrical_devices_6`,
            groupTitle: 'air_heat_pump',
            items: [
              {
                key: `${type}_information_electrical_devices_left_6`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.airHeatPumpCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.airHeatPumpCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.airHeatPumpCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_electrical_devices_right_6`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.electricalDevice?.airHeatPumpInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.electricalDevice?.airHeatPumpInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.electricalDevice?.airHeatPumpInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              },
            ]
          },
        ]?.filter((item: any, index: number) => index === 0 || item?.items?.[0]?.items?.[0]?.value !== '0 kpl')
      },
      {
        ...SummaryOption('fixtures', type !== 'apartment_rooms' ? 'common_spaces_fixtures' : null),
        ...Subtitle,
        multiRow: true,
        items: [
          {
            key: `${type}_information_fixtures_1`,
            items: [
              {
                ...SummaryColumn,
                key: `${type}_information_fixtures_left_1`,
                items: [
                  TArraySummaryItem(
                    'fixtures',
                    uniq(flatten(
                      [
                        flatten(entity?.allFloors.map((floor: any) => floor?.fixture?.fixtures?.split(',') || []) || []),
                        flatten(entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.fixtures?.split(',') || []) || []),
                        flatten(entity?.allWalls.map((wall: any) => wall?.fixture?.fixtures?.split(',') || []) || [])
                      ]
                    )),
                  ),
                ]
              },
            ]
          },
          {
            key: `${type}_information_fixtures_2`,
            groupTitle: 'sink_cabinet',
            items: [
              {
                key: `${type}_information_fixtures_left_2`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.sinkCabinetCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.sinkCabinetCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.sinkCabinetCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_fixtures_right_2`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.sinkCabinetInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.sinkCabinetInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.sinkCabinetInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_fixtures_3`,
            groupTitle: 'cabinet',
            items: [
              {
                key: `${type}_information_fixtures_left_3`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.cabinetCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.cabinetCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.cabinetCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_fixtures_right_3`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.cabinetInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.cabinetInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.cabinetInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_fixtures_4`,
            groupTitle: 'island',
            items: [
              {
                key: `${type}_information_fixtures_left_4`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.islandCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.islandCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.islandCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_fixtures_right_4`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.islandInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.islandInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.islandInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
          {
            key: `${type}_information_fixtures_5`,
            groupTitle: 'shelf',
            items: [
              {
                key: `${type}_information_fixtures_left_5`,
                ...SummaryColumn,
                items: [
                  SummaryItem(
                    'count',
                    calculateTotalCount(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.shelfCount) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.shelfCount) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.shelfCount) || []
                      ]
                    )),
                    'kpl'
                  ),
                ]
              },
              {
                key: `${type}_information_fixtures_right_5`,
                ...SummaryColumn,
                items: [
                  TArraySummaryItem(
                    'installation_year',
                    uniq(flatten(
                      [
                        entity?.allFloors.map((floor: any) => floor?.fixture?.shelfInstallationYear) || [],
                        entity?.allCeilings.map((ceiling: any) => ceiling?.fixture?.shelfInstallationYear) || [],
                        entity?.allWalls.map((wall: any) => wall?.fixture?.shelfInstallationYear) || []
                      ]
                    ))
                  ),
                ]
              }
            ]
          },
        ]?.filter((item: any, index: number) => index === 0 || item?.items?.[0]?.items?.[0]?.value !== '0 kpl')
      },
      /*
      {
        ...SummaryOption('cooling'),
        ...Subtitle,
        items: [
          {
            ...SummaryColumn,
            key: `${type}_information_cooling_left',
            items: [
            ]
          },
          {
            ...SummaryColumn,
            key: `${type}_information_cooling_right',
            items: [
            ]
          }
        ]
      }
      */
    ]
  }
}

export const SummaryBuildingLayout = (building: any, template: any, propertyType: string | null) => {
  const layout = [
    {
      ...SummaryOption('building_basic_information'),
      ...Title,
      items: [
        {
          ...SummaryOption('basic_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'building_basic_information_left',
              items: [
                TSummaryItem('building_type', building?.type === 'other' ? building?.typeInfo : building?.type),
                SummaryItem('building_id', building?.buildingIdentifier),
                SummaryItem('commissioning_year', building?.commissioningYear),
                SummaryItem(
                  'common_spaces_count',
                  (building?.spaces || [])?.length,
                  'kpl'
                )
              ]
            },
            {
              ...SummaryColumn,
              key: 'building_basic_information_right',
              items: [
                SummaryItem('floor_count', building?.floorCount, 'kpl'),
                SummaryItem('floor_area', building?.floorArea, 'm²', true),
                SummaryItem('volume', building?.buildingVolume, 'm³', true),
                TArraySummaryItem(
                  'common_spaces',
                  flatten((building?.spaces || [])
                    ?.map((spaces: any) => spaces?.rooms || []))
                    ?.map((room: any) => room?.nameFi)
                )
              ]
            }
          ],
        },
        {
          ...SummaryOption('structures'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'structures_left',
              items: [
                TSummaryItem('primary_building_material', building?.buildingMaterial === 'other' ? building?.buildingMaterialInfo : building?.buildingMaterial),
                TSummaryItem('roof_type', building?.waterRoof?.type === 'other' ? building?.waterRoof?.typeInfo : building?.waterRoof?.type),
              ]
            },
            {
              ...SummaryColumn,
              key: 'structures_right',
              items: [
                TSummaryItem('water_roof_material_alt', building?.waterRoof?.material === 'other' ? building?.waterRoof?.materialInfo : building?.waterRoof?.material),
              ]
            }
          ],
        },
        {
          ...SummaryOption('hvac_systems'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'hvac_systems_left',
              items: [
                TSummaryItem('heating_system', building?.heatingSystem?.type),
                TSummaryItem('cooling_system', building?.coolingSystemType),
                TSummaryItem('heat_distribution_method', building?.heatingSystem?.heatDistributionType)
              ]
            },
            {
              ...SummaryColumn,
              key: 'hvac_systems_right',
              items: [
                TSummaryItem('ventilation_system', building?.ventilationSystem?.type),
                TSummaryItem('heat_recovery', building?.ventilationSystem?.hasHeatRecovery),
              ]
            }
          ],
        },
        {
          ...SummaryOption('elevators'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'elevators_left',
              items: [
                SummaryItem('count', building?.elevatorSystem?.count, 'kpl'),
              ]
            },
          ],
        },
        {
          ...SummaryOption('tv_and_communication_connections'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'tv_and_communication_connections_left',
              items: [
                TSummaryItem('antenna_system_and_type', building?.communicationAndDataNetworkSystem?.antennaSystemType === 'other' ? building?.communicationAndDataNetworkSystem?.antennaSystemTypeInfo : building?.communicationAndDataNetworkSystem?.antennaSystemType),
                TSummaryItem('cable_company', building?.cableCompany === 'other' ? building?.cableCompanyInfo : building?.cableCompany),
              ]
            },
            {
              ...SummaryColumn,
              key: 'tv_and_communication_connections_right',
              items: [
                TSummaryItem(
                  'telecommunication_system',
                  building?.communicationAndDataNetworkSystem?.telecommunicationSystemType === 'other' ?
                    building?.communicationAndDataNetworkSystem?.telecommunicationSystemTypeInfo :
                    building?.communicationAndDataNetworkSystem?.telecommunicationSystemType
                  ),
                TSummaryItem(
                  'shared_broadband',
                  building?.hasSharedBroadband ? 'yes' : 'no',
                  building?.sharedBroadbandSpeed ?
                  `(${building?.sharedBroadbandSpeed})` :
                  ''
                ),
              ]
            }
          ],
        }
      ]
    },
    {
      ...SummaryOption('building_structures', 'structures'),
      ...Title,
      items: [
        FoundationsAndPlinthLayout(building?.foundation),
        BaseFloorLayout(building?.baseFloor),
        ExteriorWallsAndFacadeLayout(building?.exteriorWall),
        IntermediateFloorsLayout(building?.intermediateFloor),
        InteriorWallsLayout(building?.interiorWall),
        UpperFloorLayout(building?.upperFloor),
        WaterRoofLayout(building?.waterRoof),
      ],
    },
    {
      ...SummaryOption('building_parts'),
      ...Title,
      items: [
        BuildingWindowsLayout({
          ...(building?.apartmentWindow || {}),
          ...(building?.commonSpaceWindow || {}),
        }),
        BuildingDoorsLayout({
          ...(building?.exteriorDoor || {}),
          ...(building?.apartmentDoor || {}),
          ...(building?.commonSpaceDoor || {}),
          ...(building?.interiorDoor || {}),
          ...(building?.balconyAndTerraceDoor || {}),
        }),
        BuildingOtherDoorsLayout(building?.otherDoor || {}),
        BuildingBalconiesLayout(building?.balcony),
        BuildingTerracesLayout(building?.terrace),
      ]
    },
    {
      ...SummaryOption('building_systems'),
      ...Title,
      items: [
        BuildingVentilationLayout(building?.ventilationSystem),
        BuildingHeatingLayout(building?.heatingSystem),
        ...BuildingStormwaterLayout({
          ...building?.drainageSystem,
          ...building?.rainwaterSystem,
        })?.items,
        ...BuildingDomesticWaterLayout({
          ...building?.propertyWaterSystem,
          ...building?.waterPipeSystem,
          ...building?.warmWaterSystem,
        })?.items,
        BuildingWasteManagementLayout(building?.wasteManagementSystem),
        BuildingElevatorLayout(building?.elevatorSystem),
        ...BuildingElectricalEngineeringLayout({
          ...building?.mainDistributionSystem,
          ...building?.connectionAndProductionSystem,
          ...building?.installationAndAuxiliarySystem,
          ...building?.connectionSystem,
          ...building?.lightingSystem,
          ...building?.groundingSystem,
          ...building?.communicationAndDataNetworkSystem,
          ...building?.fireSafetySystem,
        })?.items,
        ...BuildingSewerageLayout({
          ...building?.propertySewerageSystem,
          ...building?.sewerPipeSystem,
          ...building?.wasteWaterHeatRecoverySystem,
        })?.items,
        BuildingFireSafetyLayout(building?.fireSafetySystem, propertyType),
        BuildingSecurityMonitoringLayout(building?.securityMonitoringSystem),
        BuildingIndustrialWasteRemovalAndPurificationLayout(building?.industrialWasteRemovalAndPurificationSystem),
        BuildingEnergyRecoveryAndRecyclingLayout(building?.energyRecoveryAndRecyclingSystem),
      ]
    },
    RoomLayout(building, 'building_spaces')
  ]
  
  const buildingCategoryOrder = template?.buildingCategoryOrder || DefaultBuildingCategoryOrder
  const buildingBasicInformationOrder = template?.buildingBasicInformationOrder || DefaultBuildingBasicInformationOrder
  const buildingStructuresOrder = template?.buildingStructuresOrder || DefaultBuildingStructuresOrder
  const buildingPartsOrder = template?.buildingPartsOrder || DefaultBuildingPartsOrder
  const buildingSystemsOrder = template?.buildingSystemsOrder || DefaultBuildingSystemsOrder({ propertyType })
  const buildingSpacesOrder = template?.buildingSpacesOrder || DefaultBuildingSpacesOrder

  const finalLayout = []
  for (const category of buildingCategoryOrder) {
    let categoryLayout: any = layout.find((item: any) => (item?.parentKey || item?.key) === category)
    if (categoryLayout?.key === 'building_basic_information') {
      const categoryItems = []
      for (const item of buildingBasicInformationOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'building_structures') {
      const categoryItems = []
      for (const item of buildingStructuresOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'building_parts') {
      const categoryItems = []
      for (const item of buildingPartsOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
        if (propertyType !== 'housing' && item === 'doors') {
          const otherDoorItems = categoryLayout?.items.find((i: any) => i?.key === 'other_doors')?.items
          for (const otherDoorItem of otherDoorItems) {
            categoryItems.push(otherDoorItem)
          }
        }
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'building_systems') {
      const categoryItems = []
      for (const item of buildingSystemsOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'building_spaces') {
      const categoryItems = []
      for (const item of buildingSpacesOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    }
    finalLayout.push(categoryLayout)
  }
  return finalLayout
}

export const SummaryApartmentLayout = (apartment: any, template: any, extraData?: any) => {
  const IsHousingProperty = extraData?.propertyType === 'housing'
  const layout = [
    {
      ...SummaryOption('apartment_basic_information'),
      ...Title,
      items: [
        {
          ...SummaryOption('basic_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'apartment_basic_information_left',
              items: [
                TSummaryItem(
                  IsHousingProperty ? 'apartment_type' : 'space_type',
                  apartment?.type === 'other' ? apartment?.typeInfo : apartment?.type
                ),
                SummaryItem('area', apartment?.area, 'm²'),
                !IsHousingProperty && apartment?.ownershipType ? TSummaryItem('ownership_type', apartment?.ownershipType) : null,
                SummaryItem('room_count', apartment?.rooms?.length, 'kpl'),
                TArraySummaryItem(
                  'rooms',
                  apartment?.rooms?.map((room: any) => room?.nameFi)
                ),
              ]?.filter((item: any) => item)
            },
            {
              ...SummaryColumn,
              key: 'apartment_basic_information_right',
              items: [
                !IsHousingProperty && apartment?.floor ? SummaryItem('floor_number', apartment?.floor) : null,
                !IsHousingProperty && apartment?.wing ? SummaryItem('wing', apartment?.wing) : null,
                !IsHousingProperty && apartment?.spaceNumber ? SummaryItem('space_number', apartment?.spaceNumber) : null,
                SummaryItem('address_information', apartment?.address),
                SummaryItem('postal_code', extraData?.postalCode),
                SummaryItem('city', extraData?.city),
              ]?.filter((item: any) => item)
            }
          ],
        },
        IsHousingProperty && {
          ...SummaryOption('resident_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'resident_information_left',
              items: [
                SummaryItem('resident', apartment?.residentName),
                SummaryItem('resident_moved_in_at', apartment?.residentMovedInAt),
              ]
            },
            {
              ...SummaryColumn,
              key: 'resident_information_right',
              items: [
                SummaryItem('phone_number', apartment?.residentPhone),
                SummaryItem('email', apartment?.residentEmail),
              ]
            }
          ],
        },
        (!IsHousingProperty && apartment?.ownershipType === 'rent') && {
          ...SummaryOption('owner_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'owner_left',
              items: [
                SummaryItem('contact_person', apartment?.ownerContactPersonName),
                SummaryItem('company', apartment?.ownerCompany),
                SummaryItem('business_id', apartment?.ownerCompanyBusinessId),
                SummaryItem('phone_number', apartment?.ownerContactPersonPhone),
                SummaryItem('email', apartment?.ownerContactPersonEmail),
              ]
            },
            {
              ...SummaryColumn,
              key: 'owner_right',
              items: [
                SummaryItem('street_address', apartment?.ownerAddress),
                SummaryItem('postal_code', apartment?.ownerPostalCode),
                SummaryItem('city_or_municipality', apartment?.ownerCity),
              ]
            }
          ],
        },
        (!IsHousingProperty && apartment?.ownershipType === 'rent') && {
          ...SummaryOption('tenant_information'),
          ...Subtitle,
          items: [
            {
              ...SummaryColumn,
              key: 'tenant_left',
              items: [
                SummaryItem('contact_person', apartment?.tenantContactPersonName),
                SummaryItem('company', apartment?.tenantCompany),
                SummaryItem('business_id', apartment?.tenantCompanyBusinessId),
                SummaryItem('phone_number', apartment?.tenantContactPersonPhone),
                SummaryItem('email', apartment?.tenantContactPersonEmail),
              ]
            },
            {
              ...SummaryColumn,
              key: 'tenant_right',
              items: [
                SummaryItem('street_address', apartment?.tenantAddress),
                SummaryItem('postal_code', apartment?.tenantPostalCode),
                SummaryItem('city_or_municipality', apartment?.tenantCity),
              ]
            }
          ],
        },
      ]
    },
    RoomLayout(apartment)
  ]

  const apartmentCategoryOrder = template?.apartmentCategoryOrder || DefaultApartmentCategoryOrder
  const apartmentBasicInformationOrder = template?.apartmentBasicInformationOrder || DefaultApartmentBasicInformationOrder({
    propertyType: extraData?.propertyType
  })
  const apartmentRoomsOrder = template?.apartmentRoomsOrder || DefaultApartmentRoomsOrder

  const finalLayout = []
  for (const category of apartmentCategoryOrder) {
    let categoryLayout: any = layout.find((item: any) => (item?.key) === category)
    if (categoryLayout?.key === 'apartment_basic_information') {
      const categoryItems = []
      for (const item of apartmentBasicInformationOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    } else if (categoryLayout?.key === 'apartment_rooms') {
      const categoryItems = []
      for (const item of apartmentRoomsOrder) {
        categoryItems.push(categoryLayout?.items.find((i: any) => i?.key === item))
      }
      categoryLayout = { ...categoryLayout, items: categoryItems }
    }
    finalLayout.push(categoryLayout)
  }
  return finalLayout
}