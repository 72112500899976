import { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from './Button'
import Input from './Input'
import CheckboxMultiSelect from './CheckboxMultiSelect'
import CloseAltIcon from '../../Assets/Icons/close-alt.svg'
import { Colors } from '../../Utils/theme'

const CustomEntitySelect = (props: any) => {
  const { selectableStore }: any = useStore()
  const { manufacturers } = selectableStore
  const { type, label, disabled } = props
  const { t } = useTranslation()

  // Dialog state
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState('list')
  const [searchKey, setSearchKey] = useState('')

  const openEntityDialog = () => setOpen(true)
  const closeEntityDialog = () => {
    setOpen(false)
    setSearchKey('')
    onCancel()
  }

  const openCreateMode = () => setMode('create')
  const openEditMode = () => setMode('edit')
  const onCancel = () => {
    setMode('list')
    setName('')
    setSelectedItems([])
  }

  const clearSearchKey = () => setSearchKey('')
  const clearName = () => setName('')

  // Entity state
  const [selectedItems, setSelectedItems] = useState([])
  const [name, setName] = useState('')

  useEffect(() => {
    if (!disabled && type === 'manufacturer' && !manufacturers?.length) {
      selectableStore.getManufacturers()
    }
  }, [type])

  const SelectableItems = useMemo(() => {
    if (type === 'manufacturer') {
      if (searchKey) {
        const sKey = searchKey.toLowerCase()
        return manufacturers.filter((manufacturer: any) => manufacturer.name.toLowerCase().includes(sKey))
      }
      return manufacturers
    }
    return []
  }, [type, manufacturers, searchKey])

  const getContainerStyle = () => {
    let extraStyles: any = {}
    if (props?.sx) extraStyles = { ...extraStyles, ...props.sx }
    if (props.width) extraStyles.width = props.width
    if (props.minWidth) extraStyles.minWidth = props.minWidth
    if (props.maxWidth) extraStyles.maxWidth = props.maxWidth
    if (props.ml) extraStyles.ml = props.ml
    if (props.mr) extraStyles.mr = props.mr
    if (props.mb) extraStyles.mb = props.mb
    if (props.mt) extraStyles.mt = props.mt
    if (Object.keys(extraStyles).length) {
      return { ...styles.container, ...extraStyles }
    }
    return styles.container
  }

  const onSelectItem = (item: any) => {
    props.onChange(item.name)
    closeEntityDialog()
  }
  const onClearItem = () => {
    props.onChange('')
    closeEntityDialog()
  }
  const onUpdateSelectedItems = (item: string) => {
    if (selectedItems?.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem: string) => selectedItem !== item))
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }
  const onCreateItem = () => {
    if (type === 'manufacturer') {
      selectableStore.createManufacturer({ name }, onCancel)
    }
  }
  const onArchive = () => {
    if (type === 'manufacturer') {
      selectableStore.archiveManufacturers(selectedItems, onCancel)
    }
  }

  const renderLabel = () => {
    if (label) {
      return <Typography sx={styles.label}>{label}</Typography>
    }
    return null
  }

  const renderEntityDialogContent = () => {
    if (mode === 'list') {
      return (
        <>
          <DialogContent sx={styles.listContent}>
            <Input
              placeholder={t('search_by_name')}
              value={searchKey}
              onChange={setSearchKey}
              onAction={clearSearchKey}
              mb='1rem'
              inputSx={styles.searchInput}
              iconSx={styles.searchInputIcon}
              clearIconSx={styles.clearIcon}
              altClearIcon
              altSearch
            />
            {SelectableItems?.map((item: any) => (
              <ButtonBase
                key={item.uuid}
                onClick={() => onSelectItem(item)}
                sx={styles.selectableItem}
              >
                <Typography sx={styles.selectableItemText}>
                  {item?.name || '-'}
                </Typography>
              </ButtonBase>
            ))}
            {!SelectableItems?.length && (
              <Typography sx={styles.contentText}>
                {t(`no_${type}s_found`)}
              </Typography>
            )}
          </DialogContent>
          <DialogActions sx={styles.actions}>
            <Button
              sx={styles.iconButton}
              text={t('add_new')}
              onClick={openCreateMode}
              variant='text'
              icon='add'
              iconSx={styles.icon}
            />
            {SelectableItems?.length ? (
              <Button
                sx={styles.iconButton}
                text={t('edit')}
                onClick={openEditMode}
                variant='text'
                icon='edit'
                iconSx={styles.icon}
              />
            ) : null}
          </DialogActions>
        </>
      )
    } else if (mode === 'create') {
      return (
        <>
          <DialogContent sx={styles.content}>
            <Input
              label={`${t(`add_${type}`)}`}
              value={name}
              onChange={setName}
              onAction={clearName}
              mb='0.25rem'
              iconSx={styles.altClearIcon}
              altClearIcon
            />
          </DialogContent>
          <DialogActions sx={styles.actions}>
            <Button
              sx={styles.outlinedButton}
              text={t('cancel')}
              onClick={onCancel}
              variant='secondary'
            />
            <Button
              sx={styles.button}
              text={t('save')}
              onClick={onCreateItem}
            />
          </DialogActions>
        </>
      )
    } else if (mode === 'edit') {
      return (
        <>
          <DialogContent sx={styles.listContent}>
            <CheckboxMultiSelect
              type='list'
              options={SelectableItems?.map((item: any) => ({
                label: item?.name || '-',
                value: item?.uuid
              }))}
              value={selectedItems}
              onChange={onUpdateSelectedItems}
              bgColor={Colors.altGrayBg}
            />
          </DialogContent>
          <DialogActions sx={styles.actions}>
            <Button
              sx={styles.iconButton}
              text={t('cancel')}
              onClick={onCancel}
              variant='text'
              icon='backAlt'
              iconSx={styles.icon}
            />
            <Button
              sx={styles.iconButton}
              text={`${t('delete')} ${selectedItems.length ? `(${selectedItems.length})` : ''}`}
              onClick={onArchive}
              variant='text'
              icon='delete'
              iconSx={styles.icon}
              disabled={!selectedItems?.length}
            />
          </DialogActions>
        </>
      )
    }
    return null
  }

  const renderEntityDialog = () => {
    if (open) {
      return (
        <Dialog
          onClose={closeEntityDialog}
          sx={styles.dialog}
          disableRestoreFocus
          open
        >
          {renderEntityDialogContent()}
        </Dialog>
      )
    }
    return null
  }

  return (
    <>
      <Box sx={getContainerStyle()}>
        {renderLabel()}
        <ButtonBase
          onClick={openEntityDialog}
          sx={disabled ? styles.disabledSelectableButton : styles.selectableButton}
          disabled={disabled}
          disableRipple
        >
          <Typography sx={styles.value}>
            {props?.value || props?.placeholder || t(`select_${type}`)}
          </Typography>
        </ButtonBase>
        {!disabled && props?.value && (
          <Box
            component='img'
            src={CloseAltIcon}
            sx={styles.clearIconButton}
            onClick={onClearItem}
          />
        )}
      </Box>
      {renderEntityDialog()}
    </>
  )
}

export default observer(CustomEntitySelect)

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    position: 'relative'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    margin: 0,
    marginBottom: '0.5rem',
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 600
  },
  value: {
    fontSize: '1.125rem',
    color: Colors.text,
  },
  selectableButton: {
    height: '3.125rem',
    backgroundColor: Colors.white,
    borderRadius: '0.3125rem',
    borderColor: Colors.border,
    padding: '0rem 0.125rem',
    paddingLeft: '1.1565rem',
    border: `0.0625rem solid ${Colors.border}`,
    justifyContent: 'flex-start',
    '&::placeholder': {
      color: Colors.text
    },
    '&.Mui-focused': {
      border: `0.125rem solid ${Colors.border}`,
    },
  },
  disabledSelectableButton: {
    height: '3.125rem',
    backgroundColor: Colors.grayBg,
    borderRadius: '0.3125rem',
    borderColor: Colors.border,
    padding: '0rem 0.125rem',
    paddingLeft: '1.1565rem',
    border: `0.0625rem solid ${Colors.border}`,
    justifyContent: 'flex-start',
    '&::placeholder': {
      color: Colors.text
    },
    '&.Mui-focused': {
      border: `0.125rem solid ${Colors.border}`,
    },
  },
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '26rem',
      maxWidth: '26rem',
      borderRadius: '0.625rem',
      padding: '0rem 0rem 0rem 0rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '0rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  listContent: {
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  content: {
  },
  contentText: {
    textAlign: 'center',
    color: Colors.content,
    fontSize: '1rem',
    marginTop: '1.5rem',
    marginBottom: '0.5rem'
  },
  outlinedButton: {
    width: '10rem',
    height: '2.75rem',
    padding: '0rem 2rem',
    color: Colors.text,
    fontSize: '0.875rem',
    ml: '1rem',
    mt: '0.5rem',
    mb: '0.5rem',
    border: `0.0625rem solid ${Colors.button}`,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  button: {
    width: '10rem',
    height: '2.75rem',
    padding: '0rem 2rem',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    mr: '1rem',
    mt: '0.5rem',
    mb: '0.5rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: '100%',
    borderTop: `0.0625rem solid ${Colors.border}`,
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  iconButton: {
    height: '2.75rem',
    padding: '0rem 1.5rem',
    color: Colors.text,
    fontSize: '0.875rem',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  icon: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.625rem'
  },
  searchInput: {
    height: '2.75rem',
    fontSize: '1rem',
    paddingLeft: '1.875rem'
  },
  searchInputIcon: {
    width: '1.325rem',
    height: '1.325rem',
    marginRight: '0.625rem'
  },
  selectableItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0.375rem',
    borderRadius: '0.3125rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  selectableItemText: {
    fontSize: '1rem',
    color: Colors.text,
  },
  clearIcon: {
    width: '1.625rem',
    height: '1.625rem',
    top: '0.5rem',
    right: '0rem',
  },
  altClearIcon: {
    top: '2.625rem',
    right: '0.625rem',
    width: '1.675rem'
  },
  clearIconButton: {
    position: 'absolute',
    top: '2.575rem',
    right: '1rem',
    cursor: 'pointer',
    width: '1.875rem',
    opacity: 0.8,
    ':hover': {
      opacity: 1
    }
  }
} as const