import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '../Common/Button'
import { Colors } from '../../Utils/theme'

export default function SidebarActions(props: any) {
  const { t } = useTranslation()

  const { updatedAt, archivedAt, saveDisabled, mt } = props

  const onSave = () => props.onSave()
  const onArchive = () => props.onArchive()
  const onRestore = () => props.onRestore()

  const UpdatedAt = useMemo(() => {
    let date = updatedAt
    if (date) {
      const luxonDate = DateTime.fromISO(date)
      date = `${luxonDate.setLocale('fi').toFormat('ccc')} ${luxonDate.toFormat('dd.MM.yyyy')} ${t('at')} ${luxonDate.toFormat('HH.mm')}`
    }
    return date
  }, [updatedAt])

  return (
    <Box sx={styles.container} mt={mt ?? '8.125rem'}>
      <Box sx={styles.contentContainer}>
        <Typography variant='h5' sx={styles.title}>
          {t('actions')}
        </Typography>
        <Box sx={styles.actionInfo}>
          <Box sx={styles.stateContainer}>
            <Box sx={styles.actionItemColumn}>
              <Typography variant='body1' sx={styles.label}>
                {t('state')}:
              </Typography>
              <Typography variant='body1' color={Colors.text} mb='0.5rem'>
                {props?.statusText ? props.statusText : (!archivedAt ? t('active') : t('archived'))}
              </Typography>
            </Box>
            {!archivedAt && props?.onArchive ? (
              <Box sx={styles.actionItemColumn}>
                <Button
                  text={t('archive')}
                  onClick={onArchive}
                  variant='text'
                  sx={styles.archiveButton}
                />
              </Box>
            ) : null}
            {archivedAt && props?.onRestore ? (
              <Box sx={styles.actionItemColumn}>
                <Button
                  text={t('restore')}
                  onClick={onRestore}
                  variant='text'
                  sx={styles.archiveButton}
                />
              </Box>
            ) : null}
          </Box>
          <Typography variant='body1' sx={styles.label}>
            {t('last_updated_at')}:
          </Typography>
          <Typography variant='body1' color={Colors.text}>
            {UpdatedAt}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        <Button
          text={t('save')}
          onClick={onSave}
          width='100%'
          disabled={saveDisabled || archivedAt}
        />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: {
      lgplus: '17rem',
      xs: '14rem'
    },
    maxWidth: {
      lgplus: '17rem',
      xs: '14rem'
    },
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    // p: '2rem 2rem 0.5rem 2rem',
    mb: '1.5rem'
  },
  contentContainer: {
    p: {
      lgplus: '1.5rem 1.5rem 1.5rem 1.5rem',
      xs: '1.125rem 1.125rem 1.125rem 1.125rem'
    }
  },
  title: {
    fontWeight: 800,
    alignSelf: 'flex-start',
    mb: '1rem',
    textTransform: 'uppercase'
  },
  actionInfo: {

  },
  label: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.primary,
    mb: '0.0625rem'
  },
  footer: {
    padding: '1rem',
    backgroundColor: Colors.secondary10,
    borderTop: `1px solid ${Colors.secondary}` 
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '0.375rem'
  },
  actionItemColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  archiveButton: {
    height: {
      lgplus: '2.625rem',
      xs: '2.375rem'
    },
    fontSize: {
      lgplus: '0.925rem',
      xs: '0.825rem'
    },
    fontWeight: 600,
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    borderRadius: {
      lgplus: '0.625rem',
      xs: '0.5rem'
    },
    p: {
      lgplus: '0.5rem 1.5rem',
      xs: '0.5rem 1rem'
    },
    mb: '0.625rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  }
} as const