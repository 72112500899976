import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import HousingImage from '../../Assets/Images/asoy-lg.png'
import IndustrialImage from '../../Assets/Images/teollisuustila-lg.png'
import BusinessImage from '../../Assets/Images/liiketila-lg.png'
import { Colors } from '../../Utils/theme'

const ImageMappings = {
  housing: HousingImage,
  industrial: IndustrialImage,
  business: BusinessImage
}

export default function PropertyTypeItem(props: any) {
  const { value, label, info, selected, onSelect, mb } = props

  const getStyles = () => {
    const containerStyles = selected ? styles.selectedContainer : styles.container
    return { ...containerStyles, marginBottom: mb || '0rem' }
  }

  const onClick = () => onSelect(value)

  return (
    <ButtonBase sx={getStyles()} onClick={onClick}>
      <Box
        component='img'
        src={ImageMappings?.[value]}
        sx={styles.image}
      />
      <Box sx={styles.content}>
        <Typography variant='body2' sx={styles.type}>{label}</Typography>
        <Typography variant='body2' sx={styles.info}>{info}</Typography>
      </Box>
    </ButtonBase>
  )
}

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    border: `1px solid ${Colors.borderAlt}`,
    borderRadius: '1.125rem',
    padding: '0.875rem 1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.secondary10
    }
  },
  selectedContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: Colors.secondary10,
    border: `1px solid ${Colors.borderAlt}`,
    borderRadius: '1.125rem',
    padding: '0.875rem 1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.secondary10
    }
  },
  image: {
    height: '4.5rem',
    objectFit: 'cover',
    mr: '1.25rem',
  },
  content: {
    width: 'calc(100% - 5rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  type: {
    fontSize: '1.125rem',
    color: Colors.heading,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '0.375rem',
    textAlign: 'left'
  },
  info: {
    fontSize: '0.875rem',
    color: Colors.text,
    fontWeight: 400,
    textAlign: 'left'
  }
} as const
