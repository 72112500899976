import Divider from '@mui/material/Divider'
import { Colors } from '../../Utils/theme'

export default function SummaryDivider(props: any) {
  const { orientation } = props
  return (
    <Divider
      sx={orientation === 'horizontal' ? styles.horizontalDivider : styles.verticalDivider}
      orientation={orientation || 'vertical'}
      flexItem
    />
  )
}

const styles = {
  verticalDivider: {
    width: '0.0625rem',
    backgroundColor: Colors.button,
    mr: '2.325rem',
  },
  horizontalDivider: {
    height: '0.0625rem',
    backgroundColor: Colors.button,
  }
} as const