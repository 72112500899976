import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'
import Checkbox from './Checkbox'
import SummarySelectable from './SummarySelectable'
import { Colors } from '../../Utils/theme'

interface SummaryEntitySelectProps {
  section: string
  altSection?: string
  items: Array<{ title: string; key: string; orderKey: string; dragDisabled?: boolean, items: string[] }>
  hiddenItems: string[]
  onHiddenChange: (section: string, itemKeys: string[], isHidden: boolean) => void
  onReorder: (result: DropResult) => void
}

export default function SummaryEntitySelect(props: SummaryEntitySelectProps) {
  const { t } = useTranslation()
  const { section, altSection, items, hiddenItems } = props

  const onSectionHiddenChange = () => {
    const allItems = items.map(item => item?.items?.flat()).flat()
    const itemKeys = allItems.map((i: any) => i)?.filter((i: any) => i)
    props.onHiddenChange(section, itemKeys, !itemKeys.every((i: any) => hiddenItems.includes(i)))
  }
  const onSubsectionHiddenChange = (subsectionKey: string) => {
    const allItems = items?.find((item: any) => item?.key === subsectionKey)?.items || []
    const itemKeys = allItems.map((i: any) => i)
    props.onHiddenChange(section, itemKeys, !itemKeys.every((i: any) => hiddenItems.includes(i)))
  }
  const onItemHiddenChange = (item: any) => {
    props.onHiddenChange(section, [item], !hiddenItems.includes(item))
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    props.onReorder(result)
  }

  const renderItems = (items: string[], parentId: string) => (
    <Droppable droppableId={parentId} type='ITEM'>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={styles.itemsContainer}
        >
          {items.map((item: any, index: number) => {
            const isHidden = hiddenItems.includes(item)
            return (
              <SummarySelectable
                key={item}
                id={item}
                label={t(item)}
                selected={!isHidden}
                onChange={() => onItemHiddenChange(item)}
                index={index}
                showDragHandle={true}
              />
            )
          })}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box sx={styles.container}>
        <Checkbox
          label={section ? t(altSection || section) : ''}
          checked={items.map(item => item?.items?.flat()).flat().some((i: any) => !hiddenItems.includes(i))}
          onChange={() => onSectionHiddenChange()}
          labelSx={styles.categoryLabel}
          size='1.375rem'
          iconSize='1rem'
          mb='0.5rem'
        />
        <Typography sx={styles.infoText}>
          {t(`${altSection || section}_summary_selection_info`)}
        </Typography>
        <Divider sx={styles.divider} />
        <Droppable droppableId='SECTIONS' type={section}>
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={styles.subSectionsContainer}
            >
              {items.map((item, index) => {
                const isHidden = item?.items?.flat().every((i: any) => hiddenItems.includes(i))
                return (
                  <Draggable 
                    key={item.title} 
                    draggableId={`${section}-${item.title}-${item.orderKey}`} 
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          transform: provided.draggableProps.style?.transform,
                        }}
                        sx={[
                          styles.sectionWrapper,
                          snapshot.isDragging && styles.dragging
                        ]}
                      >
                        <SummarySelectable
                          id={item.title}
                          label={t(item.title)}
                          dragDisabled={item?.dragDisabled}
                          selected={!isHidden}
                          onChange={() => onSubsectionHiddenChange(item?.key)}
                          index={index}
                          dragHandleProps={provided.dragHandleProps}
                          title
                          showDragHandle
                        />
                        {renderItems(item.items, `${section}-${item.key}-${item.orderKey}`)}
                      </Box>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.625rem',
    border: `1px solid ${Colors.border}`,
    backgroundColor: Colors.secondary10,
    p: '1.375rem 1.375rem 0.375rem 1.375rem',
    mb: '1.5rem'
  },
  sectionWrapper: {
    marginBottom: '1.5rem',
    position: 'relative',
    height: 'auto',
  },
  dragging: {
    boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.625rem',
  },
  subSectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  itemsContainer: {
    minHeight: '2rem',
    position: 'relative',
  },
  categoryLabel: {
    fontWeight: 700,
    fontSize: '1.375rem',
    color: Colors.primary
  },
  subSectionContainer: {
    mb: '1.5rem'
  },
  infoText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    pl: '2rem',
    mb: '1.5rem',
  },
  divider: {
    height: '0.0625rem',
    width: 'calc(100% + 3rem)',
    backgroundColor: Colors.border,
    ml: '-1.5rem',
    mb: '1.5rem'
  },
} as const