import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import { showMessage } from '../Utils/message'

export default class NotificationStore {
  rootStore

  notifications: any = []
  totalNotifications: number = 10
  notification: any = null
  skip: number = 0
  take: number = 10
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setNotifications = (notifications: any) => { this.notifications = notifications }
  setTotalNotifications = (totalNotifications: number) => { this.totalNotifications = totalNotifications }
  setNotification = (notification: any) => { this.notification = notification }
  setSkip = (skip: number) => { this.skip = skip }
  setTake = (take: number) => { this.take = take }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setNotifications([])
    this.setTotalNotifications(0)
    this.setNotification(null)
    this.setLoading(false)
  }

  async getNotifications() {
    this.setLoading(true)
    try {
      const response: any = await Api.getNotifications({
        skip: this.skip,
        take: this.take
      })
      if (response?.ok) {
        let notifications = response.data?.items || []
        notifications = notifications
          ?.filter((notification: any) => {
            return !notification?.file &&
              notification?.target !== 'file' &&
              !(notification?.target === 'equipment' && notification?.action === 'update')
          })
        this.setNotifications(notifications)
        const totalNotifications = response.data?.total || 0
        this.setTotalNotifications(totalNotifications)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
