import { useState, MouseEvent } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Backdrop from '@mui/material/Backdrop'
import ProjectsIcon from '../../Assets/Icons/projects.svg'
import CostsIcon from '../../Assets/Icons/costs.svg'
import SummaryIcon from '../../Assets/Icons/summary.svg'
import MenuIcon from '../../Assets/Icons/menu-primary.svg'
import { Colors } from '../../Utils/theme'

export default function Tabs(props: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const onMenuClose = () => setAnchorEl(null)

  const renderArchiveFolderButton = () => {
    if (props?.onArchiveClick) {
      return (
        <ButtonBase onClick={props.onArchiveClick} sx={styles.iconButton}>
          <Box sx={styles.icon} component='img' src={ProjectsIcon} alt='projects' />
        </ButtonBase>
      )
    }
    return null
  }

  const renderCostsButton = () => {
    if (props?.onCostsClick) {
      return (
        <ButtonBase onClick={props.onCostsClick} sx={styles.iconButton}>
          <Box sx={styles.icon} component='img' src={CostsIcon} alt='costs' />
        </ButtonBase>
      )
    }
    return null
  }

  const renderSummaryButton = () => {
    if (props?.onSummaryClick) {
      return (
        <ButtonBase onClick={props.onSummaryClick} sx={styles.iconButton}>
          <Box sx={styles.icon} component='img' src={SummaryIcon} alt='summary' />
        </ButtonBase>
      )
    }
    return null
  }

  const renderMenuItems = () => {
    return props.menuOptions.map((option: any, index: number) => {
      return (
        <MenuItem key={index} onClick={option.onClick}>
          {option.text || '-'}
        </MenuItem>
      )
    })
  }

  const renderMenuButton = () => {
    if (props?.menuOptions) {
      return (
        <>
          <ButtonBase onClick={onMenuClick} sx={styles.iconButton}>
            <Box sx={styles.icon} component='img' src={MenuIcon} alt='menu' />
          </ButtonBase>
          <Backdrop sx={styles.backdrop} open={isMenuOpen} onClick={onMenuClose}>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={onMenuClose}
              // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {renderMenuItems()}
            </Menu>
          </Backdrop>
        </>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container} mt={props?.mt || 0} mb={props?.mb || 0}>
      <Box sx={styles.tabs}>{props.children}</Box>
      <Box sx={styles.actionsRow}>
        {renderArchiveFolderButton()}
        {renderCostsButton()}
        {renderSummaryButton()}
        {renderMenuButton()}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    height: {
      xl: '3.5rem',
      xs: '3.25rem',
    },
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.secondary10,
    color: Colors.black,
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.625rem'
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    flex: 1,
    minWidth: 0,
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0
  },
  editButton: {
    height: {
      xl: '3.5rem',
      xs: '3.25rem',
    },
    borderLeft: `1px solid ${Colors.border}`,
    padding: '0 1.5rem',
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.primary,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
  editIcon: {
    maxWidth: '1.5rem',
    marginRight: '0.5rem'
  },
  backdrop: {
    backgroundColor: Colors.backdrop,
    zIndex: 1000
  },
  iconButton: {
    width: '3.5rem',
    height: {
      xl: '3.5rem',
      xs: '3.25rem',
    },
    borderLeft: `1px solid ${Colors.border}`,
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
  icon: {
    maxWidth: '1.375rem'
  },
  lastIconButton: {
    width: '3.5rem',
    height: {
      xl: '3.5rem',
      xs: '3.25rem',
    },
    borderTopRightRadius: '0.625rem',
    borderBottomRightRadius: '0.625rem',
    borderLeft: `1px solid ${Colors.border}`,
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
} as const