import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import flatten from 'lodash/flatten'
import { GridRenderCellParams } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import SimpleFileUpload from '../../../Components/Common/SimpleFileUpload'
import SimpleFile from '../../../Components/Common/SimpleFile'
import BuildingStructures from './BuildingStructures'
import BuildingParts from './BuildingParts'
import BuildingSystems from './BuildingSystems'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import Chip from '../../../Components/Common/Chip'
import Tabs from '../../../Components/Common/Tabs'
import Tab from '../../../Components/Common/Tab'
import TableActions from '../../../Components/Common/TableActions'
import Table from '../../../Components/Common/Table'
import IconButton from '../../../Components/Common/IconButton'
import CreateSpaceModal from '../../../Components/Common/CreateSpaceModal'
import Lightbox from '../../../Components/Common/Lightbox'
import SidebarActions from '../../../Components/Common/SidebarActions'
import SidebarReminders from '../../../Components/Common/SidebarReminders'
import ReminderModal from '../../../Components/Common/ReminderModal'
import NavigationGuard from '../../../Components/Common/NavigationGuard'
import CustomEntitySelect from '../../../Components/Common/CustomEntitySelect'
import { Button, Input, Select, CoverImage } from '../../../Components'
import {
  BuildingTypeOptions,
  AntennaSystemOptions,
  CommunicationSystemOptions,
  BuildingCoolingSystemTypes,
  BuildingHeatDistributionTypes,
  BuildingHeatingSystems,
  BuildingMaterials,
  BuildingTypes,
  BuildingVentilationSystems,
  BuildingWaterRoofMaterials,
  BuildingWaterRoofTypes,
  CableCompanyOptions,
  InternetSpeedOptions,
  BuildingPartsOptions,
  BuildingStructuresOptions,
  BuildingSystemsOptions
} from '../../../Utils/building'
import { RoomSectionOptions } from '../../../Utils/room'
import { BuildingTabs, UserRoles } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'
import { YearOptions } from '../../../Utils/layout'

const Building = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const [searchParams, setSearchParams]: any = useSearchParams()
  const [showHasUnsavedChanges, setShowHasUnsavedChanges] = useState<BuildingTabs | null>(null)

  const { 
    sessionStore,
    userStore,
    propertyStore,
    buildingStore,
    spaceStore,
    reminderStore,
    archiveStore,
  }: any = useStore()
  const { isAdmin } = sessionStore
  const { entities } = propertyStore
  const { building, hasUnsavedChanges } = buildingStore
  const { loading } = spaceStore

  const setUnsavedChanges = () => hasUnsavedChanges ?
    undefined :
    buildingStore.setHasUnsavedChanges(true)

  const saveBuildingDataRef = useRef<any>(null)

  // General
  const [tab, setTab] = useState(BuildingTabs.BasicInformation)
  const [selectedImage, setSelectedImage] = useState(null)
  const [remindersOpen, setRemindersOpen] = useState(true)
  const [reminder, setReminder] = useState<any>(null)

  const updateTab = (to: BuildingTabs | null) => {
    if (hasUnsavedChanges) {
      setShowHasUnsavedChanges(to)
    } else {
      setTab(to as BuildingTabs)
    }
  }

  const hideShowHasUnsavedChanges = (to?: BuildingTabs | null) => {
    if (to) {
      buildingStore.setHasUnsavedChanges(false)
      setShowHasUnsavedChanges(null)
      setTab(to)
    } else {
      setShowHasUnsavedChanges(null)
    }
  }

  const openRemindersTab = () => setTab(BuildingTabs.Reminders)
  const toggleOpenReminders = () => setRemindersOpen(!remindersOpen)
  const openReminder = (reminder: any) => {
    setReminder(reminder)
    propertyStore.getPropertyEntities(params.pUuid, { types: ['buildings', 'spaces'] })
    userStore.getUsers()
  }
  const closeReminder = () => setReminder(null)

  // Building basic information (editable)
  const [nameFi, setNameFi] = useState('')
  const [type, setType] = useState('')
  const [typeInfo, setTypeInfo] = useState('')
  const [buildingIdentifier, setBuildingIdentifier] = useState('')
  const [commissioningYear, setCommissioningYear] = useState('')
  const [floorCount, setFloorCount] = useState('')
  const [floorArea, setFloorArea] = useState('')
  const [buildingVolume, setBuildingVolume] = useState('')

  // Building structures (aggregate, non-editable data)
  const [roofType, setRoofType] = useState('')
  const [roofTypeNotes, setRoofTypeNotes] = useState('')
  const [roofMaterial, setRoofMaterial] = useState('')
  const [roofMaterialNotes, setRoofMaterialNotes] = useState('')
  // Editable building structure data
  const [buildingMaterial, setBuildingMaterial] = useState('')
  const [buildingMaterialInfo, setBuildingMaterialInfo] = useState('')

  // HVAC systems (aggregate, non-editable data)
  const [heatingSystem, setHeatingSystem] = useState('')
  const [heatDistribution, setHeatDistribution] = useState('')
  const [ventilationSystem, setVentilationSystem] = useState('')
  // Editable HVAC system data
  const [hasCoolingSystem, setHasCoolingSystem] = useState('')
  const [coolingSystemType, setCoolingSystemType] = useState('')
  const [hasHeatRecovery, setHasHeatRecovery] = useState('')

  // Elevators (aggregate, non-editable data)
  const [elevatorCount, setElevatorCount] = useState('')

  // TV & internet (aggregate, non-editable data)
  const [antennaSystem, setAntennaSystem] = useState('')
  const [antennaSystemInfo, setAntennaSystemInfo] = useState('')
  const [communicationSystem, setCommunicationSystem] = useState('')
  const [communicationSystemInfo, setCommunicationSystemInfo] = useState('')

  // TV & internet (editable)
  const [cableCompany, setCableCompany] = useState('')
  const [cableCompanyInfo, setCableCompanyInfo] = useState('')
  const [hasSharedBroadband, setHasSharedBroadband] = useState('')
  const [sharedBroadbandSpeed, setSharedBroadbandSpeed] = useState('')

  // Equipment
  const [equipment, setEquipment] = useState<any>([])

  // Action confirmation
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')
  const [actionTarget, setActionTarget] = useState<any>(null)

  // Filters
  const [reminderFilter, setReminderFilter] = useState('upcoming')

  const openCreateSpace = () => {
    setActionType('create_space')
  }
  const closeCreateSpace = () => {
    setActionType('')
  }

  const openCreateEntityDialog = (entity: string) => {
    if (entity === 'reminder') {
      setActionType('create_reminder')
      propertyStore.getPropertyEntities(params.pUuid, { types: ['buildings', 'spaces'] })
      userStore.getUsers()
    }
  }
  const toCreateReminder = () => openCreateEntityDialog('reminder')
  const closeEntityDialog = () => setActionType('')
  const openArchiveSpaceConfirmation = (space: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_space')
    setActionTarget(space)
    spaceStore.setSpace(space)
  }
  const openArchiveBuildingConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive_building')
  }
  const openArchiveReminderConfirmation = (reminder: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_reminder')
    setActionTarget(reminder)
  }
  const openArchiveEquipmentConfirmation = (uuid: string) => {
    setShowActionConfirmation(true)
    setActionType('archive_equipment')
    setActionTarget({ uuid })
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
    setActionTarget(null)
  }
  
  const openBuildingBackgroundPhoto = () => setSelectedImage(building?.backgroundPhoto)
  const clearSelectedImage = () => setSelectedImage(null)

  const addNewEquipment = () => {
    setEquipment([
      ...equipment,
      {
        uuid: uuidv4(),
        nameFi: '',
        manufacturer: '',
        model: '',
        installationYear: '',
        count: '',
        location: '',
        additionalInfo: ''
      }
    ])
  }

  const updateEquipment = (uuid: string, field: string, value: string) => {
    const updatedEquipment = equipment.map((item: any) => {
      if (item.uuid === uuid) {
        return { ...item, [field]: value }
      }
      return item
    })
    setEquipment(updatedEquipment)
  }

  const toEquipmentFolder = (eUuid: string) => {
    navigate(`/archive/properties/${params?.pUuid}/buildings/${params?.bUuid}/equipment/${eUuid}`)
  }

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const bUuid = params?.bUuid || null
    if (pUuid && bUuid && pUuid?.length === 36 && bUuid?.length === 36) {
      // buildingStore.getBuilding(pUuid, bUuid)
      if (isAdmin) {
        reminderStore.getPropertyReminders(params?.pUuid, { buildingUuid: bUuid })
      }
    }
    return () => {
      if (archiveStore?.pendingItems?.length) {
        for (const item of archiveStore.pendingItems) {
          if (item?.previewUrl) {
            // Release the preview URLs to prevent memory leaks
            URL.revokeObjectURL(item.previewUrl)
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const bUuid = params?.bUuid || null
    if (pUuid && bUuid && pUuid?.length === 36 && bUuid?.length === 36) {
      if (tab  === BuildingTabs.BasicInformation) {
        setSearchParams({}, { replace: true })
        buildingStore.getBuilding(pUuid, bUuid)
      } else if (tab === BuildingTabs.BuildingStructures) {
        if (searchParams?.tab !== 'structures') {
          setSearchParams({ tab: 'structures' }, { replace: true })
        }
        buildingStore.getBuildingStructures(pUuid, bUuid)
      } else if (tab === BuildingTabs.BuildingParts) {
        if (searchParams?.tab !== 'parts') {
          setSearchParams({ tab: 'parts' }, { replace: true })
        }
        buildingStore.getBuildingParts(pUuid, bUuid)
      } else if (tab === BuildingTabs.BuildingSystems) {
        if (searchParams?.tab !== 'systems') {
          setSearchParams({ tab: 'systems' }, { replace: true })
        }
        buildingStore.getBuildingSystems(pUuid, bUuid)
      } else if (tab === BuildingTabs.BuildingSpaces) {
        if (searchParams?.tab !== BuildingTabs.BuildingSpaces) {
          setSearchParams({ tab: BuildingTabs.BuildingSpaces }, { replace: true })
        }
        spaceStore.getSpaces(pUuid, bUuid)
      } else if (tab === BuildingTabs.BuildingEquipment) {
        if (searchParams?.tab !== BuildingTabs.BuildingEquipment) {
          setSearchParams({ tab: BuildingTabs.BuildingEquipment }, { replace: true })
        }
        buildingStore.getBuildingEquipment(pUuid, bUuid)
      } else if (tab === BuildingTabs.Reminders) {
        if (searchParams?.tab !== BuildingTabs.Reminders) {
          setSearchParams({ tab: BuildingTabs.Reminders }, { replace: true })
        }
        reminderStore.getPropertyReminders(params?.pUuid, { buildingUuid: bUuid })
      }
    }
  }, [tab])

  useEffect(() => {
    const bUuid = params?.bUuid || null
    if (bUuid && bUuid?.length === 36) {
      if (building && building?.uuid === bUuid) {
        setNameFi(building?.nameFi)
        setType(building?.type)
        setTypeInfo(building?.typeInfo)
        setBuildingIdentifier(building?.buildingIdentifier)
        setCommissioningYear(building?.commissioningYear)
        setFloorCount(building?.floorCount || '')
        setFloorArea(building?.floorArea || '')
        setBuildingVolume(building?.buildingVolume || '')
        setBuildingMaterial(building?.buildingMaterial || '')
        setBuildingMaterialInfo(building?.buildingMaterialInfo || '')
        setRoofType(building?.waterRoofType || '')
        setRoofTypeNotes(building?.waterRoofTypeNotes || '')
        setRoofMaterial(building?.waterRoofMaterial || '')
        setRoofMaterialNotes(building?.waterRoofMaterialNotes || '')
        setHeatingSystem(building?.heatingSystemType || '')
        setHasCoolingSystem(building?.hasCoolingSystem || false)
        setCoolingSystemType(building?.coolingSystemType || '')
        setHasHeatRecovery(building?.ventilationSystemHasHeatRecovery || false)
        setHeatDistribution(building?.heatDistributionType || '')
        setVentilationSystem(building?.ventilationSystemType || '')
        setElevatorCount(`${building?.elevatorCount}` || '')
        setAntennaSystem(building?.antennaSystemType || '')
        setAntennaSystemInfo(building?.antennaSystemTypeNotes || '')
        setCommunicationSystem(building?.telecommunicationSystemType || '')
        setCommunicationSystemInfo(building?.telecommunicationSystemTypeNotes || '')
        setCableCompany(building?.cableCompany || '')
        setCableCompanyInfo(building?.cableCompanyInfo || '')
        setHasSharedBroadband(building?.hasSharedBroadband || false)
        setSharedBroadbandSpeed(building?.sharedBroadbandSpeed || '')
        setEquipment(building?.equipment || [])
      }
    }
  }, [building])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === 'structures') {
        setTab(BuildingTabs.BuildingStructures)
      } else if (selectedTab === 'parts') {
        setTab(BuildingTabs.BuildingParts)
      } else if (selectedTab === 'systems') {
        setTab(BuildingTabs.BuildingSystems)
      } else if (selectedTab === 'spaces') {
        setTab(BuildingTabs.BuildingSpaces)
      } else if (selectedTab === 'equipment') {
        setTab(BuildingTabs.BuildingEquipment)
      } else if (selectedTab === 'reminders') {
        setTab(BuildingTabs.Reminders)
      }
    }
  }, [location])

  useEffect(() => {
    if (reminderFilter !== 'upcoming') {
      reminderStore.getPropertyReminders(
        params?.pUuid,
        { all: true, buildingUuid: params?.bUuid }
      )
    }
  }, [reminderFilter])

  const BuildingSectionOptions = useMemo(() => {
    const structures = BuildingStructuresOptions.map((item: any) => ({
      value: item.value,
      label: `${t('structures')} - ${t(item?.label || item?.value)}`
    }))
    const parts = BuildingPartsOptions.map((item: any) => ({
      value: item.value,
      label: `${t('building_parts')} - ${t(item?.label || item?.value)}`
    }))
    const systems = BuildingSystemsOptions.map((item: any) => ({
      value: item.value,
      label: `${t('systems')} - ${t(item?.label || item?.value)}`
    }))
    return [...structures, ...parts, ...systems]
  }, [])

  const TypeOptions = BuildingTypeOptions.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingMaterialOptions = BuildingMaterials.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingRoofTypeOptions = Object.values(BuildingWaterRoofTypes).map((item: any) => ({ value: item, label: t(item) }))
  const BuildingRoofMaterialOptions = Object.values(BuildingWaterRoofMaterials).map((item: any) => ({ value: item, label: t(item) }))
  const BuildingHeatingSystemOptions = BuildingHeatingSystems.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingCoolingSystemTypeOptions = BuildingCoolingSystemTypes.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const HeatDistributionTypes = BuildingHeatDistributionTypes.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingVentilationSystemOptions = BuildingVentilationSystems.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingElevatorCountOptions = Array.from({ length: 10 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }))
  const BuildingAntennaSystemOptions = AntennaSystemOptions.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingCableCompanyOptions = CableCompanyOptions.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingCommunicationSystemsOptions = CommunicationSystemOptions.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const BuildingSharedBroadbandSpeedOptions = InternetSpeedOptions.map((item: any) => ({ value: item?.value, label: t(item?.label) }))

  const ReminderFilterOptions = [
    { value: 'upcoming', label: t('only_upcoming') },
    { value: 'past', label: t('only_past') },
    { value: 'archived', label: t('only_archived') },
    { value: 'all', label: t('all') }
  ]

  const AdminUserOptions = useMemo(() => {
    if (userStore?.users?.length) {
      return userStore?.users
        ?.filter((user: any) => user?.role === UserRoles.Admin)
        ?.map((user: any) => {
        return {
          value: user.uuid,
          label: `${user?.firstName} ${user?.lastName}`,
          email: user?.email || '',
          phone: user?.phone || ''
        }
      })
    }
    return []
  }, [userStore.users])

  const getReminderTargetEntityOptions = () => {
    return [{ value: 'buildings', label: t('buildings') }]
  }

  const getReminderTargetSubEntityOptions = (entity: string) => {
    if (entity === 'buildings') {
      return flatten(
        entities?.buildings
          ?.filter((b: any) => b?.uuid === building.uuid)
          ?.map((building: any) => {
            return [
              { value: building.uuid, label: building.nameFi },
              ...building?.spaces?.map((space: any) => {
                return {
                  value: space.uuid,
                  parentValue: building.uuid,
                  label: `${building.nameFi} - ${space.nameFi}`
                }
              })
            ]
          }
        )
      )
    }
    return []
  }

  const getReminderTargetOptions = (targetEntity: string, targetSubEntity?: string) => {
    if (targetEntity === 'buildings') {
      const subEntity: any = getReminderTargetSubEntityOptions(targetEntity)
        ?.find((item: any) => item.value === targetSubEntity)
      if (subEntity?.parentValue) {
        return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
      } else {
        const structures = BuildingStructuresOptions.map((item: any) => ({
          value: item.value,
          label: `${t('structures')} - ${t(item?.label || item?.value)}`
        }))
        const parts = BuildingPartsOptions.map((item: any) => ({
          value: item.value,
          label: `${t('building_parts')} - ${t(item?.label || item?.value)}`
        }))
        const systems = BuildingSystemsOptions.map((item: any) => ({
          value: item.value,
          label: `${t('systems')} - ${t(item?.label || item?.value)}`
        }))
        return [...structures, ...parts, ...systems]
      }
    }
    return []
  }

  const toSpace = (space: any) => navigate(`${location.pathname}/spaces/${space?.uuid}`)
  const toArchive = () => navigate(`/archive/properties/${params?.pUuid}/buildings/${params?.bUuid}`)

  const SpaceColumns = isAdmin ? [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toSpace(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'area',
      headerName: t('area'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params.row?.area ? `${params.row.area} m²` : '-'
      }
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt2'
            onClick={() => openArchiveSpaceConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ] : [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toSpace(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ]

  const AdminReminderColumns = useMemo(() => [
    {
      field: 'actionTitle',
      headerName: t('title'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.actionTitle)}
          onClick={() => openReminder(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'target',
      headerName: t('property'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        const isBuilding = params?.row?.targetSubEntity === 'building'
        let label = isBuilding ?
          BuildingSectionOptions?.find((item: any) => item.value === params?.row?.target)?.label :
          params?.row?.target
        if (label?.includes(' - ')) {
          label = label.split(' - ')[1]
        }
        return <Chip text={label ? t(label) : '-'} />
      }
    },
    {
      field: 'actionDate',
      headerName: t('action_date'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt2'
            onClick={() => openArchiveReminderConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ], [isAdmin])

  const ReminderColumns = useMemo(() => {
    return AdminReminderColumns.slice(0, 3)
  }, [isAdmin])

  const ReminderRows = useMemo(() => {
    const now = DateTime.now()
    if (reminderFilter === 'upcoming' || !reminderFilter) {
      // Filter out archived or past reminders
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        (DateTime.fromISO(reminder?.actionDate) > now ||
        reminder?.scheduledReminders?.some((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) > now
        }))
      })
    } else if (reminderFilter === 'past') {
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        DateTime.fromISO(reminder?.actionDate) < now &&
        reminder?.scheduledReminders?.every((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) < now
        })
      })
    } else if (reminderFilter === 'archived') {
      return reminderStore?.reminders?.filter((reminder: any) => reminder?.archivedAt)
    }
    return reminderStore?.reminders
  }, [reminderStore?.reminders, reminderFilter])

  const UpcomingReminderCount = useMemo(() => {
    return reminderStore?.reminders?.filter((reminder: any) => {
      return !reminder?.archivedAt &&
      DateTime.fromISO(reminder?.actionDate) > DateTime.now() &&
      reminder?.scheduledReminders?.some((item: any) => {
        return DateTime.fromISO(item?.scheduledAt) > DateTime.now()
      })
    })?.length
  }, [reminderStore?.reminders])

  const isSaveDisabled = () => {
    if (!nameFi) return true
    return false
  }

  const save = () => {
    if ([
        BuildingTabs.BasicInformation,
        BuildingTabs.BuildingSpaces,
        BuildingTabs.Reminders
      ].includes(tab)
    ) {
      const updatedBuilding = {
        nameFi,
        type,
        typeInfo: type === BuildingTypes.Other ? typeInfo : null,
        buildingIdentifier,
        commissioningYear,
        floorCount,
        floorArea,
        buildingVolume,
        buildingMaterial,
        buildingMaterialInfo,
        hasCoolingSystem,
        coolingSystemType,
        cableCompany,
        cableCompanyInfo,
        hasSharedBroadband,
        sharedBroadbandSpeed
      }
      buildingStore.updateBuilding(params?.pUuid, updatedBuilding)
    } else if (tab === BuildingTabs.BuildingEquipment) {
      if (hasUnsavedChanges) {
        buildingStore.createOrUpdateBuildingEquipment(
          params?.pUuid,
          params?.bUuid,
          equipment
        )
      }
    } else {
      saveBuildingDataRef.current.onSave()
    }
    buildingStore.setHasUnsavedChanges(false)
  }

  const uploadEquipmentFiles = (uuid: string, files: any) => {
    archiveStore.setPendingItems([
      ...archiveStore.pendingItems,
      ...files.map((file: any) => ({
        uuid,
        previewUrl: URL.createObjectURL(file),
        file
      }))
    ])
    if (!hasUnsavedChanges) {
      buildingStore.setHasUnsavedChanges(true)
    }
  }

  const openEquipmentFile = (uuid: string, file: any) => setSelectedImage(file?.url)

  /*
  const archiveEquipmentFile = (uuid: string, fileUuid: string) => {
    buildingStore.archiveBuildingEquipmentFile(
      params?.pUuid,
      params?.bUuid,
      uuid,
      fileUuid
    )
  }
  */

  const createSpace = (space: any) => {
    spaceStore.createSpace(
      params?.pUuid,
      params?.bUuid,
      space,
      () => closeCreateSpace()
    )
  }

  const archiveBuilding = () => {
    buildingStore.archiveBuilding(
      params?.pUuid,
      () => {
        closeActionConfirmation()
        navigate(`/properties/${params?.pUuid}?tab=buildings`, { replace: true })
      }
    )
  }
  const archiveSpace = () => {
    spaceStore.archiveSpace(
      params?.pUuid,
      params?.bUuid,
      () => {
        closeActionConfirmation()
        spaceStore.setSpace(null)
      }
    )
  }

  const createReminder = (newReminder: any) => {
    reminderStore.createPropertyReminder(
      params?.pUuid,
      newReminder,
      () => closeEntityDialog()
    )
  }
  const updateReminder = (updatedReminder: any) => {
    reminderStore.updatePropertyReminder(
      params?.pUuid,
      reminder?.uuid,
      updatedReminder,
      () => closeReminder()
    )
  }
  const archiveReminder = () => {
    reminderStore.archivePropertyReminder(
      params?.pUuid,
      actionTarget?.uuid,
      () => closeActionConfirmation()
    )
  }

  const archiveEquipment = () => {
    const uuid = actionTarget?.uuid
    if (uuid) {
      if (building?.equipment?.find((item: any) => item.uuid === uuid)) {
        buildingStore.archiveBuildingEquipment(
          params?.pUuid,
          params?.bUuid,
          uuid,
          () => closeActionConfirmation()
        )
      } else {
        const updatedEquipment = equipment.filter((item: any) => item.uuid !== uuid)
        setEquipment(updatedEquipment)
        closeActionConfirmation()
      }
    } else {
      closeActionConfirmation()
    }
  }

  const renderTabContent = () => {
    if (tab === BuildingTabs.BasicInformation) {
      return (
        <>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('basic_information')}
            </Typography>
            <Input
              label={t('building_name_or_identifier')}
              value={nameFi}
              onChange={setNameFi}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Select
              label={t('building_type')}
              placeholder={isAdmin ? t('select') : null}
              options={TypeOptions}
              value={type}
              onChange={setType}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            {type === BuildingTypes.Other && (
              <Input
                placeholder={t('enter_more_specific_description')}
                value={typeInfo}
                onChange={setTypeInfo}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            )}
            <Input
              label={t('building_id')}
              value={buildingIdentifier}
              onChange={setBuildingIdentifier}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Select
              label={t('commissioning_year')}
              placeholder={isAdmin ? t('select') : null}
              options={YearOptions()}
              value={commissioningYear}
              onChange={setCommissioningYear}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('floor_count')}
                value={floorCount}
                onChange={setFloorCount}
                mr='2rem'
                unit={t('pcs')}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('floor_area')}
                value={floorArea}
                onChange={setFloorArea}
                mr='2rem'
                unit='m²'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('volume')}
                value={buildingVolume}
                onChange={setBuildingVolume}
                unit='m³'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('structures')}
            </Typography>
            <Select
              label={t('primary_building_material')}
              placeholder={isAdmin ? t('select') : null}
              options={BuildingMaterialOptions}
              value={buildingMaterial}
              onChange={setBuildingMaterial}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            {buildingMaterial === 'other' && (
              <Input
                placeholder={isAdmin ? t('enter_more_specific_description') : null}
                value={buildingMaterialInfo}
                onChange={setBuildingMaterialInfo}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            )}
            <Select
              label={t('roof_type')}
              options={BuildingRoofTypeOptions}
              value={roofType}
              onChange={setRoofType}
              mb='1.25rem'
              disabled
            />
            {roofType === 'other' && roofTypeNotes && (
              <Input
                value={roofTypeNotes}
                onChange={setRoofTypeNotes}
                mb='1.25rem'
                disabled
              />
            )}
            <Select
              label={t('water_roof_material_alt')}
              options={BuildingRoofMaterialOptions}
              value={roofMaterial}
              onChange={setRoofMaterial}
              mb='1.25rem'
              disabled
            />
            {roofMaterial === 'other' && roofMaterialNotes && (
              <Input
                value={roofMaterialNotes}
                onChange={setRoofMaterialNotes}
                mb='1.25rem'
                disabled
              />
            )}
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('hvac_systems')}
            </Typography>
            <Select
              label={t('heating_system')}
              options={BuildingHeatingSystemOptions}
              value={heatingSystem}
              onChange={setHeatingSystem}
              mb='1.25rem'
              disabled
            />
            <Select
              label={t('cooling_system')}
              placeholder={isAdmin ? t('select') : null}
              options={[{ value: 'yes', label: t('yes') }, { value: 'no', label: t('no') }]}
              value={hasCoolingSystem}
              onChange={setHasCoolingSystem}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            {hasCoolingSystem === 'yes' && (
              <Select
                placeholder={isAdmin ? t('select_terminal_device') : null}
                options={BuildingCoolingSystemTypeOptions}
                value={coolingSystemType}
                onChange={setCoolingSystemType}
                mb='1.25rem'
                disabled={!isAdmin}
              />
            )}
            <Select
              label={t('heat_distribution_method')}
              options={HeatDistributionTypes}
              value={heatDistribution}
              onChange={setHeatDistribution}
              mb='1.25rem'
              disabled
            />
            <Select
              label={t('ventilation_system')}
              options={BuildingVentilationSystemOptions}
              value={ventilationSystem}
              onChange={setVentilationSystem}
              mb='1.25rem'
              disabled
            />
            <Select
              label={t('heat_recovery')}
              options={[{ value: 'yes', label: t('yes') }, { value: 'no', label: t('no') }]}
              value={hasHeatRecovery}
              onChange={setHasHeatRecovery}
              mb='1.25rem'
              disabled
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('elevators')}
            </Typography>
            <Select
              label={t('count')}
              options={BuildingElevatorCountOptions}
              value={`${elevatorCount}`}
              onChange={setElevatorCount}
              mb='1.25rem'
              disabled
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('tv_and_communication_connections')}
            </Typography>
            <Select
              label={t('antenna_system_and_type')}
              options={BuildingAntennaSystemOptions}
              value={antennaSystem}
              onChange={setAntennaSystem}
              mb='1.25rem'
              disabled
            />
            {antennaSystem === 'other' && (
              <Input
                placeholder={isAdmin ? t('enter_more_specific_description') : null}
                value={antennaSystemInfo}
                onChange={setAntennaSystemInfo}
                mb='1.25rem'
                disabled
              />
            )}
            <Select
              label={t('cable_company')}
              placeholder={isAdmin ? t('select') : null}
              options={BuildingCableCompanyOptions}
              value={cableCompany}
              onChange={setCableCompany}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            {cableCompany === 'other' && (
              <Input
                placeholder={isAdmin ? t('enter_more_specific_description') : null}
                value={cableCompanyInfo}
                onChange={setCableCompanyInfo}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            )}
            <Select
              label={t('telecommunication_system')}
              options={BuildingCommunicationSystemsOptions}
              value={communicationSystem}
              onChange={setCommunicationSystem}
              mb='1.25rem'
              disabled
            />
            {communicationSystem === 'other' && (
              <Input
                value={communicationSystemInfo}
                onChange={setCommunicationSystemInfo}
                mb='1.25rem'
                disabled
              />
            )}
            <Select
              label={t('shared_broadband')}
              placeholder={isAdmin ? t('select') : null}
              options={[{ value: 'yes', label: t('yes') }, { value: 'no', label: t('no') }]}
              value={hasSharedBroadband}
              onChange={setHasSharedBroadband}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            {hasSharedBroadband === 'yes' && (
              <Select
                placeholder={isAdmin ? t('select') : null}
                options={BuildingSharedBroadbandSpeedOptions}
                value={sharedBroadbandSpeed}
                onChange={setSharedBroadbandSpeed}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            )}
          </Box>
        </>
      )
    } else if (tab === BuildingTabs.BuildingStructures) {
      return <BuildingStructures uuid={params?.pUuid} saveRef={saveBuildingDataRef} />
    } else if (tab === BuildingTabs.BuildingParts) {
      return <BuildingParts uuid={params?.pUuid} saveRef={saveBuildingDataRef} />
    } else if (tab === BuildingTabs.BuildingSystems) {
      return <BuildingSystems uuid={params?.pUuid} saveRef={saveBuildingDataRef} />
    } else if (tab === BuildingTabs.BuildingSpaces) {
      return (
        <>
          <TableActions
            onAddClick={isAdmin ? openCreateSpace : undefined}
          />
          <Table
            rows={spaceStore.spaces}
            columns={SpaceColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === BuildingTabs.BuildingEquipment) {
      if (equipment?.length) {
        return equipment?.map((equipment: any, index: number) => (
          <Box key={equipment?.uuid} sx={styles.formContainer}>
            <Box sx={styles.formHeaderRow}>
              <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                {
                  building?.property?.type === 'industrial' ?
                  t('single_equipment') :
                  t('single_equipment_alt')
                } {index + 1}
              </Typography>
              <Box sx={styles.formHeaderActions}>
                {isAdmin && index === 0 ? (
                  <Button
                    sx={styles.addItemButton}
                    text={t('add_new')}
                    onClick={addNewEquipment}
                    variant='secondary'
                    icon='add'
                  />) : null}
                {isAdmin ? (
                  <Button
                    sx={styles.archiveItemButton}
                    text={t('delete')}
                    onClick={() => openArchiveEquipmentConfirmation(equipment?.uuid)}
                    variant='text'
                    icon='delete'
                  />
                ) : null}
              </Box>
            </Box>
            <Input
              label={
                building?.property?.type === 'industrial' ?
                t('equipment_name') :
                t('equipment_name_alt')
              }
              value={equipment?.nameFi || ''}
              onChange={(value: string) => updateEquipment(equipment.uuid, 'nameFi', value)}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <CustomEntitySelect
                type='manufacturer'
                label={t('manufacturer')}
                placeholder={isAdmin ? t('select') : null}
                value={equipment?.manufacturer || ''}
                onChange={(value: string) => updateEquipment(equipment.uuid, 'manufacturer', value)}
                mr='2rem'
                disabled={!isAdmin}
                // setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('model')}
                value={equipment?.model || ''}
                onChange={(value: string) => updateEquipment(equipment.uuid, 'model', value)}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('commissioning_year')}
                placeholder={isAdmin ? t('select') : null}
                value={equipment?.installationYear || ''}
                options={YearOptions()}
                onChange={(value: string) => updateEquipment(equipment.uuid, 'installationYear', value)}
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('count')}
                value={equipment?.count || ''}
                unit={t('pcs')}
                onChange={(value: string) => updateEquipment(equipment.uuid, 'count', value)}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={t('location')}
              value={equipment?.location || ''}
              onChange={(value: string) => updateEquipment(equipment.uuid, 'location', value)}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Input
              label={t('additional_info')}
              value={equipment?.additionalInfo || ''}
              onChange={(value: string) => updateEquipment(equipment.uuid, 'additionalInfo', value)}
              mb='2rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
              multiline
            />
            <Box sx={styles.egrid}>
              {isAdmin ? (
                <SimpleFileUpload
                  onUploadFiles={(files) => uploadEquipmentFiles(equipment.uuid, files)}
                />
              ) : null}
              {equipment?.files?.map((file: any) => (
                <SimpleFile
                  key={file.uuid}
                  file={file}
                  menuOptions={isAdmin ? [
                    { text: t('open_in_archive'), onClick: () => toEquipmentFolder(equipment.uuid) }
                  ] : undefined}
                  onFileClick={() => openEquipmentFile(equipment.uuid, file)}
                  dense
                />
              ))}
              {archiveStore?.pendingItems?.filter((item: any) => item?.uuid === equipment?.uuid)?.map((item: any) => (
                <SimpleFile
                  key={item?.uuid}
                  file={item.file}
                  previewUrl={item.previewUrl}
                  dense
                />
              ))}
            </Box>
          </Box>
        ))
      }
      return (
        <Box sx={styles.formContainer}>
          <Box sx={styles.formHeaderRow}>
            <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
              {building?.property?.type === 'industrial' ? t('equipment_alt') : t('equipment_alt_2')}
            </Typography>
            {isAdmin ? (
              <Button
                sx={styles.addItemButton}
                text={t('add_new')}
                onClick={addNewEquipment}
                variant='secondary'
                icon='add'
              />
            ) : null}
          </Box>
        </Box>
      )
    } else if (tab === BuildingTabs.Reminders) {
      return (
        <>
          <TableActions
            sort={reminderFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ReminderFilterOptions}
            onSortSelect={setReminderFilter}
            onAddClick={isAdmin ? toCreateReminder : undefined}
          />
          <Table
            rows={ReminderRows}
            columns={isAdmin ? AdminReminderColumns : ReminderColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (selectedImage) {
      if (building?.files?.some((file: any) => file?.type === 'image' && file?.src === selectedImage)) {
        return (
          <Lightbox
            open={selectedImage}
            onClose={clearSelectedImage}
            index={
              building?.files
                .filter((file: any) => file?.type === 'image')
                .map((file: any) => ({ src: file?.url }))
                .findIndex((file: any) => file.src === selectedImage)
            }
            images={
              building?.files
                .filter((file: any) => file?.type === 'image')
                .map((file: any) => ({ src: file?.url }))
            }
          />
        )
      }
      const equipmentFiles = building?.equipment?.find((equipment: any) => {
        return equipment?.files?.some((file: any) => file?.type === 'image' && file?.url === selectedImage)
      })?.files || []
      if (equipmentFiles?.length) {
        return (
          <Lightbox
            open={selectedImage}
            onClose={clearSelectedImage}
            index={
              equipmentFiles
                .map((file: any) => ({ src: file?.url }))
                .findIndex((file: any) => file.src === selectedImage)
            }
            images={
              equipmentFiles
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
            }
          />
        )
      }
    }
    return null
  }

  const renderRightColumnContent = () => {
    if (isAdmin) {
      return (
        <>
          <SidebarActions
            updatedAt={building?.updatedAt || building?.createdAt || ''}
            archivedAt={building?.archivedAt}
            onSave={save}
            onArchive={openArchiveBuildingConfirmation}
            saveDisabled={isSaveDisabled()}
          />
          <SidebarReminders
            updatedAt={reminderStore?.updatedAt || ''}
            count={UpcomingReminderCount}
            onAdd={toCreateReminder}
            onView={openRemindersTab}
            onToggleOpen={toggleOpenReminders}
            isOpen={remindersOpen}
          />
        </>
      )
    }
    return null
  }

  const renderEntityDialog = () => {
    if (actionType === 'create_space') {
      return (
        <CreateSpaceModal
          onClose={closeEntityDialog}
          onSave={createSpace}
        />
      )
    } else if (actionType === 'create_reminder' || reminder) {
      return (
        <ReminderModal
          mode={reminder ? 'edit' : 'create'}
          property={building?.property}
          building={building}
          onClose={reminder ? closeReminder : closeEntityDialog}
          onSave={reminder ? updateReminder : createReminder}
          users={AdminUserOptions}
          getTargetEntityOptions={getReminderTargetEntityOptions}
          getTargetSubEntityOptions={getReminderTargetSubEntityOptions}
          getTargetOptions={getReminderTargetOptions}
          reminder={reminder}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      if (actionType === 'archive_building') {
        return (
          <ConfirmationDialog
            title={t('archive_building_title')}
            content={t('archive_building_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveBuilding}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_space') {
        return (
          <ConfirmationDialog
            title={t('archive_space_title')}
            content={t('archive_space_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveSpace}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_reminder') {
        return (
          <ConfirmationDialog
            title={t('archive_reminder_title')}
            content={t('archive_reminder_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveReminder}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_equipment') {
        return (
          <ConfirmationDialog
            title={
              building?.property?.type === 'industrial' ?
                t('archive_equipment_title') :
                t('archive_equipment_title_2')
            }
            content={
              building?.property?.type === 'industrial' ?
                t('archive_equipment_info') :
                t('archive_equipment_info_2')
            }
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveEquipment}
            actionText={t('archive')}
          />
        )
      }
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage
        bgPhotoUrl={building?.backgroundPhoto}
        onClick={building?.backgroundPhoto ? openBuildingBackgroundPhoto : null}
        toArchiveFolder={isAdmin ? toArchive : null}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Typography variant='h2' color={Colors.primary} mt='1rem'>
            {building?.nameFi || '-'}
          </Typography>
          <Box sx={styles.infoRow}>
            <Chip
              text={building?.property?.nameFi || ''}
              href={`/properties/${params?.pUuid}`}
            />
          </Box>
          <Tabs mb='2rem' onArchiveClick={isAdmin ? toArchive : null}>
            <Tab
              value={BuildingTabs.BasicInformation}
              text={t(BuildingTabs.BasicInformation)}
              selected={tab === BuildingTabs.BasicInformation}
              onClick={updateTab}
              first
            />
            <Tab
              value={BuildingTabs.BuildingStructures}
              text={t(BuildingTabs.BuildingStructures)}
              selected={tab === BuildingTabs.BuildingStructures}
              onClick={updateTab}
            />
            <Tab
              value={BuildingTabs.BuildingParts}
              text={t(BuildingTabs.BuildingParts)}
              selected={tab === BuildingTabs.BuildingParts}
              onClick={updateTab}
            />
            <Tab
              value={BuildingTabs.BuildingSystems}
              text={t(BuildingTabs.BuildingSystems)}
              selected={tab === BuildingTabs.BuildingSystems}
              onClick={updateTab}
            />
            <Tab
              value={BuildingTabs.BuildingSpaces}
              text={t('common_spaces')}
              selected={tab === BuildingTabs.BuildingSpaces}
              onClick={updateTab}
            />
            {['industrial', 'business']?.includes(building?.property?.type) && (
              <Tab
                value={BuildingTabs.BuildingEquipment}
                text={building?.property?.type === 'industrial' ? t('equipment_alt') : t('equipment_alt_2')}
                selected={tab === BuildingTabs.BuildingEquipment}
                onClick={updateTab}
              />
            )}
            <Tab
              value={BuildingTabs.Reminders}
              text={t(BuildingTabs.Reminders)}
              selected={tab === BuildingTabs.Reminders}
              onClick={updateTab}
            />
          </Tabs>
          {renderTabContent()}
        </Box>
        <Box sx={styles.rightColumn}>
          {renderRightColumnContent()}
        </Box>
      </Box>
      {renderEntityDialog()}
      {renderLightbox()}
      {renderActionConfirmation()}
      <NavigationGuard
        hasUnsavedChanges={hasUnsavedChanges}
        forceShow={showHasUnsavedChanges}
        onHide={hideShowHasUnsavedChanges}
        onSave={save}
      />
    </Box>
  )
}

export default observer(Building)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
  formHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formHeaderActions: {
    display: 'flex',
    flexDirection: 'row',
  },
  addItemButton: {
    fontSize: '1rem',
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.text,
    textTransform: 'uppercase',
    border: `3px solid ${Colors.button}`,
    mb: '1.25rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  archiveItemButton: {
    fontSize: '1rem',
    fontWeight: 700,
    minWidth: '8rem',
    color: Colors.text,
    textTransform: 'uppercase',
    mb: '1.25rem',
    ml: '0.5rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  egrid: {
    position: 'relative',
    userSelect: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    width: '100%',
    gap: '1.5rem',
    // pt: '1.5rem',
    isolation: 'isolate',
    overflowY: 'auto',
    overflowX: 'hidden',
    mb: '1.5rem'
  }
} as const
