import Box from '@mui/material/Box'
import Checkbox from './Checkbox'
import InputLabel from '@mui/material/InputLabel'
import { Colors } from '../../Utils/theme'

export default function CheckboxMultiSelect(props: any) {
  const { label, value, options } = props

  const onChange = (val: string) => props.onChange(val)

  const renderLabel = () => {
    if (label) {
      return (
        <InputLabel
          sx={{ ...styles.label, fontWeight: props?.labelFontWeight || 600 }}
          disableAnimation
        >
          {label}
        </InputLabel>
      )
    }
    return null
  }

  return (
    <Box sx={styles.container} mb={props?.mb || 0}>
      {renderLabel()}
      <Box sx={props?.type === 'list' ? styles.listOptionsContainer : styles.optionsContainer}>
        {options.map((option: any, index: number) => (
          <Checkbox
            key={option.value}
            label={option.label || ''}
            checked={value?.includes(option.value)}
            onChange={() => onChange(option.value)}
            labelSx={styles.optionLabel}
            disabled={props?.disabled}
            mb={index !== options?.length - 1 ?  '0.875rem' : '0rem'}
            minWidth={{ lg: 'calc(33%)', xl: 'calc(25%)'}}
            bgColor={props?.bgColor}
          />
        ))}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    margin: 0,
    marginBottom: '0.875rem',
    color: Colors.heading,
    fontSize: '1rem',
    fontWeight: 600
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // alignSelf: 'flex-start',
    borderRadius: '0.625rem'
  },
  listOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // alignSelf: 'flex-start',
    borderRadius: '0.625rem'
  },
  optionLabel: {
    fontWeight: 400,
    marginRight: '1.5rem'
  }
} as const