import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'

export default class ApartmentStore {
  rootStore

  apartments: any = []
  apartment: any = null
  files: any = []
  hasUnsavedChanges: boolean = false
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setApartments = (apartments: any) => { this.apartments = apartments }
  setApartment = (apartment: any) => { this.apartment = apartment }
  setFiles = (files: any) => { this.files = files }
  setHasUnsavedChanges = (hasUnsavedChanges: boolean) => { this.hasUnsavedChanges = hasUnsavedChanges }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setApartments([])
    this.setApartment(null)
    this.setFiles([])
    this.setLoading(false)
  }

  async getApartments(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getApartments(pUuid)
      if (response?.ok) {
        const apartments = response.data?.items || []
        this.setApartments(apartments)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getApartment(pUuid: string, aUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getApartment(pUuid, aUuid)
      if (response?.ok) {
        const apartment = response?.data || null
        this.setApartment(apartment)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createApartment(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const isHousingProperty = this.rootStore.propertyStore.property?.type === 'housing'
      const response: any = await Api.createApartment(pUuid, payload)
      if (response?.ok) {
        this.getApartments(pUuid)
        showMessage(
          isHousingProperty ?
          i18n.t('apartment_created') :
          i18n.t('property_space_created')
        )
        if (callback) {
          callback()
        }
      } else {
        showMessage(
          isHousingProperty ?
          i18n.t('apartment_creation_failed') :
          i18n.t('property_space_creation_failed'),
          'error'
        )
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateApartment(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const isHousingProperty = this.apartment?.property?.type === 'housing'
      const response: any = await Api.updateApartment(pUuid, this.apartment?.uuid, payload)
      if (response?.ok) {
        this.getApartments(pUuid)
        showMessage(
          isHousingProperty ?
          i18n.t('apartment_updated') :
          i18n.t('space_updated')
        )
        if (callback) {
          callback()
        }
      } else {
        showMessage(
          isHousingProperty ?
          i18n.t('apartment_update_failed') :
          i18n.t('space_update_failed'),
          'error'
        )
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateApartmentBackgroundPhoto(pUuid: string, fUuid: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateApartmentBackgroundPhoto(pUuid, this.apartment?.uuid, fUuid)
      if (response?.ok) {
        const data = response?.data || null
        this.setApartment(data)
        this.getApartments(pUuid)
        showMessage(i18n.t('apartment_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('apartment_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveApartment(pUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const isHousingProperty = this.apartment?.property?.type === 'housing'
      const response: any = await Api.archiveApartment(pUuid, this.apartment.uuid)
      if (response?.ok) {
        this.getApartments(pUuid)
        showMessage(
          isHousingProperty ?
          i18n.t('apartment_archived') :
          i18n.t('space_archived')
        )
        if (callback) {
          callback()
        }
      } else {
        showMessage(
          isHousingProperty ?
          i18n.t('apartment_archive_failed') :
          i18n.t('space_archive_failed'),
          'error'
        )
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getApartmentFiles(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getApartmentFiles(pUuid, this.apartment.uuid)
      if (response?.ok) {
        const files = response?.data?.items || []
        this.setFiles(files)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async uploadApartmentFiles(pUuid: string, files: any) {
    this.setLoading(true)
    try {
      const okResponses = []
      const errorResponses = []
      for (const file of files) {
        const response: any = await Api.uploadApartmentFile(pUuid, this.apartment.uuid, file)
        if (response?.ok) {
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
      }
      if (okResponses?.length) {
        if (okResponses?.length === files?.length) {
          if (okResponses?.length === 1) {
            showMessage(i18n.t('file_uploaded'))
          } else {
            showMessage(i18n.t('files_uploaded'))
          }
        }
        this.getApartment(pUuid, this.apartment.uuid)
      } else {
        showMessage(i18n.t('file_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateApartmentFile(pUuid: string, fUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateApartmentFile(pUuid, this.apartment.uuid, fUuid, payload)
      if (response?.ok) {
        const files = response?.data || []
        this.setApartment({
          ...this.apartment,
          files
        })
        showMessage(i18n.t('file_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async downloadApartmentFile(pUuid: string, file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.downloadApartmentFile(pUuid, this.apartment.uuid, file?.uuid)
      if (response?.ok) {
        // Create a blob from the response
        const blob = new Blob([response.data], { type: file?.mimeType })
        
        // Create a local URL for the blob
        const blobUrl = URL.createObjectURL(blob)
        
        // Create and trigger download link
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = file?.name || file?.originalName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        
        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl)
      } else {
        showMessage(i18n.t('file_download_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archiveApartmentFile(pUuid: string, fUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveApartmentFile(pUuid, this.apartment.uuid, fUuid)
      if (response?.ok) {
        const apartment = response?.data || null
        this.setApartment(apartment)
        showMessage(i18n.t('file_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
