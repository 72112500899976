import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'
import i18n from '../Localization'
import { showMessage } from '../Utils/message'
import {
  DefaultApartmentBasicInformationOrder,
  DefaultApartmentCategoryOrder,
  DefaultApartmentRoomsOrder,
  DefaultBuildingBasicInformationOrder,
  DefaultBuildingCategoryOrder,
  DefaultBuildingPartsOrder,
  DefaultBuildingSpacesOrder,
  DefaultBuildingStructuresOrder,
  DefaultBuildingSystemsOrder,
  DefaultPropertyAreasOrder,
  DefaultPropertyBasicInformationOrder,
  DefaultPropertyContactInformationOrder,
  DefaultPropertyCategoryOrder,
  SummaryDefaultApartmentHiddenItems,
  SummaryDefaultBuildingHiddenItems,
  SummaryDefaultPropertyHiddenItems,
} from '../Utils/summary'

export default class PropertyStore {
  rootStore

  // Property
  properties: any = []
  totalProperties: number = 0
  property: any = null
  files: any = []
  filters: any = null
  entities: any = []
  hasUnsavedChanges: boolean = false

  // Property summary
  propertySummary: any = null
  summaryTemplates: any = []
  summaryTemplate: any = null

  // Miscellanous
  loading = false

  constructor(rootStore: any) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setProperties = (properties: any) => { this.properties = properties }
  setTotalProperties = (totalProperties: number) => { this.totalProperties = totalProperties }
  setProperty = (property: any) => { this.property = property }
  setFiles = (files: any) => { this.files = files }
  setFilters = (filters: any) => { this.filters = filters }
  setEntities = (entities: any) => { this.entities = entities }
  setHasUnsavedChanges = (hasUnsavedChanges: boolean) => { this.hasUnsavedChanges = hasUnsavedChanges }
  setPropertySummary = (summary: any) => { this.propertySummary = summary }
  setSummaryTemplates = (summaryTemplates: any) => { this.summaryTemplates = summaryTemplates }
  setSummaryTemplate = (summaryTemplate: any) => { this.summaryTemplate = summaryTemplate }
  setLoading = (loading: boolean) => { this.loading = loading }

  reset() {
    this.setProperties([])
    this.setTotalProperties(0)
    this.setProperty(null)
    this.setFiles([])
    this.setFilters(null)
    this.setLoading(false)
  }

  async getProperties() {
    this.setLoading(true)
    try {
      const response: any = await Api.getProperties()
      if (response?.ok) {
        const properties = response.data?.items || []
        const total = response.data?.total || 0
        this.setProperties(properties)
        this.setTotalProperties(total)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getProperty(uuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getProperty(uuid)
      if (response?.ok) {
        const property = response?.data || null
        this.setProperty(property)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createProperty(payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createProperty(payload)
      if (response?.ok) {
        this.getProperties()
        showMessage(i18n.t('property_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updateProperty(uuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updateProperty(uuid, payload)
      if (response?.ok) {
        this.getProperties()
        showMessage(i18n.t('property_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertyBackgroundPhoto(pUuid: string, fUuid: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyBackgroundPhoto(pUuid, fUuid)
      if (response?.ok) {
        const data = response?.data || null
        this.setProperty(data)
        showMessage(i18n.t('property_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  /*
  async updatePropertyBackgroundPhoto(file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyBackgroundPhoto(this.property?.uuid, file)
      if (response?.ok) {
        const data = response?.data || null
        this.setProperty(data)
        showMessage(i18n.t('property_updated'))
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
  */

  async archiveProperty(uuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archiveProperty(uuid)
      if (response?.ok) {
        this.getProperties()
        showMessage(i18n.t('property_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertyFiles() {
    this.setLoading(true)
    try {
      const response: any = await Api.getPropertyFiles(this.property.uuid)
      if (response?.ok) {
        const files = response?.data?.items || []
        this.setFiles(files)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async uploadPropertyFiles(pUuid: string, files: any) {
    this.setLoading(true)
    try {
      const okResponses = []
      const errorResponses = []
      for (const file of files) {
        const response: any = await Api.uploadPropertyFile(pUuid, file)
        if (response?.ok) {
          okResponses.push(response)
        } else {
          errorResponses.push(response)
        }
      }
      if (okResponses?.length) {
        if (okResponses?.length === files?.length) {
          if (okResponses?.length === 1) {
            showMessage(i18n.t('file_uploaded'))
          } else {
            showMessage(i18n.t('files_uploaded'))
          }
        }
        this.getProperty(pUuid)
      } else {
        showMessage(i18n.t('file_upload_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertyFile(pUuid: string, fUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyFile(pUuid, fUuid, payload)
      if (response?.ok) {
        const files = response?.data || []
        this.setProperty({
          ...this.property,
          files
        })
        showMessage(i18n.t('file_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async downloadPropertyFile(pUuid: string, file: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.downloadPropertyFile(pUuid, file?.uuid)
      if (response?.ok) {
        // Create a blob from the response
        const blob = new Blob([response.data], { type: file?.mimeType })
        
        // Create a local URL for the blob
        const blobUrl = URL.createObjectURL(blob)
        
        // Create and trigger download link
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = file?.name || file?.originalName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        
        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl)
      } else {
        showMessage(i18n.t('file_download_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archivePropertyFile(pUuid: string, fUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archivePropertyFile(pUuid, fUuid)
      if (response?.ok) {
        const property = response?.data || null
        this.setProperty(property)
        showMessage(i18n.t('file_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('file_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertyAreas(pUuid: string) {
    this.setLoading(true)
    try {
      const response: any = await Api.getPropertyAreas(pUuid)
      if (response?.ok) {
        const property = response?.data || null
        this.setProperty(property)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertyAreas(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertyAreas(pUuid, payload)
      if (response?.ok) {
        this.getPropertyAreas(pUuid)
        showMessage(i18n.t('property_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('property_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertyEntities(pUuid: string, params?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.getPropertyEntities(pUuid, params || {})
      if (response?.ok) {
        const entities = response?.data || null
        this.setEntities(entities)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async getPropertySummary(pUuid: string, tUuid?: string, query?: any) {
    this.setLoading(true)
    try {
      // Set default summary template
      this.setSummaryTemplate(
        {
          propertyCategoryOrder: (query ? query?.propertyCategoryOrder : DefaultPropertyCategoryOrder) || DefaultPropertyCategoryOrder,
          propertyBasicInformationOrder: (query ? query?.propertyBasicInformationOrder : DefaultPropertyBasicInformationOrder) || DefaultPropertyBasicInformationOrder,
          propertyContactInformationOrder: (query ? query?.propertyContactInformationOrder : DefaultPropertyContactInformationOrder) || DefaultPropertyContactInformationOrder,
          propertyAreasOrder: (query ? query?.propertyAreasOrder : DefaultPropertyAreasOrder) || DefaultPropertyAreasOrder,
          buildingCategoryOrder: (query ? query?.buildingCategoryOrder : DefaultBuildingCategoryOrder) || DefaultBuildingCategoryOrder,
          buildingBasicInformationOrder: (query ? query?.buildingBasicInformationOrder : DefaultBuildingBasicInformationOrder) || DefaultBuildingBasicInformationOrder,
          buildingStructuresOrder: (query ? query?.buildingStructuresOrder : DefaultBuildingStructuresOrder) || DefaultBuildingStructuresOrder,
          buildingPartsOrder: (query ? query?.buildingPartsOrder : DefaultBuildingPartsOrder) || DefaultBuildingPartsOrder,
          buildingSystemsOrder: (query ? query?.buildingSystemsOrder : DefaultBuildingSystemsOrder()) || DefaultBuildingSystemsOrder(),
          buildingSpacesOrder: (query ? query?.buildingSpacesOrder : DefaultBuildingSpacesOrder) || DefaultBuildingSpacesOrder,
          apartmentCategoryOrder: (query ? query?.apartmentCategoryOrder : DefaultApartmentCategoryOrder) || DefaultApartmentCategoryOrder,
          apartmentBasicInformationOrder: (query ? query?.apartmentBasicInformationOrder : DefaultApartmentBasicInformationOrder()) || DefaultApartmentBasicInformationOrder(),
          apartmentRoomsOrder: (query ? query?.apartmentRoomsOrder : DefaultApartmentRoomsOrder) || DefaultApartmentRoomsOrder,
          propertyHiddenItems: (query ? query?.propertyHiddenItems : SummaryDefaultPropertyHiddenItems) || SummaryDefaultPropertyHiddenItems,
          // propertyBasicInformationHiddenItems: [],
          // propertyAreasHiddenItems: [],
          buildingHiddenItems: (query ? query?.buildingHiddenItems : SummaryDefaultBuildingHiddenItems) || SummaryDefaultBuildingHiddenItems,
          // buildingBasicInformationHiddenItems: [],
          // buildingStructuresHiddenItems: [],
          // buildingPartsHiddenItems: [],
          // buildingSystemsHiddenItems: [],
          // buildingSpacesHiddenItems: [],
          apartmentHiddenItems: (query ? query?.apartmentHiddenItems : SummaryDefaultApartmentHiddenItems) || SummaryDefaultApartmentHiddenItems,
          // apartmentBasicInformationHiddenItems: [],
          // apartmentRoomsHiddenItems: [],
        }
      )

      const response: any = await Api.getPropertySummary(pUuid)
      if (response?.ok) {
        const data = response?.data || null

        if (data?.type === 'housing') {
          this.setSummaryTemplate({
            ...this.summaryTemplate,
            buildingSystemsOrder: (query ? query?.buildingSystemsOrder : DefaultBuildingSystemsOrder({ propertyType: data?.type })) || DefaultBuildingSystemsOrder({ propertyType: data?.type }),
          })
        }

        this.setSummaryTemplates(data?.templates?.map((t: any) => ({
          ...t,
          propertyCategoryOrder: (t?.propertyCategoryOrder?.split(',')?.map((i: any) => i)) || [], 
          propertyBasicInformationOrder: (t?.propertyBasicInformationOrder?.split(',')?.map((i: any) => i)) || [],
          propertyContactInformationOrder: (t?.propertyContactInformationOrder?.split(',')?.map((i: any) => i)) || [],
          propertyAreasOrder: (t?.propertyAreasOrder?.split(',')?.map((i: any) => i)) || [],
          buildingCategoryOrder: (t?.buildingCategoryOrder?.split(',')?.map((i: any) => i)) || [],
          buildingBasicInformationOrder: (t?.buildingBasicInformationOrder?.split(',')?.map((i: any) => i)) || [],
          buildingStructuresOrder: (t?.buildingStructuresOrder?.split(',')?.map((i: any) => i)) || [],
          buildingPartsOrder: (t?.buildingPartsOrder?.split(',')?.map((i: any) => i)) || [],
          buildingSystemsOrder: (t?.buildingSystemsOrder?.split(',')?.map((i: any) => i)) || [],
          buildingSpacesOrder: (t?.buildingSpacesOrder?.split(',')?.map((i: any) => i)) || [],
          apartmentCategoryOrder: (t?.apartmentCategoryOrder?.split(',')?.map((i: any) => i)) || [],
          apartmentBasicInformationOrder: (t?.apartmentBasicInformationOrder?.split(',')?.map((i: any) => i)) || [],
          apartmentRoomsOrder: (t?.apartmentRoomsOrder?.split(',')?.map((i: any) => i)) || [],
          propertyHiddenItems: (t?.propertyHiddenItems?.split(',')?.map((i: any) => i)) || [],
          // propertyBasicInformationHiddenItems: [],
          // propertyAreasHiddenItems: [],
          buildingHiddenItems: (t?.buildingHiddenItems?.split(',')?.map((i: any) => i)) || [],
          // buildingBasicInformationHiddenItems: [],
          // buildingStructuresHiddenItems: [],
          // buildingPartsHiddenItems: [],
          // buildingSystemsHiddenItems: [],
          // buildingSpacesHiddenItems: [],
          apartmentHiddenItems: (t?.apartmentHiddenItems?.split(',')?.map((i: any) => i)) || [],
          // apartmentBasicInformationHiddenItems: [],
          // apartmentRoomsHiddenItems: [],
        })) || [])
        if (tUuid) {
          const template = this.summaryTemplates?.find((t: any) => t?.uuid === tUuid) || null
          if (template) {
            this.setSummaryTemplate(template)
          }
        }
        this.setPropertySummary(data)
      } else {
        // TODO
        showMessage('')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async createPropertySummaryTemplate(pUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.createPropertySummaryTemplate(
        pUuid,
        {
          name: payload?.name,
          propertyCategoryOrder: this.summaryTemplate?.propertyCategoryOrder?.join(','),
          propertyBasicInformationOrder: this.summaryTemplate?.propertyBasicInformationOrder?.join(','),
          propertyContactInformationOrder: this.summaryTemplate?.propertyContactInformationOrder?.join(','),
          propertyAreasOrder: this.summaryTemplate?.propertyAreasOrder?.join(','),
          buildingCategoryOrder: this.summaryTemplate?.buildingCategoryOrder?.join(','),
          buildingBasicInformationOrder: this.summaryTemplate?.buildingBasicInformationOrder?.join(','),
          buildingStructuresOrder: this.summaryTemplate?.buildingStructuresOrder?.join(','),
          buildingPartsOrder: this.summaryTemplate?.buildingPartsOrder?.join(','),
          buildingSystemsOrder: this.summaryTemplate?.buildingSystemsOrder?.join(','),
          buildingSpacesOrder: this.summaryTemplate?.buildingSpacesOrder?.join(','),
          apartmentCategoryOrder: this.summaryTemplate?.apartmentCategoryOrder?.join(','),
          apartmentBasicInformationOrder: this.summaryTemplate?.apartmentBasicInformationOrder?.join(','),
          apartmentRoomsOrder: this.summaryTemplate?.apartmentRoomsOrder?.join(','),
          propertyHiddenItems: this.summaryTemplate?.propertyHiddenItems?.join(','),
          // propertyBasicInformationHiddenItems: [],
          // propertyAreasHiddenItems: [], 
          buildingHiddenItems: this.summaryTemplate?.buildingHiddenItems?.join(','),
          // buildingBasicInformationHiddenItems: [],
          // buildingStructuresHiddenItems: [],
          // buildingPartsHiddenItems: [],
          // buildingSystemsHiddenItems: [],
          // buildingSpacesHiddenItems: [],
          apartmentHiddenItems: this.summaryTemplate?.apartmentHiddenItems?.join(','),
          // apartmentBasicInformationHiddenItems: [],
          // apartmentRoomsHiddenItems: [],
        }
      )
      if (response?.ok) {
        this.setSummaryTemplate(response?.data)
        this.getPropertySummary(pUuid, response?.data?.uuid)
        showMessage(i18n.t('template_created'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('template_creation_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async updatePropertySummaryTemplate(pUuid: string, tUuid: string, payload: any, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.updatePropertySummaryTemplate(
        pUuid,
        tUuid,
        {
          name: payload?.name,
          propertyCategoryOrder: this.summaryTemplate?.propertyCategoryOrder?.join(','),
          propertyBasicInformationOrder: this.summaryTemplate?.propertyBasicInformationOrder?.join(','),
          propertyContactInformationOrder: this.summaryTemplate?.propertyContactInformationOrder?.join(','),
          propertyAreasOrder: this.summaryTemplate?.propertyAreasOrder?.join(','),
          buildingCategoryOrder: this.summaryTemplate?.buildingCategoryOrder?.join(','),
          buildingBasicInformationOrder: this.summaryTemplate?.buildingBasicInformationOrder?.join(','),
          buildingStructuresOrder: this.summaryTemplate?.buildingStructuresOrder?.join(','),
          buildingPartsOrder: this.summaryTemplate?.buildingPartsOrder?.join(','),
          buildingSystemsOrder: this.summaryTemplate?.buildingSystemsOrder?.join(','),
          buildingSpacesOrder: this.summaryTemplate?.buildingSpacesOrder?.join(','),
          apartmentCategoryOrder: this.summaryTemplate?.apartmentCategoryOrder?.join(','),
          apartmentBasicInformationOrder: this.summaryTemplate?.apartmentBasicInformationOrder?.join(','),
          apartmentRoomsOrder: this.summaryTemplate?.apartmentRoomsOrder?.join(','),
          propertyHiddenItems: this.summaryTemplate?.propertyHiddenItems?.join(','),
          // propertyBasicInformationHiddenItems: [],
          // propertyAreasHiddenItems: [], 
          buildingHiddenItems: this.summaryTemplate?.buildingHiddenItems?.join(','),
          // buildingBasicInformationHiddenItems: [],
          // buildingStructuresHiddenItems: [],
          // buildingPartsHiddenItems: [],
          // buildingSystemsHiddenItems: [],
          // buildingSpacesHiddenItems: [],
          apartmentHiddenItems: this.summaryTemplate?.apartmentHiddenItems?.join(','),
          // apartmentBasicInformationHiddenItems: [],
          // apartmentRoomsHiddenItems: [],
        }
      )
      if (response?.ok) {
        this.getPropertySummary(pUuid, tUuid)
        showMessage(i18n.t('template_updated'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('template_update_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }

  async archivePropertySummaryTemplate(pUuid: string, tUuid: string, callback?: any) {
    this.setLoading(true)
    try {
      const response: any = await Api.archivePropertySummaryTemplate(pUuid, tUuid)
      if (response?.ok) {
        this.getPropertySummary(pUuid)
        showMessage(i18n.t('template_archived'))
        if (callback) {
          callback()
        }
      } else {
        showMessage(i18n.t('template_archive_failed'), 'error')
      }
    } catch (e) {
      console.log(e)
    }
    this.setLoading(false)
  }
}
