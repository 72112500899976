import { useEffect, useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import SummaryBackgroundImage from '../../../Components/Common/SummaryBackgroundImage'
import SummaryImages from '../../../Components/Common/SummaryImages'
import SummaryHeader from '../../../Components/Common/SummaryHeader'
import SummaryItem from '../../../Components/Common/SummaryItem'
import SummaryDivider from '../../../Components/Common/SummaryDivider'
import {
  SummaryPropertyLayout,
  SummaryBuildingLayout,
  SummaryApartmentLayout
} from '../../../Utils/summary'
import { parseSummaryTemplateQueryString } from '../../../Utils/transformers'

const Summary = () => {
  const {
    propertyStore,
  }: any = useStore()
  const { propertySummary, summaryTemplate } = propertyStore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation()
  const params = useParams()

  useEffect(() => {
    const uuid = params?.pUuid || null
    if (uuid && uuid?.length === 36) {
      if (location?.search?.includes('template')) {
        const selectedTemplate = location?.search?.split('template=')?.[1] || ''
        if (selectedTemplate && selectedTemplate?.length === 36) {
          propertyStore.getPropertySummary(uuid, selectedTemplate)
        } else {
          propertyStore.getPropertySummary(uuid)
        }
      } else {
        const query = parseSummaryTemplateQueryString(location?.search)
        propertyStore.getPropertySummary(uuid, null, query)
      }
    }
  }, [])

  const BackgroundPhotos = useMemo(() => {
    if (propertySummary) {
      const backgroundPhotos = []
      for (const building of propertySummary?.buildings || []) {
        if (building?.backgroundPhoto) {
          backgroundPhotos.push({ src: building?.backgroundPhoto, isBuilding: true })
        }
      }
      for (const apartment of propertySummary?.apartments || []) {
        if (apartment?.backgroundPhoto) {
          backgroundPhotos.push({ src: apartment?.backgroundPhoto, isApartment: true })
        }
      }

      return backgroundPhotos
    }
    return []
  }, [propertySummary])

  /*
  const PropertyPhotos = useMemo(() => {
    return propertySummary?.files
      ?.filter((file: any) => file?.type === 'image')
      ?.map((file: any) => ({
        uuid: file?.uuid,
        src: file?.url,
        apartmentId: file?.apartmentId,
        buildingId: file?.buildingId
      }))
  }, [propertySummary])
  */

  const renderItems = (section: string, items: any) => {
    return items?.map((item: any, index: number) => {
      if (item?.type === 'summary_column') {
        if (!item?.items) return null
        return (
          <Fragment key={item?.key}>
            {item?.items?.length ? <SummaryDivider /> : null}
            <Box sx={styles.summarySectionRow} width={items?.length > 1 ? '50%' : '100%'}>
              <Box sx={styles.summaryColumn}>
                {renderItems(`${section}-${item.key}`, item?.items || [])}
              </Box>
            </Box>
          </Fragment>
        )
      } else {
        if (!item) {
          return null
        }
        return (
          <SummaryItem
            key={`${section}-${item?.label}-${index}`}
            label={item?.label ? t(item.label) : ''}
            value={item?.type === 'translated' && item?.value ? t(item.value) : item?.value}
            extra={item?.extra}
            mb={index === items.length - 1 ? '0rem' : null}
          />
        )
      }
    })
  }

  const renderSubSections = (entity: string, mainSection: string, subSections: any) => {
    const shownParentTitles = []
    return subSections.map((section: any) => {
      if (!section?.items) return null
      if (
        entity === 'property' &&
        summaryTemplate?.propertyHiddenItems?.includes(section?.parentKey || section?.key)
      ) {
        return null
      }
      if (
        entity === 'building' && summaryTemplate?.buildingHiddenItems?.includes(section?.parentKey || section?.key)
      ) {
        return null
      }
      if (
        entity === 'apartment' && summaryTemplate?.apartmentHiddenItems?.includes(section?.parentKey || section?.key)
      ) {
        return null
      }
      if (mainSection === 'building_parts' && section?.parentTitle) {
        if (shownParentTitles.includes(section?.parentTitle)) {
          section.parentTitle = null
        } else {
          shownParentTitles.push(section?.parentTitle)
        }
      }
      return (
        <Box key={`${mainSection}-${section?.key}`} sx={styles.printSectionContent}>
          {section?.parentTitle ? <SummaryHeader type='subtitle' title={t(section?.parentTitle)} /> : null}
          <SummaryHeader type={section?.type || 'subtitle'} title={t(section?.title)} />
          {section?.groupTitle ? <SummaryHeader type='group_title' title={`${t(section?.groupTitle)} ${section?.groupTitleExt || ''}`?.trim()} mt='1.5rem' /> : null}
          {section?.multiRow ? section?.items?.map((s: any, index: number) => (
            <Fragment key={`${section}-${section?.key}-row-${index}`}>
              {s?.groupTitle ? <SummaryHeader type='group_title' title={`${t(s?.groupTitle)} ${s?.groupTitleExt || ''}`?.trim()} mt='1.5rem' /> : null}
              <Box sx={styles.summaryRow}>
                {renderItems(`${section}-${section?.key}`, s?.items || [])}
              </Box>
            </Fragment>
            )) : (
              <Box sx={styles.summaryRow}>
                {renderItems(`${section}-${section?.key}`, section?.items || [])}
              </Box>
            )
        }
        </Box>
      )
    })
  }

  const PropertySummaryContent = useMemo(() => {
    return SummaryPropertyLayout(propertySummary, summaryTemplate)?.map((mainSection: any) => {
      return (
        <Fragment key={mainSection?.key}>
          {/* <SummaryHeader title={t(mainSection?.title)} /> */}
          {renderSubSections('property', mainSection?.key, mainSection?.items || [])}
        </Fragment>
      )
    })
  }, [propertySummary])

  const BuildingSummaryContent = useMemo(() => {
    return propertySummary?.buildings?.map((building: any, index: number) => {
      return (
        <Box key={building?.uuid} sx={styles.printContent}>
          <SummaryBackgroundImage
            url={building?.backgroundPhoto || propertySummary?.backgroundPhoto}
            title1={propertySummary?.nameFi}
            title2={building?.nameFi}
            subtitle={building?.commissioningYear}
            mt={index !== 0 ? '3rem' : '0rem'}
          />
          {SummaryBuildingLayout(building, summaryTemplate, propertySummary?.type)?.map((mainSection: any) => {
            return (
              <Fragment key={mainSection?.key}>
                {/* <SummaryHeader title={t(mainSection?.title)} /> */}
                {renderSubSections('building', mainSection?.key, mainSection?.items || [])}
              </Fragment>
            )})
          }
        </Box>
      )
    })
  }, [propertySummary])

  const ApartmentSummaryContent = useMemo(() => {
    return propertySummary?.apartments?.map((apartment: any, index: number) => {
      return (
        <Box key={apartment?.uuid} sx={styles.printContent}>
          <SummaryBackgroundImage
            url={apartment?.backgroundPhoto || propertySummary?.backgroundPhoto}
            title1={propertySummary?.nameFi}
            title2={apartment?.nameFi}
            subtitle={apartment?.commissioningYears}
            mt={index !== 0 ? '3rem' : '0rem'}
          />
          {SummaryApartmentLayout(
            apartment,
            summaryTemplate,
            {
              propertyType: propertySummary?.type,
              postalCode: propertySummary?.postalCode,
              city: propertySummary?.city,
            }
          )?.map((mainSection: any) => {
            return (
              <Fragment key={mainSection?.key}>
                {/* <SummaryHeader title={t(mainSection?.title)} /> */}
                {renderSubSections('apartment', mainSection?.key, mainSection?.items || [])}
              </Fragment>
            )})
          }
        </Box>
      )
    })
  }, [propertySummary])

  const PropertyContent = useMemo(() => {
    if (!propertySummary) {
      return <CircularProgress sx={styles.loading} />
    }
    if (summaryTemplate?.propertyHiddenItems?.length === 16) {
      return null
    }
    return (
      <Box sx={styles.printContent} mb='1rem'>
        <SummaryBackgroundImage
          url={propertySummary?.backgroundPhoto}
          title1={t('condominium')}
          title2={propertySummary?.nameFi}
          subtitle={propertySummary?.buildingCommissioningYears?.join(', ')}
        />
        <SummaryImages images={BackgroundPhotos} />
        {PropertySummaryContent}
      </Box>
    )
  }, [propertySummary])

  const BuildingContent = useMemo(() => {
    if (propertySummary) {
      if (!propertySummary?.buildings?.length) {
        return null
      }
      if (summaryTemplate?.buildingHiddenItems?.length === 35) {
        return null
      }
      return (
        <Box sx={styles.printContent}>
          <SummaryHeader title={t('buildings')} mb='1rem' />
          {BuildingSummaryContent}
        </Box>
      )
    }
    return null
  }, [propertySummary])

  const ApartmentContent = useMemo(() => {
    if (propertySummary) {
      if (!propertySummary?.apartments?.length) {
        return null
      }
      if (summaryTemplate?.apartmentHiddenItems?.length === 13) {
        return null
      }
      return (
        <Box sx={styles.printContent}>
          <SummaryHeader title={propertySummary?.type === 'housing' ? t('apartments') : t('spaces')} />
          {ApartmentSummaryContent}
        </Box>
      )
    }
    return null
  }, [propertySummary])

  return (
    <Box sx={styles.container}>
      {PropertyContent}
      {BuildingContent}
      {ApartmentContent}
    </Box>
  )
}

export default observer(Summary)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '20rem',
  },
  printContent: {
    width: '100%',
    '@media print': {
      pageBreakAfter: 'always',
    },
  },
  printSectionContent: {
    width: '100%',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    mt: '1rem',
  },
  summarySectionRow: {
    display: 'flex',
    flexDirection: 'row',
    // width: '50%',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  summaryColumn: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  loading: {
    position: 'absolute',
    top: '49%',
    alignSelf: 'center',
  }
} as const
