import Box from '@mui/material/Box'
import { Draggable } from '@hello-pangea/dnd'
import Checkbox from './Checkbox'
import DragIcon from '../../Assets/Icons/drag.svg'
import { MouseEvent } from 'react'
import { Colors } from '../../Utils/theme'

interface SummarySelectableProps {
  id: string
  label: string
  selected: boolean
  onChange: () => void
  index: number
  title?: boolean
  showDragHandle?: boolean
  dragHandleProps?: any
  dragDisabled?: boolean
}

export default function SummarySelectable(props: SummarySelectableProps) {
  const { 
    id, 
    label, 
    selected, 
    onChange, 
    title, 
    index,
    showDragHandle = false,
    dragHandleProps,
    // dragDisabled = false
  } = props

  const DragHandle = (handleProps: any) => (
    <Box
      {...handleProps}
      sx={styles.dragHandle}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
      }}
    >
      <Box sx={styles.icon} component='img' src={DragIcon} />
    </Box>
  )

  const Content = (providedDragHandleProps?: any, isDragging?: boolean) => (
    <Box
      sx={[
        styles.container,
        { ml: title ? '0rem' : '2.75rem' },
        isDragging ? styles.dragging : {}
      ]}
    >
      {showDragHandle && DragHandle(dragHandleProps || providedDragHandleProps)}
      <Box 
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation()
          onChange()
        }}
      >
        <Checkbox
          label={label}
          checked={selected}
          onChange={() => {}}
          labelSx={title ? styles.sectionLabel : styles.itemLabel}
          size='1.375rem'
          iconSize='1rem'
          ml='0.75rem'
        />
      </Box>
    </Box>
  )

  // If dragHandleProps is provided, the dragging is handled by the parent
  if (dragHandleProps) {
    return Content()
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            transform: provided.draggableProps.style?.transform,
          }}
        >
          {Content(provided.dragHandleProps, snapshot?.isDragging)}
        </Box>
      )}
    </Draggable>
  )
}

const styles = {
  container: {
    height: '3rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.border}`,
    borderRadius: '0.3125rem',
    p: '0rem 0.75rem',
    mb: '0.75rem',
    userSelect: 'none',
    position: 'relative',
  },
  dragging: {
    boxShadow: '0rem 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
    backgroundColor: Colors.secondary10,
  },
  dragHandle: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    margin: '-0.5rem',
    borderRadius: '0.25rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  icon: {
    height: '1.5rem',
  },
  sectionLabel: {
    fontWeight: 700,
    fontSize: '1rem',
    color: Colors.text,
    textTransform: 'uppercase',
  },
  itemLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.text
  },
} as const