import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import CollapseSection from '../../../Components/Common/CollapseSection'
import CustomForm from '../../../Components/Common/CustomForm'
import Button from '../../../Components/Common/Button'
import { useStore } from '../../../Models/RootStore'
import { BuildingSections, BuildingSystemsLayout, HousingSystemCategories, SystemCategories, SystemCategoryMappings } from '../../../Utils/building'
import { ComponentTypes } from '../../../Utils/layout'
import { Colors } from '../../../Utils/theme'

const BuildingSystems = (props: any) => {
  const { sessionStore, buildingStore }: any = useStore()
  const { isAdmin } = sessionStore
  const { building, hasUnsavedChanges } = buildingStore
  const { t } = useTranslation()
  
  const ventilation: any = useRef({})
  const heating: any = useRef({})
  const drainageSystem: any = useRef({})
  const rainwaterSystem: any = useRef({})
  const propertyWater: any = useRef({})
  const waterPipe: any = useRef({})
  const warmWater: any = useRef({})
  const wasteManagement: any = useRef({})
  const elevator: any = useRef({})
  const mainDistribution: any = useRef({})
  const connectionAndProduction: any = useRef({})
  const installationAndAuxiliarySystem: any = useRef({})
  const connectionSystem: any = useRef({})
  const lighting: any = useRef({})
  const grounding: any = useRef({})
  const communicationAndDataNetwork: any = useRef({})
  const fireSafety: any = useRef({})
  const propertySewerage: any = useRef({})
  const sewerPipe: any = useRef({})
  const wasteWaterHeatRecovery: any = useRef({})
  const securityMonitoring: any = useRef({})
  const industrialWasteRemovalAndPurification: any = useRef({})
  const energyRecoveryAndRecycling: any = useRef({})

  const [category, setCategory] = useState(SystemCategories.Ventilation)
  const [lastUpdatedValue, setLastUpdatedValue] = useState('')
  const [openSections, setOpenSections] = useState([
    ...BuildingSystemsLayout.map((section: any) => section.title)
  ])

  const onAddMaintenanceCompany = () => {
    industrialWasteRemovalAndPurification.current = {
      ...industrialWasteRemovalAndPurification.current,
      maintenanceCompanies: [
        ...industrialWasteRemovalAndPurification.current?.maintenanceCompanies,
        {
          uuid: uuidv4(),
          company: '',
          contactPersonName: '',
          contactPersonPhone: '',
          contactPersonEmail: '',
          companyAddress: '',
          companyPostalCode: '',
          companyCity: '',
          contractLink: ''
        }
      ]
    }
    setLastUpdatedValue(DateTime.now().toISO())
  }

  const onArchiveMaintenanceCompany = (uuid: string) => {
    industrialWasteRemovalAndPurification.current = {
      ...industrialWasteRemovalAndPurification.current,
      maintenanceCompanies: industrialWasteRemovalAndPurification.current?.maintenanceCompanies
        ?.filter((company: any) => company?.uuid !== uuid)
    }
    setLastUpdatedValue(DateTime.now().toISO())
  }

  const onAddTransportCompany = () => {
    industrialWasteRemovalAndPurification.current = {
      ...industrialWasteRemovalAndPurification.current,
      transportCompanies: [
        ...industrialWasteRemovalAndPurification.current?.transportCompanies,
        {
          uuid: uuidv4(),
          company: '',
          contactPersonName: '',
          contactPersonPhone: '',
          contactPersonEmail: '',
          companyAddress: '',
          companyPostalCode: '',
          companyCity: '',
          contractLink: ''
        }
      ]
    }
    setLastUpdatedValue(DateTime.now().toISO())
  }

  const onArchiveTransportCompany = (uuid: string) => {
    industrialWasteRemovalAndPurification.current = {
      ...industrialWasteRemovalAndPurification.current,
      transportCompanies: industrialWasteRemovalAndPurification.current?.transportCompanies
        ?.filter((company: any) => company?.uuid !== uuid)
    }
    setLastUpdatedValue(DateTime.now().toISO())
  }

  useEffect(() => {
    if (building) {
      for (const [key, value] of Object.entries(building?.ventilationSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          ventilation.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.heatingSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          heating.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.drainageSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          drainageSystem.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.rainwaterSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          rainwaterSystem.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.propertyWaterSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          propertyWater.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.waterPipeSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          waterPipe.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.warmWaterSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          warmWater.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.wasteManagementSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'container') {
            wasteManagement.current[key] = building?.wasteManagementSystem?.[key]?.length ? 
              building?.wasteManagementSystem?.[key]?.split(',') :
              []
          } else {
            wasteManagement.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(building?.elevatorSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          elevator.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.mainDistributionSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          mainDistribution.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.connectionAndProductionSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          connectionAndProduction.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.installationAndAuxiliarySystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          installationAndAuxiliarySystem.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.connectionSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          connectionSystem.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.lightingSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          if (key === 'interiorLightingTypes') {
            lighting.current[key] = building?.lightingSystem?.[key]?.length ? 
              building?.lightingSystem?.[key]?.split(',') :
              []
          } else {
            lighting.current[key] = value
          }
        }
      }
      for (const [key, value] of Object.entries(building?.groundingSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          grounding.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.communicationAndDataNetworkSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          communicationAndDataNetwork.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.fireSafetySystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          fireSafety.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.propertySewerageSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          propertySewerage.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.sewerPipeSystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          sewerPipe.current[key] = value
        }
      }
      for (const [key, value] of Object.entries(building?.wasteWaterHeatRecoverySystem || {})) {
        if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
          wasteWaterHeatRecovery.current[key] = value
        }
      }
      if (building?.property?.type !== 'housing') {
        for (const [key, value] of Object.entries(building?.securityMonitoringSystem || {})) {
          if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
            securityMonitoring.current[key] = value
          }
        }
        for (const [key, value] of Object.entries(building?.industrialWasteRemovalAndPurificationSystem || {})) {
          if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
            if (key === 'maintenanceCompanies') {
              const companies = building?.industrialWasteRemovalAndPurificationSystem?.[key]?.length ?
                building?.industrialWasteRemovalAndPurificationSystem?.[key]?.map((company: any) => ({
                  uuid: company?.uuid || uuidv4(),
                  company: company?.company || '',
                  contactPersonName: company?.contactPersonName || '',
                  contactPersonPhone: company?.contactPersonPhone || '',
                  contactPersonEmail: company?.contactPersonEmail || '',
                  companyAddress: company?.companyAddress || '',
                  companyPostalCode: company?.companyPostalCode || '',
                  companyCity: company?.companyCity || '',
                  contractLink: company?.contractLink || ''
                })) :
                [{
                  uuid: uuidv4(),
                  company: '',
                  contactPersonName: '',
                  contactPersonPhone: '',
                  contactPersonEmail: '',
                  companyAddress: '',
                  companyPostalCode: '',
                  companyCity: '',
                  contractLink: ''
                }]
              industrialWasteRemovalAndPurification.current[key] = companies
            } else if (key === 'transportCompanies') {
              const companies = building?.industrialWasteRemovalAndPurificationSystem?.[key]?.length ?
                building?.industrialWasteRemovalAndPurificationSystem?.[key]?.map((company: any) => ({
                  uuid: company?.uuid || uuidv4(),
                  company: company?.company || '',
                  contactPersonName: company?.contactPersonName || '',
                  contactPersonPhone: company?.contactPersonPhone || '',
                  contactPersonEmail: company?.contactPersonEmail || '',
                  companyAddress: company?.companyAddress || '',
                  companyPostalCode: company?.companyPostalCode || '',
                  companyCity: company?.companyCity || '',
                  contractLink: company?.contractLink || ''
                })) :
                [{
                  uuid: uuidv4(),
                  company: '',
                  contactPersonName: '',
                  contactPersonPhone: '',
                  contactPersonEmail: '',
                  companyAddress: '',
                  companyPostalCode: '',
                  companyCity: '',
                  contractLink: '',
                  isNew: true
                }]
              industrialWasteRemovalAndPurification.current[key] = companies
            } else {
              industrialWasteRemovalAndPurification.current[key] = value
            }
          }
        }
        for (const [key, value] of Object.entries(building?.energyRecoveryAndRecyclingSystem || {})) {
          if (!['id', 'uuid', 'createdAt', 'updatedAt'].includes(key)) {
            energyRecoveryAndRecycling.current[key] = value
          }
        }
      }

      if (!industrialWasteRemovalAndPurification.current?.maintenanceCompanies?.length) {
        industrialWasteRemovalAndPurification.current = {
          ...industrialWasteRemovalAndPurification.current,
          maintenanceCompanies: [{
            uuid: uuidv4(),
            company: '',
            contactPersonName: '',
            contactPersonPhone: '',
            contactPersonEmail: '',
            companyAddress: '',
            companyPostalCode: '',
            companyCity: '',
            contractLink: '',
            isNew: true
          }]
        }
      }
      if (!industrialWasteRemovalAndPurification.current?.transportCompanies?.length) {
        industrialWasteRemovalAndPurification.current = {
          ...industrialWasteRemovalAndPurification.current,
          transportCompanies: [{
            uuid: uuidv4(),
            company: '',
            contactPersonName: '',
            contactPersonPhone: '',
            contactPersonEmail: '',
            companyAddress: '',
            companyPostalCode: '',
            companyCity: '',
            contractLink: '',
            isNew: true
          }]
        }
      }

      setLastUpdatedValue(DateTime.now().toISO())
    }
  }, [building])

  const onSave = () => {
    const updatedBuilding = {
      ventilationSystem: ventilation.current,
      heatingSystem: heating.current,
      drainageSystem: drainageSystem.current,
      rainwaterSystem: rainwaterSystem.current,
      propertyWaterSystem: propertyWater.current,
      waterPipeSystem: waterPipe.current,
      warmWaterSystem: warmWater.current,
      wasteManagementSystem: {
        ...wasteManagement.current,
        container: wasteManagement.current?.container?.join(',')
      },
      elevatorSystem: elevator.current,
      mainDistributionSystem: mainDistribution.current,
      connectionAndProductionSystem: connectionAndProduction.current,
      installationAndAuxiliarySystem: installationAndAuxiliarySystem.current,
      connectionSystem: connectionSystem.current,
      lightingSystem: {
        ...lighting.current,
        interiorLightingTypes: lighting.current?.interiorLightingTypes?.join(',')
      },
      groundingSystem: grounding.current,
      communicationAndDataNetworkSystem: communicationAndDataNetwork.current,
      fireSafetySystem: fireSafety.current,
      propertySewerageSystem: propertySewerage.current,
      sewerPipeSystem: sewerPipe.current,
      wasteWaterHeatRecoverySystem: wasteWaterHeatRecovery.current,
      securityMonitoringSystem: securityMonitoring.current,
      industrialWasteRemovalAndPurificationSystem: {
        ...industrialWasteRemovalAndPurification.current,
        maintenanceCompanies: industrialWasteRemovalAndPurification.current
          ?.maintenanceCompanies
          ?.filter((company: any) => !company?.isNew || company?.company)
          ?.map((company: any) => ({ ...company, isNew: undefined })),
        transportCompanies: industrialWasteRemovalAndPurification.current
          ?.transportCompanies
          ?.filter((company: any) => !company?.isNew || company?.company)
          ?.map((company: any) => ({ ...company, isNew: undefined }))
      },
      energyRecoveryAndRecyclingSystem: energyRecoveryAndRecycling.current
    }
    buildingStore.updateBuildingSystems(props?.uuid, updatedBuilding)
  }

  useImperativeHandle(
    props?.saveRef,
    () => ({ onSave }),
    []
  )

  // Collapse state toggles
  const toggleOpenSection = (section: string) => {
    if (openSections.includes(section)) {
      setOpenSections(openSections.filter((item: string) => item !== section))
    } else {
      setOpenSections([...openSections, section])
    }
  }

  const onChange = (category: string, key: string, value: any, uuid?: string) => {
    if (category === BuildingSections.Ventilation) {
      ventilation.current = { ...(ventilation?.current || {}), [key]: value }
    } else if (category === BuildingSections.Heating) {
      if (key === 'type' && value !== heating?.current?.type) {
        heating.current = {
          ...heating?.current,
          type: value,
          heatDistributionType: null,
        }
        setTimeout(() => setLastUpdatedValue('heatDistributionType_null'), 150)
      } else {
        heating.current = { ...(heating?.current || {}), [key]: value }
      }
    } else if (category === BuildingSections.DrainageSystem) {
      drainageSystem.current = { ...(drainageSystem?.current || {}), [key]: value }
    } else if (category === BuildingSections.RainwaterSystem) {
      rainwaterSystem.current = { ...(rainwaterSystem?.current || {}), [key]: value }
    } else if (category === BuildingSections.PropertyWater) {
      propertyWater.current = { ...(propertyWater?.current || {}), [key]: value }
    } else if (category === BuildingSections.WaterPipe) {
      waterPipe.current = { ...(waterPipe?.current || {}), [key]: value }
    } else if (category === BuildingSections.WarmWater) {
      warmWater.current = { ...(warmWater?.current || {}), [key]: value }
    } else if (category === BuildingSections.WasteManagement) {
      wasteManagement.current = { ...(wasteManagement?.current || {}), [key]: value }
    } else if (category === BuildingSections.Elevator) {
      elevator.current = { ...(elevator?.current || {}), [key]: value }
    } else if (category === BuildingSections.MainDistribution) {
      mainDistribution.current = { ...(mainDistribution?.current || {}), [key]: value }
    } else if (category === BuildingSections.ConnectionAndProduction) {
      connectionAndProduction.current = { ...(connectionAndProduction?.current || {}), [key]: value }
    } else if (category === BuildingSections.InstallationAndAuxiliarySystem) {
      installationAndAuxiliarySystem.current = { ...(installationAndAuxiliarySystem?.current || {}), [key]: value }
    } else if (category === BuildingSections.ConnectionSystem) {
      connectionSystem.current = { ...(connectionSystem?.current || {}), [key]: value }
    } else if (category === BuildingSections.Lighting) {
      lighting.current = { ...(lighting?.current || {}), [key]: value }
    } else if (category === BuildingSections.Grounding) {
      grounding.current = { ...(grounding?.current || {}), [key]: value }
    } else if (category === BuildingSections.CommunicationAndDataNetwork) {
      communicationAndDataNetwork.current = { ...(communicationAndDataNetwork?.current || {}), [key]: value }
    } else if (category === BuildingSections.FireSafety) {
      fireSafety.current = { ...(fireSafety?.current || {}), [key]: value }
    } else if (category === BuildingSections.PropertySewerage) {
      propertySewerage.current = { ...(propertySewerage?.current || {}), [key]: value }
    } else if (category === BuildingSections.SewerPipe) {
      sewerPipe.current = { ...(sewerPipe?.current || {}), [key]: value }
    } else if (category === BuildingSections.WasteWaterHeatRecovery) {
      wasteWaterHeatRecovery.current = { ...(wasteWaterHeatRecovery?.current || {}), [key]: value }
    } else if (category === BuildingSections.SecurityMonitoring) {
      securityMonitoring.current = { ...(securityMonitoring?.current || {}), [key]: value }
    } else if (category === BuildingSections.IndustrialWasteRemovalAndPurification) {
      if (key?.includes('maintenanceCompanies')) {
        const itemKey = key?.split('_')?.[1]
        industrialWasteRemovalAndPurification.current = {
          ...(industrialWasteRemovalAndPurification?.current || {}),
          ['maintenanceCompanies']: industrialWasteRemovalAndPurification?.current?.['maintenanceCompanies']?.map((company: any) => {
            if (company?.uuid === uuid) {
              return { ...company, [itemKey]: value }
            }
            return company
          })
        }
      } else if (key?.includes('transportCompanies')) {
        const itemKey = key?.split('_')?.[1]
        industrialWasteRemovalAndPurification.current = {
          ...(industrialWasteRemovalAndPurification?.current || {}),
          ['transportCompanies']: industrialWasteRemovalAndPurification?.current?.['transportCompanies']?.map((company: any) => {
            if (company?.uuid === uuid) {
              return { ...company, [itemKey]: value }
            }
            return company
          }
        )}
      } else {
        industrialWasteRemovalAndPurification.current = { ...(industrialWasteRemovalAndPurification?.current || {}), [key]: value }
      }
    } else if (category === BuildingSections.EnergyRecoveryAndRecycling) {
      energyRecoveryAndRecycling.current = { ...(energyRecoveryAndRecycling?.current || {}), [key]: value }
    } else {
      console.log('Unknown category', category)
    }
    setLastUpdatedValue(`${key}_${value}`)
    if (!hasUnsavedChanges) {
      buildingStore.setHasUnsavedChanges(true)
    }
  }

  const getValues = useCallback((category: string) => {
    if (category === BuildingSections.Ventilation) {
      return ventilation?.current || {}
    } else if (category === BuildingSections.Heating) {
      return heating?.current || {}
    } else if (category === BuildingSections.DrainageSystem) {
      return drainageSystem?.current || {}
    } else if (category === BuildingSections.RainwaterSystem) {
      return rainwaterSystem?.current || {}
    } else if (category === BuildingSections.PropertyWater) {
      return propertyWater?.current || {}
    } else if (category === BuildingSections.WaterPipe) {
      return waterPipe?.current || {}
    } else if (category === BuildingSections.WarmWater) {
      return warmWater?.current || {}
    } else if (category === BuildingSections.WasteManagement) {
      return wasteManagement?.current || {}
    } else if (category === BuildingSections.Elevator) {
      return elevator?.current || {}
    } else if (category === BuildingSections.MainDistribution) {
      return mainDistribution?.current || {}
    } else if (category === BuildingSections.ConnectionAndProduction) {
      return connectionAndProduction?.current || {}
    } else if (category === BuildingSections.InstallationAndAuxiliarySystem) {
      return installationAndAuxiliarySystem?.current || {}
    } else if (category === BuildingSections.ConnectionSystem) {
      return connectionSystem?.current || {}
    } else if (category === BuildingSections.Lighting) {
      return lighting?.current || {}
    } else if (category === BuildingSections.Grounding) {
      return grounding?.current || {}
    } else if (category === BuildingSections.CommunicationAndDataNetwork) {
      return communicationAndDataNetwork?.current || {}
    } else if (category === BuildingSections.FireSafety) {
      return fireSafety?.current || {}
    } else if (category === BuildingSections.PropertySewerage) {
      return propertySewerage?.current || {}
    } else if (category === BuildingSections.SewerPipe) {
      return sewerPipe?.current || {}
    } else if (category === BuildingSections.WasteWaterHeatRecovery) {
      return wasteWaterHeatRecovery?.current || {}
    } else if (category === BuildingSections.SecurityMonitoring) {
      return securityMonitoring?.current || {}
    } else if (category === BuildingSections.IndustrialWasteRemovalAndPurification) {
      return industrialWasteRemovalAndPurification?.current || {}
    } else if (category === BuildingSections.EnergyRecoveryAndRecycling) {
      return energyRecoveryAndRecycling?.current || {}
    }
  }, [
    ventilation,
    heating,
    drainageSystem,
    rainwaterSystem,
    propertyWater,
    waterPipe,
    warmWater,
    wasteManagement,
    elevator,
    mainDistribution,
    connectionAndProduction,
    installationAndAuxiliarySystem,
    connectionSystem,
    lighting,
    grounding,
    communicationAndDataNetwork,
    fireSafety,
    propertySewerage,
    sewerPipe,
    wasteWaterHeatRecovery,
    securityMonitoring,
    industrialWasteRemovalAndPurification,
    energyRecoveryAndRecycling
  ])

  const renderCategories = () => {
    const categories = Object.values(
      building?.property?.type === 'housing' ?
        HousingSystemCategories :
        SystemCategories
    )
    return categories.map((cat: string) => {
      return (
        <Button
          key={`${cat}`}
          text={t(cat)}
          onClick={() => setCategory(cat as SystemCategories)}
          sx={category === cat ? styles.activeCategory : styles.category}
          mr='1.25rem'
          mb='1.25rem'
        />
      )
    })
  }

  const renderCustomForm = (sections: any, title: string, index: number) => {
    if (sections?.length) {
      // Extract values from the current category
      const values = getValues(title)
      // Filter out items that should not be visible
      let items = sections.map((section: any) => {
        if (!section?.visibleIfValues && !section?.items && !section?.items?.find((item: any) => item?.visibleIfValues)) {
          // If no visibleIfValues are defined, show the item
          return section
        }
        if (!section?.visibleIfValues) {
          if (section?.items) {
            if (title === BuildingSections.WasteManagement) {
              return {
                ...section,
                // Filter out items that should not be visible
                items: section?.items
                  ?.filter((item: any) => {
                  if (!item?.visibleIfValues) {
                    return true
                  }
                  return item?.visibleIfValues?.every((visibleIfValue: any) => {
                    if (Array.isArray(values?.[visibleIfValue.key])) {
                      return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
                    }
                    // const values = getValues(visibleIfValue.key?.split('_')[0])
                    return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
                  })
                })
                ?.map((item: any) => {
                  if (!item?.disabledIfValues) {
                    return item
                  }
                  return {
                    ...item,
                    disabled: item?.disabledIfValues?.every((disabledIfValue: any) => {
                      if (Array.isArray(values?.[disabledIfValue.key])) {
                        return disabledIfValue.values?.some((value: any) => values?.[disabledIfValue.key]?.includes(value))
                      }
                      return disabledIfValue.values?.includes(values?.[disabledIfValue.key])
                    })
                  }
                })
              }
            }
            return {
              ...section,
              // Filter out items that should not be visible
              items: section?.items?.filter((item: any) => {
                if (!item?.visibleIfValues) {
                  return true
                }
                return item?.visibleIfValues?.every((visibleIfValue: any) => {
                  if (Array.isArray(values?.[visibleIfValue.key])) {
                    return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
                  }
                  // const values = getValues(visibleIfValue.key?.split('_')[0])
                  return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
                })
              })
            }
          }
        }
        // Check if all visibleIfValues are met
        const conditionsMet = section?.visibleIfValues?.every((visibleIfValue: any) => {
          if (Array.isArray(values?.[visibleIfValue.key])) {
            return visibleIfValue.values?.some((value: any) => values?.[visibleIfValue.key]?.includes(value))
          }
          // const values = getValues(visibleIfValue.key?.split('_')[0])
          return visibleIfValue.values?.includes(values?.[visibleIfValue.key])
        })
        if (conditionsMet) {
          return section
        }
        return null
      }).filter((item: any) => item)

      if (title === 'fire_safety' && building?.property?.type === 'housing') {
        items = items.filter((item: any) => {
          return !item?.hiddenForPropertyTypes || !item?.hiddenForPropertyTypes?.includes('housing')
        })
      }

      if (items?.length === 1 && items[0]?.type === ComponentTypes.Subtitle) {
        return null
      }
      if (!items?.length) {
        return null
      }
      if (items?.[0]?.type === ComponentTypes.SectionArray) {
        const itemKey = items?.[0]?.key
        return values?.[itemKey]?.map((sectionItemValues: any, index: number) => (
          <CustomForm
            key={(items?.[0]?.key || items?.[0]?.label || index) + index}
            section={items?.[0]?.items || []}
            values={sectionItemValues}
            onChange={(key: string, value: string) => onChange(title, `${itemKey}_${key}`, value, sectionItemValues?.uuid)}
            lastUpdatedValue={lastUpdatedValue}
            disabled={!isAdmin}
            itemIndex={index + 1}
            isOnlyItem={values?.[itemKey]?.length === 1}
            addItemText={t('add_new')}
            onAddItem={isAdmin ? (itemKey === 'maintenanceCompanies' ? onAddMaintenanceCompany : onAddTransportCompany) : undefined}
            archiveItemText={t('delete')}
            onArchiveItem={isAdmin ? () => {
              if (itemKey === 'maintenanceCompanies') {
                onArchiveMaintenanceCompany(sectionItemValues?.uuid)
              } else {
                onArchiveTransportCompany(sectionItemValues?.uuid)
              }
            } : undefined}
          />
        ))
      }
      return (
        <CustomForm
          key={`form-${title}-${index}`}
          section={items}
          values={values}
          onChange={(key: string, value: string) => onChange(title, key, value)}
          lastUpdatedValue={lastUpdatedValue}
          disabled={!isAdmin}
          hasDisabledValues={title === BuildingSections.WasteManagement}
        />
      )
    }
    return null
  }

  const renderCustomFormContainer = (sections: any, title: string): any => {
    return sections.map((section: any, index: number) => renderCustomForm(section, title, index))
  }

  const BuildingSystems = useMemo(() => {
    return BuildingSystemsLayout.map((layout: any) => {
      if (
        !layout?.sections?.length ||
        !category ||
        !SystemCategoryMappings?.[category]?.some((item: any) => item === layout.title)
      ) {
        return null
      }
      return (
        <CollapseSection
          key={`section-${layout.title}`}
          title={t(layout?.label ?? layout.title)}
          open={openSections?.includes(layout.title)}
          onClick={() => toggleOpenSection(layout.title)}
        >
          {renderCustomFormContainer(layout.sections, layout.title)}
        </CollapseSection>
      )
    })
  }, [openSections, lastUpdatedValue, category])

  // Do not show anything if params are missing
  const uuid = props?.uuid || null
  if (!uuid || uuid?.length !== 36) {
    return <CircularProgress sx={styles.loading} />
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftColumn}>
        <Box sx={styles.categoryContainer}>{renderCategories()}</Box>
        {BuildingSystems}
      </Box>
    </Box>
  )
}

export default observer(BuildingSystems)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'hidden'
  },
  categoryContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '0.5rem',
    borderBottom: `2px solid ${Colors.secondary}`,
    marginBottom: '0.5rem'
  },
  category: {
    height: '3rem',
    backgroundColor: Colors.white,
    color: Colors.primary,
    fontSize: '1rem',
    textTransform: 'uppercase',
    border: `3px solid ${Colors.primary}`,
    '&:hover': {
      backgroundColor: Colors.primary,
      color: Colors.white
    }
  },
  activeCategory: {
    height: '3rem',
    fontSize: '1rem',
    textTransform: 'uppercase',
    backgroundColor: Colors.primary,
    border: `3px solid ${Colors.primary}`,
    '&:hover': {
      backgroundColor: Colors.primary90,
      color: Colors.white
    }
  },
  loading: {
    margin: 'auto'
  }
} as const
