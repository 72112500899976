import { useMemo, useState, useEffect, useRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import flatten from 'lodash/flatten'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { GridRenderCellParams } from '@mui/x-data-grid'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import Lightbox from '../../../Components/Common/Lightbox'
import TableActions from '../../../Components/Common/TableActions'
import TextEditor from '../../../Components/Common/TextEditor'
import PropertyAreas from './PropertyAreas'
import CreateBuildingModal from '../../../Components/Common/CreateBuildingModal'
import CreateApartmentModal from '../../../Components/Common/CreateApartmentModal'
import CheckboxMultiSelect from '../../../Components/Common/CheckboxMultiSelect'
import ReminderModal from '../../../Components/Common/ReminderModal'
import SidebarActions from '../../../Components/Common/SidebarActions'
import SidebarReminders from '../../../Components/Common/SidebarReminders'
import IconButton from '../../../Components/Common/IconButton'
import NavigationGuard from '../../../Components/Common/NavigationGuard'
import HousingPropertyImage from '../../../Assets/Images/asoy.png'
import IndustrialPropertyImage from '../../../Assets/Images/teollisuustila.png'
import BusinessPropertyImage from '../../../Assets/Images/liiketila.png'
import { Button, CoverImage, Tabs, Tab, Table, Input, Select, MultiSelect, Chip } from '../../../Components'
import { BusinessSpaceTypes, FinnishMunicipalities, IndustrialSpaceTypes, PropertySpacesAndAreas, PropertyTabs, UserRoles } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'
import { YearOptions } from '../../../Utils/layout'
import {
  BuildingPartsOptions,
  BuildingStructuresOptions,
  BuildingSystemsOptions,
  BuildingTypeOptions
} from '../../../Utils/building'
import { ApartmentTypes } from '../../../Utils/apartment'
import { validateBusinessId } from '../../../Utils/validators'
import { showMessage } from '../../../Utils/message'
import { AreaSectionOptions } from '../../../Utils/area'
import { RoomSectionOptions } from '../../../Utils/room'
import {
  OwnershipOptions,
  RentPaymentIntervalOptions,
  InsuranceCompanyOptions,
  InsuranceTypeOptions
} from '../../../Utils/options'

const Property = () => {
  const {
    propertyStore,
    buildingStore,
    apartmentStore,
    sessionStore,
    reminderStore,
    userStore
  }: any = useStore()
  const { isAdmin } = sessionStore
  const { property, entities, loading, hasUnsavedChanges } = propertyStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [searchParams, setSearchParams]: any = useSearchParams()
  const [showHasUnsavedChanges, setShowHasUnsavedChanges] = useState<PropertyTabs | null>(null)

  const setUnsavedChanges = () => hasUnsavedChanges ?
    undefined :
    propertyStore.setHasUnsavedChanges(true)

  const savePropertyDataRef = useRef<any>(null)

  const [tab, setTab] = useState<PropertyTabs | null>(PropertyTabs.BasicInformation)
  const [selectedImage, setSelectedImage] = useState(false)
  const [remindersOpen, setRemindersOpen] = useState(true)
  const [reminder, setReminder] = useState<any>(null)

  const updateTab = (to: PropertyTabs | null) => {
    if (hasUnsavedChanges) {
      setShowHasUnsavedChanges(to)
    } else {
      setTab(to)
    }
  }

  const hideShowHasUnsavedChanges = (to?: PropertyTabs | null) => {
    if (to) {
      propertyStore.setHasUnsavedChanges(false)
      setShowHasUnsavedChanges(null)
      setTab(to)
    } else {
      setShowHasUnsavedChanges(null)
    }
  }

  const openRemindersTab = () => updateTab(PropertyTabs.Reminders)
  const toggleOpenReminders = () => setRemindersOpen(!remindersOpen)
  const openReminder = (reminder: any) => {
    setReminder(reminder)
    propertyStore.getPropertyEntities(params.uuid)
  }
  const closeReminder = () => setReminder(null)

  // Property basic information (editable)
  const [nameFi, setNameFi] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [registrationDate, setRegistrationDate] = useState('')
  const [area, setArea] = useState('')
  const [ownershipType, setOwnershipType] = useState('')
  const [spaceOwnershipType, setSpaceOwnershipType] = useState('')
  const [plotOwnershipType, setPlotOwnershipType] = useState('')
  const [hasMultipleProperties, setHasMultipleProperties] = useState(false)
  const [propertyCount, setPropertyCount] = useState('')
  const [propertyId, setPropertyId] = useState('')
  const [address, setAddress] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')

  // Rent information
  const [propertyRentCosts, setPropertyRentCosts] = useState(null)
  const [spaceRentCosts, setSpaceRentCosts] = useState(null)
  const [plotRentCosts, setPlotRentCosts] = useState(null)
  const [maintenanceCompanies, setMaintenanceCompanies] = useState([])
  const [landlords, setLandlords] = useState([])

  // Insurances
  const [insurances, setInsurances] = useState<any>([])
  const [insuranceCompanies, setInsuranceCompanies] = useState([])

  // Property admin
  const [propertyAdmin, setPropertyAdmin] = useState('')

  // Board information
  const [companyBoard, setCompanyBoard] = useState({
    member1Name: '',
    member1Email: '',
    member1Phone: '',
    member2Name: '',
    member2Email: '',
    member2Phone: '',
    member3Name: '',
    member3Email: '',
    member3Phone: '',
    areasOfResponsibility: ''
  })

  // Filters
  const [buildingFilter, setBuildingFilter] = useState('')
  const [apartmentFilter, setApartmentFilter] = useState('')
  const [reminderFilter, setReminderFilter] = useState('upcoming')

  // Property building information

  // The following fields are aggregate, non-editable data
  const [buildingTypes, setBuildingTypes] = useState('')
  const [buildingCount, setBuildingCount] = useState('')
  const [buildingCommissioningYears, setBuildingCommissioningYears] = useState([])

  // Property apartment, space & area information

  // The following fields are aggregate, non-editable data
  const [otherSpaceCount, setOtherSpaceCount] = useState('')

  // The following fields are editable data
  const [apartmentCount, setApartmentCount] = useState('')
  const [apartmentArea, setApartmentArea] = useState('')
  const [commercialSpaceCount, setCommercialSpaceCount] = useState('')
  const [commercialSpaceArea, setCommercialSpaceArea] = useState('')
  const [otherSpaceArea, setOtherSpaceArea] = useState('')
  const [spaceTypes, setSpaceTypes] = useState<any>([])
  const [spaceTypesInfo, setSpaceTypesInfo] = useState('')

  // Property parking information (editable)
  const [parkingSpaceCount, setParkingSpaceCount] = useState('')

  // Maintenance information (editable)
  const [hasMaintenanceReport, setHasMaintenanceReport] = useState(null)
  const [hasMaintenancePlan, setHasMaintenancePlan] = useState(null)
  const [pastMaintenanceInfo, setPastMaintenanceInfo] = useState('')
  const [plannedMaintenanceInfo, setPlannedMaintenanceInfo] = useState('')

  // Energy information (editable)
  const [hasValidEnergyCertificate, setHasValidEnergyCertificate] = useState(null)
  const [energyCertificateStartYear, setEnergyCertificateStartYear] = useState('')
  const [energyCertificateEndYear, setEnergyCertificateEndYear] = useState('')

  const updateEnergyCertificateStartYear = (year: string) => {
    if (year !== energyCertificateStartYear) {
      setEnergyCertificateStartYear(year)
      setEnergyCertificateEndYear('')
    }
  }
  
  // Property management information (editable)
  const [propertyManagerName, setPropertyManagerName] = useState('')
  const [propertyManagerCompany, setPropertyManagerCompany] = useState('')
  const [propertyManagerPhone, setPropertyManagerPhone] = useState('')
  const [propertyManagerEmail, setPropertyManagerEmail] = useState('')
  const [propertyManagerAddress, setPropertyManagerAddress] = useState('')
  const [propertyManagerPostalCode, setPropertyManagerPostalCode] = useState('')
  const [propertyManagerCity, setPropertyManagerCity] = useState('')
  const [propertyManagerResponsibilities, setPropertyManagerResponsibilities] = useState('')

  // Maintenance company information (editable)
  const [maintenanceCompanyContactPersonName, setMaintenanceCompanyContactPersonName] = useState('')
  const [maintenanceCompanyName, setMaintenanceCompanyName] = useState('')
  const [maintenanceCompanyPhone, setMaintenanceCompanyPhone] = useState('')
  const [maintenanceCompanyEmail, setMaintenanceCompanyEmail] = useState('')
  const [maintenanceCompanyAddress, setMaintenanceCompanyAddress] = useState('')
  const [maintenanceCompanyPostalCode, setMaintenanceCompanyPostalCode] = useState('')
  const [maintenanceCompanyCity, setMaintenanceCompanyCity] = useState('')
  const [maintenanceCompanyMoveNotificationUrl, setMaintenanceCompanyMoveNotificationUrl] = useState('')
  const [maintenanceCompanyResponsibilities, setMaintenanceCompanyResponsibilities] = useState('')

  // Security company information (editable)
  const [securityCompanyContactPersonName, setSecurityCompanyContactPersonName] = useState('')
  const [securityCompanyName, setSecurityCompanyName] = useState('')
  const [securityCompanyPhone, setSecurityCompanyPhone] = useState('')
  const [securityCompanyEmail, setSecurityCompanyEmail] = useState('')
  const [securityCompanyAddress, setSecurityCompanyAddress] = useState('')
  const [securityCompanyPostalCode, setSecurityCompanyPostalCode] = useState('')
  const [securityCompanyCity, setSecurityCompanyCity] = useState('')
  
  // Confirmation dialog (property)
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')
  const [actionTarget, setActionTarget] = useState<any>(null)

  const openCreateEntityDialog = (entity: string) => {
    if (entity === 'building') {
      setActionType('create_building')
    } else if (entity === 'apartment') {
      setActionType('create_apartment')
    } else if (entity === 'reminder') {
      setActionType('create_reminder')
      propertyStore.getPropertyEntities(params.uuid)
    }
  }
  const closeEntityDialog = () => setActionType('')

  const openArchivePropertyConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive_property')
  }
  const openArchiveReminderConfirmation = (reminder: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_reminder')
    setActionTarget(reminder)
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
    setActionTarget(null)
  }

  const openPropertyBackgroundPhoto = () => setSelectedImage(property?.backgroundPhoto)
  const clearSelectedImage = () => setSelectedImage(false)

  const updateSpaceTypes = (space: string) => {
    if (spaceTypes?.includes(space)) {
      setSpaceTypes(spaceTypes.filter((item: string) => item !== space))
    } else {
      setSpaceTypes([...spaceTypes, space])
    }
  }

  const updateRentCosts = (type: string, field: string, value: string) => {
    if (type === 'property') {
      setPropertyRentCosts({ ...propertyRentCosts, [field]: value })
    } else if (type === 'space') {
      setSpaceRentCosts({ ...spaceRentCosts, [field]: value })
    } else if (type === 'plot') {
      setPlotRentCosts({ ...plotRentCosts, [field]: value })
    }
  }

  const addNewInsurance = () => {
    setInsurances([
      ...insurances,
      {
        uuid: uuidv4(),
        type: '',
        company: '',
        number: '',
      }
    ])
  }
  const updateInsurance = (uuid: string, field: string, value: string) => {
    const updatedInsurances = insurances.map((item: any) => {
      if (item.uuid === uuid) {
        return { ...item, [field]: value }
      }
      return item
    })
    setInsurances(updatedInsurances)
  }
  const removeInsurance = (uuid: string) => {
    const updatedInsurances = insurances.filter((item: any) => item.uuid !== uuid)
    setInsurances(updatedInsurances)
  }

  const addNewInsuranceCompany = () => {
    setInsuranceCompanies([
      ...insuranceCompanies,
      {
        uuid: uuidv4(),
        company: '',
        contactPersonName: '',
        phone: '',
        email: '',
        address: '',
        postalCode: '',
        city: ''
      }
    ])
  }
  const updateInsuranceCompany = (uuid: string, field: string, value: string) => {
    const updatedCompanies = insuranceCompanies.map((item: any) => {
      if (item.uuid === uuid) {
        return { ...item, [field]: value }
      }
      return item
    })
    setInsuranceCompanies(updatedCompanies)
  }
  const removeInsuranceCompany = (uuid: string) => {
    const updatedCompanies = insuranceCompanies.filter((item: any) => item.uuid !== uuid)
    setInsuranceCompanies(updatedCompanies)
  }

  const addNewLandlord = () => {
    setLandlords([
      ...landlords,
      {
        uuid: uuidv4(),
        contactPersonName: '',
        company: '',
        phone: '',
        email: '',
        address: '',
        postalCode: '',
        city: ''
      }
    ])
  }
  const updateLandlord = (uuid: string, field: string, value: string) => {
    const updatedLandlords = landlords.map((item: any) => {
      if (item.uuid === uuid) {
        return { ...item, [field]: value }
      }
      return item
    })
    setLandlords(updatedLandlords)
  }
  const removeLandlord = (uuid: string) => {
    const updatedLandlords = landlords.filter((item: any) => item.uuid !== uuid)
    setLandlords(updatedLandlords)
  }

  const addNewMaintenanceCompany = () => {
    setMaintenanceCompanies([
      ...maintenanceCompanies,
      {
        uuid: uuidv4(),
        contactPersonName: '',
        company: '',
        phone: '',
        email: '',
        address: '',
        postalCode: '',
        city: '',
        responsibilities: ''
      }
    ])
  }
  const updateMaintenanceCompany = (uuid: string, field: string, value: string) => {
    const updatedMaintenanceCompanies = maintenanceCompanies.map((item: any) => {
      if (item.uuid === uuid) {
        return { ...item, [field]: value }
      }
      return item
    })
    setMaintenanceCompanies(updatedMaintenanceCompanies)
  }
  const removeMaintenanceCompany = (uuid: string) => {
    const updatedMaintenanceCompanies = maintenanceCompanies.filter((item: any) => item.uuid !== uuid)
    setMaintenanceCompanies(updatedMaintenanceCompanies)
  }

  const updateCompanyBoard = (field: string, value: string) => {
    setCompanyBoard({ ...companyBoard, [field]: value })
  }

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      propertyStore.getProperty(uuid)
      if (isAdmin) {
        reminderStore.getPropertyReminders(params?.uuid)
        userStore.getUsers()
      }
    }
  }, [])

  useEffect(() => {
    const uuid = params?.uuid || null
    if (uuid && uuid?.length === 36) {
      if (property?.uuid === uuid) {
        setNameFi(property?.nameFi || '')
        setBusinessId(property?.businessId || '')
        setRegistrationDate(property?.registrationDate || '')
        setArea(property?.area || '')
        setOwnershipType(property?.ownershipType || '')
        setSpaceOwnershipType(property?.spaceOwnershipType || '')
        setPlotOwnershipType(property?.plotOwnershipType || '')
        setHasMultipleProperties(property?.hasMultipleProperties || false)
        setPropertyCount(property?.hasMultipleProperties ? property?.propertyCount : 1)
        setPropertyId(property?.propertyId || '')
        setBuildingCommissioningYears(property?.buildingCommissioningYears || [])
        setAddress(property?.address || '')
        setPostalCode(property?.postalCode || '')
        setCity(property?.city || '')
        setPropertyRentCosts(property?.rentCosts?.property || null)
        setSpaceRentCosts(property?.rentCosts?.space || null)
        setPlotRentCosts(property?.rentCosts?.plot || null)
        if (property?.type !== 'housing') {
          setMaintenanceCompanies(property?.maintenanceCompanies || [])
        }
        setLandlords(property?.landlords || [])
        setInsurances(property?.insurances || [])
        setInsuranceCompanies(property?.insuranceCompanies || [])
        setPropertyAdmin(property?.propertyAdmin || '')
        setCompanyBoard(property?.companyBoard || '')
        const types = (property?.buildingTypes || []).map((type: any) => {
          if (BuildingTypeOptions.find((item: any) => item.value === type)) {
            return t(type)
          }
          return type
        })
        setBuildingTypes(types?.join(', '))
        setBuildingCount(property?.buildingCount || '')
        setApartmentCount(property?.apartmentCount || '')
        setApartmentArea(property?.apartmentArea || '')
        setCommercialSpaceCount(property?.commercialSpaceCount || '')
        setCommercialSpaceArea(property?.commercialSpaceArea || '')
        setOtherSpaceCount(property?.otherSpaceCount || '')
        setOtherSpaceArea(property?.otherSpaceArea || '')
        setSpaceTypes(property?.spaceTypes?.split(',') || [])
        setSpaceTypesInfo(property?.spaceTypesInfo || '')
        setParkingSpaceCount(property?.parkingSpaceCount || '')
        setHasMaintenanceReport(
          property?.hasMaintenanceReport === null ?
          null :
          property?.hasMaintenanceReport
        )
        setHasMaintenancePlan(
          property?.hasMaintenancePlan === null ?
          null :
          property?.hasMaintenancePlan
        )
        setPastMaintenanceInfo(property?.pastMaintenanceInfo || '')
        setPlannedMaintenanceInfo(property?.plannedMaintenanceInfo || '')
        setHasValidEnergyCertificate(
          property?.hasValidEnergyCertificate === null ?
          null :
          property?.hasValidEnergyCertificate
        )
        setEnergyCertificateStartYear(property?.energyCertificateStartYear || '')
        setEnergyCertificateEndYear(property?.energyCertificateEndYear || '')
        setPropertyManagerName(property?.propertyManagerName || '')
        setPropertyManagerCompany(property?.propertyManagerCompany || '')
        setPropertyManagerPhone(property?.propertyManagerPhone || '')
        setPropertyManagerEmail(property?.propertyManagerEmail || '')
        setPropertyManagerAddress(property?.propertyManagerAddress || '')
        setPropertyManagerPostalCode(property?.propertyManagerPostalCode || '')
        setPropertyManagerCity(property?.propertyManagerCity || '')
        setPropertyManagerResponsibilities(property?.propertyManagerResponsibilities || '')
        if (property?.type === 'housing') {
          setMaintenanceCompanyContactPersonName(property?.maintenanceCompanyContactPersonName || '')
          setMaintenanceCompanyName(property?.maintenanceCompanyName || '')
          setMaintenanceCompanyPhone(property?.maintenanceCompanyPhone || '')
          setMaintenanceCompanyEmail(property?.maintenanceCompanyEmail || '')
          setMaintenanceCompanyAddress(property?.maintenanceCompanyAddress || '')
          setMaintenanceCompanyPostalCode(property?.maintenanceCompanyPostalCode || '')
          setMaintenanceCompanyCity(property?.maintenanceCompanyCity || '')
          setMaintenanceCompanyMoveNotificationUrl(property?.maintenanceCompanyMoveNotificationUrl || '')
          setMaintenanceCompanyResponsibilities(property?.maintenanceCompanyResponsibilities || '')
          setSecurityCompanyContactPersonName(property?.securityCompanyContactPersonName || '')
        }
        setSecurityCompanyName(property?.securityCompanyName || '')
        setSecurityCompanyPhone(property?.securityCompanyPhone || '')
        setSecurityCompanyEmail(property?.securityCompanyEmail || '')
        setSecurityCompanyAddress(property?.securityCompanyAddress || '')
        setSecurityCompanyPostalCode(property?.securityCompanyPostalCode || '')
        setSecurityCompanyCity(property?.securityCompanyCity || '')
      }
    }
  }, [property])

  useEffect(() => {
    if (tab === PropertyTabs.BasicInformation) {
      setSearchParams({}, { replace: true })
      propertyStore.getProperty(params.uuid)
    } else if (tab === PropertyTabs.Buildings) {
      if (searchParams?.tab !== PropertyTabs.Buildings) {
        setSearchParams({ tab: PropertyTabs.Buildings }, { replace: true })
      }
      buildingStore.getBuildings(params?.uuid)
    } else if (tab === PropertyTabs.Spaces) {
      if (searchParams?.tab !== PropertyTabs.Spaces) {
        setSearchParams({ tab: PropertyTabs.Spaces }, { replace: true })
      }
      apartmentStore.getApartments(params?.uuid)
    } else if (tab === PropertyTabs.Areas) {
      if (searchParams?.tab !== PropertyTabs.Areas) {
        setSearchParams({ tab: PropertyTabs.Areas }, { replace: true })
      }
      propertyStore.getPropertyAreas(params?.uuid)
    } else if (tab === PropertyTabs.ContactInformation) {
      if (searchParams?.tab !== PropertyTabs.ContactInformation) {
        setSearchParams({ tab: PropertyTabs.ContactInformation }, { replace: true })
      }
      propertyStore.getProperty(params.uuid)
    } else if (tab === PropertyTabs.Reminders) {
      if (searchParams?.tab !== PropertyTabs.Reminders) {
        setSearchParams({ tab: PropertyTabs.Reminders }, { replace: true })
      }
      reminderStore.getPropertyReminders(params?.uuid)
    }
  }, [tab])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === PropertyTabs.Buildings) setTab(PropertyTabs.Buildings)
      else if (selectedTab === PropertyTabs.Spaces) setTab(PropertyTabs.Spaces)
      else if (selectedTab === PropertyTabs.Areas) setTab(PropertyTabs.Areas)
      else if (selectedTab === PropertyTabs.ContactInformation) setTab(PropertyTabs.ContactInformation)
      else if (selectedTab === PropertyTabs.Reminders) setTab(PropertyTabs.Reminders)
    }
  }, [location])

  useEffect(() => {
    if (reminderFilter !== 'upcoming') {
      reminderStore.getPropertyReminders(params?.uuid, { all: true })
    }
  }, [reminderFilter])

  const Title = useMemo(() => {
    if (tab === PropertyTabs.BasicInformation) {
      return t('edit_property')
    }
    return property?.nameFi || ''
  }, [tab, property])

  const PropertyTypeImage = useMemo(() => {
    if (property?.type) {
      const img = property?.type === 'housing' ?
        HousingPropertyImage :
        property?.type === 'industrial' ?
        IndustrialPropertyImage :
        property?.type === 'business' ?
        BusinessPropertyImage :
        null

      if (img) {
        return <Box component='img' src={img} sx={styles.propertyTypeImage} />
      }
    }
    return null
  }, [property])

  const BuildingRows = useMemo(() => {
    if (buildingFilter) {
      return buildingStore?.buildings?.filter((building: any) => building?.type === buildingFilter)
    }
    return buildingStore?.buildings
  }, [buildingStore?.buildings, buildingFilter])

  const ApartmentRows = useMemo(() => {
    if (apartmentFilter) {
      return apartmentStore?.apartments?.filter((apartment: any) => apartment?.type === apartmentFilter)
    }
    return apartmentStore?.apartments
  }, [apartmentStore?.apartments, apartmentFilter])

  const ReminderRows = useMemo(() => {
    const now = DateTime.now()
    if (reminderFilter === 'upcoming' || !reminderFilter) {
      // Filter out archived or past reminders
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        (DateTime.fromISO(reminder?.actionDate) > now ||
        reminder?.scheduledReminders?.some((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) > now
        }))
      })
    } else if (reminderFilter === 'past') {
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        DateTime.fromISO(reminder?.actionDate) < now &&
        reminder?.scheduledReminders?.every((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) < now
        })
      })
    } else if (reminderFilter === 'archived') {
      return reminderStore?.reminders?.filter((reminder: any) => reminder?.archivedAt)
    }
    return reminderStore?.reminders
  }, [reminderStore?.reminders, reminderFilter])

  const UpcomingReminderCount = useMemo(() => {
    return reminderStore?.reminders?.filter((reminder: any) => {
      return !reminder?.archivedAt &&
      DateTime.fromISO(reminder?.actionDate) > DateTime.now() &&
      reminder?.scheduledReminders?.some((item: any) => {
        return DateTime.fromISO(item?.scheduledAt) > DateTime.now()
      })
    })?.length
  }, [reminderStore?.reminders])

  const BuildingSectionOptions = useMemo(() => {
    const structures = BuildingStructuresOptions.map((item: any) => ({
      value: item.value,
      label: `${t('structures')} - ${t(item?.label || item?.value)}`
    }))
    const parts = BuildingPartsOptions.map((item: any) => ({
      value: item.value,
      label: `${t('building_parts')} - ${t(item?.label || item?.value)}`
    }))
    const systems = BuildingSystemsOptions.map((item: any) => ({
      value: item.value,
      label: `${t('systems')} - ${t(item?.label || item?.value)}`
    }))
    return [...structures, ...parts, ...systems]
  }, [])

  const BuildingFilterOptions = BuildingTypeOptions.map((item: any) => ({ value: item?.value, label: t(item?.label) }))
  const ApartmentFilterOptions = Object.values(
    property?.type === 'housing' ?
      ApartmentTypes :
      property?.type === 'industrial' ?
      IndustrialSpaceTypes :
      BusinessSpaceTypes
  ).map((item: any) => ({
    value: item,
    label: item === 'other' ?
      t('other_type') :
      property?.type !== 'housing' ?
      t(item) :
      item.split('+').join(' + ')
  }))
  const ReminderFilterOptions = [
    { value: 'upcoming', label: t('only_upcoming') },
    { value: 'past', label: t('only_past') },
    { value: 'archived', label: t('only_archived') },
    { value: 'all', label: t('all') }
  ]
  const AdminUserOptions = useMemo(() => {
    if (userStore?.users?.length) {
      return userStore?.users
        ?.filter((user: any) => user?.role === UserRoles.Admin)
        ?.map((user: any) => {
        return {
          value: user.uuid,
          label: `${user?.firstName} ${user?.lastName}`,
          email: user?.email || '',
          phone: user?.phone || ''
        }
      })
    }
    return []
  }, [userStore.users])

  const toBuilding = (building: any) => navigate(`${location.pathname}/buildings/${building?.uuid}`)
  const toApartment = (apartment: any) => navigate(`${location.pathname}/apartments/${apartment?.uuid}`)
  const toArchive = () => navigate(`/archive/properties/${params?.uuid}`)
  const toArchiveFolder = () => navigate(`/archive/properties/${params?.uuid}/files`)
  const toCosts = () => navigate(`${location.pathname}/costs`)
  const toSummary = () => navigate(`${location.pathname}/summary`)

  const toCreateBuilding = () => openCreateEntityDialog('building')
  const toCreateApartment = () => openCreateEntityDialog('apartment')
  const toCreateReminder = () => openCreateEntityDialog('reminder')

  const BuildingColumns = [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toBuilding(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip
          text={
            params.row?.type ?
            params.row.type === 'other' ?
            t('other_type') :
            t(params.row.type) :
            '-'
          }
        />
      )
    },
    {
      field: 'commissioningYear',
      headerName: t('commissioning_year'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ]

  const ApartmentColumns = [
    {
      field: 'nameFi',
      headerName: t('name_or_identifier'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toApartment(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip
          text={
            params.row?.type ?
            params.row.type === 'other' ?
            t('other_type') :
            t(params.row.type) :
            '-'
          }
        />
      )
    },
    {
      field: 'area',
      headerName: t('area'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? `${value} m²` : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('last_edited_at'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ]

  const AdminReminderColumns = useMemo(() => [
    {
      field: 'actionTitle',
      headerName: t('title'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.actionTitle)}
          onClick={() => openReminder(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'target',
      headerName: t('property'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        const isBuilding = params?.row?.targetSubEntity === 'building'
        let label = isBuilding ?
          BuildingSectionOptions?.find((item: any) => item.value === params?.row?.target)?.label :
          params?.row?.target
        if (label?.includes(' - ')) {
          label = label.split(' - ')[1]
        }
        return <Chip text={label ? t(label) : '-'} />
      }
    },
    {
      field: 'actionDate',
      headerName: t('action_date'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.setLocale('fi').toFormat('ccc')} ${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt2'
            onClick={() => openArchiveReminderConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ], [isAdmin])

  const ReminderColumns = useMemo(() => {
    return AdminReminderColumns.slice(0, 3)
  }, [isAdmin])

  const getCityOptions = () => {
    return FinnishMunicipalities.map((item: any) => {
      return { value: item, label: item }
    })
  }

  const PropertySpaceOptions: any = Object.values(PropertySpacesAndAreas)
    .map((item: any) => ({ value: item, label: t(item) }))

  const getReminderTargetEntityOptions = () => {
    return [
      { value: 'areas', label: t('areas') },
      entities?.buildings?.length ? { value: 'buildings', label: t('buildings') } : null,
      entities?.apartments?.length ? {
        value: 'apartments',
        label: property?.type === 'housing' ?
          t('apartments') :
          t('spaces')
      } : null,
    ].filter((item: any) => item)
  }

  const getReminderTargetSubEntityOptions = (entity: string) => {
    if (entity === 'buildings') {
      return flatten(entities?.buildings?.map((building: any) => {
        return [
          { value: building.uuid, label: building.nameFi },
          ...building?.spaces?.map((space: any) => {
            return {
              value: space.uuid,
              parentValue: building.uuid,
              label: `${building.nameFi} - ${space.nameFi}`
            }
          })
        ]
      }))
    } else if (entity === 'apartments') {
      return flatten(entities?.apartments?.map((apartment: any) => {
        return [
          { value: apartment.uuid, label: apartment.nameFi, },
          ...apartment?.rooms?.map((room: any) => {
            return {
              value: room.uuid,
              parentValue: apartment.uuid,
              label: `${apartment.nameFi} - ${room.nameFi}`
            }
          })
        ]
      }))
    }
    return []
  }

  const getReminderTargetOptions = (targetEntity: string, targetSubEntity?: string) => {
    if (targetEntity === 'areas') {
      return AreaSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
    } else if (targetEntity === 'buildings') {
      const subEntity: any = getReminderTargetSubEntityOptions(targetEntity)
        ?.find((item: any) => item.value === targetSubEntity)
      if (subEntity?.parentValue) {
        return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
      } else {
        return BuildingSectionOptions
      }
    } else if (targetEntity === 'apartments') {
      return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
    } else if (targetEntity === 'spaces') {
      return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
    }
    return []
  }

  const isSaveDisabled = () => {
    if (!nameFi) {
      return true
    }
    return false
  }

  /*
  const updatePropertyBackgroundPhoto = (file: any) => {
    propertyStore.updatePropertyBackgroundPhoto(params?.uuid, file?.uuid)
  }
  */

  const createBuilding = (newBuilding: any) => {
    buildingStore.createBuilding(
      params?.uuid,
      newBuilding,
      () => closeEntityDialog()
    )
  }
  const createApartment = (newApartment: any) => {
    apartmentStore.createApartment(
      params?.uuid,
      newApartment,
      () => closeEntityDialog()
    )
  }

  const save = () => {
    if (tab !== PropertyTabs.Areas) {
      if (businessId && !validateBusinessId(businessId)) {
        return showMessage(t('invalid_business_id'), 'error')
      }

      const updatedProperty: any = {
        nameFi,
        businessId,
        registrationDate,
        area,
        ownershipType,
        spaceOwnershipType,
        plotOwnershipType,
        hasMultipleProperties,
        propertyCount,
        propertyId,
        address,
        postalCode,
        city,
        insurances: insurances
          ?.filter((item: any) => item?.type || item?.company || item?.number),
        insuranceCompanies: insuranceCompanies
          ?.filter((item: any) => item?.name || item?.contactPersonName || item?.phone || item?.email),
        landlords: landlords
          ?.filter((item: any) => item?.contactPersonName || item?.company || item?.phone || item?.email),
        maintenanceCompanies: maintenanceCompanies
          ?.filter((item: any) => item?.contactPersonName || item?.company || item?.phone || item?.email),
        admins: propertyAdmin ? [propertyAdmin] : [],
        companyBoard: property?.type === 'housing' ? (companyBoard || null) : null,
        apartmentCount,
        apartmentArea,
        commercialSpaceCount,
        commercialSpaceArea,
        // otherSpaceCount,
        otherSpaceArea,
        spaceTypes: spaceTypes?.join(','),
        spaceTypesInfo,
        parkingSpaceCount,
        hasMaintenanceReport: hasMaintenanceReport === undefined ?
          null :
          hasMaintenanceReport,
        hasMaintenancePlan: hasMaintenancePlan === undefined ?
          null :
          hasMaintenancePlan,
        pastMaintenanceInfo,
        plannedMaintenanceInfo,
        hasValidEnergyCertificate: hasValidEnergyCertificate === undefined ?
          null :
          hasValidEnergyCertificate,
        energyCertificateStartYear,
        energyCertificateEndYear,
        propertyManagerName,
        propertyManagerCompany,
        propertyManagerPhone,
        propertyManagerEmail,
        propertyManagerAddress,
        propertyManagerPostalCode,
        propertyManagerCity,
        propertyManagerResponsibilities,
        maintenanceCompanyContactPersonName,
        maintenanceCompanyName,
        maintenanceCompanyPhone,
        maintenanceCompanyEmail,
        maintenanceCompanyAddress,
        maintenanceCompanyPostalCode,
        maintenanceCompanyCity,
        maintenanceCompanyMoveNotificationUrl,
        maintenanceCompanyResponsibilities,
        securityCompanyContactPersonName,
        securityCompanyName,
        securityCompanyPhone,
        securityCompanyEmail,
        securityCompanyAddress,
        securityCompanyPostalCode,
        securityCompanyCity,
      }
      if (property?.ownershipType === 'rent' && propertyRentCosts) {
        updatedProperty.propertyRentCosts = propertyRentCosts
      }
      if (property?.spaceOwnershipType === 'rent' && spaceRentCosts) {
        updatedProperty.spaceRentCosts = spaceRentCosts
      }
      if (property?.plotOwnershipType === 'rent' && plotRentCosts) {
        updatedProperty.plotRentCosts = plotRentCosts
      }
      propertyStore.updateProperty(property?.uuid, updatedProperty)
    } else {
      savePropertyDataRef.current.onSave()
    }
    propertyStore.setHasUnsavedChanges(false)
  }
  const archiveProperty = () => {
    propertyStore.archiveProperty(
      property?.uuid,
      () => {
        closeActionConfirmation()
        navigate('/properties', { replace: true })
      }
    )
  }

  const createReminder = (newReminder: any) => {
    reminderStore.createPropertyReminder(
      params?.uuid,
      newReminder,
      () => closeEntityDialog()
    )
  }
  const updateReminder = (updatedReminder: any) => {
    reminderStore.updatePropertyReminder(
      params?.uuid,
      reminder?.uuid,
      updatedReminder,
      () => closeReminder()
    )
  }
  const archiveReminder = () => {
    reminderStore.archivePropertyReminder(
      params?.uuid,
      actionTarget?.uuid,
      () => closeActionConfirmation()
    )
  }

  if (!property || !params.uuid || params.uuid?.length !== 36) {
    return null
  }

  const renderTabContent = () => {
    if (tab === PropertyTabs.BasicInformation) {
      return (
        <>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('basic_information')}
            </Typography>
            <Input
              label={t('property_name')}
              value={nameFi}
              onChange={setNameFi}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('business_id')}
                value={businessId}
                onChange={setBusinessId}
                mr='2rem'
                setUnsavedChanges={setUnsavedChanges}
                disabled={!isAdmin}
              />
              <Input
                label={t('company_registration_date')}
                value={registrationDate}
                onChange={setRegistrationDate}
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('plot_area')}
                value={area}
                onChange={setArea}
                unit='m²'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Box sx={styles.row}>
              {property?.type !== 'housing' ? (
                <Select
                  options={OwnershipOptions}
                  value={ownershipType}
                  onChange={setOwnershipType}
                  label={t('property_ownership_type')}
                  placeholder={isAdmin ? t('select') : null}
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              ) : null}
              {property?.type !== 'housing' ? (
                <Select
                  options={OwnershipOptions}
                  value={spaceOwnershipType}
                  onChange={setSpaceOwnershipType}
                  label={t('property_space_ownership_type')}
                  placeholder={isAdmin ? t('select') : null}
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              ) : null}
              <Select
                options={OwnershipOptions}
                value={plotOwnershipType}
                onChange={setPlotOwnershipType}
                label={t('property_plot_ownership_type')}
                placeholder={isAdmin ? t('select') : null}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
                maxWidth='calc(33% - 1rem)'
              />
            </Box>
            <Box sx={styles.row}>
              <Select
                label={t('has_multiple_properties')}
                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                value={hasMultipleProperties}
                onChange={setHasMultipleProperties}
                maxWidth='16rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('property_count')}
                value={propertyCount}
                onChange={setPropertyCount}
                disabled={!hasMultipleProperties || !isAdmin}
                unit={t('pcs')}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={t('property_id')}
              value={propertyId}
              onChange={setPropertyId}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <MultiSelect
              options={YearOptions()}
              value={buildingCommissioningYears}
              onChange={() => {}}
              label={t('commissioning_years')}
              mb='1.25rem'
              disabled
            />
            <Input
              label={t('street_address')}
              value={address}
              onChange={setAddress}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={postalCode}
                onChange={setPostalCode}
                maxWidth='16rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Select
                label={t('city_or_municipality')}
                options={getCityOptions()}
                value={city}
                onChange={setCity}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
          </Box>
          {
            [ownershipType, spaceOwnershipType, plotOwnershipType].includes('rent') ? (
              <Box sx={styles.formContainer}>
                <Typography variant='h5' sx={styles.subtitle}>
                  {t('rent_information')}
                </Typography>
                {ownershipType === 'rent' ? (
                  <Box sx={styles.row}>
                    <Input
                      label={t('property_rent')}
                      value={propertyRentCosts?.total || ''}
                      onChange={(value: string) => updateRentCosts('property', 'total', value)}
                      unit='€'
                      mr='2rem'
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    <Select
                      label={t('payment_interval')}
                      placeholder={isAdmin ? t('select') : null}
                      options={RentPaymentIntervalOptions}
                      value={propertyRentCosts?.interval || ''}
                      onChange={(value: string) => updateRentCosts('property', 'interval', value)}
                      mr='2rem'
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    <Input
                      label={t('payment_date')}
                      value={propertyRentCosts?.date || ''}
                      onChange={(value: string) => updateRentCosts('property', 'date', value)}
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  </Box>
                ) : null}
                {spaceOwnershipType === 'rent' ? (
                  <Box sx={styles.row}>
                    <Input
                      label={t('space_rent')}
                      value={spaceRentCosts?.total || ''}
                      onChange={(value: string) => updateRentCosts('space', 'total', value)}
                      unit='€'
                      mr='2rem'
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    <Select
                      label={t('payment_interval')}
                      placeholder={isAdmin ? t('select') : null}
                      options={RentPaymentIntervalOptions}
                      value={spaceRentCosts?.interval || ''}
                      onChange={(value: string) => updateRentCosts('space', 'interval', value)}
                      mr='2rem'
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    <Input
                      label={t('payment_date')}
                      value={spaceRentCosts?.date || ''}
                      onChange={(value: string) => updateRentCosts('space', 'date', value)}
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  </Box>
                ) : null}
                {plotOwnershipType === 'rent' ? (
                  <Box sx={styles.row}>
                    <Input
                      label={t('plot_rent')}
                      value={plotRentCosts?.total || ''}
                      onChange={(value: string) => updateRentCosts('plot', 'total', value)}
                      unit='€'
                      mr='2rem'
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    <Select
                      label={t('payment_interval')}
                      placeholder={isAdmin ? t('select') : null}
                      options={RentPaymentIntervalOptions}
                      value={plotRentCosts?.interval || ''}
                      onChange={(value: string) => updateRentCosts('plot', 'interval', value)}
                      mr='2rem'
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                    <Input
                      label={t('payment_date')}
                      value={plotRentCosts?.date || ''}
                      onChange={(value: string) => updateRentCosts('plot', 'date', value)}
                      disabled={!isAdmin}
                      setUnsavedChanges={setUnsavedChanges}
                    />
                  </Box>
                ) : null}
              </Box>
            ) : null
          }
          {insurances?.length ? insurances?.map((insurance, index) => (
            <Box key={insurance?.uuid} sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('insurance')} {index + 1}
                </Typography>
                <Box sx={styles.formHeaderActions}>
                  {isAdmin && index === 0 ? (
                    <Button
                      sx={styles.addItemButton}
                      text={t('add_new')}
                      onClick={addNewInsurance}
                      variant='secondary'
                      icon='add'
                    />) : null}
                  {isAdmin ? (
                    <Button
                      sx={styles.deleteItemButton}
                      text={t('delete')}
                      onClick={() => removeInsurance(insurance?.uuid)}
                      variant='text'
                      icon='delete'
                    />
                  ) : null}
                </Box>
              </Box>
              <Select
                label={t('insurance_type')}
                placeholder={isAdmin ? t('select') : null}
                options={InsuranceTypeOptions}
                value={insurance?.type || ''}
                onChange={(value: string) => updateInsurance(insurance.uuid, 'type', value)}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
                mb='1.25rem'
              />
              {insurance?.type === 'other' ? (
                <Input
                  label={t('other_insurance')}
                  value={insurance?.typeNotes || ''}
                  onChange={(value: string) => updateInsurance(insurance.uuid, 'typeNotes', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                  mb='1.25rem'
                />
              ) : null}
              <Box sx={styles.row}>
                <Select
                  label={t('insurance_company')}
                  placeholder={isAdmin ? t('select') : null}
                  options={InsuranceCompanyOptions}
                  value={insurance?.company || ''}
                  onChange={(value: string) => updateInsurance(insurance.uuid, 'company', value)}
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('insurance_number')}
                  value={insurance?.number || ''}
                  onChange={(value: string) => updateInsurance(insurance.uuid, 'number', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              {insurance?.company === 'other' ? (
                <Input
                  label={t('other_insurance_company')}
                  value={insurance?.companyNotes || ''}
                  onChange={(value: string) => updateInsurance(insurance.uuid, 'companyNotes', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                  mb='1.25rem'
                />
              ) : null}
            </Box>
          )) : (
            <Box sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('insurances')}
                </Typography>
                {isAdmin ? (
                  <Button
                    sx={styles.addItemButton}
                    text={t('add_new')}
                    onClick={addNewInsurance}
                    variant='secondary'
                    icon='add'
                  />
                ) : null}
            </Box>
          </Box>
          )}
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('buildings')}
            </Typography>
            <Input
              label={t('building_types')}
              value={buildingTypes}
              mb='1.25rem'
              disabled
            />
            <Input
              label={t('building_count')}
              value={buildingCount}
              onChange={() => {}}
              unit={t('pcs')}
              mb='1.25rem'
              disabled
            />
          </Box>
          {property?.type === 'housing' && (
            <Box sx={styles.formContainer}>
              <Typography variant='h5' sx={styles.subtitle}>
                {t('common_spaces_and_areas')}
              </Typography>
              <Box sx={styles.row}>
                <Input
                  label={t('residence_count')}
                  value={apartmentCount}
                  onChange={setApartmentCount}
                  mr='2rem'
                  unit={t('pcs')}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('total_residence_area')}
                  value={apartmentArea}
                  onChange={setApartmentArea}
                  unit='m²'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Box sx={styles.row}>
                <Input
                  label={t('commercial_space_count')}
                  value={commercialSpaceCount}
                  onChange={setCommercialSpaceCount}
                  mr='2rem'
                  unit={t('pcs')}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('total_commercial_space_area')}
                  value={commercialSpaceArea}
                  onChange={setCommercialSpaceArea}
                  unit='m²'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Box sx={styles.row}>
                <Input
                  label={t('other_space_count')}
                  value={otherSpaceCount}
                  onChange={setOtherSpaceCount}
                  mr='2rem'
                  unit={t('pcs')}
                  disabled
                />
                <Input
                  label={t('total_other_space_area')}
                  value={otherSpaceArea}
                  onChange={setOtherSpaceArea}
                  unit='m²'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                  // disabled
                />
              </Box>
              <CheckboxMultiSelect
                options={PropertySpaceOptions}
                value={spaceTypes}
                onChange={updateSpaceTypes}
                label={t('common_spaces_and_areas')}
                mb='0.625rem'
                disabled={!isAdmin}
              />
              {spaceTypes?.some((space: any) => space === 'other') && (
                <Input
                  placeholder={t('enter_more_specific_description')}
                  value={spaceTypesInfo}
                  onChange={setSpaceTypesInfo}
                  mb='1.25rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              )}
            </Box>
          )}
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('parking')}
            </Typography>
            <Input
              label={t('parking_space_count')}
              value={parkingSpaceCount}
              onChange={setParkingSpaceCount}
              mb='1.25rem'
              unit={t('pcs')}
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('maintenance_information')}
            </Typography>
            <Box sx={styles.row}>
              <Select
                label={t('has_maintenance_report')}
                placeholder={isAdmin ? t('select') : null}
                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                value={hasMaintenanceReport}
                onChange={setHasMaintenanceReport}
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Select
                label={t('has_maintenance_plan')}
                placeholder={isAdmin ? t('select') : null}
                options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
                value={hasMaintenancePlan}
                onChange={setHasMaintenancePlan}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            {hasMaintenancePlan && (
              <>
                <Box sx={styles.editorContainer}>
                  <TextEditor
                    label={t('past_maintenance_info')}
                    value={pastMaintenanceInfo}
                    onChange={setPastMaintenanceInfo}
                    disabled={!isAdmin}
                  />
                </Box>
                <Box sx={styles.editorContainer}>
                  <TextEditor
                    label={t('planned_maintenance_info')}
                    value={plannedMaintenanceInfo}
                    onChange={setPlannedMaintenanceInfo}
                    disabled={!isAdmin}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('energy_certificate')}
            </Typography>
            <Select
              label={t('property_has_valid_energy_certificate')}
              placeholder={isAdmin ? t('select') : null}
              options={[{ value: true, label: t('yes') }, { value: false, label: t('no') }]}
              value={hasValidEnergyCertificate}
              onChange={setHasValidEnergyCertificate}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            {hasValidEnergyCertificate && (
              <Box sx={styles.row}>
                <Select
                  options={YearOptions()}
                  value={energyCertificateStartYear}
                  onChange={updateEnergyCertificateStartYear}
                  label={t('year_of_preparation')}
                  placeholder={isAdmin ? t('select') : null}
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Select
                  options={
                    Array
                    .from({ length: 10 }, (_, i) => ({
                      value: `${Number(energyCertificateStartYear ) + 10 - i}`,
                      label: `${Number(energyCertificateStartYear) + 10 - i}`
                    }))
                  }
                  value={energyCertificateEndYear}
                  onChange={setEnergyCertificateEndYear}
                  label={t('valid_until_year')}
                  placeholder={isAdmin ? t('select') : null}
                  disabled={!isAdmin || !energyCertificateStartYear}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
            )}
          </Box>
        </>
      )
    } else if (tab === PropertyTabs.ContactInformation) {
      return (
        <>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('property_admin')}
            </Typography>
            <Select
              label={t('user')}
              placeholder={isAdmin ? t('select') : null}
              options={AdminUserOptions}
              value={propertyAdmin}
              onChange={setPropertyAdmin}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={AdminUserOptions?.find((user: any) => user?.value === propertyAdmin)?.phone || ''}
                onChange={() => {}}
                maxWidth='16rem'
                mr='2rem'
                disabled
              />
              <Input
                label={t('email')}
                value={AdminUserOptions?.find((user: any) => user?.value === propertyAdmin)?.email || ''}
                onChange={() => {}}
                disabled
              />
            </Box>
          </Box>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('property_management')}
            </Typography>
            <Input
              label={t('contact_person')}
              value={propertyManagerName}
              onChange={setPropertyManagerName}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Input
              label={t('company')}
              value={propertyManagerCompany}
              onChange={setPropertyManagerCompany}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={propertyManagerPhone}
                onChange={setPropertyManagerPhone}
                maxWidth='20rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('email')}
                value={propertyManagerEmail}
                onChange={setPropertyManagerEmail}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={t('street_address')}
              value={propertyManagerAddress}
              onChange={setPropertyManagerAddress}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('postal_code')}
                value={propertyManagerPostalCode}
                onChange={setPropertyManagerPostalCode}
                maxWidth='16rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('city_or_municipality')}
                value={propertyManagerCity}
                onChange={setPropertyManagerCity}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={t('areas_of_responsibility')}
              value={propertyManagerResponsibilities}
              onChange={setPropertyManagerResponsibilities}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
              multiline
            />
          </Box>
          {property?.type !== 'housing' && maintenanceCompanies?.length ? maintenanceCompanies?.map((mCompany, index) => (
            <Box key={mCompany?.uuid} sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('maintenance_company')} {index + 1}
                </Typography>
                <Box sx={styles.formHeaderActions}>
                  {isAdmin && index === 0 ? (
                    <Button
                      sx={styles.addItemButton}
                      text={t('add_new')}
                      onClick={addNewMaintenanceCompany}
                      variant='secondary'
                      icon='add'
                    />) : null}
                  {isAdmin ? (
                    <Button
                      sx={styles.deleteItemButton}
                      text={t('delete')}
                      onClick={() => removeMaintenanceCompany(mCompany?.uuid)}
                      variant='text'
                      icon='delete'
                    />
                  ) : null}
                </Box>
              </Box>
              <Input
                label={t('contact_person')}
                value={mCompany.contactPersonName || ''}
                onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'contactPersonName', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('company')}
                value={mCompany?.company|| ''}
                onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'company', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('phone_number')}
                  value={mCompany?.phone || ''}
                  onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'phone', value)}
                  maxWidth='20rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('email')}
                  value={mCompany?.email || ''}
                  onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'email', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('street_address')}
                value={mCompany?.address || ''}
                onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'address', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('postal_code')}
                  value={mCompany?.postalCode || ''}
                  onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'postalCode', value)}
                  maxWidth='16rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('city_or_municipality')}
                  value={mCompany?.city || ''}
                  onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'city', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('areas_of_responsibility')}
                value={mCompany?.responsibilities || ''}
                onChange={(value: string) => updateMaintenanceCompany(mCompany.uuid, 'responsibilities', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
                multiline
              />
            </Box>
          )) : property?.type !== 'housing' ? (
            <Box sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('maintenance_companies')}
                </Typography>
                {isAdmin ? (
                  <Button
                    sx={styles.addItemButton}
                    text={t('add_new')}
                    onClick={addNewMaintenanceCompany}
                    variant='secondary'
                    icon='add'
                  />
                ) : null}
              </Box>
            </Box>
          ) : null}
          {property?.type === 'housing' && (
            <Box sx={styles.formContainer}>
              <Typography variant='h5' sx={styles.subtitle}>
                {t('maintenance_company')}
              </Typography>
              <Input
                label={t('contact_person')}
                value={maintenanceCompanyContactPersonName}
                onChange={setMaintenanceCompanyContactPersonName}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('company')}
                value={maintenanceCompanyName}
                onChange={setMaintenanceCompanyName}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('phone_number')}
                  value={maintenanceCompanyPhone}
                  onChange={setMaintenanceCompanyPhone}
                  maxWidth='20rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('email')}
                  value={maintenanceCompanyEmail}
                  onChange={setMaintenanceCompanyEmail}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('street_address')}
                value={maintenanceCompanyAddress}
                onChange={setMaintenanceCompanyAddress}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('postal_code')}
                  value={maintenanceCompanyPostalCode}
                  onChange={setMaintenanceCompanyPostalCode}
                  maxWidth='16rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('city_or_municipality')}
                  value={maintenanceCompanyCity}
                  onChange={setMaintenanceCompanyCity}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('move_notifications')}
                value={maintenanceCompanyMoveNotificationUrl}
                onChange={setMaintenanceCompanyMoveNotificationUrl}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('areas_of_responsibility')}
                value={maintenanceCompanyResponsibilities}
                onChange={setMaintenanceCompanyResponsibilities}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
                multiline
              />
            </Box>
          )}
          {property?.type !== 'housing' && (
            <Box sx={styles.formContainer}>
              <Typography variant='h5' sx={styles.subtitle}>
                {t('security')}
              </Typography>
              <Input
                label={t('contact_person')}
                value={securityCompanyContactPersonName}
                onChange={setSecurityCompanyContactPersonName}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('company')}
                value={securityCompanyName}
                onChange={setSecurityCompanyName}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('phone_number')}
                  value={securityCompanyPhone}
                  onChange={setSecurityCompanyPhone}
                  maxWidth='20rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('email')}
                  value={securityCompanyEmail}
                  onChange={setSecurityCompanyEmail}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('street_address')}
                value={securityCompanyAddress}
                onChange={setSecurityCompanyAddress}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('postal_code')}
                  value={securityCompanyPostalCode}
                  onChange={setSecurityCompanyPostalCode}
                  maxWidth='16rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('city_or_municipality')}
                  value={securityCompanyCity}
                  onChange={setSecurityCompanyCity}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
            </Box>
          )}
          {landlords?.length ? landlords?.map((landlord, index) => (
            <Box key={landlord?.uuid} sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('landlord')} {index + 1}
                </Typography>
                <Box sx={styles.formHeaderActions}>
                  {isAdmin && index === 0 ? (
                    <Button
                      sx={styles.addItemButton}
                      text={t('add_new')}
                      onClick={addNewLandlord}
                      variant='secondary'
                      icon='add'
                    />) : null}
                  {isAdmin ? (
                    <Button
                      sx={styles.deleteItemButton}
                      text={t('delete')}
                      onClick={() => removeLandlord(landlord?.uuid)}
                      variant='text'
                      icon='delete'
                    />
                  ) : null}
                </Box>
              </Box>
              <Input
                label={t('contact_person')}
                value={landlord?.contactPersonName || ''}
                onChange={(value: string) => updateLandlord(landlord.uuid, 'contactPersonName', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('company')}
                value={landlord?.company|| ''}
                onChange={(value: string) => updateLandlord(landlord.uuid, 'company', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('phone_number')}
                  value={landlord?.phone || ''}
                  onChange={(value: string) => updateLandlord(landlord.uuid, 'phone', value)}
                  maxWidth='20rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('email')}
                  value={landlord?.email || ''}
                  onChange={(value: string) => updateLandlord(landlord.uuid, 'email', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('street_address')}
                value={landlord?.address || ''}
                onChange={(value: string) => updateLandlord(landlord.uuid, 'address', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('postal_code')}
                  value={landlord?.postalCode || ''}
                  onChange={(value: string) => updateLandlord(landlord.uuid, 'postalCode', value)}
                  maxWidth='16rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('city_or_municipality')}
                  value={landlord?.city || ''}
                  onChange={(value: string) => updateLandlord(landlord.uuid, 'city', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
            </Box>
          )) : (
            <Box sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('landlords')}
                </Typography>
                {isAdmin ? (
                  <Button
                    sx={styles.addItemButton}
                    text={t('add_new')}
                    onClick={addNewLandlord}
                    variant='secondary'
                    icon='add'
                  />
                ) : null}
              </Box>
            </Box>
          )}
          {insuranceCompanies?.length ? insuranceCompanies?.map((iCompany, index) => (
            <Box key={iCompany?.uuid} sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('insurance_company')} {index + 1}
                </Typography>
                <Box sx={styles.formHeaderActions}>
                  {isAdmin && index === 0 ? (
                    <Button
                      sx={styles.addItemButton}
                      text={t('add_new')}
                      onClick={addNewInsuranceCompany}
                      variant='secondary'
                      icon='add'
                    />) : null}
                  {isAdmin ? (
                    <Button
                      sx={styles.deleteItemButton}
                      text={t('delete')}
                      onClick={() => removeInsuranceCompany(iCompany?.uuid)}
                      variant='text'
                      icon='delete'
                    />
                  ) : null}
                </Box>
              </Box>
              <Input
                label={t('company')}
                value={iCompany?.company|| ''}
                onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'company', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('contact_person')}
                value={iCompany?.contactPersonName || ''}
                onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'contactPersonName', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('phone_number')}
                  value={iCompany?.phone || ''}
                  onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'phone', value)}
                  maxWidth='20rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('email')}
                  value={iCompany?.email || ''}
                  onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'email', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
              <Input
                label={t('street_address')}
                value={iCompany?.address || ''}
                onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'address', value)}
                mb='1.25rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Box sx={styles.row}>
                <Input
                  label={t('postal_code')}
                  value={iCompany?.postalCode || ''}
                  onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'postalCode', value)}
                  maxWidth='16rem'
                  mr='2rem'
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
                <Input
                  label={t('city_or_municipality')}
                  value={iCompany?.city || ''}
                  onChange={(value: string) => updateInsuranceCompany(iCompany.uuid, 'city', value)}
                  disabled={!isAdmin}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Box>
            </Box>
          )) : (
            <Box sx={styles.formContainer}>
              <Box sx={styles.formHeaderRow}>
                <Typography variant='h5' sx={{ ...styles.subtitle, alignSelf: 'center' }}>
                  {t('insurance_companies')}
                </Typography>
                {isAdmin ? (
                  <Button
                    sx={styles.addItemButton}
                    text={t('add_new')}
                    onClick={addNewInsuranceCompany}
                    variant='secondary'
                    icon='add'
                  />
                ) : null}
              </Box>
            </Box>
          )}
          {property?.type === 'housing' && (
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('housing_company_board')}
            </Typography>
            <Input
              label={`${t('board_member')} 1`}
              value={companyBoard?.member1Name || ''}
              onChange={(value: string) => updateCompanyBoard('member1Name', value)}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={companyBoard?.member1Phone || ''}
                onChange={(value: string) => updateCompanyBoard('member1Phone', value)}
                maxWidth='20rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('email')}
                value={companyBoard?.member1Email || ''}
                onChange={(value: string) => updateCompanyBoard('member1Email', value)}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={`${t('board_member')} 2`}
              value={companyBoard?.member2Name || ''}
              onChange={(value: string) => updateCompanyBoard('member2Name', value)}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={companyBoard?.member2Phone || ''}
                onChange={(value: string) => updateCompanyBoard('member2Phone', value)}
                maxWidth='20rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('email')}
                value={companyBoard?.member2Email || ''}
                onChange={(value: string) => updateCompanyBoard('member2Email', value)}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={`${t('board_member')} 3`}
              value={companyBoard?.member3Name || ''}
              onChange={(value: string) => updateCompanyBoard('member3Name', value)}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
            />
            <Box sx={styles.row}>
              <Input
                label={t('phone_number')}
                value={companyBoard?.member3Phone || ''}
                onChange={(value: string) => updateCompanyBoard('member3Phone', value)}
                maxWidth='20rem'
                mr='2rem'
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
              <Input
                label={t('email')}
                value={companyBoard?.member3Email || ''}
                onChange={(value: string) => updateCompanyBoard('member3Email', value)}
                disabled={!isAdmin}
                setUnsavedChanges={setUnsavedChanges}
              />
            </Box>
            <Input
              label={t('areas_of_responsibility')}
              value={companyBoard?.areasOfResponsibility || ''}
              onChange={(value: string) => updateCompanyBoard('areasOfResponsibility', value)}
              mb='1.25rem'
              disabled={!isAdmin}
              setUnsavedChanges={setUnsavedChanges}
              multiline
            />
          </Box>
          )}
        </>
      )
    } else if (tab === PropertyTabs.Buildings) {
      return (
        <>
          <TableActions
            sort={buildingFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={BuildingFilterOptions}
            onSortSelect={setBuildingFilter}
            // onPhotosClick={viewBuildingPhotos}
            onAddClick={isAdmin ? toCreateBuilding : undefined}
            allowClearable
          />
          <Table
            rows={BuildingRows}
            columns={BuildingColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === PropertyTabs.Spaces) {
      return (
        <>
          <TableActions
            sort={apartmentFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ApartmentFilterOptions}
            onSortSelect={setApartmentFilter}
            // onPhotosClick={viewBuildingPhotos}
            onAddClick={isAdmin ? toCreateApartment : undefined}
            allowClearable
          />
          <Table
            rows={ApartmentRows}
            columns={ApartmentColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    } else if (tab === PropertyTabs.Areas) {
      return (
        <PropertyAreas
          uuid={params?.uuid}
          saveRef={savePropertyDataRef}
        />
      )
    } else if (tab === PropertyTabs.Reminders) {
      return (
        <>
          <TableActions
            sort={reminderFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ReminderFilterOptions}
            onSortSelect={setReminderFilter}
            onAddClick={isAdmin ? toCreateReminder : undefined}
          />
          <Table
            rows={ReminderRows}
            columns={isAdmin ? AdminReminderColumns : ReminderColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (selectedImage && property?.files?.some((file: any) => file?.type === 'image')) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={
            property?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
              .findIndex((file: any) => file.src === selectedImage)
          }
          images={
            property?.files
              .filter((file: any) => file?.type === 'image')
              .map((file: any) => ({ src: file?.url }))
          }
        />
      )
    }
    return null
  }

  const renderRightColumnContent = () => {
    if (isAdmin) {
      return (
        <>
          <SidebarActions
            updatedAt={property?.updatedAt || property?.createdAt || ''}
            archivedAt={property?.archivedAt}
            onSave={save}
            onArchive={openArchivePropertyConfirmation}
            saveDisabled={isSaveDisabled()}
            mt='5.125rem'
          />
          <SidebarReminders
            updatedAt={reminderStore?.updatedAt || ''}
            count={UpcomingReminderCount}
            onAdd={toCreateReminder}
            onView={openRemindersTab}
            onToggleOpen={toggleOpenReminders}
            isOpen={remindersOpen}
          />
        </>
      )
    }
    return null
  }

  const renderEntityDialog = () => {
    if (actionType === 'create_building') {
      return (
        <CreateBuildingModal
          onClose={closeEntityDialog}
          onSave={createBuilding}
        />
      )
    } else if (actionType === 'create_apartment') {
      return (
        <CreateApartmentModal
          propertyType={property?.type}
          onClose={closeEntityDialog}
          onSave={createApartment}
        />
      )
    } else if (actionType === 'create_reminder' || reminder) {
      return (
        <ReminderModal
          mode={reminder ? 'edit' : 'create'}
          property={property}
          onClose={reminder ? closeReminder : closeEntityDialog}
          onSave={reminder ? updateReminder : createReminder}
          users={AdminUserOptions}
          getTargetEntityOptions={getReminderTargetEntityOptions}
          getTargetSubEntityOptions={getReminderTargetSubEntityOptions}
          getTargetOptions={getReminderTargetOptions}
          reminder={reminder}
          tab={reminder ? undefined : tab}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      if (actionType === 'archive_property') {
        return (
          <ConfirmationDialog
            title={t('archive_property_title')}
            content={t('archive_property_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveProperty}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_reminder') {
        return (
          <ConfirmationDialog
            title={t('archive_reminder_title')}
            content={t('archive_reminder_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveReminder}
            actionText={t('archive')}
          />
        )
      }
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage
        bgPhotoUrl={property?.backgroundPhoto}
        onClick={property?.backgroundPhoto ? openPropertyBackgroundPhoto : null}
        toArchiveFolder={isAdmin ? toArchiveFolder : undefined}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Box sx={styles.titleRow}>
            {PropertyTypeImage}
            <Typography variant='h2' color={Colors.primary}>{Title}</Typography>
          </Box>
          <Tabs
            mt='1.75rem'
            mb='2rem'
            onArchiveClick={isAdmin ? toArchive : null}
            onCostsClick={toCosts}
            onSummaryClick={toSummary}
          >
            <Tab
              value={PropertyTabs.BasicInformation}
              text={t(PropertyTabs.BasicInformation)}
              selected={tab === PropertyTabs.BasicInformation}
              onClick={updateTab}
              first
            />
            <Tab
              value={PropertyTabs.Buildings}
              text={t(PropertyTabs.Buildings)}
              selected={tab === PropertyTabs.Buildings}
              onClick={updateTab}
            />
            <Tab
              value={PropertyTabs.Spaces}
              text={t(property?.type && property.type !== 'housing' ? 'spaces' : 'apartments')}
              selected={tab === PropertyTabs.Spaces}
              onClick={updateTab}
            />
            <Tab
              value={PropertyTabs.Areas}
              text={t(PropertyTabs.Areas)}
              selected={tab === PropertyTabs.Areas}
              onClick={updateTab}
            />
            <Tab
              value={PropertyTabs.ContactInformation}
              text={t(PropertyTabs.ContactInformation)}
              selected={tab === PropertyTabs.ContactInformation}
              onClick={updateTab}
            />
            <Tab
              value={PropertyTabs.Reminders}
              text={t(PropertyTabs.Reminders)}
              selected={tab === PropertyTabs.Reminders}
              onClick={updateTab}
            />
          </Tabs>
          {renderTabContent()}
          {renderLightbox()}
        </Box>
        <Box sx={styles.rightColumn}>
          {renderRightColumnContent()}
        </Box>
      </Box>
      {renderEntityDialog()}
      {renderActionConfirmation()}
      <NavigationGuard
        hasUnsavedChanges={hasUnsavedChanges}
        forceShow={showHasUnsavedChanges}
        onHide={hideShowHasUnsavedChanges}
        onSave={save}
      />
    </Box>
  )
}

export default observer(Property)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem',
    overflowY: 'scroll',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 1rem)',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '18rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  editorContainer: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mt: '1rem'
  },
  propertyTypeImage: {
    height: '1.75rem',
    marginRight: '0.875rem'
  },
  formHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formHeaderActions: {
    display: 'flex',
    flexDirection: 'row',
  },
  addItemButton: {
    fontSize: '1rem',
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.text,
    textTransform: 'uppercase',
    border: `3px solid ${Colors.button}`,
    mb: '1.25rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  deleteItemButton: {
    fontSize: '1rem',
    fontWeight: 700,
    minWidth: '8rem',
    color: Colors.text,
    textTransform: 'uppercase',
    mb: '1.25rem',
    ml: '0.5rem',
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  }
} as const
