import { useMemo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Colors } from '../../Utils/theme'

export default function SummaryHeader(props: any) {
  const { title, type, mt, mb } = props

  const ContainerStyles = useMemo(() => {
    return {
      ...(
        !type ? 
        styles.titleRow :
        type === 'subtitle' ?
        styles.subtitleRow :
        styles.groupTitleRow
      ),
      mt: props?.mt || type === 'group_title' ? '1.5rem' : '2.5rem',
      mb: props?.mb || '1.325rem',
    }
  }, [type, mt, mb])

  return (
    <Box sx={ContainerStyles}>
      <Typography
        variant={!type ? 'h3' : 'h4'}
        sx={!type ? styles.title : type === 'subtitle' ? styles.subtitle : styles.groupTitle}
      >
        {title}
      </Typography>
    </Box>
  )
}

const styles = {
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.primaryDarkest,
    padding: '0.5rem 1.125rem',
    '@media print': {
      pageBreakInside: 'avoid',
      mt: '0rem !important',
    },
  },
  subtitleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.button30,
    padding: '0.5rem 1.125rem',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  groupTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    pl: '0rem',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  title: {
    fontSize: '1.5rem',
    color: Colors.white,
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '1.25rem',
    color: Colors.primaryDarkest,
    fontWeight: 500,
  },
  groupTitle: {
    fontSize: '1.125rem',
    color: Colors.primaryDarkest,
    fontWeight: 500,
  }
} as const