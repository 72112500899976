import i18n from '../Localization'

export const EnergyClassOptions = [
  { value: 'a', label: 'A' },
  { value: 'b', label: 'B' },
  { value: 'c', label: 'C' },
  { value: 'd', label: 'D' },
  { value: 'e', label: 'E' },
  { value: 'f', label: 'F' },
  { value: 'g', label: 'G' }
].map((item: any) => ({ value: item.value, label: item.label, rawLabel: true }))

export const OwnershipOptions = [
  { value: 'own', label: i18n.t('own') },
  { value: 'rent', label: i18n.t('rent') }
]

export const RentPaymentIntervalOptions = [
  { value: 'month', label: i18n.t('once_a_month') },
  { value: 'year', label: i18n.t('once_a_year') }
]

export const InsuranceCompanyOptions = [
  { value: 'if', label: 'If' },
  { value: 'op_pohjola', label: 'OP Pohjola' },
  { value: 'fennia', label: 'Fennia' },
  { value: 'lahitapiola', label: 'LähiTapiola' },
  { value: 'lahivakuutus', label: 'Lähivakuutus' },
  { value: 'nordea', label: 'Nordea' },
  { value: 'kaleva', label: 'Kaleva' },
  { value: 'varma', label: 'Varma' },
  { value: 'pohjantahti', label: 'Pohjantähti' },
  { value: 'other', label: 'Muu' }
]

export const InsuranceTypeOptions = [
  { value: 'home', label: 'Omaisuusvakuutus' },
  { value: 'property', label: 'Kiinteistövakuutus' },
  { value: 'property_owner', label: 'Kiinteistönomistajan vastuuvakuutus' },
  { value: 'administration', label: 'Hallinnon vastuuvakuutus' },
  { value: 'legal_protection', label: 'Oikeusturvavakuutus' },
  { value: 'other', label: 'Muu' }
]