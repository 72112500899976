import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import BackIcon from '../../Assets/Icons/arrow-left.svg'
import SortIcon from '../../Assets/Icons/sort.svg'
import CopyIcon from '../../Assets/Icons/copy.svg'
import PlayIcon from '../../Assets/Icons/play.svg'
// import ChartIcon from '../../Assets/Icons/graph.svg'
import ChartIcon from '../../Assets/Icons/graph-alt.svg'
import MenuIcon from '../../Assets/Icons/menu.svg'
import ExclamationIcon from '../../Assets/Icons/exclamation-mark.svg'
import GraphIcon from '../../Assets/Icons/graph.svg'
import PieChartIcon from '../../Assets/Icons/pie-chart.svg'
// import FolderIcon from '../../Assets/Icons/folder.svg'
import FolderIcon from '../../Assets/Icons/folder-alt.svg'
import BinIcon from '../../Assets/Icons/bin.svg'
import BinAltIcon from '../../Assets/Icons/bin-alt.svg'
import BinAlt2Icon from '../../Assets/Icons/bin-alt-2.svg'
import LeftArrowIcon from '../../Assets/Icons/left-arrow.svg'
import RightArrowIcon from '../../Assets/Icons/right-arrow.svg'
import DownloadIcon from '../../Assets/Icons/download.svg'
import UploadIcon from '../../Assets/Icons/upload-alt.svg'
import EditIcon from '../../Assets/Icons/edit.svg'
import DotMenuIcon from '../../Assets/Icons/dot-menu.svg'
import ListIcon from '../../Assets/Icons/file-structure.svg'
import GridIcon from '../../Assets/Icons/grid.svg'
import InfoIcon from '../../Assets/Icons/meta.svg'
import LogIcon from '../../Assets/Icons/log.svg'
import ProjectsIcon from '../../Assets/Icons/projects.svg'
import CostsIcon from '../../Assets/Icons/costs.svg'
import SummaryIcon from '../../Assets/Icons/summary.svg'
import BinAlt3Icon from '../../Assets/Icons/trash-2.svg'
import Download2Icon from '../../Assets/Icons/download-2.svg'
import Upload2Icon from '../../Assets/Icons/upload-2.svg'
import Edit2Icon from '../../Assets/Icons/edit-2.svg'
import List2Icon from '../../Assets/Icons/list-2.svg'
import Grid2Icon from '../../Assets/Icons/menu-2.svg'
import Info2Icon from '../../Assets/Icons/info-2.svg'
import Add2Icon from '../../Assets/Icons/add-2.svg'
import { Colors } from '../../Utils/theme'

const IconMappings: any = {
  add2: Add2Icon,
  back: BackIcon,
  sort: SortIcon,
  copy: CopyIcon,
  play: PlayIcon,
  chart: ChartIcon,
  menu: MenuIcon,
  exclamation: ExclamationIcon,
  graph: GraphIcon,
  pieChart: PieChartIcon,
  folder: FolderIcon,
  bin: BinIcon,
  binAlt: BinAltIcon,
  binAlt2: BinAlt2Icon,
  binAlt3: BinAlt3Icon,
  arrowLeft: LeftArrowIcon,
  arrowRight: RightArrowIcon,
  download: DownloadIcon,
  download2: Download2Icon,
  upload: UploadIcon,
  upload2: Upload2Icon,
  edit: EditIcon,
  edit2: Edit2Icon,
  dotMenu: DotMenuIcon,
  list: ListIcon,
  list2: List2Icon,
  grid: GridIcon,
  grid2: Grid2Icon,
  info: InfoIcon,
  info2: Info2Icon,
  log: LogIcon,
  projects: ProjectsIcon,
  costs: CostsIcon,
  summary: SummaryIcon,
}

const IconButton = React.forwardRef((props: any, ref: any) => {
  const { sx, onClick, loading, disabled } = props

  const getStyles = () => {
    let buttonStyles: any = { ...styles.button }
    if (sx) buttonStyles = { ...buttonStyles, ...sx }
    if (props?.mr) buttonStyles.mr = props.mr
    if (props?.ml) buttonStyles.ml = props.ml
    if (props?.transparentBg) {
      buttonStyles.backgroundColor = 'transparent'
      buttonStyles.color = Colors.primary
      buttonStyles[':hover'] = {
        boxShadow: 0,
        backgroundColor: Colors.primary10,
        borderRadius: '0.625rem'
      }
    }
    return buttonStyles
  }

  const getIconStyle = () => {
    const iconStyles: any = { ...styles.icon }
    if (props?.iconSize) {
      iconStyles.height = props.iconSize
      iconStyles.width = 'auto'
    }
    if (props?.disabled) {
      iconStyles.filter = 'opacity(0.5)'
    }
    return iconStyles
  }

  return (
    <ButtonBase
      sx={getStyles()}
      onClick={onClick}
      disabled={loading || disabled}
      ref={ref ?? null}
      aria-describedby={props?.ariaDescribedBy ?? undefined}
    >
      <Box component='img' src={IconMappings[props.icon]} sx={getIconStyle()} />
    </ButtonBase>
  )
})

export default IconButton

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    minWidth: 0,
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: '0.625rem',
    backgroundColor: Colors.primary,
    ':hover': {
      backgroundColor: Colors.primaryDarker,
      boxShadow: 0
    },
  },
  icon: {
    height: '1.375rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
  }
} as const
