import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Table from '../../../Components/Common/Table'
import TableActions from '../../../Components/Common/TableActions'
import FilterPropertiesModal from '../../../Components/Common/FilterPropertiesModal'
import IconButton from '../../../Components/Common/IconButton'
import CreatePropertyModal from '../../../Components/Common/CreateProperty2Modal'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Button, Chip } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'

const Properties = () => {
  const { sessionStore, propertyStore }: any = useStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { language, isAdmin, me } = sessionStore
  const { loading, properties } = propertyStore

  // Table state
  const [searchKey, setSearchKey] = useState('')
  const [filters, setFilters] = useState<any>({})
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [propertyVisibility, setPropertyVisibility] = useState('all_properties')
  // const [bulkAction, setBulkAction] = useState('')

  // Confirmation dialog (property)
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false)

  const clearSearchKey = () => setSearchKey('')
  const openFiltersModal = () => setShowFiltersModal(true)
  const closeFiltersModal = () => setShowFiltersModal(false)

  const openCreatePropertyDialog = () => setShowCreatePropertyModal(true)
  const closeCreatePropertyDialog = () => setShowCreatePropertyModal(false)
  
  useEffect(() => {
    if (!properties?.length) {
      propertyStore.getProperties('only_properties')
    }
  }, [])

  useEffect(() => {
    if (filters?.visibility) {
      propertyStore.getProperties(filters.visibility)
    }
  }, [filters])

  const PropertyVisibilityOptions = useMemo(() => [
    { value: 'all_properties', label: t('view_all') },
    { value: 'own_properties', label: t('only_own_properties') },
  ], [])

  /*
  const bulkActionOptions = useMemo(() => [
    // { value: 'download_as_csv', label: t('download_as_csv') }
  ], [language])
  */

  const toProperty = (row: any) => navigate(`/properties/${row?.uuid}`)
  const toCreateProperty = () => openCreatePropertyDialog()
  // const toViewPropertyOnMap = () => navigate(`/properties/map`)
  const toProjects = (row: any) => navigate(`/properties/${row?.uuid}/projects`)
  const toCosts = (row: any) => navigate(`/properties/${row?.uuid}/costs`)
  const toSummary = (row: any) => navigate(`/properties/${row?.uuid}/summary`)
  const toBuilding = (building: any) => {
    const propertyUuid = properties?.find((property: any) => property?.id === building?.propertyId)?.uuid
    return navigate(`/properties/${propertyUuid}/buildings/${building?.uuid}`)
  }
  const toApartment = (apartment: any) => {
    const propertyUuid = properties?.find((property: any) => property?.id === apartment?.propertyId)?.uuid
    return navigate(`/properties/${propertyUuid}/apartments/${apartment?.uuid}`)
  }

  /*
  const selectBulkAction = (action: string) => {
    setBulkAction(action)
    if (action === 'download_as_csv') {
      propertyStore.downloadPropertiesAsCSV()
    }
  }
  */

  const clearFilters = () => setFilters({})
  const updateFilters = (updatedFilters: any) => {
    setFilters(updatedFilters)
    closeFiltersModal()
  }

  const getPropertyCount = (previewFilters: any) => {
    let properties = propertyStore.properties
    if (searchKey) {
      properties = properties.filter((property: any) => property?.nameFi?.toLowerCase().includes(searchKey.toLowerCase()))
    }
    if (previewFilters) {
      if (previewFilters?.type) {
        properties = properties.filter((property: any) => property?.type === previewFilters?.type)
      }
      if (previewFilters?.status) {
        properties = properties.filter((property: any) => {
          if (previewFilters?.status === 'active') {
            return !property?.archivedAt
          }
          return property?.archivedAt
        })
      }
      if (previewFilters?.city) {
        properties = properties.filter((property: any) => property?.city === previewFilters?.city)
      }
    }
    return properties?.length
  }

  const createProperty = (newProperty: any) => {
    propertyStore.createProperty(newProperty, () => {
      closeCreatePropertyDialog()
      propertyStore.getProperties(filters.visibility)
    })
  }
  
  const getRowId = (row: any) => row?.uuid

  const PropertyColumns: GridColDef[] = useMemo(() => [
    /*
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 70,
      headerClassName: 'kiinteistomuisti-table-column--header',
    },
    */
    {
      field: 'nameFi',
      headerName: t('property_name'),
      flex: 1,
      // maxWidth: 700,
      minWidth: 240,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.nameFi.trim()}
          onClick={() => toProperty(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'businessId',
      headerName: t('business_id'),
      flex: 1,
      // maxWidth: 170,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        params?.row?.businessId ?
          <Chip text={params.row.businessId} copy /> :
          '-'
      )
    },
    {
      field: 'city',
      headerName: t('city'),
      flex: 1,
      // maxWidth: 220,
      maxWidth: 260,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'propertyManagerName',
      headerName: t('contact_person'),
      flex: 1,
      // maxWidth: 240,
      maxWidth: 260,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value ? t(value) : '-'
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      // maxWidth: 250,
      maxWidth: 260,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      // maxWidth: 170,
      maxWidth: 250,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='projects'
            onClick={() => toProjects(params.row)}
            iconSize='1.25rem'
            mr='0.5rem'
            transparentBg
          />
          <IconButton
            icon='costs'
            onClick={() => toCosts(params.row)}
            iconSize='1.25rem'
            mr='0.5rem'
            transparentBg
          />
          <IconButton
            icon='summary'
            onClick={() => toSummary(params.row)}
            iconSize='1.25rem'
            transparentBg
          />
        </Box>
      )
    }
  ], [language])

  const BuildingColumns: GridColDef[] = useMemo(() => [
    {
      field: 'nameFi',
      headerName: t('building'),
      flex: 1,
      minWidth: 250,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.nameFi)}
          onClick={() => toBuilding(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip
          text={
            params.row?.type ?
            params.row.type === 'other' ?
            t('other_type') :
            t(params.row.type) :
            '-'
          }
        />
      )
    },
    {
      field: 'commissioningYear',
      headerName: t('commissioning_year'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ], [language])

  const ApartmentColumns: GridColDef[] = useMemo(() => [
    {
      field: 'nameFi',
      headerName: t('apartment'),
      flex: 1,
      minWidth: 250,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={params.row.nameFi}
          onClick={() => toApartment(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'address',
      headerName: t('address'),
      flex: 1,
      maxWidth: 360,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: (value: string) => value || '-'
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Chip
          text={
            params.row?.type ?
            params.row.type === 'other' ?
            t('other_type') :
            t(params.row.type) :
            '-'
          }
        />
      )
    },
    {
      field: 'updatedAt',
      headerName: t('edited_at'),
      flex: 1,
      maxWidth: 300,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    }
  ], [language])

  const rows = useMemo(() => {
    let properties = propertyStore.properties
    if (propertyVisibility === 'own_properties') {
      properties = properties.filter((property: any) => {
        return property?.admins?.find((admin: any) => admin?.userUuid === me?.uuid)
      })
    }
    if (searchKey) {
      properties = properties.filter((property: any) => {
        return property?.nameFi?.toLowerCase().includes(searchKey.toLowerCase())
      })
    }
    if (filters) {
      if (filters?.status) {
        properties = properties.filter((property: any) => {
          if (filters?.status === 'active') {
            return !property?.archivedAt
          }
          return property?.archivedAt
        })
      }
      if (filters?.city) {
        properties = properties.filter((property: any) => property?.city === filters?.city)
      }
    }
    return properties
  }, [propertyStore.properties, searchKey, filters, propertyVisibility])

  const renderTables = () => {
    if (loading) {
      return null
    }
    
    if (!filters?.visibility || filters?.visibility === 'only_properties') {
      return (
        <Table
          rows={rows}
          columns={PropertyColumns}
          getRowId={getRowId}
          hideCheckboxSelection
        />
      )
    } else if (filters?.visibility === 'properties_and_buildings') {
      return rows?.map((row: any) => {
        return (
          <Box key={row?.uuid} sx={styles.nestedTableContainer}>
            <Table
              rows={[row]}
              columns={PropertyColumns}
              getRowId={getRowId}
              hideCheckboxSelection
            />
            {
              row?.buildings?.length ? (
                <Table
                  rows={row.buildings}
                  columns={BuildingColumns}
                  getRowId={getRowId}
                  hideCheckboxSelection
                  nested
                />
              ) : null
            }
          </Box>
        )
      })
    } else if (filters?.visibility === 'properties_and_buildings_and_apartments') {
      return rows?.map((row: any) => {
        return (
          <Box key={row?.uuid} sx={styles.nestedTableContainer}>
            <Table
              rows={[row]}
              columns={PropertyColumns}
              getRowId={getRowId}
              hideCheckboxSelection
            />
            {
              row?.buildings?.length ? (
                <Table
                  rows={row.buildings}
                  columns={BuildingColumns}
                  getRowId={getRowId}
                  hideCheckboxSelection
                  nested
                />
              ) : null
            }
            {
              row?.apartments?.length ? (
                <Table
                  rows={row.apartments}
                  columns={ApartmentColumns}
                  getRowId={getRowId}
                  hideCheckboxSelection
                  nested
                />
              ): null
            }
          </Box>
        )
      })
    }
  }

  const renderCreatePropertyDialog = () => {
    if (showCreatePropertyModal) {
      return (
        <CreatePropertyModal
          onClose={closeCreatePropertyDialog}
          onSave={createProperty}
        />
      )
    }
    return null
  }

  const renderFiltersModal = () => {
    if (showFiltersModal) {
      return (
        <FilterPropertiesModal
          filters={filters}
          onClose={closeFiltersModal}
          updateFilters={updateFilters}
          clearFilters={clearFilters}
          getPropertyCount={getPropertyCount}
          cityOptions={
            propertyStore?.properties
              ?.filter((property: any) => property?.city)
              ?.map((property: any) => ({ value: property?.city, label: property?.city }))
          }
          propertyManagerOptions={
            propertyStore?.properties
              ?.filter((property: any) => property?.propertyManagerName)
              ?.map((property: any) => ({
                value: property?.propertyManagerName,
                label: property?.propertyManagerName
              }))
          }
        />
      )
    }
    return null
  }

  return (
    <Box sx={styles.container}>
      <TableActions
        searchKey={searchKey}
        updateSearch={setSearchKey}
        clearSearch={clearSearchKey}
        onFilterClick={openFiltersModal}
        filterCount={Object.keys(filters).length}
        sort={propertyVisibility}
        sortPlaceholder={t('view_all')}
        sortOptions={isAdmin ? PropertyVisibilityOptions : undefined}
        onSortSelect={isAdmin ? setPropertyVisibility : undefined}
        sortSx={styles.sortSelect}
        // onBulkActionSelect={selectBulkAction}
        // bulkActionOptions={bulkActionOptions}
        // bulkAction={bulkAction}
        onAddClick={isAdmin ? toCreateProperty : undefined}
        // onViewMapClick={toViewPropertyOnMap}
      />
      {renderTables()}
      {renderCreatePropertyDialog()}
      {renderFiltersModal()}
    </Box>
  )
}

export default observer(Properties)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    pb: '10rem'
  },
  nestedTableContainer: {
    width: '100%',
    mb: '1.5rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
  sortSelect: {
    width: {
      xs: '15rem'
    }
  }
} as const
