import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Button from './Button'
import Input from './Input'
import RadioButton from './RadioButton'
import { Colors } from '../../Utils/theme'

export default function SidebarSummaryActions(props: any) {
  const { t } = useTranslation()

  const { saveDisabled, templates, selectedTemplate, isAdmin } = props

  const [name, setName] = useState('')
  const [templateAction, setTemplateAction] = useState('')

  const enableTemplateCreation = () => setTemplateAction('create')
  const enableTemplateUpdate = () => {
    setName(selectedTemplate?.name || '')
    setTemplateAction('update')
  }
  const disableTemplateAction = () => setTemplateAction('')

  const LastEditedAt = useMemo(() => {
    // Find the most recent date from the templates
    let date = templates.reduce((acc: string, template: any) => {
      if (template.updatedAt > acc) {
        return template.updatedAt
      }
      return acc
    }, '-')
    if (date && date !== '-') {
      const luxonDate = DateTime.fromISO(date)
      date = `${luxonDate.setLocale('fi').toFormat('ccc')} ${luxonDate.toFormat('dd.MM.yyyy')} ${t('at')} ${luxonDate.toFormat('HH.mm')}`
    }
    return date
  }, [templates])
  
  const toSummary = () => props.openSummary()
  const onSave = () => props.onSave(templateAction, name)
  // const onCancel = () => props.onCancel()

  return (
    <>
    <Box sx={styles.container} mt='5.25rem'>
      <Box sx={styles.contentContainer}>
        <Typography variant='h5' sx={styles.title}>
          {t('info')}
        </Typography>
        <Box sx={styles.actionInfo} mb='0.75rem'>
          <Box sx={styles.stateContainer}>
            <Box sx={styles.actionItemColumn}>
              <Typography variant='body1' sx={styles.label}>
                {t('template_count')}:
              </Typography>
              <Typography variant='body1' color={Colors.text}>
                {templates?.length || 0} {t('pcs')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.actionInfo}>
          <Box sx={styles.stateContainer}>
            <Box sx={styles.actionItemColumn}>
              <Typography variant='body1' sx={styles.label}>
                {t('last_edited_at')}:
              </Typography>
              <Typography variant='body1' color={Colors.text}>
                {LastEditedAt}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        <Button
          text={t('view_summary')}
          onClick={toSummary}
          width='100%'
          disabled={saveDisabled}
        />
      </Box>
    </Box>
    {isAdmin ? (
      <Box sx={styles.container} mt='2rem'>
        <Box sx={styles.contentContainer}>
          <Typography variant='h5' sx={styles.title}>
            {t('template')}
          </Typography>
          <RadioButton
            label={t('do_not_save')}
            checked={!templateAction}
            onChange={disableTemplateAction}
            mb='0.625rem'
          />
          {selectedTemplate?.uuid && (
            <RadioButton
              label={t('update_template')}
              checked={templateAction === 'update'}
              onChange={enableTemplateUpdate}
              mb='0.625rem'
            />
          )}
          <RadioButton
            label={t('save_as_template')}
            checked={templateAction === 'create'}
            onChange={enableTemplateCreation}
          />
          <Collapse in={!!templateAction}>
            <Box sx={styles.stateContainer} mt='1rem'>
              <Box sx={styles.actionItemColumn}>
                <Input 
                  label={t('name')}
                  value={name}
                  onChange={setName}
                />
              </Box>
            </Box>
          </Collapse>
        </Box>
        <Collapse in={!!templateAction}>
          <Box sx={styles.footer}>
            <Button
              text={t('save')}
              onClick={onSave}
              width='100%'
              disabled={!name || !templateAction || saveDisabled}
            />
          </Box>
        </Collapse>
      </Box>
    ) : null}
    </>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: {
      lgplus: '20rem',
      xs: '17rem'
    },
    maxWidth: {
      lgplus: '20rem',
      xs: '17rem'
    },
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    // p: '2rem 2rem 0.5rem 2rem',
    mb: '1.5rem'
  },
  contentContainer: {
    p: {
      lgplus: '1.5rem 1.5rem 1.5rem 1.5rem',
      xs: '1.125rem 1.125rem 1.125rem 1.125rem'
    }
  },
  title: {
    fontWeight: 800,
    alignSelf: 'flex-start',
    mb: '1rem',
    textTransform: 'uppercase'
  },
  actionInfo: {

  },
  label: {
    fontSize: '1rem',
    fontWeight: 700,
    color: Colors.primary,
    mb: '0.0625rem'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    backgroundColor: Colors.secondary10,
    borderTop: `1px solid ${Colors.secondary}` 
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '0.375rem'
  },
  actionItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  cancelButton: {
    backgroundColor: Colors.transparent,
    color: Colors.primary,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: Colors.transparent,
      textDecoration: 'underline'
    }
  },
} as const