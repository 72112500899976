import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from './Button'
import Chip from './Chip'
import Input from './Input'
import PropertyTypeItem from './PropertyTypeItem'
import { validateBusinessId } from '../../Utils/validators'
import { Colors } from '../../Utils/theme'

export default function CreateProperty2Modal(props: any) {
  const { onClose, loading, onSave } = props
  const { t } = useTranslation()

  // General
  const [phase, setPhase] = useState<number>(1)

  // Phase 1 (type selection)
  const [type, setType] = useState<string>('')

  // Phase 2 (property details)
  const [nameFi, setNameFi] = useState('')
  const [businessId, setBusinessId] = useState('')

  const getIsSaveDisabled = () => {
    if (loading) {
      return true
    }

    if (phase === 1) {
      return !type
    } else if (phase === 2) {
      return !nameFi || (businessId && !validateBusinessId(businessId))
    }
    return false
  }

  const onPreviousClick = () => {
    if (phase > 1) {
      setPhase(phase - 1)
    } else {
      onClose()
    }
  }

  const onNextClick = () => {
    if (phase < 2) {
      setPhase(phase + 1)
    } else {
      createProperty()
    }
  }

  const createProperty = () => onSave({ type, nameFi, businessId: businessId  || undefined })

  const renderContentSection = () => {
    if (phase === 1) {
      return (
        <>
          <PropertyTypeItem
            value='housing'
            label={t('housing_property')}
            info={t('housing_property_info')}
            selected={type === 'asoy'}
            onSelect={setType}
            mb='1.25rem'
          />
          <PropertyTypeItem
            value='industrial'
            label={t('industrial_property')}
            info={t('industrial_property_info')}
            selected={type === 'industrial'}
            onSelect={setType}
            mb='1.25rem'
          />
          <PropertyTypeItem
            value='business'
            label={t('business_property')}
            info={t('business_property_info')}
            selected={type === 'business'}
            onSelect={setType}
          />
        </>
      )
    } else if (phase === 2) {
      return (
        <>
          <Input
            label={t('property_name')}
            value={nameFi}
            onChange={setNameFi}
            mb='1.25rem'
          />
          <Input
            label={t('business_id')}
            value={businessId}
            onChange={setBusinessId}
            mb='1.25rem'
            invalid={businessId && !validateBusinessId(businessId)}
          />
        </>
      )
    }
  }

  return (
    <Dialog
      onClose={onClose}
      sx={styles.dialog}
      disableRestoreFocus
      open
    >
      <Chip
        onClick={onClose}
        sx={styles.closeButton}
        text={t('close')}
      />
      <DialogTitle variant='h2' sx={styles.title}>{t('add_new_property')}</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.countContainer}>
          <Typography variant='body1' sx={styles.count}>{phase}/2</Typography>
        </Box>
        {renderContentSection()}
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          sx={styles.outlinedButton}
          text={phase === 1 ? t('cancel') : t('back')}
          onClick={onPreviousClick}
          variant='secondary'
        />
        <Button
          sx={styles.button}
          text={phase < 2 ? t('next') : t('save')}
          onClick={onNextClick}
          loading={loading}
          disabled={getIsSaveDisabled()}
        />
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '39rem',
      borderRadius: '0.625rem',
      padding: '1.25rem 0.75rem'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem'
  },
  title: {
    color: Colors.primary
  },
  content: {
  },
  contentText: {
    marginBottom: '1.5rem'
  },
  outlinedButton: {
    fontWeight: 700,
    minWidth: '10rem',
    color: Colors.button,
    border: `0.0625rem solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
  button: {
    pl: '2.5rem',
    pr: '2.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginBottom: '1rem'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: 'calc(100% - 1.5rem)',
    border: 0
  },
  actionButton: {
    height: '3.125rem',
    padding: '0rem 2.5rem',
  },
  countContainer: {
    height: '2rem',
    width: '3rem',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: '0.125rem',
    mb: '1rem'
  },
  count: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.text
  },
} as const
