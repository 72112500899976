import { useMemo } from 'react'
import sampleSize from 'lodash/sampleSize'
import Box from '@mui/material/Box'

export default function SummaryImages(props: any) {
  const { images } = props

  const Images = useMemo(() => {
    if (images?.length) {
      let visibleImages = []
      const buildingImages = images.filter((image: any) => image?.isBuilding)
      if (buildingImages?.length > 2) {
        visibleImages.push(...sampleSize(buildingImages, 2))
      } else {
        visibleImages = [...buildingImages]
      }
      const apartmentImages = images.filter((image: any) => image?.isApartment)
      if (apartmentImages?.length > 2) {
        visibleImages.push(...sampleSize(apartmentImages, 2))
      } else {
        visibleImages = [...visibleImages, ...apartmentImages]
      }
      if (visibleImages?.length < 4) {
        const remainingImages = images.filter((image: any) => !visibleImages.find((visibleImage: any) => visibleImage?.src=== image?.src))
        visibleImages.push(...sampleSize(remainingImages, 4 - visibleImages?.length))
      }
      return visibleImages
    }
    return []
  }, [images])

  if (!Images?.length) {
    return null
  }

  return (
    <Box sx={styles.container}>
      {Images.map((image: any, index: number) => (
        <Box
          key={index}
          sx={styles.photo}
          component='img'
          src={image.src}
          mr={index < 3 ? '1.125rem' : 0}
          draggable={false}
        />
      ))}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    overflowX: 'hidden',
    mt: '1.325rem',
  },
  photo: {
    width: 'calc(25% - 0.84375rem)',
    objectFit: 'cover',
    userSelect: 'none',
    height: {
      xs: '10rem'
    },
    '@media print': {
      height: '8rem'
    },
  },
} as const