import { useMemo, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { DateTime } from 'luxon'
import { GridRenderCellParams } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ConfirmationDialog from '../../../Components/Common/ConfirmationDialog'
import SpaceFloor from './SpaceFloor'
import SpaceCeiling from './SpaceCeiling'
import SpaceWall from './SpaceWall'
import Lightbox from '../../../Components/Common/Lightbox'
import SidebarActions from '../../../Components/Common/SidebarActions'
import SidebarReminders from '../../../Components/Common/SidebarReminders'
import SidebarFormSettings from '../../../Components/Common/SidebarFormSettings'
import ReminderModal from '../../../Components/Common/ReminderModal'
import TableActions from '../../../Components/Common/TableActions'
import Table from '../../../Components/Common/Table'
import IconButton from '../../../Components/Common/IconButton'
import NavigationGuard from '../../../Components/Common/NavigationGuard'
import RoomVisibleItemsModal from '../../../Components/Common/RoomVisibleItemsModal'
import { CoverImage, Tabs, Tab, Chip, Input, Button } from '../../../Components'
import { RoomSectionOptions } from '../../../Utils/room'
import { SpaceTabs, UserRoles } from '../../../Utils/constants'
import { Colors, Fonts } from '../../../Utils/theme'

const Space = () => {
  const {
    sessionStore,
    userStore,
    propertyStore,
    spaceStore,
    reminderStore
  }: any = useStore()
  const { isAdmin } = sessionStore
  const { space, loading, hasUnsavedChanges } = spaceStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [searchParams, setSearchParams]: any = useSearchParams()
  const [showHasUnsavedChanges, setShowHasUnsavedChanges] = useState<SpaceTabs | null>(null)

  const saveSpaceDataRef = useRef<any>(null)

  // General
  const [tab, setTab] = useState(SpaceTabs.BasicInformation)
  const [selectedImage, setSelectedImage] = useState(null)
  const [remindersOpen, setRemindersOpen] = useState(true)
  const [reminder, setReminder] = useState<any>(null)
  const [formSettingsOpen, setFormSettingsOpen] = useState(true)
  const [visibleItemsOpen, setVisibleItemsOpen] = useState(false)

  const updateTab = (to: SpaceTabs | null) => {
    if (hasUnsavedChanges) {
      setShowHasUnsavedChanges(to)
    } else {
      setTab(to as SpaceTabs)
    }
  }

  const hideShowHasUnsavedChanges = (to?: SpaceTabs | null) => {
    if (to) {
      spaceStore.setHasUnsavedChanges(false)
      setShowHasUnsavedChanges(null)
      setTab(to)
    } else {
      setShowHasUnsavedChanges(null)
    }
  }

  const openRemindersTab = () => setTab(SpaceTabs.Reminders)
  const toggleOpenReminders = () => setRemindersOpen(!remindersOpen)
  const openReminder = (reminder: any) => {
    setReminder(reminder)
    propertyStore.getPropertyEntities(params.pUuid, { types: ['buildings', 'spaces'] })
    userStore.getUsers()
  }
  const closeReminder = () => setReminder(null)

  const toggleOpenFormSettings = () => setFormSettingsOpen(!formSettingsOpen)
  const openVisibleItems = () => setVisibleItemsOpen(true)
  const closeVisibleItems = () => setVisibleItemsOpen(false)

  // Space basic information
  const [nameFi, setNameFi] = useState('')
  const [area, setArea] = useState('')

  // Filters
  const [reminderFilter, setReminderFilter] = useState('upcoming')

  // Confirmation dialog (space)
  const [showActionConfirmation, setShowActionConfirmation] = useState(false)
  const [actionType, setActionType] = useState('')
  const [actionTarget, setActionTarget] = useState<any>(null)

  const openArchiveSpaceConfirmation = () => {
    setShowActionConfirmation(true)
    setActionType('archive_space')
  }
  const openArchiveReminderConfirmation = (reminder: any) => {
    setShowActionConfirmation(true)
    setActionType('archive_reminder')
    setActionTarget(reminder)
  }
  const closeActionConfirmation = () => {
    setShowActionConfirmation(false)
    setActionType('')
  }

  const openSpaceBackgroundPhoto = () => setSelectedImage(space?.backgroundPhoto)
  const clearSelectedImage = () => setSelectedImage(null)

  const openCreateEntityDialog = (entity: string) => {
    if (entity === 'reminder') {
      setActionType('create_reminder')
      propertyStore.getPropertyEntities(params.pUuid, { types: ['buildings', 'spaces'] })
      userStore.getUsers()
    }
  }
  const toCreateReminder = () => openCreateEntityDialog('reminder')

  const closeEntityDialog = () => setActionType('')

  useEffect(() => {
    const pUuid = params?.pUuid || null
    const bUuid = params?.bUuid || null
    const sUuid = params?.sUuid || null
    if (pUuid && bUuid && sUuid && pUuid?.length === 36 && bUuid?.length === 36 && sUuid?.length === 36) {
      // spaceStore.getSpace(pUuid, bUuid, sUuid)
      if (isAdmin) {
        reminderStore.getPropertyReminders(params?.pUuid, { spaceUuid: sUuid })
      }
    }
  }, [])

  useEffect(() => {
    if (tab === SpaceTabs.BasicInformation) {
      setSearchParams({}, { replace: true })
      spaceStore.getSpace(params?.pUuid, params?.bUuid, params?.sUuid)
    } else if (tab === SpaceTabs.Floor) {
      setSearchParams({ tab: SpaceTabs.Floor }, { replace: true })
      spaceStore.getSpaceFloor(params?.pUuid, params?.bUuid, params?.sUuid)
    } else if (tab === SpaceTabs.Ceiling) {
      setSearchParams({ tab: SpaceTabs.Ceiling }, { replace: true })
      spaceStore.getSpaceCeiling(params?.pUuid, params?.bUuid, params?.sUuid)
    } else if (tab === SpaceTabs.Wall1) {
      setSearchParams({ tab: SpaceTabs.Wall1 }, { replace: true })
      spaceStore.getSpaceWall(params?.pUuid, params?.bUuid, params?.sUuid, 1)
    } else if (tab === SpaceTabs.Wall2) {
      setSearchParams({ tab: SpaceTabs.Wall2 }, { replace: true })
      spaceStore.getSpaceWall(params?.pUuid, params?.bUuid, params?.sUuid, 2)
    } else if (tab === SpaceTabs.Wall3) {
      setSearchParams({ tab: SpaceTabs.Wall3 }, { replace: true })
      spaceStore.getSpaceWall(params?.pUuid, params?.bUuid, params?.sUuid, 3)
    } else if (tab === SpaceTabs.Wall4) {
      setSearchParams({ tab: SpaceTabs.Wall4 }, { replace: true })
      spaceStore.getSpaceWall(params?.pUuid, params?.bUuid, params?.sUuid, 4)
    } else if (tab === SpaceTabs.Wall5) {
      setSearchParams({ tab: SpaceTabs.Wall5 }, { replace: true })
      spaceStore.getSpaceWall(params?.pUuid, params?.bUuid, params?.sUuid, 5)
    } else if (tab === SpaceTabs.Wall6) {
      setSearchParams({ tab: SpaceTabs.Wall6 }, { replace: true })
      spaceStore.getSpaceWall(params?.pUuid, params?.bUuid, params?.sUuid, 6)
    } else if (tab === SpaceTabs.Reminders) {
      if (searchParams?.tab !== SpaceTabs.Reminders) {
        setSearchParams({ tab: SpaceTabs.Reminders }, { replace: true })
      }
      reminderStore.getPropertyReminders(params?.pUuid, { spaceUuid: params?.sUuid })
    }
  }, [tab])

  useEffect(() => {
    const sUuid = params?.sUuid || null
    if (sUuid && sUuid?.length === 36) {
      if (space && space?.uuid === sUuid) {
        setNameFi(space?.nameFi)
        setArea(space?.area || '')
      }
    }
  }, [space])

  useEffect(() => {
    if (location?.search?.includes('tab')) {
      const selectedTab = location?.search?.split('tab=')?.[1] || ''
      if (selectedTab === SpaceTabs.Floor) {
        setTab(SpaceTabs.Floor)
      } else if (selectedTab === SpaceTabs.Ceiling) {
        setTab(SpaceTabs.Ceiling)
      } else if (selectedTab === SpaceTabs.Wall1) {
        setTab(SpaceTabs.Wall1)
      } else if (selectedTab === SpaceTabs.Wall2) {
        setTab(SpaceTabs.Wall2)
      } else if (selectedTab === SpaceTabs.Wall3) {
        setTab(SpaceTabs.Wall3)
      } else if (selectedTab === SpaceTabs.Wall4) {
        setTab(SpaceTabs.Wall4)
      } else if (selectedTab === SpaceTabs.Wall5) {
        setTab(SpaceTabs.Wall5)
      } else if (selectedTab === SpaceTabs.Wall6) {
        setTab(SpaceTabs.Wall6)
      } else if (selectedTab === SpaceTabs.Reminders) {
        setTab(SpaceTabs.Reminders)
      }
    }
  }, [location])

  useEffect(() => {
    if (reminderFilter !== 'upcoming') {
      reminderStore.getPropertyReminders(params?.pUuid, { all: true, spaceUuid: params?.sUuid })
    }
  }, [reminderFilter])

  const ReminderFilterOptions = [
    { value: 'upcoming', label: t('only_upcoming') },
    { value: 'past', label: t('only_past') },
    { value: 'archived', label: t('only_archived') },
    { value: 'all', label: t('all') }
  ]

  const AdminUserOptions = useMemo(() => {
    if (userStore?.users?.length) {
      return userStore?.users
        ?.filter((user: any) => user?.role === UserRoles.Admin)
        ?.map((user: any) => {
        return {
          value: user.uuid,
          label: `${user?.firstName} ${user?.lastName}`,
          email: user?.email || '',
          phone: user?.phone || ''
        }
      })
    }
    return []
  }, [userStore.users])

  const getReminderTargetEntityOptions = () => {
    return [{ value: 'buildings', label: t('buildings') }]
  }

  const getReminderTargetSubEntityOptions = () => {
    return [{
      value: params?.sUuid,
      parentValue: params?.bUuid,
      label: `${space?.building.nameFi} - ${space.nameFi}`
    }]
  }

  const getReminderTargetOptions = () => {
    return RoomSectionOptions.map((item: any) => ({ value: item.value, label: t(item.label) }))
  }

  const AdminReminderColumns = useMemo(() => [
    {
      field: 'actionTitle',
      headerName: t('title'),
      flex: 1,
      minWidth: 200,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={styles.name}
          text={t(params.row.actionTitle)}
          onClick={() => openReminder(params.row)}
          variant='text'
        />
      )
    },
    {
      field: 'target',
      headerName: t('property'),
      flex: 1,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <Chip text={params.row?.target ? t(params.row.target) : '-'} />
      }
    },
    {
      field: 'actionDate',
      headerName: t('action_date'),
      flex: 1,
      maxWidth: 320,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      valueFormatter: (value: string) => {
        if (value) {
          const datetime = DateTime.fromISO(value)
          return `${datetime.toFormat('dd.MM.yyyy')} klo ${datetime.toFormat('HH.mm')}`
        }
        return '-'
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      maxWidth: 170,
      headerClassName: 'kiinteistomuisti-table--header',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={styles.actions}>
          <IconButton
            icon='binAlt2'
            onClick={() => openArchiveReminderConfirmation(params.row)}
            iconSize='1.25rem'
            mr='1rem'
            transparentBg
          />
        </Box>
      )
    }
  ], [isAdmin])

  const ReminderColumns = useMemo(() => {
    return AdminReminderColumns.slice(0, 3)
  }, [isAdmin])

  const ReminderRows = useMemo(() => {
    const now = DateTime.now()
    if (reminderFilter === 'upcoming' || !reminderFilter) {
      // Filter out archived or past reminders
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        (DateTime.fromISO(reminder?.actionDate) > now ||
        reminder?.scheduledReminders?.some((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) > now
        }))
      })
    } else if (reminderFilter === 'past') {
      return reminderStore?.reminders?.filter((reminder: any) => {
        return !reminder?.archivedAt &&
        DateTime.fromISO(reminder?.actionDate) < now &&
        reminder?.scheduledReminders?.every((item: any) => {
          return DateTime.fromISO(item?.scheduledAt) < now
        })
      })
    } else if (reminderFilter === 'archived') {
      return reminderStore?.reminders?.filter((reminder: any) => reminder?.archivedAt)
    }
    return reminderStore?.reminders
  }, [reminderStore?.reminders, reminderFilter])

  const UpcomingReminderCount = useMemo(() => {
    return reminderStore?.reminders?.filter((reminder: any) => {
      return !reminder?.archivedAt &&
      DateTime.fromISO(reminder?.actionDate) > DateTime.now() &&
      reminder?.scheduledReminders?.some((item: any) => {
        return DateTime.fromISO(item?.scheduledAt) > DateTime.now()
      })
    })?.length
  }, [reminderStore?.reminders])

  const isSaveDisabled = () => {
    if (!nameFi) return true
    return false
  }

  const changeBackgroundPhoto = (file: any) => {
    spaceStore.updateSpaceBackgroundPhoto(params?.pUuid, params?.bUuid, file)
  }

  const save = () => {
    if (tab === SpaceTabs.BasicInformation || tab === SpaceTabs.Reminders) {
      const updatedSpace = {
        nameFi,
        area,
      }
      spaceStore.updateSpace(params?.pUuid, params?.bUuid, updatedSpace)
    } else {
      saveSpaceDataRef.current.onSave()
    }
    spaceStore.setHasUnsavedChanges(false)
  }

  const archive = () => {
    spaceStore.archiveSpace(
      params?.pUuid,
      params?.bUuid,
      () => {
        closeActionConfirmation()
        navigate(`/properties/${params?.pUuid}/buildings/${params?.bUuid}?tab=spaces`, { replace: true })
      }
    )
  }

  const createReminder = (newReminder: any) => {
    reminderStore.createPropertyReminder(
      params?.pUuid,
      newReminder,
      () => closeEntityDialog()
    )
  }
  const updateReminder = (updatedReminder: any) => {
    reminderStore.updatePropertyReminder(
      params?.pUuid,
      reminder?.uuid,
      updatedReminder,
      () => closeReminder()
    )
  }
  const archiveReminder = () => {
    reminderStore.archivePropertyReminder(
      params?.pUuid,
      actionTarget?.uuid,
      () => closeActionConfirmation()
    )
  }
  
  const updateSpaceSettings = (payload: any) => {
    spaceStore.updateSpaceSettings(
      params?.pUuid,
      params?.bUuid,
      payload,
      () => closeVisibleItems()
    )
  }

  const renderTabContent = () => {
    if (tab === SpaceTabs.BasicInformation) {
      return (
        <>
          <Box sx={styles.formContainer}>
            <Typography variant='h5' sx={styles.subtitle}>
              {t('basic_information')}
            </Typography>
            <Input
              label={t('space_name_or_identifier')}
              value={nameFi}
              onChange={setNameFi}
              mb='1.25rem'
              disabled={!isAdmin}
            />
            <Input
              label={t('area')}
              value={area}
              onChange={setArea}
              unit='m²'
              mb='1.25rem'
              disabled={!isAdmin}
            />
          </Box>
        </>
      )
    } else if (tab === SpaceTabs.Floor) {
      return <SpaceFloor pUuid={params?.pUuid} bUuid={params?.bUuid} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Ceiling) {
      return <SpaceCeiling pUuid={params?.pUuid} bUuid={params?.bUuid} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Wall1) {
      return <SpaceWall pUuid={params?.pUuid} bUuid={params?.bUuid} wallNumber={1} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Wall2) {
      return <SpaceWall pUuid={params?.pUuid} bUuid={params?.bUuid} wallNumber={2} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Wall3) {
      return <SpaceWall pUuid={params?.pUuid} bUuid={params?.bUuid} wallNumber={3} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Wall4) {
      return <SpaceWall pUuid={params?.pUuid} bUuid={params?.bUuid} wallNumber={4} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Wall5) {
      return <SpaceWall pUuid={params?.pUuid} bUuid={params?.bUuid} wallNumber={5} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Wall6) {
      return <SpaceWall pUuid={params?.pUuid} bUuid={params?.bUuid} wallNumber={6} saveRef={saveSpaceDataRef} />
    } else if (tab === SpaceTabs.Reminders) {
      return (
        <>
          <TableActions
            sort={reminderFilter}
            sortPlaceholder={t('view_all')}
            sortOptions={ReminderFilterOptions}
            onSortSelect={setReminderFilter}
            onAddClick={isAdmin ? toCreateReminder : undefined}
          />
          <Table
            rows={ReminderRows}
            columns={isAdmin ? AdminReminderColumns : ReminderColumns}
            loading={loading}
            hideCheckboxSelection
          />
        </>
      )
    }
    return null
  }

  const renderLightbox = () => {
    if (space?.backgroundPhoto && selectedImage) {
      return (
        <Lightbox
          open={selectedImage}
          onClose={clearSelectedImage}
          index={0}
          images={[{ src: space?.backgroundPhoto }]}
        />
      )
    }
    return null
  }

  const renderRightColumnContent = () => {
    if (isAdmin) {
      return (
        <>
          <SidebarActions
            updatedAt={space?.updatedAt || space?.createdAt || ''}
            archivedAt={space?.archivedAt}
            onSave={save}
            onArchive={openArchiveSpaceConfirmation}
            saveDisabled={isSaveDisabled()}
          />
          <SidebarReminders
            updatedAt={reminderStore?.updatedAt || ''}
            count={UpcomingReminderCount}
            onAdd={toCreateReminder}
            onView={openRemindersTab}
            onToggleOpen={toggleOpenReminders}
            isOpen={remindersOpen}
          />
          <SidebarFormSettings
            updatedAt={space?.settingsUpdatedAt || ''}
            isOpen={formSettingsOpen}
            onToggleOpen={toggleOpenFormSettings}
            onView={openVisibleItems}
          />
        </>
      )
    }
    return null
  }

  const renderEntityDialog = () => {
    if (actionType === 'create_reminder' || reminder) {
      return (
        <ReminderModal
          mode={reminder ? 'edit' : 'create'}
          property={space?.building?.property}
          space={space}
          onClose={reminder ? closeReminder : closeEntityDialog}
          onSave={reminder ? updateReminder : createReminder}
          users={AdminUserOptions}
          getTargetEntityOptions={getReminderTargetEntityOptions}
          getTargetSubEntityOptions={getReminderTargetSubEntityOptions}
          getTargetOptions={getReminderTargetOptions}
          reminder={reminder}
          disabled={!isAdmin}
        />
      )
    }
    return null
  }

  const renderVisibleItemsModal = () => {
    if (visibleItemsOpen) {
      return (
        <RoomVisibleItemsModal
          onClose={closeVisibleItems}
          room={space}
          onSave={updateSpaceSettings}
          type='space'
        />
      )
    }
    return null
  }


  const renderActionConfirmation = () => {
    if (showActionConfirmation) {
      if (actionType === 'archive_space') {
        return (
          <ConfirmationDialog
            title={t('archive_space_title')}
            content={t('archive_space_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archive}
            actionText={t('archive')}
          />
        )
      } else if (actionType === 'archive_reminder') {
        return (
          <ConfirmationDialog
            title={t('archive_reminder_title')}
            content={t('archive_reminder_info')}
            onClose={closeActionConfirmation}
            cancelText={t('cancel')}
            onAction={archiveReminder}
            actionText={t('archive')}
          />
        )
      }
    }
    return null
  }
  
  if (
    !space ||
    !params?.pUuid ||
    !params?.bUuid ||
    !params?.sUuid ||
    params.pUuid?.length !== 36 ||
    params.bUuid?.length !== 36 ||
    params.sUuid?.length !== 36
  ) {
    return null
  }

  return (
    <Box sx={styles.container}>
      <CoverImage
        bgPhotoUrl={space?.backgroundPhoto}
        onUpdateBackgroundPhoto={isAdmin ? changeBackgroundPhoto : undefined}
        onClick={space?.backgroundPhoto ? openSpaceBackgroundPhoto : null}
      />
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Typography variant='h2' color={Colors.primary} mt='1rem'>
            {space?.nameFi || ''}
            <Box component='span' textTransform='lowercase' fontWeight={500}>
              {space?.area ? ` (${space?.area}m²)` : ''}
            </Box>
          </Typography>
          <Box sx={styles.infoRow}>
            <Chip
              text={space?.building?.property?.nameFi || ''}
              href={`/properties/${params?.pUuid}`}
            />
            <Box sx={styles.dot} />
            <Chip
              text={space?.building?.nameFi || ''}
              href={`/properties/${params?.pUuid}/buildings/${params?.bUuid}`}
            />
          </Box>
          <Tabs mb={[SpaceTabs.BasicInformation, SpaceTabs.Reminders]?.includes(tab) ? '2rem' : '1rem'}>
            <Tab
              value={SpaceTabs.BasicInformation}
              text={t(SpaceTabs.BasicInformation)}
              selected={tab === SpaceTabs.BasicInformation}
              onClick={updateTab}
              first
            />
            <Tab
              value={SpaceTabs.Floor}
              text={t(SpaceTabs.Floor)}
              selected={tab === SpaceTabs.Floor}
              onClick={updateTab}
              hidden={space?.floorHidden}
            />
            <Tab
              value={SpaceTabs.Ceiling}
              text={t(SpaceTabs.Ceiling)}
              selected={tab === SpaceTabs.Ceiling}
              onClick={updateTab}
              hidden={space?.ceilingHidden}
            />
            <Tab
              value={SpaceTabs.Wall1}
              text={t(SpaceTabs.Wall1)}
              selected={tab === SpaceTabs.Wall1}
              onClick={updateTab}
              hidden={space?.wall1Hidden}
            />
            <Tab
              value={SpaceTabs.Wall2}
              text={t(SpaceTabs.Wall2)}
              selected={tab === SpaceTabs.Wall2}
              onClick={updateTab}
              hidden={space?.wall2Hidden}
            />
            <Tab
              value={SpaceTabs.Wall3}
              text={t(SpaceTabs.Wall3)}
              selected={tab === SpaceTabs.Wall3}
              onClick={updateTab}
              hidden={space?.wall3Hidden}
            />
            <Tab
              value={SpaceTabs.Wall4}
              text={t(SpaceTabs.Wall4)}
              selected={tab === SpaceTabs.Wall4}
              onClick={updateTab}
              hidden={space?.wall4Hidden}
            />
            <Tab
              value={SpaceTabs.Wall5}
              text={t(SpaceTabs.Wall5)}
              selected={tab === SpaceTabs.Wall5}
              onClick={updateTab}
              hidden={space?.wall5Hidden}
            />
            <Tab
              value={SpaceTabs.Wall6}
              text={t(SpaceTabs.Wall6)}
              selected={tab === SpaceTabs.Wall6}
              onClick={updateTab}
              hidden={space?.wall6Hidden}
            />
            <Tab
              value={SpaceTabs.Reminders}
              text={t(SpaceTabs.Reminders)}
              selected={tab === SpaceTabs.Reminders}
              onClick={updateTab}
            />
          </Tabs>
          {renderTabContent()}
        </Box>
        <Box sx={styles.rightColumn}>
          {renderRightColumnContent()}
        </Box>
      </Box>
      {renderLightbox()}
      {renderEntityDialog()}
      {renderVisibleItemsModal()}
      {renderActionConfirmation()}
      <NavigationGuard
        hasUnsavedChanges={hasUnsavedChanges}
        forceShow={showHasUnsavedChanges}
        onHide={hideShowHasUnsavedChanges}
        onSave={save}
      />
    </Box>
  )
}

export default observer(Space)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 0',
    mb: '1rem'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  column: {
    flexDirection: 'column',
    width: '100%'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(50% - 1rem)',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem'
  },
  infoLabel: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: Colors.heading,
    textTransform: 'uppercase',
    mb: '0.5rem'
  },
  dot: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    display: 'inline-block',
    marginLeft: '0.5rem',
    marginRight: '0.75rem'
  },
  name: {
    minWidth: 0,
    width: '100%',
    height: '2.25rem',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.text,
    justifyContent: 'flex-start',
    textTransform: 'none',
    pl: 0,
    ':hover': {
      backgroundColor: Colors.transparent,
      transition: 'font-weight 0.3s ease-in-out'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.secondary10,
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: {
      lgplus: '2rem 2rem 0.5rem 2rem',
      xs: '1.625rem 1.5rem 0.125rem 1.5rem'
    },
    mb: '1.5rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    mb: '1.5rem'
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '20rem',
    borderRadius: '0.3125rem',
    border: `1px solid ${Colors.border}`,
    p: '1.5rem 1.5rem 1rem 1.5rem'
  },
  subtitle: {
    alignSelf: 'flex-start',
    mb: '1.25rem',
    textTransform: 'uppercase'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingRight: '1rem'
  },
  SpacePartTabsContainer: {
    height: '3.5rem',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.primary,
    color: Colors.black,
    border: `1px solid ${Colors.border}`,
    borderBottomLeftRadius: '0.625rem',
    borderBottomRightRadius: '0.625rem'
  },
  SpacePartTab: {
    height: '3.5rem',
    borderRight: `1px solid ${Colors.border}`,
    fontFamily: Fonts.body,
    fontSize: '1rem',
    fontWeight: 600,
    color: Colors.white90,
    textTransform: 'uppercase',
    transition: 'background-color 0.3s',
    padding: {
      xl: '0 1.5rem',
      xs: '0 1rem'
    },
    '&:hover': {
      color: Colors.white,
      backgroundColor: Colors.primary90
    }
  },
  firstTab: {
    borderBottomLeftRadius: '0.625rem'
  },
  selectedTab: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    '&:hover': {
      backgroundColor: Colors.primary90
    }
  },
  addButton: {
    width: '4rem',
    height: '3.5rem',
    borderLeft: `1px solid ${Colors.border}`,
    '&:hover': {
      backgroundColor: Colors.secondary20
    }
  },
  addIcon: {
    maxWidth: '1.5rem'
  },
} as const
