import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import uniq from 'lodash/uniq'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Select from '../../../Components/Common/Select'
import SummaryEntitySelect from '../../../Components/Common/SummaryEntitySelect'
import SidebarSummaryActions from '../../../Components/Common/SidebarSummaryActions'
import {
  SummarySectionPropertyItemsMappings,
  SummarySectionBuildingItemsMappings,
  SummarySectionApartmentItemsMappings
} from '../../../Utils/summary'
import { composeSummaryTemplateQueryString, parseSummaryTemplateQueryString } from '../../../Utils/transformers'

const SummarySettings = () => {
  const {
    sessionStore,
    propertyStore,
  }: any = useStore()
  const { isAdmin } = sessionStore
  const { propertySummary, summaryTemplate, summaryTemplates } = propertyStore
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    const uuid = params?.pUuid || null
    if (uuid && uuid?.length === 36) {
      if (location?.search?.includes('template')) {
        const selectedTemplate = location?.search?.split('template=')?.[1] || ''
        if (selectedTemplate && selectedTemplate?.length === 36) {
          propertyStore.getPropertySummary(uuid, selectedTemplate)
        } else {
          propertyStore.getPropertySummary(uuid)
        }
      } else {
        const query = parseSummaryTemplateQueryString(location?.search)
        propertyStore.getPropertySummary(uuid, null, query)
      }
    }
  }, [])

  const onSelectTemplate = (tUuid: string) => {
    const template = summaryTemplates?.find((t: any) => t.uuid === tUuid)
    if (template) {
      propertyStore.setSummaryTemplate(template)
    }
  }

  const toSummary = () => {
    if (summaryTemplate?.uuid && !hasChanges) {
      navigate(`/properties/${params?.pUuid}/summary?template=${summaryTemplate?.uuid}`)
    } else {
      const queryString = composeSummaryTemplateQueryString(summaryTemplate)
      navigate(`/properties/${params?.pUuid}/summary?${queryString}`.trim())
    }
  }

  const onHiddenChange = (section: string, itemKeys: string[], isHidden: boolean) => {
    if (isHidden) {
      if (section === 'property') {
        propertyStore.setSummaryTemplate({
          ...summaryTemplate,
          propertyHiddenItems: uniq([...summaryTemplate?.propertyHiddenItems, ...itemKeys])
        })
      } else if (section === 'buildings') {
        propertyStore.setSummaryTemplate({
          ...summaryTemplate,
          buildingHiddenItems: uniq([...summaryTemplate?.buildingHiddenItems, ...itemKeys])
        })
      } else if (section === 'apartments') {
        propertyStore.setSummaryTemplate({
          ...summaryTemplate,
          apartmentHiddenItems: uniq([...summaryTemplate?.apartmentHiddenItems, ...itemKeys])
        })
      }
    } else {
      if (section === 'property') {
        propertyStore.setSummaryTemplate({
          ...summaryTemplate,
          propertyHiddenItems: summaryTemplate
            ?.propertyHiddenItems
            ?.filter((item: string) => !itemKeys.includes(item))
        })
      } else if (section === 'buildings') {
        propertyStore.setSummaryTemplate({
          ...summaryTemplate,
          buildingHiddenItems: summaryTemplate
            ?.buildingHiddenItems
            ?.filter((item: string) => !itemKeys.includes(item))
        })
      } else if (section === 'apartments') {
        propertyStore.setSummaryTemplate({
          ...summaryTemplate,
          apartmentHiddenItems: summaryTemplate
            ?.apartmentHiddenItems
            ?.filter((item: string) => !itemKeys.includes(item))
        })
      }
    }
    if (!hasChanges) {
      setHasChanges(true)
    }
  }

  const onReorderItems = (result: any) => {
    const { source, destination, type } = result
  
    if (!destination || !source || destination?.index === source?.index) {
      return
    }
  
    if (type !== 'ITEM') {
      // Reorder sections
      const section = result?.draggableId?.split('-')?.[0]
      const newItems = Array.from(
        section === 'property' ?
          summaryTemplate?.propertyCategoryOrder :
          section === 'buildings' ?
          summaryTemplate?.buildingCategoryOrder :
          summaryTemplate?.apartmentCategoryOrder
      )
      const [removed] = newItems.splice(source.index, 1)
      newItems.splice(destination.index, 0, removed)
      if (section) {
        if (section === 'property') {
          propertyStore.setSummaryTemplate({
            ...summaryTemplate,
            propertyCategoryOrder: newItems
          })
        } else if (section === 'buildings') {
          propertyStore.setSummaryTemplate({
            ...summaryTemplate,
            buildingCategoryOrder: newItems
          })
        } else if (section === 'apartments') {
          propertyStore.setSummaryTemplate({
            ...summaryTemplate,
            apartmentCategoryOrder: newItems
          })
        }
      }
    } else {
      // Reorder items within a section
      // const sectionId = source?.droppableId?.split('-')?.[0]
      // const subsectionId = source?.droppableId?.split('-')?.[1]
      const orderKey = source?.droppableId?.split('-')?.[2]
      // const itemId = result?.draggableId
      const orderedItems = Array.from(summaryTemplate?.[orderKey])
      const [removed] = orderedItems.splice(source.index, 1)
      orderedItems.splice(destination.index, 0, removed)

      propertyStore.setSummaryTemplate({
        ...summaryTemplate,
        [orderKey]: orderedItems
      })
    }
    if (!hasChanges) {
      setHasChanges(true)
    }
  }

  const onTemplateSave = (action: string, templateName: string) => {
    if (!action || !templateName) {
      return
    }
    if (action === 'update') {
      if (summaryTemplate?.uuid) {
        propertyStore.updatePropertySummaryTemplate(
          params?.pUuid,
          summaryTemplate?.uuid,
          { name: templateName }
        )
      } 
    } else {
      propertyStore.createPropertySummaryTemplate(
        params?.pUuid,
        { name: templateName }
      )
    }
    setHasChanges(false)
  }

  if (!propertySummary || !summaryTemplate) {
    return (
      <Box sx={styles.container}>
        <CircularProgress sx={styles.loading} />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.leftColumn}>
          <Box sx={styles.titleRow} mt={summaryTemplates?.length ? '0rem' : '1rem'}>
            <Typography variant='h1' sx={styles.title}>
              {t('summary_information')}
            </Typography>
            {summaryTemplates.length ? (
              <Select
                options={summaryTemplates?.map((t: any) => ({
                  label: t?.name,
                  value: t?.uuid
                })) || []}
                placeholder={t('download_template')}
                value={summaryTemplate?.uuid}
                onChange={onSelectTemplate}
                sx={styles.templateSelect}
                inputSx={styles.templateSelectInput}
                disableClearable
              />
            ) : null}
          </Box>
          <SummaryEntitySelect
            section='property'
            items={
              propertyStore
              ?.summaryTemplate
              ?.propertyCategoryOrder
              ?.map((key: string) => {
                const item = SummarySectionPropertyItemsMappings?.find((m: any) => m.key === key)
                return {
                  ...item,
                  items: propertyStore?.summaryTemplate?.[item?.orderKey] || []
                }
              })
            }
            onReorder={onReorderItems}
            hiddenItems={summaryTemplate?.propertyHiddenItems}
            onHiddenChange={onHiddenChange}
          />
          <SummaryEntitySelect
            section='buildings'
            items={
              propertyStore
              ?.summaryTemplate
              ?.buildingCategoryOrder
              ?.map((key: string) => {
                const item = SummarySectionBuildingItemsMappings({ propertyType: propertySummary?.type })?.find((m: any) => m.key === key)
                if (!item) {
                  return null
                }
                return {
                  ...item,
                  items: propertyStore?.summaryTemplate?.[item?.orderKey] || []
                }
              })
            }
            onReorder={onReorderItems}
            hiddenItems={summaryTemplate?.buildingHiddenItems}
            onHiddenChange={onHiddenChange}
          />
          <SummaryEntitySelect
            section='apartments'
            items={
              propertyStore
              ?.summaryTemplate
              ?.apartmentCategoryOrder
              ?.map((key: string) => {
                const item = SummarySectionApartmentItemsMappings({ propertyType: propertySummary?.type })?.find((m: any) => m.key === key)
                if (!item) {
                  return null
                }
                return {
                  ...item,
                  items: propertyStore?.summaryTemplate?.[item?.orderKey] || []
                }
              })
            }
            onReorder={onReorderItems}
            hiddenItems={summaryTemplate?.apartmentHiddenItems}
            onHiddenChange={onHiddenChange}
            altSection={propertySummary?.type === 'housing' ? 'apartments' : 'spaces'}
          />
        </Box>
        <Box sx={styles.rightColumn}>
          <SidebarSummaryActions
            templates={summaryTemplates}
            selectedTemplate={summaryTemplate}
            openSummary={toSummary}
            onSave={onTemplateSave}
            isAdmin={isAdmin}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default observer(SummarySettings)

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '20rem',
    overflowY: 'scroll',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // height: '100vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    marginRight: {
      xl: '3rem',
      lgplus: '2.5rem',
      xs: '2rem'
    }
  },
  rightColumn: {
    position: 'sticky',
    height: {
      lg: 'calc(100vh - 10rem)',
      xs: 'calc(100vh - 8.5rem)'
    }
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 800,
  },
  templateSelect: {
    width: '16rem',
  },
  templateSelectInput: {
    fontSize: '1rem',
  },
  loading: {
    position: 'absolute',
    top: '49%',
    alignSelf: 'center',
  },
} as const
