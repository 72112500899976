import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DefaultCoverPhoto from '../../Assets/Images/cover.png'
import { Colors } from '../../Utils/theme'

export default function SummaryBackgroundImage(props: any) {
  const { url, title1, title2, subtitle } = props

  return (
    <Box sx={styles.container} mt={props?.mt || '0rem'}>
      <Box
        sx={styles.photo}
        component='img'
        src={url|| DefaultCoverPhoto}
        onClick={props?.onClick}
        draggable={false}
      />
      <Box sx={styles.gradient} />
      <Box sx={styles.textContainer}>
        <Typography sx={styles.title1}>{title1}</Typography>
        <Typography sx={styles.title2}>{title2}</Typography>
        <Typography sx={styles.subtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    '@media print': {
      pageBreakInside: 'avoid',
      mt: '0rem !important',
    },
  },
  photo: {
    width: '100%',
    objectFit: 'cover',
    userSelect: 'none',
    height: {
      xs: '22rem'
    },
    '@media print': {
      height: '20rem'
    },
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '70%',
    background: 'linear-gradient(270deg, rgba(0, 75, 89, 0) 0%, rgba(0, 75, 89, 0.9) 76.5%)'
  },
  textContainer: {
    position: 'absolute',
    bottom: '1.375rem',
    left: '1.75rem',
  },
  title1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.white,
    userSelect: 'none',
  },
  title2: {
    fontSize: '2rem',
    fontWeight: 700,
    color: Colors.white,
    userSelect: 'none',
  },
  subtitle: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.lightWhite,
    userSelect: 'none',
  },
} as const