import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Colors } from '../../Utils/theme'

export default function SummaryItem(props: any) {
  const { label, value, extra } = props

  return (
    <Box sx={styles.container} mb={props?.mb || '0.75rem'}>
      {extra?.hideLabel ? null : <Typography sx={styles.label}>{label}</Typography>}
      {value?.startsWith('https://') ? (
        <Link
          href={value}
          target='_blank'
          rel='noopener noreferrer'
          sx={styles.linkValue}
        >
          Avaa
        </Link>
      ) : (
        <Typography sx={styles.value}>
          {extra?.group ? (
            <Box component='span' sx={styles.groupValue}>{extra.group}: </Box>
            ) : null}
          {value || '-'}
        </Typography>
      )}
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    pr: '0.6125rem',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  label: {
    fontSize: '0.938rem',
    color: Colors.primaryDarkest,
    fontWeight: 700,
    pb: '0.25rem',
  },
  groupValue: {
    color: Colors.primary,
    fontWeight: 400,
    fontStyle: 'italic',
  },
  value: {
    fontSize: '1rem',
    color: Colors.text,
    fontWeight: 400,
  },
  linkValue: {
    color: Colors.button,
    fontSize: '1rem',
    fontWeight: 600,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
} as const