import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import UploadIcon from '../../Assets/Icons/file-upload.svg'
import { Colors } from '../../Utils/theme'

export default function SimpleFileUpload(props: any) {
  const onUploadFiles = useCallback(async (files: any) => {
    if (files && files?.length) {
      props.onUploadFiles(files)
    }
  }, [])

  const drop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'text/plain': ['.txt']
    },
    multiple: true,
    noDrag: true,
    noClick: false,
    noKeyboard: true,
    onDrop: onUploadFiles
  })

  return (
    <Box sx={styles.container} {...drop.getRootProps()}>
      <input {...drop.getInputProps()} />
      <Box component='img' src={UploadIcon} sx={styles.icon} />
    </Box>
  )
}

const styles = {
  container: {
    minWidth: '11rem',
    height: '11rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    borderRadius: '0.625rem',
    border: `3px dashed ${Colors.border}`,
    p: '1.125rem',
    transition: 'border 0.3s',
    '&:hover': {
      cursor: 'pointer',
      border: `3px dashed ${Colors.buttonHover}`
    }
  },
  icon: {
    height: '5rem',
    width: 'auto',
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserDrag: 'none',
  }
} as const
