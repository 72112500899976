import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../Models/RootStore'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import Button from '../Common/Button'
import { Colors } from '../../Utils/theme'

const FileUploadButton = (props: any) => {
  const { archiveStore }: any = useStore()

  const { t } = useTranslation()

  const { entity, category, field, onUploadReady } = props

  const onUploadFile = useCallback(async (files: any) => {
    if (files && files?.length) {
      if (entity === 'building') {
        const url = await archiveStore.uploadBuildingSystemFile(files[0], category, field)
        onUploadReady(url || '')
      }
    }
  }, [entity, category, field])

  const buttonDrop = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'text/plain': ['.txt']
    },
    multiple: false,
    noDrag: true,
    noClick: false,
    noKeyboard: true,
    onDrop: onUploadFile
  })

  return (
    <Box {...buttonDrop.getRootProps()}>
      <input {...buttonDrop.getInputProps()} />
      <Button
        sx={styles.button}
        text={t('upload')}
        onClick={() => {}}
        variant='secondary'
      />
    </Box>
  )
}

export default observer(FileUploadButton)

const styles = {
  button: {
    height: '3.125rem',
    fontSize: '1rem',
    fontWeight: 700,
    minWidth: '9rem',
    color: Colors.text,
    textTransform: 'uppercase',
    mt: '0.625rem',
    border: `3px solid ${Colors.button}`,
    '&:hover': {
      backgroundColor: Colors.buttonHoverLight
    }
  },
} as const
